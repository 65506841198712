import { initializeApp } from 'firebase/app';
import { getMessaging,getToken, onMessage } from "firebase/messaging";
import store from '../utils/store';
import {getDatabase} from "firebase/database"

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console. 
const firebaseConfig = {
    apiKey: "AIzaSyDSDOuxussYdzTpC38NdAQXgeMenH6STb0",
    authDomain: "uverlist-1555022012941.firebaseapp.com",
    databaseURL: "https://uverlist-1555022012941.firebaseio.com",
    projectId: "uverlist-1555022012941",
    storageBucket: "uverlist-1555022012941.appspot.com",
    messagingSenderId: "1014674398298",
    appId: "1:1014674398298:web:fdbd905eef032e18448c63"
  };

export const app = initializeApp(firebaseConfig);
const messaging = getMessaging();

export const requestForToken = () => {
    return getToken(messaging, { vapidKey: 'BJ06bCn922qtC82DAyOb_E5BBz_NPOXiHwQIDjoFO5xD4wxVixx2XLeF_afcIvs1BoboaMuNb8Twe2TS8TxSsks' })
      .then((currentToken) => {
        if (currentToken) {
          console.log('current token for client: ////////////', currentToken);
          store.storeNotificationToken(currentToken);
          // localStorage.setItem('notification-token', currentToken);
          // Perform any other neccessary action with the token
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        // localStorage.setItem('notification-token.................',);
        console.log('An error occurred while retrieving token. sandip ', err);
      });
  };
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log(payload)
      resolve(payload);
    });
});






