import React, { useEffect } from 'react';
import { WhatsappShareButton, WhatsappIcon } from 'react-share';

const Whatsapp = ({ url, quotes, hashtag }) => {
    // useEffect(()=>{
    //    console.log(url,"::::::::::::::::::::::::")
    // },[])
    return (
        <>
            <WhatsappShareButton
                // title={quotes}
                url={url}
                // quotes={quotes}
                // hashtag={hashtag}
            >
                <WhatsappIcon size={50} borderRadius={5} />
            </WhatsappShareButton>
        </>
    )
}

export default Whatsapp;
