
import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Form, Image, Nav, Tab, Dropdown, DropdownButton, Card, Modal, Tabs } from 'react-bootstrap';
import closeicon from '../../assets/images/close-icon.svg';

import StripeCheckout from 'react-stripe-checkout';
import axios from 'axios';
import { PAY_KEY } from "../../config/payment";
import { getpaymentDetails, getCardList  } from "../../api/payment";
import { addNewAds } from "../../api/ads";

const ProceedModal = ({ user, show,setShow, handleClose,form,mediaElement,navigate,displayModal,price,setIsLoading }) => {
    // const [price,setPrice] = useState(0);
    const [CardList, setCardList] = useState(false);
    const GET_Card_LIST = async () => {
        try {
            const res = await getCardList();
            setCardList(res.data)
        } catch (error) {
            console.log(error)
        }
    }


    const publishableKey = PAY_KEY;

    // const jodId = jobData._id;
    // const jobamount  = 500;
    // const priceForStripe = 100;


    // const handleSuccess = () => {

    //     console.log("success");
    // };
    // const handleFailure = () => {
    //     console.log("fail");
    // };
    const payNow = async card_token => {
        // console.log(token)card_token
        setIsLoading(true);
        handleClose(true);
        const res = await addNewAds({...form,card_token});
        if(res.ok){
            setIsLoading(false);
            displayModal('Success', res.data.message);
            navigate('/CreateManageAds')
        }else{
            setIsLoading(false);
        if (!res.ok) return displayModal('Error', res.data.message);
        if (!res.data.success) return displayModal('Error', res.data.message);
        }
        // try {
        //     const compleatejob = {
        //         "job_id":jobData?._id,
        //         "tips":tips,
        //         "card_token":token.id
        //     };
        //     const response = await getpaymentDetails(compleatejob);
        //     if(response.ok){
        //         setShow(false)
        //         setShowRetingModal(true)
        //         GET_JOB_DETAILS()
        //     }
        // } catch (error) {
        //    console.log(error)
        // }
    };
    const payNowModal = async e =>{
        e.preventDefault();
            setIsLoading(true);
            handleClose(true);
            const res = await addNewAds({...form,profileimage:mediaElement,file_type: mediaElement.includes('data:image/')?'image':'viedo'});
            if(res.ok){
                setIsLoading(false);
                displayModal('Success', res.data.message);
                navigate('/CreateManageAds')
            }else{
                setIsLoading(false);
            if (!res.ok) return displayModal('Error', res.data.message);
            if (!res.data.success) return displayModal('Error', res.data.message);
            }
    }
    useEffect(() => {
        GET_Card_LIST()
    }, [])
    return (
        <Modal show={show}>
            <Modal.Body className='px-5' >
                <Button variant="primary" className='btn-green closeicon-btn' onClick={handleClose}>
                    <Image src={closeicon} alt="" />
                </Button>
                <Row>
                    <Col md={12}>

                        <div className="">
                            <Row>
                                <Col md={12} className='text-center'>
                                    <h3>
                                      Advertisment Created Successfully
                                    </h3>
                                    <p className='mb-3'>
                                    Please complete payment for processing
                                    </p>
                                    <h3 className='mb-3'>
                                        
                                    </h3>
                                </Col>
                                {CardList?.code != 500 &&
                                <>
                                    <Col md={12} className='my-3'>
                                        
                                    <Form className='contact-us' onSubmit={e=>payNowModal(e)}>
                                        <Row>
                                            <Form.Group className="mb-3 col-md-12" controlId="customer">
                                                <Form.Control type="text" hidden  name='' defaultValue={CardList?.data?.customer} readOnly placeholder="customer" />
                                            </Form.Group>
                                            <Form.Group className="mb-3 col-md-12" controlId="cardNumber">
                                            <h4 className='from-control border p-2 rounded' >**** **** **** {CardList?.data?.last4}</h4>
                                               
                                            </Form.Group>
                                            {/* <Form.Group className="mb-3 col-md-6" controlId="exmonyear">
                                                <Form.Control type="text" name='' readOnly defaultValue={CardList?.data?.exp_month + '/' + CardList?.data?.exp_year} placeholder="exp month and year" />
                                            </Form.Group>
                                            <Form.Group className="mb-3 col-md-6" controlId="yourcvv">
                                                <Form.Control type="number" name=''  placeholder="Enter Your CVV" />
                                            </Form.Group> */}
                                        </Row>
                                        <Button variant="primary" className='btn-block w-100' type="submit">
                                            Pay Total- ${price}
                                        </Button>
                                    </Form>
                                </Col>
                                <Col className='text-center'>
                                <StripeCheckout
                                stripeKey={publishableKey}
                                label="If you want to pay other card click here"
                                billingAddress
                                currency="USD"
                                amount={price*100}
                                description={`Your total is USD${price}`}
                                token={payNow}
                                email={user?.email}
                                />
                            </Col>
                            </>
                        }
                        {CardList?.code == 500 &&
                              

                              <Card className='acount-info radius-12 p-5 text-center'>
                                  <h3>
                                      {CardList?.message}
                                  </h3>
                                  <StripeCheckout
                                stripeKey={publishableKey}
                                label="Pay Now "
                                billingAddress
                                currency="USD"
                                amount={price*100}
                                // amount={form.get("budget")*100}
                                description={`Your total is USD${price}`}
                                token={payNow}
                                email={user.email}
                                />
                              </Card>
                          
                      }
                            </Row>
                            
                            
                        </div>
                    </Col>

                </Row>

            </Modal.Body>
        </Modal>
    )
}



export default ProceedModal;