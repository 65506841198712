import React, { useState, useEffect } from "react";
import './Style.css'
import { Button, Row, Col, Form, Image, Card } from 'react-bootstrap';
import error from '../../assets/images/erroer-page.png'
const ErrorPage = () => {
    return (
        <section className="inner page error py-5">
            <div className="container">
                <Row className="">
                    <Col md={12} className="text-center mb-5">
                        <Image src={error} />
                        <h1>
                        Page Not Found
                        </h1>
                        <p >
                        The page you are looking for moved might never exsted
                        </p>

                    </Col>
                </Row>
            </div>
        </section>
    )
}
export default ErrorPage;