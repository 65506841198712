const store = {};

store.storeUser = (user) => {
    try {
        if (!user) return;
        localStorage.setItem('uverlist-user-data', JSON.stringify(user))
    } catch (err) {
        console.error(err);
    }
}

store.getUser = async () => {
    try {
        let user = await localStorage.getItem('uverlist-user-data')
        if (!user) return null;
        return JSON.parse(user);
    } catch (err) {
        console.error(err);
    }
}

store.storeUserToken = (token) => {
    try {
        if (!token) return;
        localStorage.setItem('uverlist-user-token', token)
    } catch (err) {
        console.error(err);
    }
}

store.getUserToken = async () => {
    try {
        let token = await localStorage.getItem('uverlist-user-token')
        if (!token) return null;
        return token;
    } catch (err) {
        console.error(err);
    }
}

store.clearUserData = async () => {
    try {
        await localStorage.removeItem('uverlist-user-token')
        await localStorage.removeItem('uverlist-user-data')
    } catch (err) {
        console.error(err);
    }
}

store.isTokenExist = async () => {
    try {
        const isExist = await localStorage.getItem('uverlist-user-token')
        if (!isExist) return false;
        return true;
    } catch (err) {
        console.error(err);
    }
}

store.storeLastEventKey = (eventkey) => localStorage.setItem(`uverlist-last-event-key`, eventkey);

store.getLastEventKey = () => localStorage.getItem(`uverlist-last-event-key`);

store.storeNotificationToken = (token) => {
    try {
        if (!token) return;
        localStorage.setItem('device_token', token)
    } catch (err) {
        console.error(err);
    }
}
store.getNotificationToken = async () => {
    try {
        let token = await localStorage.getItem('device_token')
        if (!token) return null;
        return token;
    } catch (err) {
        console.error(err);
    }
}

export default store;