import React, { useState, useEffect,useContext } from "react";
import { Button, Row, Col, Form, Image } from 'react-bootstrap';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import fr from '../../assets/images/frimage.png';
// API
import { forgotPassword } from "../../api/auth";
import {Context} from '../../components/Context'

// utils
// import store from '../../utils/store';

const ForgotPassword = ({ setIsUpdateUser, setIsLoggedIn, displayModal }) => {
	const navigate = useNavigate();
	const {isLoading,setIsLoading} = useContext(Context);

	const FORGOT_PASSWORD = async (e)=>{
		e.preventDefault();
		try {
			const form = new FormData(e.target);
			const data = {
				email:form.get('email')
			}
			setIsLoading(true);
			const res = await forgotPassword(data);
			if(res.ok){
				setIsLoading(false);
				navigate('/signin')
				return displayModal('Success',res.data.message)
			}else{
				setIsLoading(false);
				return displayModal('Error',res.data.message)
			}
		} catch (err) {
			console.error(err);
		}
	}

	return (
           <>
			<section className="signuplogin  contact-us page py-5">
				<div className="container">
					<div className="si-re">
						<div className="sire-left f-color-w">
                        <Image src={fr} className="img-fluid" />
						</div>
						<div className="sire-right f-color-w">
							<Form onSubmit={FORGOT_PASSWORD}>
								<h2 className="sl-su-title">
                                    Forgot Password
								</h2>
								<p className="mb-5">
									Enter your email address
								</p>
								<Form.Group className="mb-3" controlId="Email">
									<Form.Control type="text" placeholder="Email" autoFocus={true} name='email' />
								</Form.Group>
                                <Form.Group className="mb-3 text-center">
                                    <Button variant="primary" type="submit">
                                        Continue
                                    </Button>
                                </Form.Group>		
							</Form>
							<p className="text-center mt-4">Don’t have an account? <NavLink to="/SignUp">Sign Up</NavLink></p>

						</div>
					</div>
				</div>

			</section>
		</>
	);
};

export default ForgotPassword;
