import React, { useState, useEffect } from "react";
import moment from "moment";
import send from '../../../assets/images/send.svg';
import link from '../../../assets/images/link-chain.png';
import mic from '../../../assets/images/mic.png';
import stop from '../../../assets/images/stop.png';
import camera from '../../../assets/images/camera.png'
import profilepic1 from '../../../assets/images/pp1.jpg'
import userpic1 from '../../../assets/images/pp2.jpg';
import userpic2 from '../../../assets/images/pp3.jpg';
import pp3 from '../../../assets/images/pp5.jpg';
import pp4 from '../../../assets/images/pp1.jpg'
import { useReactMediaRecorder } from "react-media-recorder";
import useSound from 'use-sound';
import noImage from '../../../assets/images/noImage.png';
import './chat.css'
import { Button, Row, Col, Form, Image, Nav, Tab } from 'react-bootstrap';
import {
    createChat,
    sendMessage,
    getChatHistory,
    getChat,
} from '../../../api/chat';
import { processMessages, getRemoteUserData } from './helper';
import { chainOperator, delay } from "../../../utils/helper";
import { useLocation } from "react-router-dom";
import { getAllUsers,newAllUsers } from "../../../api/friend";
// import MyComponent from "./MyConmpnent";

const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data)
      };
    });
};


const scrollToBottom = async () => {
    await delay(20);
    const messageContainer = document.getElementById('message-container');
    if (!messageContainer) return;
    messageContainer.scrollTop = messageContainer.scrollHeight;
};

const ChatPage = ({ user, socket, displayModal, recipientId, setRecipientId }) => {
    // const { status, startRecording, stopRecording, mediaBlobUrl } =
    // useReactMediaRecorder({ audio: true });
    const [msgType,setMsgType] = useState('text');


    const location = useLocation();
    const [chatHistory, setChatHistory] = useState([]);
    const [isFetchChatHistory, setFetchChatHistory] = useState(false);
    const [showChatContainer, setShowChatContainer] = useState(true);
    const [chat, setChat] = useState({});
    const [renderMessages, setRenderMessages] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [isFetchUsers, setFetchUsers] = useState(false);
    const [activeSelect,setActiveSelect] = useState(0);
    // const [searching,setSearching] = useState(false);

    const GET_ALL_USERS = async () => {
        try {
            const res = await newAllUsers();
            if (!res.ok) return;
            setAllUsers(res.data.data);
            setFetchUsers(false);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        GET_ALL_USERS();
     
    }, [isFetchUsers]);

    const triggerSelectChat = (chatId) => {
        if (!chatId) return;

        chatHistory.forEach(chat => {
            const domToBeUnSelected = document.getElementById(chat._id);
            if (domToBeUnSelected) domToBeUnSelected.classList.remove('active');
        });
        
        const domToBeSelected = document.getElementById(chatId);
        
        if (!domToBeSelected) return;
        domToBeSelected.classList.add('active');
    }

 

    const appendMessage = (message) => {
        // console.log(message,"::::::::::::::");
        let chatData = chat;
        chatData.messages.push(message);
        chatData.messages = processMessages(chatData.messages, user);
        setChat(chatData);
    };

    const CREATE_CHAT = async (recipientId=null) => {
       
        try {
            let remoteUserId = recipientId;

            if (!recipientId) {
                remoteUserId = localStorage.getItem('recipientId')
            }
            if (!remoteUserId) return console.error('remote user id is null', remoteUserId)

            const chatPayload = {
                remoteUserId,
            };

            const res = await createChat(chatPayload);
            

            if (!res.ok) return;
            let chatResData = res.data.data;
            chatResData.messages = processMessages(chatResData.messages, user);
            setChat(chatResData);
            setShowChatContainer(true);
            setRecipientId(null);
            localStorage.removeItem('recipientId');
            return res;
        } catch (err) {
            console.error(err);
        }
    };
    
    const handleChatSelect =async (chat,index) => {
       
        let chatData = chat;
        chatData.messages = processMessages(chatData.messages, user);
        // if (window.confirm("Do you really want to leave?")) {
            // window.open("exit.html", "Thanks for Visiting!");
           let chatIdData = await CREATE_CHAT(chatData.remoteUser._id);
        //    console.log(chatIdData.data.data._id);
           chatData._id = chatIdData.data.data._id; 
            // CREATE_CHAT(chatData.remoteUser._id);
        //   }
        // CREATE_CHAT(chatData.remoteUser._id);
     
        setTimeout(() => {
            setChat(chatData);
            triggerSelectChat(chat._id);
            setActiveSelect(index);
            // GET_CHAT_HISTORY();
        }, 1000);
    };

    const listenToSocketEvents = () => {
        socket.on('userJoined', () => {
            setFetchChatHistory(true);
        });

        socket.on('userLeft', () => {
            setFetchChatHistory(true);
        });

        socket.on('message', message => {
                //1. Check for new chat
                const receivedChatId = message.chatId,
                selectedChatId = chat._id,
                existingChatIds = chatHistory.map(c => c._id);
                // console.log(existingChatIds);

            if (!existingChatIds.includes(receivedChatId) || (receivedChatId !== selectedChatId)) {
                setFetchChatHistory(true);
                GET_CHAT_HISTORY();
                setActiveSelect(0);
                // return;
            };
            // GET_CHAT_HISTORY();
            //2. If received chat === selected chat, push message

            appendMessage(message);
            
        });
    }

    useEffect(() => {
        if (!socket) return;
        listenToSocketEvents();
    }, [socket]);

  

    useEffect(() => {
        if (!user) return;
        if (chat._id) return;
       
        CREATE_CHAT();
    }, [user]);



    const GET_CHAT_HISTORY = async () => {
      
        try {
            const res = await getChatHistory();
            const chatHistory = res.data.data;
            if (!chatHistory) return;
            if (!chatHistory.length) return;

            setChatHistory(chatHistory);

            let chat = chatHistory[0];
            chat.messages = processMessages(chat.messages, user);
            setChat(chat);
            triggerSelectChat(chat._id);
            setShowChatContainer(true);
            setFetchChatHistory(false);
            scrollToBottom();
        } catch (err) {
            console.error(err);
        }
    };
    const GET_CHAT_HISTORY1 = async () => {
      
        try {
            const res = await getChatHistory();
            const chatHistory = res.data.data;
            if (!chatHistory) return;
            if (!chatHistory.length) return;

            // setChatHistory(chatHistory);

            let chat = chatHistory[0];
            chat.messages = processMessages(chat.messages, user);
            setChat(chat);
            triggerSelectChat(chat._id);
            setShowChatContainer(true);
            setFetchChatHistory(false);
            scrollToBottom();
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (!user._id) return;
        GET_CHAT_HISTORY();
       
    }, [user]);
    useEffect(() => {
        if (!user._id) return;
        GET_CHAT_HISTORY1();
       
    }, [isFetchChatHistory]);

    // useEffect(()=>{
    //     if (!user._id) return;
    //     GET_CHAT_HISTORY();
    // },[isFetchChatHistory])

    const SEND_MESSAGE = async (e) => {
        try {
            e.preventDefault();
            const text = document.getElementById('textInput').value;
            if (!text) return;

            const message = {
                chatId: chat._id,
                sender: user,
                receiver: getRemoteUserData(chat, null, user),
                text,
                time:moment().toISOString()
            };


            //1. Send Message through Socket (online)
            // console.log('Send Message through Socket (online)', message)
     
            // if(chat._id){
                // console.log(chat);
                appendMessage(message);
                setRenderMessages(!renderMessages);
              
                if(message.chatId){
                    socket.emit('message', message);
                    document.getElementById('textInput').value = '';
                    await sendMessage(message);
                }else{
                    
                    // console.log(chat);
                    // console.log(message);
                    document.getElementById('textInput').value = '';
                    let msgData = await sendMessage(message);
                    // console.log(msgData,"{{{{{{{{{{{}}}}}}}}}}}}}}}}}")
                    message.chatId = msgData._id;
                    socket.emit('message', message);   
                }
                // await getChatHistory();
                const res = await getChatHistory();
                const chatHistory = res.data.data;
                if (!chatHistory) return;
                if (!chatHistory.length) return;
                setChatHistory(chatHistory);
                setActiveSelect(0);
            // }
           
            //2. Send Message through API (offline)
            

            // if(!chat._id){
            //     let chatDate = await sendMessage(message);
            //     // console.log(chatDate.data,"LLLLLLLLLLLLLLL");
            //     setChat(chatDate.data.chat)
            //     message.chatId = chatDate.data.chat._id;
            //     appendMessage(message);
            //     setRenderMessages(!renderMessages);
            //     socket.emit('message', message);
            //     document.getElementById('textInput').value = '';
            // }
            
           // setChat({})
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [chat.messages]);

    const handleSearchUser = (e) => {
        e.preventDefault();
        // if(e.target.value===''){
        //     setChatHistory([])
        // }
        // console.log(allUsers)
        const input = e.target.value;
        if (!input) return setFetchChatHistory(true)
        // const pattern = new RegExp(input, 'ig');
        // let users = allUsers.filter(user => pattern.test(`${user.firstname} ${user.lastname}`));
        let users = allUsers.filter(item=>`${item.firstname} ${item.lastname}`.match(new RegExp(input, "gi")));
    //    console.log(allUsers,"Hllllllllllllllllllllllllllllllllll")
        const existingUsers = chatHistory.map(c => c.user ? c.user._id : null)
        const existingRemoteUsers = chatHistory.map(c => c.remoteUser ? c.remoteUser._id : null)
        // console.log(existingUsers,"OOOOOOOOOOOOOOOOO")
        
        let selectedChat = {};

        let chatHistories = users.map(item => {
            if (existingUsers.includes(item._id) || existingRemoteUsers.includes(item._id)) {

                let chat = chatHistory.find(c => c.user._id == item._id || c.remoteUser._id == item._id)
                selectedChat = chat;
                return chat;
            } else {
                return {
                    user,
                    remoteUser: item
                }
            }
        })
      
     
        if (selectedChat._id) {
            handleChatSelect(selectedChat);
        } else {
            setChat({});
        }
        // console.log(chatHistories,"hhhhhhhhhhhhhhhhhhhhhhh")
        // setTimeout(() => {
            setChatHistory(chatHistories); 
        // }, 1000);
        
    };

    return (
        <>
            <section className="userinner">
                <Row className='mt-4'>
                    <Col md={12}>
                        <div className="chat-box">
                            <div className="chat-left">
                                <div className="chta-top">
                                    <p><b>Chats</b></p>
                                    <Form>
                                        <Form.Group className="">
                                            <Form.Control type="search" id="disabledTextInput" placeholder="Search ..." onChange={handleSearchUser} />
                                        </Form.Group>
                                    </Form>
                                </div>
                                <div className="chta-list">
                                    <p>
                                        Messages
                                    </p>
                                    <ul>
                                        {
                                            renderMessages
                                                ?
                                                (
                                                    chatHistory.length > 0
                                                    &&
                                                    chatHistory.map((chat, index) => (
                                                        <li id={chat._id} key={chat._id} onClick={e => handleChatSelect(chat, index)} className={index === activeSelect ? 'active' : ''}>
                                                            <span className={getRemoteUserData(chat, 'status', user)}>
                                                                <Image src={getRemoteUserData(chat, 'profileImage', user)} alt="" />
                                                            </span>
                                                            <span className="pro-name ">
                                                                {getRemoteUserData(chat, 'fullname', user)}
                                                            </span>
                                                        </li>
                                                    ))
                                                )
                                                :
                                                (
                                                    chatHistory.length > 0
                                                    &&
                                                    chatHistory.map((chat, index) => (
                                                        <li id={chat._id} key={chat._id} onClick={e => handleChatSelect(chat, index)} className={index === activeSelect ? 'active' : ''}>
                                                            <span className={getRemoteUserData(chat, 'status', user)}>
                                                                <Image src={getRemoteUserData(chat, 'profileImage', user)} alt="" />
                                                            </span>
                                                            <span className="pro-name ">
                                                                {getRemoteUserData(chat, 'fullname', user)}
                                                            </span>
                                                        </li>
                                                    ))
                                                )
                                        }
                                    </ul>
                                </div>
                            </div>
                    
                            <div className="chat-right">
                                {
                                    showChatContainer
                                    &&
                                    chat
                                    &&
                                    <>
                                        <div className="chta-right-top">
                                            <span className={getRemoteUserData(chat, 'status', user)}>
                                                <Image src={getRemoteUserData(chat, 'profileImage', user)} alt="" />
                                            </span>
                                            <span className="pro-name">
                                                {getRemoteUserData(chat, 'fullname', user)} <br />
                                                <small>{getRemoteUserData(chat, 'status-text', user)}</small>
                                            </span>
                                        </div>
                                 
                                        <div className="chta-ms-list"  id='message-container'>

                                            {
                                                chat
                                                &&
                                                chat.messages
                                                &&
                                                chat.messages.length > 0
                                                &&
                                                chat.messages.map(message => (
                                                    <div className={message.self ? "chat-list for-m" : 'chat-list for-u'}>
                                                        <span className="pro-pic ">
                                                            <Image src={message.sender?.profileimage ? message.sender?.profileimage : noImage} alt="" />
                                                        </span>
                                                        <span className="pro-name">
                                                            {message.sender ? message.sender.firstname : ''} <br />
                                                            <p>
                                                                {message.text}
                                                            </p>
                                                            <small style={{color:"#000"}}> {moment(message.time).format("dddd, MMMM Do YYYY, h:mm A")}</small>
                                                        </span>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </>
                                }
                                <div className="chta-input">
                                    <Form>
                                        <Form.Group className="input-text">
                                            <Form.Control type="search" id="textInput" placeholder="Type your text" autocomplete="off" />
                                        </Form.Group>
                                        {/* <Form.Group className="input-atc">
                                            <Form.Label htmlFor="fileateach"><Image src={link} alt="" /></Form.Label>
                                            <Form.Control id="fileateach" type="file" placeholder="Disabled input" className="d-none" />
                                        </Form.Group> */}
                                        <Button type="submit" className="input-submit ms-2 p-3" onClick={SEND_MESSAGE}><Image src={send} alt="" /></Button>
                                      
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </section>
        </>
    );
};

export default ChatPage;