import { getCategoryList, getSubcategoryList, getUserCategoryList, getUserSubcategoryList } from '../../api/job';

const GET_CATEGORY_LIST = async (setCategoryList) => {
    try {
        const res = await getCategoryList();
        if (!res.ok) return;
        let categorylist = res.data.data;
        if (!categorylist.length) return;
        categorylist = categorylist.map(cat => ({ _id: cat._id, name: cat.name, icon: cat.icon }));
        setCategoryList(categorylist);
        
    } catch (err) {
        console.error(err);
    }
}

const GET_USER_CATEGORY_LIST = async (setCategoryList) => {
    try {
        const res = await getUserCategoryList();
         console.log(res,"..............................")
        if (!res.ok) return;
        let categorylist = res.data.data;
        console.log(categorylist,"?????????");
        if (!categorylist.length) return;
        // categorylist = categorylist.map(cat => ({ _id: cat._id, name: cat.name }));
        setCategoryList(categorylist);
    } catch (err) {
        console.error(err);
    }
}

const GET_SUB_CATEGORY_LIST = async (categoryId, setSubCategoryList, setIsCategoriesFiltered) => {
    try {
        const res = await getSubcategoryList(categoryId);
        if (!res.ok) return;
        let subcategorylist = res.data.data;
        if (!subcategorylist) return setSubCategoryList([]);
        if (!subcategorylist.length) return setSubCategoryList([]);
        subcategorylist = subcategorylist.map(item => ({ _id: item._id, name: item.name, category: item.category }));
        setSubCategoryList(subcategorylist);
        if (setIsCategoriesFiltered) setIsCategoriesFiltered(false);
    } catch (err) {
        console.error(err);
    }
}

const GET_USER_SUB_CATEGORY_LIST = async (categoryId) => {
    try {
        const res = await getUserSubcategoryList(categoryId);
        if (!res.ok) return;
        let subcategorylist = res.data.data;
        if (!subcategorylist) return [];
        if (!subcategorylist.length) return [];
        subcategorylist = subcategorylist.map(item => ({ _id: item._id, name: item.name, category: item.category }));
        return subcategorylist;
    } catch (err) {
        console.error(err);
    }
}

const GET_SUB_CATEGORY_LIST_RETURN = async (categoryId) => {
    try {
        const res = await getSubcategoryList(categoryId);
        if (!res.ok) return;
        let subcategorylist = res.data.data;
        if (!subcategorylist) return [];
        if (!subcategorylist.length) return [];
        subcategorylist = subcategorylist.map(item => ({ _id: item._id, name: item.name, category: item.category }));
        return subcategorylist;
    } catch (err) {
        console.error(err);
    }
}

const handleCategoryChange = (e, setCategoryId, setSubCategoryList, setIsCategoriesFiltered) => {
    const categoryId = e.target.value;
    setCategoryId(categoryId)
    GET_SUB_CATEGORY_LIST(categoryId, setSubCategoryList, setIsCategoriesFiltered);
}

export {
    GET_SUB_CATEGORY_LIST_RETURN,
    GET_CATEGORY_LIST,
    GET_SUB_CATEGORY_LIST,
    GET_USER_CATEGORY_LIST,
    GET_USER_SUB_CATEGORY_LIST,
    handleCategoryChange,
}