import React, { useState, useEffect } from 'react'
import { GoogleMap, useJsApiLoader, MarkerF,CircleF } from '@react-google-maps/api';
import { API_KEY } from '../config/google';
import { useNavigate } from 'react-router-dom';
import { useGeolocated } from "react-geolocated";


const containerStyle = {
    width: '100%',
    height: 320
};

const containerStyle1 = {
    width: '100%',
    height: 640
};

const options = {
	strokeColor: '#609a02',
	strokeOpacity: 0.8,
	strokeWeight: 4,
	fillColor: '#fff',
	fillOpacity: 0.8,
	clickable: false,
	draggable: false,
	editable: false,
	visible: true,
	zIndex: 1
  }

function Map({ center, jobLocations,distance }) {
	// const { coords, isGeolocationAvailable, isGeolocationEnabled } =
	// useGeolocated({
	// 	positionOptions: {
	// 		enableHighAccuracy: false,
	// 	},
	// 	userDecisionTimeout: 5000,
	// });
    const navigate = useNavigate()
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: API_KEY
    })

    // const [map, setMap] = React.useState(null)
    const [zoom, setZoom] = useState(9);
    // const [renderMarkers, setRenderMarkers] = useState(false);

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.setZoom(18);
        // map.setCenter(center);
        // setMap(map)
    }, [])

    // const onUnmount = React.useCallback(function callback(map) {
    //     setMap(null)
    // }, [])

    const viewJobDetails = (id) => {
        navigate(`/JobDetails/${id}`);
    }

    // useEffect(() => {
    //     if (!isLoaded) return;
    //     if (!jobLocations.length) return;
    //     setRenderMarkers(true);
    // }, [jobLocations, isLoaded]);
   

        return (
        <div className='map-det' >
            {
                isLoaded
                &&
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={zoom}
                    // onLoad={onLoad}
                    // onUnmount={onUnmount}
                    
                >
                     {/* <CircleF
        center={{ lat: coords.latitude, lng: coords.longitude }}
        radius={distance*1609.34}
      /> */}
      
                    {
                        jobLocations.map(job => (
                            <MarkerF
                                key={job._id}
                                title={job.title}
                                position={job.location}
                                label={{
                                    text: job.title,
                                    fontSize: '5px',
                                    color: '#000',
                                }}
                                icon={{
                                    scaledSize: {
                                        width: 40,
                                        height: 40,
                                    },
                                    url: job.icon
                                }}
                                onClick={e => viewJobDetails(job._id)}
                                options={options}
                            />
                        ))
                    }
                    <MarkerF position={center} />
                    <CircleF center={center} radius={distance*1609.34} options={options} />
                </GoogleMap>
            }
            {
                !isLoaded
                &&
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={zoom}
                    onLoad={onLoad}
                    // onUnmount={onUnmount}
                >
                </GoogleMap>
            }
        </div>
    )
}

export default React.memo(Map)