import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Banner from "../../Layouts/Banner";
import { Button, Row, Col, Form, Image, Nav, Tab } from 'react-bootstrap';
import about from '../../assets/images/about-right.jpg';
import chat from '../../assets/images/chat.svg';
import home from '../../assets/images/home.svg';
import shield from '../../assets/images/shield.svg';
import job from '../../assets/images/job.svg';
import group from '../../assets/images/group.svg';
import user1 from '../../assets/images/user.svg';
import dbord from '../../assets/images/dashboard.svg';
import logoutIcon from '../../assets/images/logout.svg';
import profilepic from '../../assets/images/profile-pic.svg'
import bell from '../../assets/images/bell.svg'
import ChatPage from "./chat";
import Dashboard from "./dashboard";
import PostJob from "./postjob";
import Message from "./message";
import Friends from "./friends";
import Account from "./account";
import Profile from "./profile";
import Notifications from "./notifications";

import store from "../../utils/store";
import JobApplications from "./jobApplications";
import JobOffers from "./jobOffers";
import noimage from '../../assets/images/noImage.png';
const CHAT_EL_ID = 'left-tabs-example-tab-chat';

const User = ({ user, socket, isLoggedIn, displayModal, setIsUpdateUser, recipientId, setRecipientId, LOGOUT }) => {
    const navigate = useNavigate();
    const [isActive, setActive] = useState(false);
    const [isFetchJobs, setFetchJobs] = useState(false);
    const [defaultActiveKey, setDefaultActiveKey] = useState('Dashboard');
    const [render, setRender] = useState(false);
    const [isRedirectChecked, setRedirectChecked] = useState(false);

    // Chat
    const [isRedirectChatNow, setIsRedirectChatNow] = useState(false);

    // Expertise
    const [isRedirectExpertise, setIsRedirectExpertise] = useState(false);
    const [expertId, setExpertId] = useState(null);

    const getDefaultActiveKey = () => {
        const lastEventKey = store.getLastEventKey();
        if (!lastEventKey) return setRender(true);
        setDefaultActiveKey(lastEventKey)
        setRender(true)
    };

    const chatRedirect = () => {
        const isRedirectChatNow = localStorage.getItem('isRedirectChatNow');
        setIsRedirectChatNow(isRedirectChatNow);

        console.log('isRedirectChatNow', isRedirectChatNow)

        if (!isRedirectChatNow) return false;

        setRender(true);
        localStorage.removeItem('isRedirectChatNow');
        return true;
    };

    const expertiseRedirect = () => {
        const isRedirectExpertise = localStorage.getItem('isRedirectExpertise');
        setIsRedirectExpertise(isRedirectExpertise);

        if (!isRedirectExpertise) return false;

        const expertId = localStorage.getItem('expertId');
        setExpertId(expertId);
        setRender(true);
        localStorage.removeItem('isRedirectExpertise');
        return true;
    };

    const checkRedirect = () => {
        setRender(false);
        const isChatRedirect = chatRedirect();
        const isExpertiseRedirect = expertiseRedirect();
        setRedirectChecked(true);
        if (!isChatRedirect && !isExpertiseRedirect) return getDefaultActiveKey();
    };

    useEffect(() => {
        if (!isLoggedIn || isRedirectChecked) return;
        checkRedirect();
    }, [user]);

    useEffect(() => {
        if (!isRedirectChatNow) return;
        document.getElementById(CHAT_EL_ID).click();
    }, [render, isRedirectChatNow]);

    useEffect(() => {
        if (!isRedirectExpertise) return;
        navigate(`/ExpertiseDetails/${expertId}`);
    }, [render, isRedirectExpertise]);
    const toggleClass = () => {
        setActive(!isActive);
    };

    return (
        <>
            <section className="aflotop"></section>
            <section className="afterlogin  py-5">
                <div className="container">
                    {
                        render
                        &&
                        <Tab.Container id="left-tabs-example" defaultActiveKey={defaultActiveKey}>
                            <Row>
                                <Col lg={4} xl={3} >
                                <Button className={"btn-green btn btn-primary d-block d-lg-none w-100 mb-3  " + (isActive ? 'd-none' : '')} onClick={toggleClass}>
                                    Open  Menu
                                </Button>
                                    <div className={"left-pnal wv-100 d-lg-block mobileview d-none  " + (isActive ? 'show' : '')} onClick={toggleClass}>
                                        <div className="left-profile wv-100">
                                            <div className="profile-pic">
                                            {user?.profileimage ? (
                                                <Image src={user?.profileimage} />
                                            ) : (
                                                <Image src={noimage} alt=""/>
                                            )}
                                              
                                            </div>
                                            <div className="profilepic-cont text-center">
                                                <h3>
                                                    {user.firstname}
                                                </h3>
                                                <p className="for-email">
                                                    {user.email}
                                                    {/* {
                                                        user.email
                                                        &&
                                                        <>
                                                            <span>{user.email.substring(0, 20)}</span><br />
                                                            <span>{user.email.substring(20, user.email.length)}</span>
                                                        </>
                                                    } */}
                                                </p>
                                                <p>
                                                    {user.phone_number}
                                                </p>
                                                <hr />
                                            </div>
                                        </div>

                                        <Nav variant="pills" className="tab-nav wv-100">
                                        <Nav.Item>
                                                <Nav.Link eventKey="Profile" onClick={e => store.storeLastEventKey("Profile")}>
                                                    <Image src={shield} alt="" /> Payment
                                                </Nav.Link>
                                            </Nav.Item>
                                        <Nav.Item>
                                                <Nav.Link eventKey="Dashboard" onClick={e => store.storeLastEventKey("Dashboard")}>
                                                    <Image src={dbord} alt="" /> Dashboard
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="Notifications" onClick={e => store.storeLastEventKey("Notifications")}>
                                                    <Image src={bell} alt="" />   Notifications
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="Account" onClick={e => store.storeLastEventKey("Account")}>
                                                    <Image src={shield} alt="" /> Account   
                                                </Nav.Link>
                                            </Nav.Item>
                                           
                                           
                                            <Nav.Item>
                                                <Nav.Link eventKey="Post-Job" onClick={e => store.storeLastEventKey("Post-Job")}>
                                                    <Image src={job} alt="" />  Post Job
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="Job-Applications" onClick={e => store.storeLastEventKey("Job-Applications")}>
                                                    <Image src={job} alt="" />  Jobs You Offered To
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="Job-Offers" onClick={e => store.storeLastEventKey("Job-Offers")}>
                                                    <Image src={job} alt="" />Jobs You Applied To
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="Friends" onClick={e => store.storeLastEventKey("Friends")}>
                                                    <Image src={group} alt="" />   Friends
                                                </Nav.Link>
                                            </Nav.Item>
                                            {/* <Nav.Item>
                                                <Nav.Link eventKey="Profile" onClick={e => store.storeLastEventKey("Profile")}>
                                                    <Image src={user1} alt="" />  Job Details
                                                </Nav.Link>
                                            </Nav.Item> */}
                                            <Nav.Item>
                                                <Nav.Link eventKey="chat" onClick={e => store.storeLastEventKey("chat")}>
                                                    <Image src={chat} alt="" />  Chat
                                                </Nav.Link>
                                            </Nav.Item>
                                            
                                            
                                            <Nav.Item>
                                                <Nav.Link eventKey="second" onClick={LOGOUT}>
                                                    <Image src={logoutIcon} alt="" />  Logout
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </Col>
                                <Col  lg={8} xl={9}>
                                    <Tab.Content className="main-Content-user">
                                        <Tab.Pane eventKey="Dashboard">
                                            <Dashboard
                                                user={user}
                                                displayModal={displayModal}
                                                isFetchJobs={isFetchJobs}
                                                setFetchJobs={setFetchJobs}
                                                socket={socket}
                                            />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="Post-Job">
                                            <PostJob
                                                user={user}
                                                displayModal={displayModal}
                                                isLoggedIn={isLoggedIn}
                                                setFetchJobs={setFetchJobs}
                                                socket={socket}
                                            />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="Job-Applications">
                                            <JobApplications
                                                socket={socket}
                                                user={user}
                                                displayModal={displayModal}
                                            />
                                        </Tab.Pane>
                                        
                                        <Tab.Pane eventKey="Job-Offers">
                                            <JobOffers
                                                socket={socket}
                                                user={user}
                                                displayModal={displayModal}
                                            />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="Message">
                                            <Message />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="Friends">
                                            <Friends
                                                user={user}
                                                displayModal={displayModal}
                                                socket={socket}
                                            />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="Profile">
                                            <Profile
                                             user={user}
                                             socket={socket}
                                             displayModal={displayModal}
                                            />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="chat">
                                            <ChatPage
                                                user={user}
                                                socket={socket}
                                                displayModal={displayModal}
                                                recipientId={recipientId}
                                                setRecipientId={setRecipientId}
                                            />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="Account">
                                            <Account
                                                user={user}
                                                displayModal={displayModal}
                                                setIsUpdateUser={setIsUpdateUser}
                                            />
                                        </Tab.Pane>
                                        {/* <Tab.Pane eventKey="Profile">
                                            <Account
                                                user={user}
                                                displayModal={displayModal}
                                                setIsUpdateUser={setIsUpdateUser}
                                            />
                                        </Tab.Pane> */}
                                        <Tab.Pane eventKey="Notifications">
                                            <Notifications
                                                socket={socket}
                                                user={user}
                                                displayModal={displayModal}
                                            />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    }
                </div>
            </section>
        </>
    );
};

export default User;
