import { create } from 'apisauce';
import { baseURL, endPoint } from '../config/baseUrl';
import store from '../utils/store';

const api = create({
    baseURL
})

api.addAsyncRequestTransform(async (request) => {
    let token = await store.getUserToken();
    if (!token) return;
    request.headers['x-access-token'] = token;
})

export {
    api, 
    endPoint
}