import React, { useState, useEffect, useContext } from "react";
import { Link, NavLink } from 'react-router-dom';
import about from '../../assets/images/about-right.jpg';
import jobbanner from '../../assets/images/job-banner.png';
import chat from '../../assets/images/chat.png';
import user from '../../assets/images/user.png';
import cattype from '../../assets/images/type.png';
import star from '../../assets/images/star.png';
import { Button, Row, Col, Form, Image, Nav, Tab, Card, Table } from 'react-bootstrap';
import CustomPagination from "../../components/CustomPagination";
import Map from '../../components/GoogleMaps';
import { Context } from "../../components/Context";

const CatJobList = ({
   // coords,
    jobs,
    limit,
    totalPages,
    handlePageClick,
    setPointer,
    pointer,
    currentPageLimit,
    handleChatNow,
    handleApplyJob,
    
}) => {
    const { distance,coords } = useContext(Context);
    const [jobLocations, setJobLocations] = useState([]);
    const getJobLocations = () => {
        const jobLocations = jobs.map(job => {
            const { coordinates } = job.location;
            return {
                _id: job._id,
                title: job.title.substring(0, 10) + '..',
                icon: job?.profileImage,
                location: {
                    lat: coordinates[1],
                    lng: coordinates[0],
                }
            }
        });
        setJobLocations(jobLocations);
    };

    useEffect(() => {
        if (!jobs.length) return setJobLocations([]);
        getJobLocations();
    }, [jobs]);

    return (
        <>
        
            <Col lg={12} md={12} className="job-fil-right">
                <Row>
                    <Col md={12}>
                        <h2>
                            Showing {currentPageLimit}/{jobs.length}  jobs
                        </h2>
                    </Col>
                </Row>
                <Row className="job-fil mt-3">
                    <div className="mb-4" md={12}>
                        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15282225.79979123!2d73.7250245393691!3d20.750301298393563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30635ff06b92b791%3A0xd78c4fa1854213a6!2sIndia!5e0!3m2!1sen!2sin!4v1587818542745!5m2!1sen!2sin" width="100%" height="250" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
                        {
                            coords
                            &&
                            <Map
                                center={{
                                    lat: coords.latitude,
                                    lng: coords.longitude,
                                }}
                                jobLocations={jobLocations}
                                distance={Number(distance)}
                            />
                        }
                    </div>
                    {
                        jobs.length > 0
                        &&
                        jobs.map(job => (
                            <Col md={12} xl={4} lg={6} className="mb-4" key={job._id}>
                                <Card>
                                    <div className="top-img">
                                        <NavLink to={`/JobDetails/${job._id}`}><Image src={job?.profileImage} alt="" /></NavLink>
                                    </div>
                                    <Card.Body>
                                        <span className="price">{job.salary}</span>
                                        <div className="title-cont w-100">
                                            <h3><small>{job.createdDate}  -  {job.createdTime}</small><br />
                                                <NavLink to={`/JobDetails/${job._id}`}>{job.title ? job.title.substring(0, 50) : ''}</NavLink></h3>
                                        </div>
                                        <div className="text-cont-top w-100">
                                            <div className="tct-left">
                                                <Image src={user} alt="user" /> <span><NavLink style={{color:'#000'}} to={`/EmployerProfile/${job.employer_id._id}`}>{job.employer_id ? job.employer_id.firstname : ''}</NavLink>, aa</span>  <Image src={cattype} alt="user" /> <span>Service</span>
                                            </div>
                                            <div className="tct-right text-end">
                                                <Image src={star} alt="user" /> 4.5 - 39 view
                                            </div>
                                        </div>
                                        <div className="text-cont w-100">
                                            <p>
                                                {job.description ? job.description.substring(0, 80) + '...' : ''}
                                            </p>
                                        </div>
                                        <div className="btn-cont w-100 text-end">
                                            <Button className="btn-outline-green" onClick={e => handleChatNow(e, job.employer_id)} >
                                                <Image src={chat} alt="" /> Chat  With Us
                                            </Button>
                                            <Button className="btn-green" onClick={e => handleApplyJob(e, job)}>
                                                Apply Now
                                            </Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                    }
                </Row>
                <Row>
                    <Col>
                        <div style={{ display: 'flex', justifyContent: 'right', marginTop: 10, padding: 0 }}>
                            <CustomPagination
                                totalPages={totalPages}
                                handlePageClick={handlePageClick}
                                setPointer={setPointer}
                                pointer={pointer}
                                limit={limit}
                            />
                        </div>
                    </Col>
                </Row>
            </Col>
        </>
    )
}

export default CatJobList;