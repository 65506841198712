import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form, Image, Card } from 'react-bootstrap';
import './EmployeeProfile.css'
import ep1 from '../../assets/images/pp1.jpg';
import call from '../../assets/images/phone-cal.svg';
import email from '../../assets/images/email.svg';
import chat from '../../assets/images/chat.png';
import star from '../../assets/images/star.png';
import { useParams } from "react-router-dom";
import { getProfile } from "../../api/profile";
import { useNavigate } from 'react-router-dom';
import noimage from  '../../assets/images/noImage.png'
import moment from 'moment'

const avgRatingCalculator = (ratings)=>{
if(ratings.length===0){
    return 0
}
const sum = ratings.reduce((total, rating) => total + rating.rate, 0);
const avg = sum / ratings.length;  
return avg.toFixed(1);
}
const EmployeeProfile = ({}) => {
    
    const navigate = useNavigate();
function handleGoBack() {
    navigate(-1);
  }
    let  {id}  = useParams();
    const [avgRating,setAvgRating] = useState(0);
    const [employee, SetEmployee]=useState({});
      const GET_Employee_DETAILS = async () => {
        try {
          const res = await getProfile(id);
          
          SetEmployee(res.data.data);
          setAvgRating(avgRatingCalculator(res.data.data.rating))
        } catch (error) {
          console.log(error);
        }
      };
      useEffect(() => {
        GET_Employee_DETAILS();
      }, []);
    return (
        <>
            
            <section className="job-banner">
                <div className="container">
                    <Row>
                        <Col md={12} className="text-center">
                            <h1>
                                View Profile
                            </h1>
                            <button className="btn btn-green" onClick={handleGoBack}>Go back</button>
                            {/* <p>
                                You will achieve the level of safety excellence that you demonstrate you want 
                            </p> */}
                        </Col>
                    </Row>
                </div>
            </section>
            <section className="joblispage-top py-5">
                <div className="container">
                    <Row>
                        <Col md={4} lg={3} className="text-center">
                            <div className="exp-details">
                                <div className="ex-pic">
                                {employee?.profileimage ? (
                                               <Image src={employee?.profileimage} alt="" />
                                            ) : (
                                                <Image src={noimage} alt=""/>
                                           )}
                                </div>
                                <div className="ex-cont">
                                    <h3>{employee?.firstname} <br /> <small><Image src={star} alt="user" /> 
                                    {avgRating}/5 - {employee?.rating?employee?.rating.length:''} Reviews</small></h3>
                                    
                                        <>
                                            <Button className="btn-outline-green mb-3 w-100" >
                                                <Image src={chat} alt="" /> Chat
                                            </Button>
                                    </>
                                    
                                </div>
                            </div>
                        </Col>
                        <Col md={8} lg={9} className="ps-lg-5 ps-md-5 ">
                            <div className="exptiselist mb-3">
                            
                                <p>
                                    <strong>
                                        Full Name
                                    </strong>
                                    <span>
                                      {employee?.firstname} {employee?.lastname}
                                    </span>
                                </p>
                                <p>
                                    <strong>
                                        Email Id
                                    </strong>
                                    <span>
                                    {employee?.email}
                                    </span>
                                </p>
                                <p>
                                    <strong>
                                        Phone No
                                    </strong>
                                    <span>
                                    {employee?.phone_number}
                                    </span>
                                </p>
                                    <p>
                                        <strong>
                                          Gender
                                        </strong>
                                        <span>
                                        {employee?.gender}
                                        </span>
                                    </p>
                                
                                <p>
                                    <strong>
                                        Address
                                    </strong>
                                    <span>
                                    {employee?.address}
                                    </span>
                                </p>
                                
                            </div>

                            <div className="exptiselist mb-3 reting-info">
                            <div className="reting-heading mb-3">
                                <h2>
                                <Image src={star} alt="user" /> {avgRating}/5 - {employee?.rating?employee?.rating.length:''} Reviews
                                </h2>
                                <hr />
                            </div>
                            <div className="rating-maxlant">
                            {
                                employee?.rating
                                &&
                                employee?.rating.map((item,index)=>{
                                    return (
                                        <div className="reting-list mb-3">
                                        <div className="reting-list-top">
                                            <div className="pp-pic">
                                            {item?.job_id?.employeesId?.profileimage ? ( <Image src={item?.job_id?.employeesId?.profileimage}  alt="" />):(<Image src={noimage} alt=""/>) }
                                            
                                            </div>
                                            <div className="rp-cont">
                                                <h3>
                                                    {item?.job_id?.employeesId?.firstname} {item?.job_id?.employeesId?.lastname} <small><Image src={star} alt="user" /> <span>{item?.rate} Stars</span></small>
                                                </h3>
                                                <p>
                                                 {moment().diff(item?.currentTime,"days")} days ago
        
                                                </p>
                                            </div>
                                        </div>
                                        <div className="reting-list-bottom">
                                            <p>{item?.desc}</p>
                                        </div>
                                    </div>
                                    )
                                })
                            }
                            </div>
                            
                            {/* <div className="reting-list mb-3">
                                <div className="reting-list-top">
                                    <div className="pp-pic">
                                    <Image src={ep1} alt="" />
                                    </div>
                                    <div className="rp-cont">
                                        <h3>
                                            Sumit pol <small><Image src={star} alt="user" /> <span>2 Stars</span></small>
                                        </h3>
                                        <p>
                                            5 days ago

                                        </p>
                                    </div>
                                </div>
                                <div className="reting-list-bottom">
                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less.</p>
                                </div>
                            </div>
                            <div className="reting-list mb-3">
                                <div className="reting-list-top">
                                    <div className="pp-pic">
                                    <Image src={ep1} alt="" />
                                    </div>
                                    <div className="rp-cont">
                                        <h3>
                                            Sumit pol <small><Image src={star} alt="user" /> <span>2 Stars</span></small>
                                        </h3>
                                        <p>
                                            5 days ago

                                        </p>
                                    </div>
                                </div>
                                <div className="reting-list-bottom">
                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. </p>
                                </div>
                            </div> */}
                        </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </>
    )
}
export default EmployeeProfile;