import { useEffect } from "react";
import GooglePlacesAutocomplete, { getLatLng, geocodeByAddress } from "react-google-places-autocomplete";
import { chainOperator, format_time_24 } from "../../../utils/helper";

const filterUnrelatedCategories = ({
    user,
    categoryList,
    subcategoryList,
    setSubCategoryList,
    setCategoryList,
    setCategoryId,
    document,
    setIsCategoriesFiltered,
    
}) => {
    const userId = chainOperator(user, ['_id'], '');
    if (!userId) return;

    // Remove Job categories that equals User Job Category
    const userJobCategory = chainOperator(user, ['profession', 'category', '_id'], '')
    const userJobSubCategory = chainOperator(user, ['profession', 'subcategory', '_id'], '')
    const jobCategories = categoryList;
    const jobSubCategories = subcategoryList;

    const matchingJobCategory = jobCategories.find(item => item._id == userJobCategory);
    if (!matchingJobCategory) return setIsCategoriesFiltered(true);

    
    const matchingJobSubCategories = jobSubCategories.filter(item => item.category == matchingJobCategory._id)
    
    if (matchingJobSubCategories.length > 1) {

        // 1. if matchingJobCategory has multiple subcategories
        let filteredSubCategories = jobSubCategories.filter(item => item._id !== userJobSubCategory);
        setSubCategoryList(filteredSubCategories);
    } else if (matchingJobSubCategories.length === 1) {

        // 2. if matchingJobCategory has single subcategory
        const filteredCategories = jobCategories.filter(item => item._id !== matchingJobCategory._id);
        setCategoryList(filteredCategories);
        setSubCategoryList([])
        setCategoryId('')
        document.querySelector('[name=category]').value = '';
    }
    setIsCategoriesFiltered(true);
}

const convertToBase64 = async (file) => {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(file);
    })
}

const handleFileChange = async (e, displayModal, setFile) => {

    switch (e.target.name) {
        case 'profileImage': {
            const base64Image = await convertToBase64(e.target.files[0]);
            // console.log(base64Image)
            setFile(base64Image);
            break;
        }
        case 'jobImages': {
            // let images;
            // const base64Image = await convertToBase64(e.target.files[0]);
            // console.log(images)
            // setFile(images=>[...images,base64Image]);
            // break;
            const files = e.target.files;
            
            if (!files) return;
            if (!files.length) return;
            if (files.length > 4) return displayModal('Error', 'Cannot Upload More than 4 Images!')
            // console.log(files)
            let images = [];

            for (const file of files) {
                
                images.push({
                    url: await convertToBase64(file),
                    caption: ''
                });
                
            }
            // console.log(images);

            setFile(images);
            break;
        }
        case 'video': {
            const videoBase64 = await convertToBase64(e.target.files[0]);
            setFile(videoBase64);
            break;
        }
    }
}

const handleFileClick = (e, document) => {
    e.preventDefault();

    switch (e.target.id) {
        case 'profile-image-button': {
            document.getElementById('profile-image-control').click();
            break;
        }
        case 'gallery-button': {
            document.getElementById('gallery-control').click();
            break;
        }
        case 'video-button': {
            document.getElementById('video-control').click();
            break;
        }
    }
}

const getCity = (addressComponents) => addressComponents[0].long_name;

const getState = (addressComponents) => addressComponents[addressComponents.length - 2].long_name;

const getCountry = (addressComponents) => addressComponents[addressComponents.length - 1].long_name;

const getLocation = async (address, document, setLocation) => {
    
    const results = await geocodeByAddress(address.value.description);
    // console.log(results[0].formatted_address,"??????????????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
    if (!results) return;
    if (!results.length) return;

    const loc = await getLatLng(results[0]);

    const { address_components, formatted_address } = results[0];

    const location = {
        latitude: loc.lat,
        longitude: loc.lng,
        address: formatted_address,
        city: getCity(address_components),
        state: getState(address_components),
        country: getCountry(address_components)
    }
    // console.log(location)
    document.querySelector('[name=city]').value = location.city;
    document.querySelector('[name=state]').value = location.state;
    document.querySelector('[name=country]').value = location.country;
    document.querySelector('[name=lat]').value = location.latitude;
    document.querySelector('[name=lng]').value = location.longitude;
    document.querySelector('[name=address]').value = location.address;
    setLocation(location);
}

const handlePriceType = (e, document, setPriceType) => {
    document.getElementById('fixed').style.backgroundColor = 'white';
    document.getElementById('range').style.backgroundColor = 'white';
    e.target.style.backgroundColor = 'green';
    setPriceType(e.target.id);
}

const resetForm = ({
    el,
    setProfileImage,
    setGallery,
    setVideo,
    setPriceType,
    document,
    setIsJobMoreThanOneDay,
    setLocation,
    setFixedPrice,
    setPrivateEmployeeInputs,
    setSuccessInviteFullData,
    setSuccessInvite,
    profile1,
    setStartTime,
    setEndTime
}) => {
    el.reset();
    setFixedPrice('')
    setProfileImage(profile1);
    // setGallery([{ url: '' }]);
    setGallery([]);
    setVideo(null);
    document.getElementById('video').src = null;
   
    setPriceType('fixed');
    document.getElementById('fixed').style.backgroundColor = 'green';
    setIsJobMoreThanOneDay(false);
    setLocation({});
    setSuccessInviteFullData([]);
    setPrivateEmployeeInputs([]);
    setSuccessInvite([]);
    setStartTime(`${new Date().getHours().toString()}:${new Date().getMinutes().toString()}`)
    setEndTime(`${new Date().getHours().toString()}:${new Date().getMinutes().toString()}`)
}

const handleClose = (setShow) => setShow(false);

const handleShow = ({ e, setShowInviteModal, setJobType, setSocialMediaShare }) => {
    setShowInviteModal(e.target.checked);
    setJobType(e.target.checked ? 'private' : 'public');
    setSocialMediaShare(e.target.checked ? false : true);
}

export {
    filterUnrelatedCategories,
    handleFileChange,
    handleFileClick,
    getLocation,
    handlePriceType,
    resetForm,
    handleClose,
    handleShow,
}