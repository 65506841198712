import { api, endPoint } from './client';

const createJob = (data) => api.post(`${endPoint.jobs}/create-web-job`, data)

const getMyJobs = () => api.get(`${endPoint.jobs}/get-my-worked-job`);

const getCategoryList = () => api.get(`${endPoint.jobs}/allcategorylist`)

const getSubcategoryList = (catId) => api.get(`${endPoint.jobs}/subcategorylist/${catId}`)

const getUserCategoryList = () => api.get(`${endPoint.jobs}/usercategorylist`)

const getUserSubcategoryList = (catId) => api.get(`${endPoint.jobs}/usersubcategorylist/${catId}`)

const getAllJobList = (query) => api.get(`${endPoint.jobs}/allJobList?${query}`);

const allJobListBody = (data) => api.post(`${endPoint.jobs}/allJobListBody`,data);

const recentJobList = () => api.get(`${endPoint.jobs}/recentJobs`);

const getJobDetails = (id) => api.get(`${endPoint.jobs}/get-job-details-web/${id}`);

const applyJob = (jobId, data) => api.post(`${endPoint.jobs}/applyJob/${jobId}`, data);


const getReceivedApplicationList = () => api.get(`${endPoint.jobs}/receivedApplications`);


const receivedApplicationsDisapproved = () => api.get(`${endPoint.jobs}/receivedApplicationsdisapproved`);

const getAppliedApplicationList = () => api.get(`${endPoint.jobs}/appliedApplications`);

const jobApplicationApproval = (id, status) => api.patch(`${endPoint.jobs}/applicationApproval/${id}/${status}`, {})

const getSentJobOffers = () => api.get(`${endPoint.jobs}/sentJobOffers`);

const getReceivedJobOffers = () => api.get(`${endPoint.jobs}/receivedJobOffers`);

const getAcceptedJobOffers = () => api.get(`${endPoint.jobs}/acceptedJobOffers`);

const acceptJobOffer = (offerId) => api.patch(`${endPoint.jobs}/acceptJobOffer/${offerId}`, {});

const rejectJobOffer = (offerId) => api.patch(`${endPoint.jobs}/rejectJobOffer/${offerId}`, {});

const getAllJobsByUserId = () => api.get(`${endPoint.jobs}/getAllJobsByUserId`);

const newAcceptJobOffer = (data) => api.post(`${endPoint.jobs}/accept-jobOffer`,data);

const newGetAcceptedJobApplicant = () => api.get(`${endPoint.jobs}/get-accepted-job-applicant`);
const getAcceptedJobApplicantByEmployeer = () => api.get(`${endPoint.jobs}/get-approved-list-by-employeer`);

const rejectJobOfferList = () =>api.get(`${endPoint.jobs}/rejectJobOfferList`);

const applyJobList = (jobId) =>api.get(`${endPoint.jobs}/applyJobList/${jobId}`)
const getOfferJobList = (jobId) =>api.get(`${endPoint.jobs}/getJobOfferList/${jobId}`)

const getApprovedJob = (jobId)=> api.get(`${endPoint.jobs}/get-approved-job/${jobId}`)

const startJob = (jobId) => api.patch(`${endPoint.jobs}/start-job/${jobId}`)
const getWorkedJobList = () => api.get(`${endPoint.jobs}/get-my-worked-job`);

const changeJobOfferStatus = (OfferId,status) => api.patch(`${endPoint.jobs}/change-job-offer-status/${OfferId}/${status}`)

const deleteJob = (data) => api.post(`${endPoint.jobs}/job_delete`,data);



export {
    createJob,
    getMyJobs,
    getCategoryList,
    getSubcategoryList,
    getAllJobList,
    getJobDetails,
    recentJobList,
    applyJob,
    getReceivedApplicationList,
    getAppliedApplicationList,
    jobApplicationApproval,
    getUserCategoryList,
    getUserSubcategoryList,
    getSentJobOffers,
    getReceivedJobOffers,
    getAcceptedJobOffers,
    acceptJobOffer,
    rejectJobOffer,
    allJobListBody,
    receivedApplicationsDisapproved,
    getAllJobsByUserId,
    newAcceptJobOffer,
    newGetAcceptedJobApplicant,
    getAcceptedJobApplicantByEmployeer,
    rejectJobOfferList,
    applyJobList,
    getApprovedJob,
    startJob,
    getWorkedJobList,
    changeJobOfferStatus,
    getOfferJobList,
    deleteJob
}