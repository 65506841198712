import { api, endPoint } from './client';

const getpaymentDetails = (data) => api.post(`${endPoint.bank}/transaction`, data);
const addBankDetails = (data)=>api.post(`${endPoint.bank}/update_bank_details`,data);
const addCard = (data)=>api.post(`${endPoint.bank}/update_card`,data);
const getBankList = ()=>api.get(`${endPoint.bank}/get_strip_bank_details`);

const getCardList = ()=>api.get(`${endPoint.bank}/get_strip_customer_details`);
const getCarddelete = (data)=>api.post(`${endPoint.bank}/delete_card`, data);
const getBankdelete = (data)=>api.post(`${endPoint.bank}/delete_bank_account`, data);
const updateDefaultAccount = (data)=>api.post(`${endPoint.bank}/update-default-bank`, data);
export {
    addBankDetails,
    getpaymentDetails,
    getBankList,
    addCard,
    getCardList,
    getCarddelete,
    getBankdelete,
    updateDefaultAccount
};