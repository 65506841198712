import React from 'react';
import { WhatsappShareButton, WhatsappIcon } from 'react-share';

const Whatsapp = ({ url, quotes, hashtag }) => {
    return (
        <>
            <WhatsappShareButton
                url={url}
                quotes={quotes}
                hashtag={hashtag}
            >
                <WhatsappIcon size={50} borderRadius={5} />
            </WhatsappShareButton>
        </>
    )
}

export default Whatsapp;
