import { api, endPoint } from './client';

const addNewAds = (data)=>api.post(`${endPoint.ads}/create-new-ad`,data);
const getMyAdd = () =>api.get(`${endPoint.ads}/my-add-list`)
const getAddList = () =>api.get(`${endPoint.ads}/add-list`)
const getAddDetail = (id) =>api.get(`${endPoint.ads}/my-add-detail/${id}`)
const isValidUrl = (data) =>api.post(`${endPoint.ads}/is-valid-url`,data)

export {
    addNewAds,
    getMyAdd,
    getAddList,
    getAddDetail,
    isValidUrl
};