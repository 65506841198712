import React, { useState, useEffect,useContext } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate, Navigate,  } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Button, Toast} from "react-bootstrap";
import { io } from 'socket.io-client';
import './App.css';
import './assets/css/App.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from './pages/Home';
import About from './pages/About';
import ContactUs from './pages/ContactUs';
import SignUp from "./pages/SignUp";
import Layout from './Layouts/Layout';
import SignIn from "./pages/signin";
import User from "./pages/user";
import CreateManageAds from "./pages/CreateManageAds";
import JobsList from "./pages/jobslist";
import JobDetails from "./pages/jobdetails";
import PrivacyPolicy from "./pages/privacypolicy";
import TermsServices from "./pages/termsofservices";
import store from "./utils/store";
import Modal from './components/Modal';
import { updateUserState } from "./utils/helper";
import { logout } from "./api/auth";
import { getAllCms, getLogoAndContactInfo } from "./api/cms";
import HomeNew from "./pages/Home2";
import ExpertiseDetails from "./pages/Expertise";
import { baseURL } from './config/baseUrl';
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import {Context} from './components/Context';
import SpinnerComponent from "./components/Spinner";
import AllJobs from "./pages/jobs/index";
import SearchJob from "./pages/jobslist/index";
import SearchCatJob from "./pages/jobslist/widthcat";
import ExpertiseList from "./pages/ExpertiseList";
import AllCategories from "./pages/AllCategories";
import { requestForToken, onMessageListener } from "./config/firebase";
import CreateNewAds from "./pages/CreateNewAds";
import Adsdetails from "./pages/AdsDetails";
import Earnings from "./pages/Earnings";
import EmployerProfile from "./pages/employerprofile";
import CreatedJob from "./pages/user/created-jobs";
import Workedjobdetails from "./pages/user/worked-jobdetails";
import EmployeeProfile from "./pages/employeeprofile";
import Otppage from "./pages/OtpPage";
import ErrorPage from "./pages/Error";

function App() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [isUpdateUser, setIsUpdateUser] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [render, setRender] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');

  const [aboutUs, setAboutUs] = useState({});
  const [privacyPolicy, setPrivacyPolicy] = useState({});
  const [terms, setTerms] = useState({});

  const [logo, setLogo] = useState('');
  const [contactInfo, setContactInfo] = useState({});
  const [socket, setSocket] = useState();

  const [recipientId, setRecipientId] = useState(null);
  const [homeSelectedCategoryId, setHomeSelectedCategoryId] = useState(null);
  const {isLoading,setIsLoading} = useContext(Context);
  const [notification, setNotification] = useState({title: '', body: ''});
  const [isTokenFound, setTokenFound] = useState(false);

  requestForToken(setTokenFound);
  const displayModal = (title = '', message = '') => {
    setModalTitle(title);
    setModalBody(message);
    setShowModal(true);
  }
  onMessageListener().then(payload => {
    setShow(true);
    setNotification({title: payload.notification.title, body: payload.notification.body})
    console.log(payload);
  }).catch(err => console.log('failed: ', err));

  // init socket
  const initSocket = () => {
    const socket = io(baseURL, {
      query: {
        token: localStorage.getItem('uverlist-user-token'),
      }
    });
    if (!socket) return;
    socket.on('connected', msg => console.log(msg))
    setSocket(socket);
  };

  useEffect(() => {
    if (!localStorage.getItem('uverlist-user-token')) return;
    initSocket();
  }, [user])

  const disconnectSocket = (userId) => {
    console.log('disconnectSocket')
    socket.emit('disconnectSocket', userId);
  };

  const LOGOUT = async (e) => {
    try {
      e.preventDefault();
      const res = await logout();
      if (!res.ok) return displayModal('Error', res.data.message);
      if (!res.data.success) return displayModal('Error', res.data.message);
      disconnectSocket(user._id);
      await store.clearUserData();
      setIsLoggedIn(false);
      setIsUpdateUser(true);
      navigate('/signin');
      return;
    } catch (err) {
      console.error(err);
    }
  }

  const updateUserState = async () => {
    try {
      const user = await store.getUser();

      if (!user) {
        setIsLoggedIn(false);
        setRender(true);
        setUser({});
        setIsUpdateUser(false);
        return;
      }

      setUser(user);
      setIsLoggedIn(true);
      setIsUpdateUser(false);
      setRender(true);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    updateUserState();
  }, [isUpdateUser])

  const GET_ALL_CMS = async () => {
    try {
      const res = await getAllCms();
      if (!res.ok) return;
      const allCms = res.data.cms;

      if (!allCms) return;
      if (!allCms.length) return;

      const aboutUsRegex = new RegExp('about', 'ig'),
        termsRegex = new RegExp('terms', 'ig'),
        privacyRegex = new RegExp('privacy', 'ig');

      const aboutUs = allCms.find(cms => aboutUsRegex.test(cms.title)) || {},
        terms = allCms.find(cms => termsRegex.test(cms.title)) || {},
        privacy = allCms.find(cms => privacyRegex.test(cms.title)) || {}
// console.log(aboutUs, "============================");
      setAboutUs(aboutUs);
      setTerms(terms);
      setPrivacyPolicy(privacy);

    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    GET_ALL_CMS();
  }, []);

  const GET_LOGO_CONTACT_INFO = async () => {
    try {
      const res = await getLogoAndContactInfo()
      if (!res.ok) return;
      const { logo, contactInfo } = res.data.data;
      setLogo(logo)
      setContactInfo(contactInfo)
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    GET_LOGO_CONTACT_INFO();
  }, []);

  return (
    <>
    
    <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide animation style={{
          position: 'absolute',
          bottom: 20,
          right: 20,
        }}>
           <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto">{notification.title}</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body>{notification.body}</Toast.Body>
        </Toast>
      <div className={isLoading ? "md:ml-64 overlay" : "md:ml-64"}>
      <Modal
        show={showModal}
        setShow={setShowModal}
        title={modalTitle}
        body={modalBody}
      />
      {
        render
        &&
        <Layout logo={logo} isLoggedIn={isLoggedIn} user={user} setIsUpdateUser={setIsUpdateUser} displayModal={displayModal} LOGOUT={LOGOUT} socket={socket}>
          <Routes>
            <Route
              path="/"
              element={
                <HomeNew
                  user={user}
                  setContactInfo={setContactInfo}
                  homeSelectedCategoryId={homeSelectedCategoryId}
                  setHomeSelectedCategoryId={setHomeSelectedCategoryId}
                />
              } />
            {/* <Route path="/" element={<Home setContactInfo={setContactInfo} />} /> */}

            <Route path="/About" element={<About aboutUs={aboutUs} />} />
            <Route path="/ExpertiseList" element={<ExpertiseList />} />
            <Route path="/AllCategories" element={<AllCategories />} />
            <Route path="/Jobs" element={<AllJobs user={user} />} />
            <Route path="/SearchJob" element={<SearchJob user={user} displayModal={displayModal} recipientId={recipientId} setRecipientId={setRecipientId} homeSelectedCategoryId={homeSelectedCategoryId} />} />
            <Route path="/SearchCatJob" element={<SearchCatJob user={user} displayModal={displayModal} recipientId={recipientId} setRecipientId={setRecipientId} homeSelectedCategoryId={homeSelectedCategoryId} />} />
            <Route path="/ContactUs" element={<ContactUs contactInfo={contactInfo} displayModal={displayModal} />} />

            <Route
              path="/signup"
              element={
                <SignUp displayModal={displayModal} />
              }
            />
            <Route
              path="/ForgotPassword"
              element={
                <ForgotPassword displayModal={displayModal} />
              }
            />
            <Route
              path="/Otp/:id/:email"
              element={
                <Otppage displayModal={displayModal} />
              }
            />
            <Route
              path="/ResetPassword"
              element={
                <ResetPassword displayModal={displayModal} />
              }
            />
            <Route path="/signin" element={
              isLoggedIn
                ?
                <Navigate to={'/user'} />
                :
                <SignIn setIsUpdateUser={setIsUpdateUser} setIsLoggedIn={setIsLoggedIn} displayModal={displayModal} />
            } />

            <Route
              path="/user"
              element={
                isLoggedIn
                  ?
                  <User
                    user={user}
                    socket={socket}
                    isLoggedIn={isLoggedIn}
                    displayModal={displayModal}
                    setIsUpdateUser={setIsUpdateUser}
                    recipientId={recipientId}
                    setRecipientId={setRecipientId}
                    LOGOUT={LOGOUT}
                  />
                  :
                  <Navigate to={'/signin'} />
              }
            />
            <Route
              path="/CreateManageAds"
              element={
                isLoggedIn
                  ?
                  <CreateManageAds
                    user={user}
                    socket={socket}
                    isLoggedIn={isLoggedIn}
                    displayModal={displayModal}
                    setIsUpdateUser={setIsUpdateUser}
                    recipientId={recipientId}
                    setRecipientId={setRecipientId}
                    LOGOUT={LOGOUT}
                  />
                  :
                  <Navigate to={'/signin'} />
              }
            />
            <Route
              path="/CreateNewAds"
              element={
                isLoggedIn
                  ?
                  <CreateNewAds
                    user={user}
                    socket={socket}
                    isLoggedIn={isLoggedIn}
                    displayModal={displayModal}
                    setIsUpdateUser={setIsUpdateUser}
                    recipientId={recipientId}
                    setRecipientId={setRecipientId}
                    LOGOUT={LOGOUT}
                  />
                  :
                  <Navigate to={'/signin'} />
              }
            />
 {/* <Route
              path="/Earnings"
              element={
                isLoggedIn
                  ?
                  <Earnings
                    user={user}
                    socket={socket}
                    isLoggedIn={isLoggedIn}
                    displayModal={displayModal}
                    setIsUpdateUser={setIsUpdateUser}
                    recipientId={recipientId}
                    setRecipientId={setRecipientId}
                    LOGOUT={LOGOUT}
                  />
                  :
                  <Navigate to={'/signin'} />
              }
            /> */}
            <Route
              path="/Earnings"
              element={
                isLoggedIn
                  ?
                  <Earnings
                    user={user}
                    socket={socket}
                    isLoggedIn={isLoggedIn}
                    displayModal={displayModal}
                    setIsUpdateUser={setIsUpdateUser}
                    recipientId={recipientId}
                    setRecipientId={setRecipientId}
                    LOGOUT={LOGOUT}
                  />
                  :
                  <Navigate to={'/signin'} />
              }
            />
            <Route path="/JobsList" element={<JobsList user={user} displayModal={displayModal} recipientId={recipientId} setRecipientId={setRecipientId} homeSelectedCategoryId={homeSelectedCategoryId} />} />

            <Route path="/JobDetails/:id" element={<JobDetails displayModal={displayModal} isLoggedIn={isLoggedIn} user={user} recipientId={recipientId} setRecipientId={setRecipientId} />} />

            <Route path="/PrivacyPolicy" element={<PrivacyPolicy privacyPolicy={privacyPolicy} />} />

            <Route path="/TermsServices" element={<TermsServices terms={terms} />} />
           
            <Route path="/ExpertiseDetails/:id" element={<ExpertiseDetails user={user} displayModal={displayModal} isLoggedIn={isLoggedIn} />} />
            <Route path="/EmployerProfile/:id" element={<EmployerProfile user={user} displayModal={displayModal} isLoggedIn={isLoggedIn}/>} />
            <Route path="/EmployeeProfile/:id" element={<EmployeeProfile user={user} displayModal={displayModal} isLoggedIn={isLoggedIn}/>} />
            <Route path="/CreatedJob/:jobId" element={<CreatedJob user={user} displayModal={displayModal} socket={socket} />} />
            <Route path="/Workedjobdetails/:jobId" element={<Workedjobdetails user={user} socket={socket}/>}  />
            <Route path="/Adsdetails/:id" element={<Adsdetails user={user} />} />
            <Route path="*" element={<ErrorPage  />} />
          </Routes>
        </Layout>
      }

<ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
    <SpinnerComponent isLoading={isLoading} />
    
    </>
  );
}

export default App;
