import { React, useEffect, useState, useContext } from 'react';
import { Button, Row, Col, Form, Image, Nav, Tabs, Tab, Card, Table } from 'react-bootstrap';
import share from '../../../../assets/images/share.png';
import del from '../../../../assets/images/del.png';
import { getAllJobsByUserId, deleteJob } from '../../../../api/job'
import { use, useNavigate } from 'react-router-dom'
import { Context } from '../../../../components/Context'

function tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
        time = time.slice(1);  // Remove full string match value
        time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
}
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
}
const JobsyouOfferedto = ({ userId, setIsLoggedIn, socket, displayModal }) => {
    const { isLoading, setIsLoading, setCreateJob, createJob, getMyCreatedJob } = useContext(Context);
    const navigate = useNavigate();


    useEffect(() => {
        getMyCreatedJob();
    }, [])

  
    const viewCreatedJob = (e, jobId) => {
        e.preventDefault();
        navigate(`/CreatedJob/${jobId}`);
    }

    const handelBtnChange = (e) => {
        e.preventDefault();
        let a = document.getElementById(e.target.id).id;
        console.log(a)
    }

    const listenToSocketEvents = () => {
        socket.on("createjob", (getjoboffer) => {
            setCreateJob(getjoboffer)
        });
    };

    useEffect(() => {
        console.log(createJob, 'createJob');
        if (!socket) return;
        listenToSocketEvents();
    }, [socket]);

    const handelDeleteJob = async (e, id) => {
        try {
            e.preventDefault();
            const res = await deleteJob({ job_id: id });
            if (res.ok) {
                displayModal('Success', res.data.message)
                getMyCreatedJob();
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <section className="profileinner">
                <Row>
                    <Col md={12} className="mb-3">
                        <div className='job-type-filter table-responsive p-c-job'>

                            <Table striped hover className='com-table nowsp-table'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Date</th>
                                        <th>Job Title</th>
                                        <th>Job Type</th>
                                        <th>Total Applied</th>
                                        <th>Amount</th>
                                        <th>Phone No</th>
                                        <th>Status</th>
                                        <th className='text-center'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        createJob.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        {item?.job_id}
                                                    </td>
                                                    <td>
                                                        {/* 10-3-2023   */}
                                                        {`${new Date(item.createdAt).getDate()}-${new Date(item.createdAt).getMonth()}-${new Date(item.createdAt).getFullYear()}`}
                                                    </td>
                                                    <td>
                                                        {item?.title}
                                                    </td>
                                                    <td>
                                                        {item?.job_type}
                                                    </td>
                                                    <td>
                                                        {item?.totalApply}
                                                    </td>
                                                    <td>
                                                        {item?.price?.type === 'fixed' ? `$${item?.price?.fixed}` : `$${item?.price?.min}-$${item?.price?.max}`}
                                                    </td>
                                                    <td>
                                                        {item?.employer_id?.phone_number}
                                                    </td>
                                                    <td className='fr-status'>
                                                        <span className={`new ${item?.status === 'In process' ? 'text-warning' : item?.status === 'closed' ? 'text-danger' : 'text-success'}`}>{item?.status}</span>
                                                    </td>
                                                    <td className='text-center'>
                                                        <a className='tbt-view tbtn mx-2 text-secondary' onClick={e => viewCreatedJob(e, item._id)}><i class="bi bi-eye-fill"></i></a>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }


                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </section>
        </>
    );
};

export default JobsyouOfferedto;