import { getAllJobList } from "../../api/job";
import { formatDate, format_time_12, getQueryString } from "../../utils/helper";
import { processJobResponse } from './helper';

const GET_JOB_LIST = async ({
    state,
    setState,
    func
}) => {
    try {
        // console.log(state,'SENDING FILTER_JOBS API REQUEST...');
        let query = {
            page: state.currentPage,
            limit: state.itemsLimit,
        }
        if (state.userId) query.userId = state.userId;
        
        if (state.searchTitle) query.title = state.searchTitle;
        
        if (state.searchLocation) query.location = state.searchLocation;
        
        if (state.selectedCategory) query.category = state.selectedCategory;
        
        if (state.selectedSubCategory) query.subcategory = state.selectedSubCategory;
        
        if (state.sortBy) query.sortBy = state.sortBy;
        
        if (state.startDate && state.endDate) {
        
            query.startDate = state.startDate;
        
            query.endDate = state.endDate;
        
        }

        if (state.price) query.priceLimit = state.price

        if (state.distance) {
            query.distance = state.distance;
            
            query.lat = state.coords ? state.coords.latitude : 0;
            
            query.long = state.coords ? state.coords.longitude : 0;
        
        }

        query = getQueryString(query);


        const res = await getAllJobList(query);
       
        
        if (!res.ok) return func.displayModal('Error', res.data.message);
        
        if (!res.data.success) return func.displayModal('Error', res.data.message);
        
        processJobResponse(res, setState.setCurrentPageLimit, setState.setTotalPages, setState.setJobs,setState.setTotalJob);
        
        setState.setIsFetchJobs(false);
    } catch (err) {
        console.error(err);
    }
}
// res.data.data.total
export {
    GET_JOB_LIST,
}