import {Button, Row, Col, Form, Image, Nav, Tab} from 'react-bootstrap';
const Message = ({  }) => {
	
	return (
		<>
        <section className="userinner">
			<Row>
                <Col md={12}>
                    <h3 className='user-listingpage-title'>
                        Message
                    </h3>
                    <hr />
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col md={12}>
                    
                </Col>
            </Row>
		</section>
		</>
	);
};

export default Message;