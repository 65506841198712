import { useEffect, useState } from 'react';
import { Modal, Button, Form, Image, Row } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';

const style = {
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 10
    },
    item: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    label: {
        fontWeight: 'bold'
    },
    value: {
        textAlign: 'right'
    },
    image: {
        width: 160,
        height: 160,
        objectFit: 'contain'
    },
    video: {
        width: '100%',
        height: 150,
        objectFit: 'contain',
        marginTop: 10,
    },
}

const ApplyJobModal = ({ show, setShow, job, handleResumeUpload, APPLY_JOB }) => {
    const [maxPrice,setMaxPrice] = useState('');
    const handleClose = () => setShow(false);
  

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Job Application</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form className='up-account' id='job-application-form'>

                        <Row className="mb-3">
                            <Form.Group md={12} controlId="formGridEmail">
                                <Form.Label className='min-leb'>Job Title</Form.Label>
                                <Form.Control className='f-inp' type="text" placeholder="Job Title" value={job.title} disabled />
                            </Form.Group>
                        </Row>

                        {/* <Row className="mb-3">
                            <Form.Group md={12} controlId="formGridEmail">
                                <Form.Label className='min-leb'>Category</Form.Label>
                                <Form.Control className='f-inp' type="text" placeholder="Category" value={job.categoryName} disabled />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group md={12} controlId="formGridEmail">
                                <Form.Label className='min-leb'>SubCategory</Form.Label>
                                <Form.Control className='f-inp' type="text" placeholder="SubCategory" value={job.subcategoryName} disabled />
                            </Form.Group>
                        </Row> */}

                       
                        {
                            job.priceType==='range'?<>
                             <Row className="mb-3">
                            <Form.Group md={12} controlId="formGridEmail">
                                <Form.Label className='min-leb'>Max Price</Form.Label>
                                {/* <Form.Control className='f-inp' type="text" placeholder="Salary" name='expectedSalary' /> */}
                                
                                {/* <CurrencyInput
                                    className='f-inp form-control'
                                    id="expectedSalary"
                                    name="expectedSalary"
                                    placeholder="$0.00"
                                    // prefix={"$"}
                                    //   fixedDecimalLength={2}
                                    decimalSeparator="."
                                    decimalsLimit={2}
                                    decimalScale={2}
                                    disabled={true}
                                    value={job.maxPrice}
                                /> */}
                                  <Form.Control className='f-inp' type="text" placeholder="Job Title" value={job.maxPrice} disabled={true} />
                            
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group md={12} controlId="formGridEmail">
                                <Form.Label className='min-leb'>Min Price</Form.Label>
                                {/* <Form.Control className='f-inp' type="text" placeholder="Salary" name='expectedSalary' /> */}
                                
                                {/* <CurrencyInput
                                    className='f-inp form-control'
                                    id="expectedSalary"
                                    name="expectedSalary"
                                    placeholder="$0.00"
                                    prefix={"$"}
                                    //   fixedDecimalLength={2}
                                    decimalSeparator="."
                                    decimalsLimit={2}
                                    decimalScale={2}
                                    disabled={true}
                                    // value={job.minPrice.splice(0, 1)}
                                /> */}
                                <Form.Control className='f-inp' type="text" placeholder="Job Title" value={job.minPrice} disabled={true} />
                            </Form.Group>
                        </Row>
                            </>: <Row className="mb-3">
                            <Form.Group md={12} controlId="formGridEmail">
                                <Form.Label className='min-leb'>Price</Form.Label>
                                {/* <Form.Control className='f-inp' type="text" placeholder="Salary" name='expectedSalary' /> */}
                                
                                {/* <CurrencyInput
                                    className='f-inp form-control'
                                    id="expectedSalary"
                                    name="expectedSalary"
                                    placeholder="$0.00"
                                    prefix={"$"}
                                    //   fixedDecimalLength={2}
                                    decimalSeparator="."
                                    decimalsLimit={2}
                                    decimalScale={2}
                                    disabled={true}
                                    // value={job.m}
                                /> */}
                                <Form.Control className='f-inp' type="text" placeholder="Job Title" value={job.salary} disabled={true} />
                            </Form.Group>
                        </Row>
                        }

                        <Row className="mb-3">
                            <Form.Group md={12} controlId="formGridEmail">
                                <Form.Label className='min-leb'>Date of Joining (Expected)</Form.Label>
                                <Form.Control className='f-inp' type="text" placeholder="Date" name='expectedJoiningDate' value={job.date} disabled={true} />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group md={12} controlId="formGridEmail">
                                <Form.Label className='min-leb'>Short Description</Form.Label>
                                <Form.Control as="textarea" rows={4} placeholder="Description" name='shortDescription' value={job.description} disabled={true} />
                            </Form.Group>
                        </Row>

                        {/* <Row className="mb-3">
                            <Form.Group md={12} controlId="formGridEmail">
                                <Form.Label className='min-leb'>Upload Resume</Form.Label>
                                <Form.Control type='file' name='resume' onChange={handleResumeUpload} />
                            </Form.Group>
                        </Row> */}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" type='submit' onClick={e => APPLY_JOB(document.getElementById('job-application-form'))}>
                        Apply
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ApplyJobModal;
