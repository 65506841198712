import { Form, Button, Image } from 'react-bootstrap';
import { handleFileChange, handleFileClick } from "../helper";

const ProfileImageControl = ({ styles, displayModal, profileImage, setProfileImage }) => (
    <>
        <div className='profile-img-jobpost' style={styles.customFileControl}>
            <Image src={profileImage} alt="" style={styles.resolution} />
        </div>
        <Form.Control id='profile-image-control' type='file' name='profileImage'  accept="image/png, image/gif, image/jpeg"  style={styles.inputFile} onChange={e => handleFileChange(e, displayModal, setProfileImage)}  />
        <Button id='profile-image-button' style={styles.selectButton} onClick={e => handleFileClick(e, document)}>select image &uarr;</Button>
        <br />
    </>
)

export default ProfileImageControl;