import React, { useState, useEffect, useContext } from "react";
import { Button, Row, Col, Form, Image } from 'react-bootstrap';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import email from '../../assets/images/email.svg';
import address from '../../assets/images/address.svg';
import facebook from '../../assets/images/facebook.png';
import google from '../../assets/images/google.png';
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';

// import FacebookLogin from 'react-facebook-login';
import {GOOGLE_CLIENT_ID,FACEBOOK_APP_ID} from '../../config/socalLogin';
// API
import { login,facebooklogin,googlelogin } from "../../api/auth";

// utils
import store from '../../utils/store';
import FacebookButton from "../../components/FacebookButton";
import {Context} from '../../components/Context'
const SignIn = ({ setIsUpdateUser, setIsLoggedIn, displayModal }) => {
	const {isLoading,setIsLoading} = useContext(Context);
	const [toggpasswordInputType, setToggpasswordInputType] = useState('password');

	const navigate = useNavigate();

	const LOGIN = async (e) => {
		// console.log(response)
		try {
			e.preventDefault();
			const form = new FormData(e.target);
			const data = {
				email: form.get('email'),
				password: form.get('password'),
				device_token: localStorage.getItem('device_token')
			}
			setIsLoading(true);
			const res = await login(data);
			if (!res.ok){
				return displayModal('Error', res.data.message)
			}else{
				setIsLoading(false);
			}
			if (!res.data.success){
				return displayModal('Error', res.data.message)
			} else{
				setIsLoading(false);
			}

			store.storeUser(res.data.data);
			store.storeUserToken(res.data.data.token);
			setIsLoggedIn(true);
			setIsUpdateUser(true);
			navigate('/Jobs');
		} catch (err) {
			console.error(err);
		}
	}
   

	const handleFacebookLogin = async response => {
		const data = {
			// email:response.email,
			// name:response.name,
			// firstname:response.first_name,
			// lastname:response.last_name,
			// profileimage:response.picture.data.url,
			fbId:response.id,
			accessToken:response.accessToken,
			device_token:localStorage.getItem('device_token')
		}
		// console.log(data);
		 const res = await facebooklogin(data)
		
		 if (!res.ok) return displayModal('Error', res.data.message)
		 if (!res.data.success) return displayModal('Error', res.data.message)
		    store.storeUser({...res.data.data,_id:res.data.data.id});
			store.storeUserToken(res.data.token);
			setIsLoggedIn(true);
			setIsUpdateUser(true);
			navigate('/Jobs');
	};

	const handelGoogleLogin = async response =>{
		const data = {
			email:response.profileObj.email,
			name:response.profileObj.name,
			firstname:response.profileObj.givenName,
			lastname:response.profileObj.familyName,
			profileimage:response.profileObj.imageUrl,
			googleId:response.profileObj.googleId,
			accessToken:response.accessToken,
			device_token:localStorage.getItem('device_token')
		}
		const res = await googlelogin(data)
		 if (!res.ok) return displayModal('Error', res.data.message)
		 if (!res.data.success) return displayModal('Error', res.data.message)
		    store.storeUser({...res.data.data,_id:res.data.data.id});
			store.storeUserToken(res.data.token);
			setIsLoggedIn(true);
			setIsUpdateUser(true);
			navigate('/Jobs');
		
	};

	// useEffect(() => {
	// 	const initClient = () => {
	// 		  gapi.client.init({
	// 		  clientId: GOOGLE_CLIENT_ID,
	// 		  scope: ''
	// 		});
	// 	 };
	// 	 gapi.load('client:auth2', initClient);
	// })
	useEffect(() => {
		const initClient = () => {
			  gapi.client.init({
			  clientId: GOOGLE_CLIENT_ID,
			  scope: ''
			});
		 };
		 gapi.load('client:auth2', initClient);
	},[])

	// const onSuccess = (res) => {
    //     console.log('success:', res);
    // };
    const onFailure = (err) => {
        console.log('failed:', err);
    };

	return (
		<>
			<section className="signuplogin  contact-us page py-5">
				<div className="container">
					<div className="si-re">
						<div className="sire-left f-color-w">
							<h1 className="sl-title">
								Flow The Step
							</h1>
							<p>
								Register and create your account
							</p>
							<p>
								Verify email and log in to your account
							</p>
							<p>
								Search for job/ post a job
							</p>
							<p>
								Apply for job/ choose employee
							</p>
							<p>
								Make payment once Job is complete/ get paid
							</p>
						</div>
						<div className="sire-right f-color-w">
							<Form onSubmit={LOGIN}>
								<h2 className="sl-su-title">
									Welcome
								</h2>
								<p className="mb-5">
									It is a long established fact that a reader will
								</p>
								<Form.Group className="mb-3" controlId="Email">
									<Form.Control type="text" placeholder="Email" autoFocus={true} name='email' />
								</Form.Group>

								<Form.Group className="mb-3 forpassword" controlId="password">
									<Form.Control type={toggpasswordInputType} placeholder="Password" name='password' />
									<i className={toggpasswordInputType == 'password' ? 'bi bi-eye-slash btnToggle' : 'bi bi-eye btnToggle'}
										id="togglePassword" onClick={e => setToggpasswordInputType(type => type == 'text' ? 'password' : 'text')}></i>
								</Form.Group>
								<Button variant="primary" type="submit">
									Sign In
								</Button>
								<Form.Group className="mb-3 text-end fg-pass" controlId="ForgotPassword">
									<NavLink to="/ForgotPassword">Forgot password?</NavLink>
								</Form.Group>
								<Form.Group className="mb-3 for-or" controlId="or">
									<span>Or</span>
								</Form.Group>
								<Form.Group className="my-4 socil-log text-center" controlId="confirmpassword">
									{/* <Button className="fb-btn">
										<Image src={facebook} alt="" /> <span>Facebook</span>
									</Button> */}
									{/* <FacebookLogin
										buttonStyle={{ color: '#fff', borderRadius: '10px', background: '#2173A8', width: '160px', padding: '12px', textTransform: 'lowercase' }}
										// containerStyle={{borderRadius:'10px'}}
										appId="893498091692950"
										autoLoad={true}
										icon="fa-facebook"
										fields="email"
										textButton="Facebook"
									
										// onClick={componentClicked}
										callback={LOGIN} /> */}
									{/* <Button className="gl-btn">
										<Image src={google} alt="" /> <span>Google</span>
									</Button> */}
							
								
								</Form.Group>
							</Form>
							<Form.Group className="my-4 socil-log text-center">
								<FacebookButton className="socil-fb" onLogin={handleFacebookLogin} FACEBOOK_APP_ID={FACEBOOK_APP_ID} />
								<GoogleLogin
									className="socil-gl"
									clientId={GOOGLE_CLIENT_ID}
									buttonText="Sign in with Google"
									onSuccess={handelGoogleLogin}
									onFailure={onFailure}
									cookiePolicy={'single_host_origin'}
									isSignedIn={false}
								/>
							</Form.Group>
							
							<p className="text-center mt-4">Don’t have an account? <NavLink to="/SignUp">Sign Up</NavLink></p>

						</div>
					</div>
				</div>

			</section>
		</>
	);
};

export default SignIn;
