import React, { useState, useEffect, useContext } from "react";
import { Button, Row, Col, Form, Image } from "react-bootstrap";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import fr from "../../assets/images/otp-icon1.png";
import { otpVerify, resendOtp } from "../../api/auth";
import OTPInput, { ResendOTP } from "otp-input-react";

const Otppage = () => {
  let { id, email } = useParams();
  const navigate = useNavigate();
  const [OTP, setOTP] = useState("");

  const otpVerifyFun = async () => {
    // console.log(OTP);
    const res = await otpVerify({
      userId: id,
      otp: OTP
    });
    
    if (res.data.success) {
      navigate('/SignIn')
    }
  }
  const resendOtpFun = async () => {
    // console.log(OTP);
    const res = await resendOtp({
      userId: id,
    });
    console.log(res);
  }

  useEffect(() => {
    if (OTP.length === 4) {

      otpVerifyFun();
    }
  }, [OTP])

  return (
    <>
      <section className="signuplogin  contact-us page py-5">
        <div className="container">
          <div className="si-re">
            <div className="sire-left f-color-w">
              <Image src={fr} className="img-fluid" />
            </div>
            <div className="sire-right f-color-w">
              <Form>
                <h2 className="sl-su-title">Verification Code</h2>
                <p className="mb-5">
                  Please enter your verification code sent to -<br />
                  <small>{email}</small>
                </p>
                <Form.Group className="mb-3 text-center forotp">
                  {/* <Form.Control type="text" placeholder="Enter your code" /> */}
                  <OTPInput
                    value={OTP}
                    onChange={setOTP}
                    autoFocus
                    OTPLength={4}
                    otpType="number"
                    disabled={false}
                    secure
                    style={{ justifyContent: 'center' }}
                  />
                  <ResendOTP
                    maxTime={60}
                    onResendClick={()=>resendOtpFun()}
                    style={{ flexDirection: 'column' }}
                  />
                </Form.Group>
                {/* <Form.Group className="mb-3 text-center">
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Form.Group> */}
              </Form>
              {/* <p className="text-center mt-4">
                If you don’t receive code?{" "}
                <a href="javascript:void(0)">Resend OTP</a>
              </p> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Otppage;
