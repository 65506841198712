import { api, endPoint } from './client';

const signup = (data) => api.post(`${endPoint.users}/user_signup_web`, data);
const otpVerify = (data) => api.post(`${endPoint.users}/verify-otp`, data);
const resendOtp = (data) => api.post(`${endPoint.users}/resend-otp`, data);

const login = (data) => api.post(`${endPoint.users}/user_login`, data);

const facebooklogin = (data) => api.post(`${endPoint.users}/facebooklogin`, data);


const googlelogin = (data) => api.post(`${endPoint.users}/googlelogin`, data)

const logout = () => api.post(`${endPoint.users}/logout`, {});

const changePassword = (data) => api.post(`${endPoint.users}/change-my-password`, data);

const forgotPassword = (data) => api.post(`${endPoint.users}/forgot-pass`,data);

export {
    signup,
    login,
    facebooklogin,
    logout,
    changePassword,
    forgotPassword,
    googlelogin,
    otpVerify,
    resendOtp
}

