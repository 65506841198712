import { useEffect, useState, useContext } from 'react';
import { Button, Row, Col, Form, Image, Nav, Tabs, Tab, Card } from 'react-bootstrap';
import star from '../../../../assets/images/star.png';
import { getMyJobs } from '../../../../api/job';
import { use, useNavigate } from 'react-router-dom';
import {Context} from '../../../../components/Context'
const JobWorked = ({ userId, setIsLoggedIn,socket }) => {
    const {isLoading,setIsLoading,workedJob,setWorkedJob,getMyWorkedJob} = useContext(Context);
    const navigate = useNavigate();
    

    function tConvert(time) {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
            time = time.slice(1);  // Remove full string match value
            time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(''); // return adjusted time or original string
    }
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
      }

    useEffect(() => {
        getMyWorkedJob();
    }, [])
    const viewCreatedJob = (e, jobId) => {
        e.preventDefault();
        navigate(`/Workedjobdetails/${jobId}`);
    }

    const listenToSocketEvents = () => {
        socket.on("workedjob", (getjoboffer) => {
            setWorkedJob(getjoboffer)
        });
      };
    
      useEffect(() => {
        if (!socket) return;
        listenToSocketEvents();
      }, [socket]);
    return (
        <>
            <section className="profileinner">
                <Row>
                    <Col md={12} className="mb-3">
                        <div className='job-type-filter p-c-job'>
                            <Tabs
                                defaultActiveKey="all"
                                id="uncontrolled-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="all" title="All">
                                    <Row className='pplist '>
                                        {workedJob.map((item, index) => {
                                            return (
                                                <Col md={12} className="mb-3">
                                                    <Card className="radius-12">
                                                        <Card.Body>
                                                            <h4>
                                                                {item?.title} <span>--  {item?.job_type}</span>
                                                            </h4>
                                                            <p className='m-0'>
                                                                {formatDate(item?.date)}<br /> {tConvert(item?.start_time)} - {tConvert(item?.end_time)}
                                                            </p>

                                                            <Button className='btn-green' onClick={e => viewCreatedJob(e, item._id)}>
                                                                View 
                                                            </Button>
                                                            <div className={`com-prosess ${item?.status === 'In process' ? 'inprocess' : 'complet'}`}>{item?.status}</div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </Tab>
                                <Tab eventKey="Public" title="Public job">
                                    {workedJob.map((item, index) => {
                                        return (
                                            item?.job_type === 'public'
                                            &&
                                            <Col md={12} className="pplist mb-3">
                                                <Card className="radius-12">
                                                    <Card.Body>
                                                        <h4>
                                                            {item?.title} <span>--  {item?.job_type}</span>
                                                        </h4>
                                                        <p className='m-0'>
                                                        {formatDate(item?.date)}<br /> {tConvert(item?.start_time)} - {tConvert(item?.end_time)}
                                                        </p>

                                                        <Button className='btn-green' onClick={e => viewCreatedJob(e, item._id)}>
                                                            View
                                                        </Button>
                                                        <div className={`com-prosess ${item?.status === 'In process' ? 'inprocess' : 'complet'}`}>{item?.status}</div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        )
                                    })}
                                </Tab>
                                <Tab eventKey="Private" title="Private job">
                                    {workedJob.map((item, index) => {
                                        return (
                                            item?.job_type === 'private'
                                            &&
                                            <Col md={12} className="pplist mb-3">
                                                <Card className="radius-12">
                                                    <Card.Body>
                                                        <h4>
                                                            {item?.title} <span>--  {item?.job_type}</span>
                                                        </h4>
                                                        <p className='m-0'>
                                                        {formatDate(item?.date)}<br /> {tConvert(item?.start_time)} - {tConvert(item?.end_time)}
                                                        </p>

                                                        <Button className='btn-green' onClick={e => viewCreatedJob(e, item._id)}>
                                                            View
                                                        </Button>
                                                        {/* <div className={`com-prosess ${item?.status === 'In process' ? 'inprocess' : 'complet'}`}>{item?.status}</div> */}
                                                        <div className={`com-prosess ${item?.status === 'In process' ? 'inprocess' :item?.status==='closed'?'closed':'complet'}`}>{item?.status}</div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>

                                        )
                                    })}
                                </Tab>
                            </Tabs>
                        </div>
                    </Col>
                </Row>

            </section>
        </>
    );
};

export default JobWorked;
