import React, { useState,useRef,useEffect } from 'react';
import BankInfo from './bankinfo';
import CreditCardInfo from './creditcardinfo';
import ProfileInfo from './profileinfo';

const Account = ({ user, displayModal, setIsUpdateUser }) => {
 
    return (
        <>
            <ProfileInfo
                user={user}
                displayModal={displayModal}
                setIsUpdateUser={setIsUpdateUser}
            />

            <BankInfo displayModal={displayModal}  />

            <CreditCardInfo user={user}  displayModal={displayModal}/>
        </>
    );
};

export default Account;