import React, { useState, useEffect,useContext } from "react";
import { Button, Image, Dropdown } from "react-bootstrap";
import { Link, NavLink, useNavigate } from 'react-router-dom';
import SignUp from "../../pages/SignUp";
import SignIn from "../../pages/signin";
// import logo from '../../assets/images/logo.svg';
import job from '../../assets/images/send.svg';
import joblist from '../../assets/images/job-list.svg';
import Download from '../../assets/images/download.svg';
import dashboardicon from '../../assets/images/dashboard-gr.svg';
import signup from '../../assets/images/user-add.svg';
import sinin from '../../assets/images/login.svg';
import toggle from '../../assets/images/menu-right.svg';
import profile from '../../assets/images/pp-pic.png';
import store from "../../utils/store";
import notification from "../../assets/images/wh-notifications.svg"
import noimage from '../../assets/images/noImage.png';
import { Context } from '../../components/Context'

const Header = ({ logo, user, isLoggedIn, LOGOUT,socket }) => {
  const navigate = useNavigate();
  const [scroll, setScroll] = useState(0)
  const { getAllNotificationsCount,setTotalNotification,totalNotification } = useContext(Context);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY < 100
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck)
      }
    })
  })



  useEffect(()=>{
    setTimeout(() => {
      getAllNotificationsCount();
    }, 1000);
    
  },[])

  const handlePostJob = (e) => {
    e.preventDefault();
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    store.storeLastEventKey('Post-Job')
    navigate(isLoggedIn ? '/user' : '/signin');
  };
  const handleAccount = (e) => {
    // e.preventDefault();
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    store.storeLastEventKey('Account')
    navigate(isLoggedIn ? '/user' : '/signin');
  };
  const handleDashboard = (e) => {
    e.preventDefault();
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    store.storeLastEventKey('Dashboard')
    navigate(isLoggedIn ? '/user' : '/signin');
  };
  const handleNotifications= (e) => {
    // e.preventDefault();
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    store.storeLastEventKey('Notifications')
    navigate(isLoggedIn ? '/user' : '/signin');
  };


  const listenToSocketEvents = () => {
    socket.on('notification', notification => {
        setTotalNotification(prevActiveStep=>prevActiveStep + 1)
    });
}

useEffect(() => {
    if (!socket) return;
    listenToSocketEvents();
}, [socket]);

  return (
    <>
    {/* <Notification /> */}
      <header className={scroll ? "main-header" : "main-header active"}>
        <div className="container">
          <div className="row">
            <div className="col-md-2 col-lg-2 col-3 comm-p-0">
              <NavLink to="/Jobs" className="brand" onClick={e => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}>
                <Image className="logo" src={logo} alt="" style={{ maxwidth: 200 }} />
              </NavLink>
            </div>
            <div className="col-md-10 col-lg-10 col-9 text-end right-nav">
              {/* <NavLink className="btn btn-outline-white" to="/Jobs" onClick={e => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}>
                <Image src={joblist} alt="Jobs listing" /> <span>Jobs listing</span>
              </NavLink>
              <NavLink className="btn btn-outline-white" to='/' onClick={handlePostJob}>
                <Image src={job} alt="Job" /> <span> Post a Job</span>
              </NavLink>
              <NavLink className="btn btn-outline-green" to="/" onClick={e => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}>
                <Image src={Download} alt="Download App" /><span> Download App </span>
              </NavLink> */}
              {
                !isLoggedIn
                &&
                <>
                  <NavLink className="btn link-btn-whi" to="/signup" onClick={e => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}>
                    <Image src={signup} alt="Sign Up" />   <span>Sign Up</span>
                  </NavLink>
                  <NavLink className="btn link-btn-whi" to="/SignIn" onClick={e => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}>
                    <Image src={sinin} alt="Sign In" />  <span>Sign In</span>
                  </NavLink>
                </>
              }
               {
                isLoggedIn
                &&
                <>
                <NavLink className="btn btn-outline-green" to="/" onClick={handleDashboard}>
                <Image src={dashboardicon} alt="Download App" /><span> Dashboard </span>
              </NavLink>
                  <Dropdown className="hed-top-drop profile-drop-pic">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {user?.profileimage ? (
                        <Image src={user?.profileimage} alt="" className="p-pic" onClick={handleAccount} />
                      ) : (
                        <Image src={noimage} alt="" className="p-pic" onClick={handleAccount} />
                      )}
                       <span>{user.username}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#/user" onClick={handleAccount}>View Account</Dropdown.Item>
                      <Dropdown.Item onClick={LOGOUT}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <NavLink className="notification-btn" to="/SignIn" onClick={handleNotifications}>
                      <Image src={notification} alt="Sign In" />  <span>{totalNotification}</span>
                  </NavLink>
                </>
                

              }
              
              <Dropdown className="hed-top-drop">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <Image src={toggle} alt="Toggle" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#/About" onClick={e => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}>About Us</Dropdown.Item>
                  <Dropdown.Item href="#/TermsServices" onClick={e => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}>Terms of Services</Dropdown.Item>
                  <Dropdown.Item href="#/PrivacyPolicy" onClick={e => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}>Privacy Policy</Dropdown.Item>
                  <Dropdown.Item href="#/ContactUs" onClick={e => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}>Contact us</Dropdown.Item>
                  <Dropdown.Item href="#/CreateManageAds" onClick={e => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}>Create/Manage Ads</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
             
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
