import React from 'react';
import { FacebookShareButton, FacebookIcon, FacebookMessengerIcon } from 'react-share';

const Facebook = ({ url, quotes, hashtag }) => {
    return (
        <>
            <FacebookShareButton
                url={url}
                quotes={quotes}
                hashtag={hashtag}
            >
                <FacebookIcon size={50} borderRadius={5} />
            </FacebookShareButton>
        </>
    )
}

export default Facebook;
