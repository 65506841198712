import { React, useEffect, useState, useContext } from 'react';
import { Button, Row, Col, Form, Image, Nav, Tabs, Tab, Card } from 'react-bootstrap';
import share from '../../../../assets/images/share.png';
import del from '../../../../assets/images/del.png';
import { getAllJobsByUserId,deleteJob } from '../../../../api/job'
import { use, useNavigate } from 'react-router-dom'
import {Context} from '../../../../components/Context'
// import moment from 'moment';
// import {
//     FacebookShareButton,
//     TwitterShareButton,
//     WhatsappShareButton
// } from "react-share";
import {
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon
} from "react-share";

function tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
        time = time.slice(1);  // Remove full string match value
        time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
}
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }
const JobCreated = ({ userId, setIsLoggedIn,socket,displayModal }) => {
    const {isLoading,setIsLoading,setCreateJob,createJob,getMyCreatedJob} = useContext(Context);
    const navigate = useNavigate();
    
   
    useEffect(() => {
        getMyCreatedJob();
    }, [])

    const handelSairTwitter = (e) => {
        e.preventDefault();

    }
    const viewCreatedJob = (e, jobId) => {
        e.preventDefault();
        navigate(`/CreatedJob/${jobId}`);
    }

    const handelBtnChange = (e) => {
        e.preventDefault();
        let a = document.getElementById(e.target.id).id;
        console.log(a)
    }

    const listenToSocketEvents = () => {
        socket.on("createjob", (getjoboffer) => {
            // setJobList(jobList=>[getjoboffer,...jobList])
            // console.log(getjoboffer)
            setCreateJob(getjoboffer)
        });
      };
    
      useEffect(() => {
        if (!socket) return;
        listenToSocketEvents();
      }, [socket]);

      const handelDeleteJob = async (e,id)=>{
        try {
            e.preventDefault();
            const res = await deleteJob({job_id:id});
            if(res.ok){
                displayModal('Success',res.data.message)
                getMyCreatedJob();
            }
        } catch (error) {
            console.log(error)
        }
      }

    return (
        <>
            <section className="profileinner">
                <Row>
                    <Col md={12} className="mb-3">
                        <div className='job-type-filter p-c-job'>
                            {/* <Button className='active' id={'btn-1'} onClick={e=>handelBtnChange(e)}>All</Button> 
                       <Button id={'btn-2'} onClick={e=>handelBtnChange(e)}>Public job</Button> 
                       <Button id={'btn-3'} onClick={e=>handelBtnChange(e)}>Private job</Button> */}
                            <Tabs
                                defaultActiveKey="profile"
                                id="uncontrolled-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="home" title="All">
                                    {/* <Sonnet /> */}
                                    <Row className='pplist'>
                                        {createJob.map((item, index) => {
                                            return (
                                                <Col md={12} className="mb-3">
                                                    <Card className="radius-12">
                                                        <Card.Body>
                                                            <h4>
                                                                {item?.title} <span>--  {item?.job_type}</span>
                                                            </h4>
                                                            <p className='m-0'>
                                                                {/* {
                                                                    item?.date!==null
                                                                    &&

                                                                }
                                                            {moment(item?.startDate).format("dddd, MMM DD")} */}
                                                         {formatDate(item?.date)}<br /> {tConvert(item?.start_time)} - {tConvert(item?.end_time)}
                                                            </p>
                                                            <p className='mt-2'>
                                                                Total Applied :<strong>{item?.totalApply}</strong>
                                                            </p>
                                                            <div className='btn-re-sh'>
                                                                <Button className='btn-green' onClick={e => viewCreatedJob(e, item._id)}>
                                                                    View
                                                                </Button>
                                                                <Button className='btn-outline-green for-share'>
                                                                    <Image src={share} alt="" /> Share
                                                                    <div className="for-share-icon" >
                                                                        <span>
                                                                            <TwitterIcon size={32} round={true} onClick={e => handelSairTwitter} /> Twitter
                                                                        </span>
                                                                        <span>
                                                                            <FacebookIcon size={32} round={true} /> Facebook
                                                                        </span>
                                                                        <span>
                                                                            <WhatsappIcon size={32} round={true} /> Whatsapp
                                                                        </span>

                                                                    </div>
                                                                </Button>
                                                                {item?.status==='closed'?
                                                                  <Button className='btn-green text-denger' onClick={e => handelDeleteJob(e, item._id)}>
                                                                  <Image src={del} width="20px" alt="" />  Delete
                                                                  </Button>:''
                                                                }
                                                              
                                                                {/* closed */}
                                                            </div>
                                                            <div className={`com-prosess ${item?.status === 'In process' ? 'inprocess' :item?.status==='closed'?'closed':'complet'}`}>{item?.status}</div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </Tab>
                                <Tab eventKey="profile" title="Public job" className=''>
                                    {/* <Sonnet /> */}
                                    <Row className='pplist'>
                                        {createJob.map((item, index) => {
                                            return (
                                                item.job_type === 'public'
                                                &&
                                                <Col md={12} className="mb-3">
                                                    <Card className="radius-12">
                                                        <Card.Body>
                                                            <h4>
                                                                {item?.title} <span>--  {item?.job_type}</span>
                                                            </h4>
                                                            <p className='m-0'>
                                                            {formatDate(item?.date)}<br /> {tConvert(item?.start_time)} - {tConvert(item?.end_time)}
                                                            </p>
                                                             <p className='mt-2'>
                                                                Total Applied :<strong>{item?.totalApply}</strong>
                                                            </p>
                                                            <div className='btn-re-sh'>
                                                                <Button className='btn-green' onClick={e => viewCreatedJob(e, item._id)}>
                                                                    View
                                                                </Button>
                                                                <Button className='btn-outline-green for-share'>
                                                                    <Image src={share} alt="" /> Share
                                                                    <div className="for-share-icon" >
                                                                        <span>
                                                                            <TwitterIcon size={32} round={true} onClick={e => handelSairTwitter} /> Twitter
                                                                        </span>
                                                                        <span>
                                                                            <FacebookIcon size={32} round={true} /> Facebook
                                                                        </span>
                                                                        <span>
                                                                            <WhatsappIcon size={32} round={true} /> Whatsapp
                                                                        </span>

                                                                    </div>
                                                                </Button>
                                                                {item?.status==='closed'?
                                                                  <Button className='btn-green text-denger' onClick={e => handelDeleteJob(e, item._id)}>
                                                                  <Image src={del} width="20px" alt="" />  Delete
                                                                  </Button>:''
                                                                }
                                                            </div>
                                                            <div className={`com-prosess ${item?.status === 'In process' ? 'inprocess' :item?.status==='closed'?'closed':'complet'}`}>{item?.status}</div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </Tab>
                                <Tab eventKey="contact" title="Private job" className=''>
                                    {/* <Sonnet /> */}
                                    <Row className='pplist'>
                                        {createJob.map((item, index) => {
                                            return (
                                                item.job_type === 'private'
                                                &&
                                                <Col md={12} className="mb-3">
                                                    <Card className="radius-12">
                                                        <Card.Body>
                                                            <h4>
                                                                {item?.title} <span>--  {item?.job_type}</span>
                                                            </h4>
                                                            <p className='m-0'>
                                                            {formatDate(item?.date)}<br /> {tConvert(item?.start_time)} - {tConvert(item?.end_time)}
                                                            </p>
                                                            <p className='mt-2'>
                                                                Total Applied :<strong>{item?.totalApply}</strong>
                                                            </p>
                                                            <div className='btn-re-sh'>
                                                                <Button className='btn-green' onClick={e => viewCreatedJob(e, item._id)}>
                                                                    View
                                                                </Button>
                                                                <Button className='btn-outline-green for-share'>
                                                                    <Image src={share} alt="" /> Share
                                                                    <div className="for-share-icon" >
                                                                        <span>
                                                                            <TwitterIcon size={32} round={true} onClick={e => handelSairTwitter} /> Twitter
                                                                        </span>
                                                                        <span>
                                                                            <FacebookIcon size={32} round={true} /> Facebook
                                                                        </span>
                                                                        <span>
                                                                            <WhatsappIcon size={32} round={true} /> Whatsapp
                                                                        </span>

                                                                    </div>
                                                                </Button>
                                                                {item?.status==='closed'?
                                                                  <Button className='btn-green text-denger' onClick={e => handelDeleteJob(e, item._id)}>
                                                                  <Image src={del} width="20px" alt="" />  Delete
                                                                  </Button>:''
                                                                }
                                                            </div>
                                                            <div className={`com-prosess ${item?.status === 'In process' ? 'inprocess' :item?.status==='closed'?'closed':'complet'}`}>{item?.status}</div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </Tab>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
                {/* <Row className='pplist'>
               {createJob.map((item,index)=>{
                  return (
                    <Col md={12} className="mb-3">
                    <Card className="radius-12">
                        <Card.Body>
                            <h4>
                            {item?.title} <span>--  {item?.job_type}</span>
                            </h4>
                            <p className='m-0'>
                                {
                                    
                                }
                                January 3, 2016 @{ tConvert(item?.start_time)} - {tConvert(item?.end_time )}
                            </p>
                            <div className='btn-re-sh'>
                                <Button className='btn-green' onClick={e => viewCreatedJob ()}>
                                    Review
                                </Button>
                                <Button className='btn-outline-green for-share'>
                                   <Image src={share} alt="" /> Share
                                   <div className="for-share-icon" >
                                   <span>
                                        <TwitterIcon size={32} round={true} onClick={e=>handelSairTwitter} /> Twitter
                                    </span>
                                    <span>
                                        <FacebookIcon size={32} round={true} /> Facebook
                                    </span>
                                    <span>
                                            <WhatsappIcon size={32} round={true} /> Whatsapp
                                    </span>
                                    
                                   </div>
                                </Button>
                                
                            </div>
                            <div className='com-prosess inprocess'>In process</div>
                        </Card.Body>
                    </Card>
                </Col>
                  )
               })}
            </Row> */}
            </section>
        </>
    );
};

export default JobCreated;