import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';


const FacebookButton = ({ onLogin,FACEBOOK_APP_ID }) => (
  <FacebookLogin
    appId={FACEBOOK_APP_ID}
    autoLoad={false}
    callback={onLogin}
    scope='public_profile,email'
    fields="first_name,last_name,name,email,picture"
    render={renderProps => (
      <button onClick={renderProps.onClick}>Facebook</button>
    )}
  />
);

export default FacebookButton;
