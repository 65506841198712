import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form, Image, Card } from 'react-bootstrap';
import './EmployerProfile.css'
import ep1 from '../../assets/images/pp1.jpg';
import call from '../../assets/images/phone-cal.svg';
import email from '../../assets/images/email.svg';
import chat from '../../assets/images/chat.png';
import star from '../../assets/images/star.png';
import { useParams } from "react-router-dom";
import { getProfile } from "../../api/profile";
import { useNavigate } from 'react-router-dom';
import noimage from  '../../assets/images/noImage.png'
import moment from 'moment'
const EmployerProfile = ({}) => {
    const avgRatingCalculator = (ratings)=>{
        if(ratings.length===0){
            return 0
        }
        const sum = ratings.reduce((total, rating) => total + rating.rate, 0);
        const avg = sum / ratings.length;  
        return avg.toFixed(1);
        }

    const navigate = useNavigate();
    function handleGoBack() {
        navigate(-1);
      }
    let  {id}  = useParams();
    const [avgRating,setAvgRating] = useState(0);
    const [employer, SetEmployer]=useState({});
      const GET_Employer_DETAILS = async () => {
        try {
          const res = await getProfile(id);
          SetEmployer(res.data.data);
          console.log(employer, "employer")
          setAvgRating(avgRatingCalculator(res.data.data.rating))
        } catch (error) {
          console.log(error);
        }
      };
      useEffect(() => {
        GET_Employer_DETAILS();
      }, []);

    return (
        <>
            
            <section className="job-banner">
                <div className="container">
                    <Row>
                        <Col md={12} className="text-center">
                            <h1>
                                View Profile
                            </h1>
                            <button className="btn btn-green" onClick={handleGoBack}>Go back</button>
                            {/* <p>
                                You will achieve the level of safety excellence that you demonstrate you want 
                            </p> */}
                        </Col>
                    </Row>
                </div>
            </section>
            <section className="joblispage-top py-5">
                <div className="container">
                    <Row>
                        <Col md={4} lg={3} className="text-center">
                            <div className="exp-details">
                                <div className="ex-pic">
                                {employer?.profileimage ? (
                                               <Image src={employer?.profileimage} alt="" />
                                            ) : (
                                                <Image src={noimage} alt=""/>
                                           )}
                                
                                </div>
                                <div className="ex-cont">
                                    <h3>{employer?.firstname} <br /> <small><Image src={star} alt="user" /> 
                                    {employer?.rating?.rate}/5 - {employer?.rating?.total} Reviews</small></h3>
                                    
                                        <>
                                            <Button className="btn-outline-green mb-3 w-100" >
                                                <Image src={chat} alt="" /> Chat
                                            </Button>
                                    </>
                                    
                                </div>
                            </div>
                        </Col>
                        <Col md={8} lg={9} className="ps-lg-5 ps-md-5 ">
                            <div className="exptiselist mb-3">
                            
                                <p>
                                    <strong>
                                        Full Name
                                    </strong>
                                    <span>
                                      {employer?.firstname} {employer?.lastname}
                                    </span>
                                </p>
                                <p>
                                    <strong>
                                        Email Id
                                    </strong>
                                    <span>
                                    {employer?.email}
                                    </span>
                                </p>
                                <p>
                                    <strong>
                                        Phone No
                                    </strong>
                                    <span>
                                    {employer?.phone_number}
                                    </span>
                                </p>
                                    <p>
                                        <strong>
                                          Gender
                                        </strong>
                                        <span>
                                        {employer?.gender}
                                        </span>
                                    </p>
                                
                                <p>
                                    <strong>
                                        Address
                                    </strong>
                                    <span>
                                    {employer?.address}
                                    </span>
                                </p>
                                
                            </div>

                            <div className="exptiselist mb-3 reting-info">
                            <div className="reting-heading mb-3">
                                <h2>
                                <Image src={star} alt="user" /> {avgRating}/5 - {employer?.rating?employer?.rating.length:''} Reviews
                                </h2>
                                <hr />
                            </div>
                            <div className="rating-maxlant">
                            {
                                employer?.rating
                                &&
                                employer?.rating.map((item,index)=>{
                                    return (
                                    <div className="reting-list mb-3">
                                        <div className="reting-list-top">
                                            <div className="pp-pic">
                                                {item?.job_id?.employersId?.profileimage ? ( <Image src={item?.job_id?.employersId?.profileimage}  alt="" />):(<Image src={noimage} alt=""/>) }
                                            </div>
                                            <div className="rp-cont">
                                                <h3>
                                                    {item?.job_id?.employersId?.firstname} {item?.job_id?.employersId?.lastname} <small><Image src={star} alt="user" /> <span>{item?.rate} Stars</span></small>
                                                </h3>
                                                <p>
                                                {moment().diff(item?.currentTime,"days")} days ago
        
                                                </p>
                                            </div>
                                        </div>
                                        <div className="reting-list-bottom">
                                            <p>{item?.desc}</p>
                                        </div>
                                    </div>
                                    )
                                })
                            }
                            </div>
                            
                            
                        </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </>
    )
}
export default EmployerProfile;