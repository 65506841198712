import React, { useEffect,useState } from "react";
import { Col, Row, Container, Button, Image, Card } from "react-bootstrap";
import ads2 from '../../assets/images/ads2.jpg';
import ads3 from '../../assets/images/ads3.jpg';
import ads4 from '../../assets/images/ads4.jpg';
import {getAddDetail} from '../../api/ads'

// import video from '../../assets/video/mountains.mp4'
import { useNavigate, NavLink,useParams } from "react-router-dom";
import  './adsdetails.css';
import AdsItem from "../../components/AdsItem";
import { Chart } from "react-google-charts";
import moment from 'moment'

//  const data = [
//     ["Day", ""],
//     ["1st jan", 1000],
//     ["2nd jan", 1170],
//     ["3rd jan", 660],
//     ["4th jan", 1030],
//     ["5th jan", 1030],
//   ];
  const options = {
    chart: {
      title: "Users reach per day",
      subtitle: "Last 4 days your ads reach",
    },
  };


const Adsdetails = () => {
    const [addDetails,setAddDetails] = useState({});
    const [chart,setChart] = useState([]);
    const { id } = useParams();
    // console.log(addDetails, "=================")
    const GET_USER_DETAIL = async ()=>{
        try {
            const res = await getAddDetail(id);
            res.data.resData.start_date = res.data.resData.start_date.slice(0,10)
            res.data.resData.end_date = res.data.resData.end_date.slice(0,10)
            console.log(res.data.resData,"HHHHHHHHHHH");
            setAddDetails(res.data.resData)
            setChart(res.data.chart)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(()=>{
       
        GET_USER_DETAIL()
        // alert(id)
    },[])
	return (
		<>
        <section class="aflotop"></section>
        <section className="inner  contact-us page py-5">
			<Container>
                <Row>
                    <Col lg={7}>
                        <Card className="ads-info-details mb-3">
                            <Row>
                                <Col lg={5}>
                                    {addDetails?.file_type==='image'? <Image alt="" src={addDetails?.profileimage} className="min-img-ads"/>:<video src={addDetails?.profileimage} controls autoplay={true} style={{width:"100%"}}>Your browser does not support the video tag.</video>} 
                                </Col>
                                <Col lg={7}>
                                </Col>
                            </Row>
                            
                            <p><strong>Type</strong> {addDetails?.ad_type}<span></span> </p>
                            <p><strong>Title</strong> <span>{addDetails?.title}</span> </p>
                            <p><strong>Description  </strong> <span>{addDetails?.description}</span></p>
                            <p><strong>Website link </strong> <span> {addDetails?.website_link}</span></p>
                            <p><strong>Start date  </strong> <span>{new Date(addDetails?.start_date).getDate()} {moment(addDetails?.start_date).format('MMMM')} {new Date(addDetails?.start_date).getFullYear()}</span></p>
                            <p><strong>End date  </strong> <span>{new Date(addDetails?.end_date).getDate()} {moment(addDetails?.end_date).format('MMMM')} {new Date(addDetails?.end_date).getFullYear()}</span></p>
                            {/* <p><strong>Cover Image</strong> <span><Image alt="" src={ads4} /></span> </p> */}
                            <h5 className="mb-3"><strong>Target Audience </strong> </h5>
                            <p><strong>Age Range</strong> <span>{addDetails?.max_age} years - {addDetails?.min_age} years</span> </p>
                            <p><strong>Zipcode</strong> <span>{addDetails?.zipcode}</span> </p>
                            <p><strong>State</strong> <span>{addDetails?.state}</span> </p>
                            <p><strong>Interests</strong> <span>{addDetails?.interest}</span> </p>
                        </Card>
                    </Col>
                    <Col lg={5} className="mb-5">
                        <Card className="mb-3">
                            <Chart
                            chartType="Line"
                            width="100%"
                            height="400px"
                            data={chart}
                            options={options}
                            />
                        </Card>
                        <Card className="mb-3 demgrafiyoureach ads-info-details">
                            <h1 className="to-reach"><small> Total reach in this ads</small> <span>{addDetails?.Clicks?.length}</span></h1>
                            <p><strong>Budget </strong> <span>${addDetails?.cost_to_advertiser?.toFixed(2)}</span> </p>
                            <p><strong>Add Budget Per Day </strong> <span>${addDetails?.budget_per_day?.toFixed(2)}</span> </p>
                            
                            <p><strong>Created Ad on</strong> <span>{new Date(addDetails?.createdAt).getDate()} {moment(addDetails?.createdAt).format('MMMM')} {new Date(addDetails?.createdAt).getFullYear()}</span> </p>
                            <p><strong>Adevertisement Length</strong> <span>{addDetails?.budgetDays} days</span></p>
                            {/* <h1 className="to-reach"><span>$35</span><small>(Balance are available)</small></h1> */}
                        </Card>
                        
                    
                    </Col>
                </Row>
			</Container>
		</section>
        
		</>
	);
};

export default Adsdetails;
