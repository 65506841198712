import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form, Image, Nav, Tab, Card, Table } from 'react-bootstrap';
import JobList from "../jobslist/JobList";
import JobFilter from "../jobslist/JobFilter";
import project1 from '../../assets/images/project1.png';
import project2 from '../../assets/images/project2.png';
import project3 from '../../assets/images/project3.png';
import project4 from '../../assets/images/project4.jpg';
import project5 from '../../assets/images/project5.jpg';
import project6 from '../../assets/images/project6.jpg';
import call from '../../assets/images/phone-cal.svg';
import email from '../../assets/images/email.svg';
import CustomPagination from "../../components/CustomPagination";
import { getExpertList } from "../../api/expertise";
import { useNavigate, NavLink } from "react-router-dom";
import { getExpectedCost, formatJobs } from "../../utils/helper";

const ExpertiseList = ({ user = {}}) =>{
    const [expertList, setExpertList] = useState([]);
    const navigate = useNavigate()
    const GET_EXPERT_LIST = async () => {
        try {
            const res = await getExpertList();
            if (!res.ok) return;
            let expertList = res.data.data;

            if (user._id) {
                expertList = expertList.filter(usr => usr._id !== user._id);
            };

            setExpertList(expertList);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        GET_EXPERT_LIST();
    }, []);
    const viewExpertDetails = (id) => {
        navigate(`/ExpertiseDetails/${id}`);
    };
        const getUserOccupation = (user) => {
        if (!user.occupation) return '';
        if (!user.occupation.length) return '';
        let categoryNames = user.occupation.map(occ => occ.category ? occ.category.name : '');
        return categoryNames.join(', ');
    };
    const getUserEmail = (email) => {
        if (!email) return;
        let idealEmail = "mohammed.thurabudeen@brainiuminfotech.com";
        if (email.length <= idealEmail.length) return email;

        let email1 = email.substring(0, idealEmail.length);
        let email2 = email.substring(idealEmail.length, email.length);
        let email3 = null;

        if (email2.length > idealEmail.length) {
            email2 = email.substring(idealEmail.length, 2 * idealEmail.length);
            email3 = email.substring(2 * idealEmail.length, email.length);
        }
        

        return (
            email3
                ?
                <span>
                    {email1} <br />
                    {email2} <br />
                    {email3}
                </span>
                :
                <span>
                    {email1} <br />
                    {email2}
                </span>
        )


    };
    return(
        <>
           <section className="job-banner">
				<div className="container">
					<Row>
						<Col md={12} className="text-center">
							<h1>
                            Expertise List
							</h1>
						</Col>
					</Row>
				</div>
			</section>
            <section className="py-5">
                <div className="container">
                <Row>
                    {/* <Col lg={4} className="mb-3">
                        <div className="expatis-list pointer" >
                            <div className="expatis-item">
                                <div className="p-pic">
                                    <Image src={project5} alt="" />
                                </div>
                                <div className="p-cont">
                                    <h3>Dwayne Johnson <br /> <small>Design</small></h3>
                                    <div class="price-details">$200 / day</div>
                                    <p className="rating">
                                        <i class="bi bi-star-fill"></i>
                                        <i class="bi bi-star-fill"></i>
                                        <i class="bi bi-star-fill"></i>
                                        <i class="bi bi-star"></i>
                                        <i class="bi bi-star"></i>

                                    </p>
                                    <p>
                                        <Image src={call} alt="" /> <span> +91 8670035689</span>
                                    </p>
                                    <p className="email-id-cust">
                                        <Image src={email} alt="" />
                                        
                                            <span>
                                            joy2005in@gmail.com
                                            </span>
                                        
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col> */}
                    {
                expertList.length > 0
                &&
                expertList.map(user=>{
                    return (
                        <Col lg={4} className="mb-3">
                    <div className="expatis-list pointer" onClick={e => viewExpertDetails(user._id)} >
                    <div className="expatis-item">
                        <div className="p-pic">
                            <Image src={user?.profileimage} alt="" />
                        </div>
                        <div className="p-cont">
                            <h3>{user.firstname} {user.lastname} <br /> <small>{getUserOccupation(user)}</small></h3>
                            <div class="price-details">{getExpectedCost(user.expectedCost)}</div>
                            <p className="rating">
                                <i class="bi bi-star-fill"></i>
                                <i class="bi bi-star-fill"></i>
                                <i class="bi bi-star-fill"></i>
                                <i class="bi bi-star"></i>
                                <i class="bi bi-star"></i>
                
                            </p>
                            <p>
                                <Image src={call} alt="" /> <span>{user.phone_number}</span>
                            </p>
                            <p className="email-id-cust">
                                <Image src={email} alt="" />
                                
                                    <span>
                                    {getUserEmail(user.email)}
                                    </span>
                                
                            </p>
                        </div>
                    </div>
                </div>
                </Col>)
                })
                
            }

                </Row>
                            <Row>
                                <Col>
                                    <div style={{ display: 'flex', justifyContent: 'right', marginTop: 10, padding: 0 }}>
                                        <CustomPagination
                                            totalPages="20"

                                            pointer='1'
                                            limit='100'
                                        />
                                    </div>
                                </Col>
                            </Row>
                    
                </div>
            </section>
			
        </>
    )
}
export default ExpertiseList;