import React, { useState, useEffect,useContext } from "react";
import { Link, NavLink,useNavigate} from 'react-router-dom';
import about from '../../assets/images/about-right.jpg';
import jobbanner from '../../assets/images/job-banner.png';
import chat from '../../assets/images/chat.png';
import user from '../../assets/images/user.png';
import cattype from '../../assets/images/type.png';
import star from '../../assets/images/star.png';
import { Button, Row, Col, Form, Image, Nav, Tab, Card, Table } from 'react-bootstrap';
import CustomPagination from "../../components/CustomPagination";
import Map from '../../components/GoogleMaps';
import { Context } from "../../components/Context";

const JobList = ({
    // coords,
    jobs,
    limit,
    totalPages,
    handlePageClick,
    setPointer,
    pointer,
    currentPageLimit,
    handleChatNow,
    handleApplyJob,
}) => {
    const { distance,coords } = useContext(Context);
    const navigate = useNavigate();
    const [jobLocations, setJobLocations] = useState([]);
    
    const getJobLocations = () => {
        const jobLocations = jobs.map(job => {
            const { coordinates } = job.location;
            return {
                _id: job._id,
                title: job.title.substring(0, 10) + '..',
                icon: job?.profileImage,
                location: {
                    lat: coordinates[1],
                    lng: coordinates[0],
                }
            }
        });
        setJobLocations(jobLocations);
    };

    useEffect(() => {
        if (!jobs.length) return setJobLocations([]);
            getJobLocations();
        
    }, [jobs,distance,coords]);

    const viewJob = (id) => navigate(`/JobDetails/${id}`)

    return (
        <>
            <Col lg={12} md={12} className="job-fil-right">
                <Row>
                    <Col md={12}>
                        <h2>
                            Showing {currentPageLimit} / {jobs.length} jobs 
                        </h2>
                    </Col>
                </Row>
            
                <Row className="job-fil mt-3">
                    
                    <div className="col-md-8">
                        <Row>
                        {
                            jobs
                            &&
                            jobs.length > 0
                            &&
                            jobs.map(job => (
                                <Col md={4} className="mb-4" onClick={e => viewJob(job._id)}>
                                 <div className="rjcarsd">
                                <img src={job?.profileImage} />
                                <div className="top-cont">
                                    <h3>
                                    {job.title}<br /><small>{job.createdDate}</small>
                                    </h3>
                                    <p>
                                    {job.description ? job.description.substring(0, 73) : ''}
                                    </p>
                                </div>
                                         <div className="bottom-cont">
                                             {
                                                 job.priceType === 'fixed'
                                                     ?
                                                     <span
                                                         style={{
                                                             fontSize: job.salary
                                                         }}
                                                     >
                                                         {job.salary}
                                                     </span>
                                                     :
                                                     <span
                                                         style={{
                                                             fontSize: job.maxPrice
                                                         }}
                                                     >
                                                         {job.salary}
                                                     </span>
                                             }
                                         </div>
                                 </div>
                                </Col>
                            ))
                        } 
                        </Row>
                    
                    
                    </div>
                    <div className="mb-4 col-md-4 for-right">
                        {
                            coords
                            &&
                            <Map
                                center={{
                                    lat: coords.latitude?coords.latitude:0,
                                    lng: coords.longitude?coords.longitude:0,
                                    
                                }}
                                
                                jobLocations={jobLocations}
                                distance={Number(distance)}
                            />
                        }
                    </div>      
                </Row>
                {/* <Row>
                    <Col>
                        <div style={{ display: 'flex', justifyContent: 'right', marginTop: 10, padding: 0,  }}>
                            <CustomPagination
                                totalPages={totalPages}
                                handlePageClick={handlePageClick}
                                setPointer={setPointer}
                                pointer={pointer}
                                limit={limit}
                            />
                        </div>
                    </Col>
                </Row> */}
            </Col>
        </>
    )
}

export default JobList;