import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form, Image, Card } from 'react-bootstrap';
import './worked-jobdetails.css'
import { getJobDetails, applyJobList } from '../../../api/job';
import { useParams, useNavigate } from 'react-router-dom'
import ep1 from '../../../assets/images/pp1.jpg';
import moment from 'moment';
import call from '../../../assets/images/phone-cal.svg';
import email from '../../../assets/images/email.svg';
import thum from '../../../assets/images/ads2.jpg';
import star from '../../../assets/images/star.png';

const Workedjobdetails = ({socket}) => {
    const navigate = useNavigate();
    function handleGoBack() {
        navigate(-1);
      }
    const [jobData, setJobData] = useState({});
    const [jobApplyList, setJobApplyList] = useState([]);
    let { jobId } = useParams();
    const format = (hrEle, minEle, hrEle1, minEle1, startDate, endDate, date) => {

        if (date !== null) {
            if (hrEle >= 0 && hrEle <= 24 && minEle >= 0 && minEle <= 60 && hrEle1 >= 0 && hrEle1 <= 24 && minEle1 >= 0 && minEle1 <= 60) {
                let AMorPM = 'AM';
                let AMorPM1 = 'AM'
                if (hrEle > 12) AMorPM = 'PM';
                if (hrEle1 > 12) AMorPM1 = 'PM';
                hrEle = (hrEle % 12);
                hrEle1 = (hrEle1 % 12);
                return `${moment(jobData?.date).format("dddd, MMM DD")},${hrEle} : ${minEle} ${AMorPM}  -  ${hrEle1} : ${minEle1} ${AMorPM1}`;
            }
        } else {

            if (hrEle >= 0 && hrEle <= 24 && minEle >= 0 && minEle <= 60 && hrEle1 >= 0 && hrEle1 <= 24 && minEle1 >= 0 && minEle1 <= 60) {
                let AMorPM = 'AM';
                let AMorPM1 = 'AM'
                if (hrEle > 12) AMorPM = 'PM';
                if (hrEle1 > 12) AMorPM1 = 'PM';
                hrEle = (hrEle % 12);
                hrEle1 = (hrEle1 % 12);
                return `${moment(jobData?.startDate).format("dddd, MMM DD")}  to  ${moment(jobData?.endDate).format("dddd, MMM DD")}   ,    ${hrEle} : ${minEle} ${AMorPM}  -  ${hrEle1} : ${minEle1} ${AMorPM1}`;
            }
        }

    }

    const GET_JOB_DETAILS = async () => {
        try {
            const res = await getJobDetails(jobId);
            setJobData(res.data.data);
            console.log(res.data.data, 'abcd');
        } catch (error) {
            console.log(error);
        }
    }
    const GET_APPLY_JOB_LIST = async () => {
        try {
            const res = await applyJobList(jobId);
            if (res.ok) {
                setJobApplyList(res.data.data);
                console.log(res.data.data, 'new data');
            }
            // conso
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        GET_JOB_DETAILS();
         GET_APPLY_JOB_LIST();
    }, [])
    const showProfile = (e, id) => {
        navigate(`/EmployeeProfile/${id}`);
    }

    const listenToSocketEvents = () => {
        socket.on("getacceptedList", (getacceptedList) => {
          setJobApplyList(getacceptedList)
        });
      };
    
      const listenToSocketEvents1 = () => {
        socket.on("getjobdetailsweb", (getacceptedList) => {
          setJobData(getacceptedList);
        });
      };
    
      useEffect(() => {
        if (!socket) return;
        listenToSocketEvents();
      }, [socket]);
    
      useEffect(() => {
        if (!socket) return;
        listenToSocketEvents1();
      }, [socket]);

    return (
        <>
            
            <section className="job-banner">
                <div className="container">
                    <Row>
                        <Col md={12} className="text-center">
                            <h1>
                            Job Details
                            </h1>
                            <button className="btn btn-green" onClick={handleGoBack}>Go back</button>
                            {/* <p>
                                You will achieve the level of safety excellence that you demonstrate you want 
                            </p> */}
                        </Col>
                    </Row>
                </div>
            </section>
            <section className="joblispage-top py-5">
                <div className="container">
                    <Row>
                        
                        <Col md={12} lg={12} className="ps-lg-5 ps-md-5 ">
                            <div className="exptiselist mb-3">
                            <Row>
                                <Col lg={4} md={4} className="mb-3">
                                    <Image src={jobData?.profileImage}  className="w-100" />
                                </Col>
                                <Col lg={8} md={8}>
                                
                                
                                <p>
                                    <strong>
                                    Job Title
                                    </strong>
                                    <span>
                                        {jobData?.title} 
                                    </span>
                                </p>
                                <p>
                                    <strong>
                                    Post By
                                    </strong>
                                    <span>
                                       <a style={{cursor:'pointer', fontWeight: '700'}} className="text-success text-bold " onClick={(e)=> showProfile(e, jobData?.employer_id?._id)}>{jobData?.employer_id?.firstname} {jobData?.employer_id?.lastname} </a>
                                    </span>
                                </p>
                                
                                {/* <p>
                                    <strong>
                                    Status
                                    </strong>
                                    <span className="text-warning">
                                    {jobData?.status}
                                    </span>
                                </p> */}
                                    {
                      jobData?.employeesId && jobData?.status === 'completed'
                      &&
                      <p>
                      <strong>Status </strong>
                      <span className="text-success">
                      {jobData?.status}
                      </span>
                      </p>
                    }
                                    {
                      jobData?.employeesId && jobData?.status === 'completed'
                      &&
                      <p>
                      <strong>Tips </strong>
                      <span>
                      {jobData?.tips}
                      </span>
                      </p>
                    }
                                <p>
                                    <strong>
                                    Project Amount
                                    </strong>
                                    {/* <span>
                                    {jobData?.price?.type === 'fixed' ? `$${jobData?.price?.fixed}` : `$${jobData?.price?.min}-$${jobData?.price?.max}`}
                                    </span> */}
                                        <span>
                        {jobData?.price?.type === "fixed"
                          ? `$${jobData?.price?.fixed}`
                          : jobData?.price ? `$${jobData?.price?.min}-$${jobData?.price?.max}` : ''}
                      </span>
                      {
                      jobData?.employeesId && jobData?.status === 'In process'
                      &&
                      <p>
                      <strong>Status </strong>
                      <span className="text-warning">
                      {jobData?.status}
                      </span>
                      </p>
                    }
                                </p>
                                    <p>
                                        <strong>
                                        Time schedule
                                        </strong>
                                        <span>
                                        {

format(jobData?.start_time?.split(':')[0], jobData?.start_time?.split(':')[1], jobData?.end_time?.split(':')[0], jobData?.end_time?.split(':')[1], jobData?.startDate, jobData?.endDate, jobData?.date)

}
                                        </span>
                                    </p>
                                
                                <p>
                                    <strong>
                                        Address
                                    </strong>
                                    <span>
                                    {jobData?.address}
                                    </span>
                                </p>
                                
                                </Col>
                            </Row>
                            </div>
                            <div className="exptiselist mb-3 reting-info applid-lis">
                                <div className="reting-heading mb-3">
                                    <h2>
                                    Job Applied list
                                    </h2>
                                    <hr />
                                </div>
                                {
                                    jobApplyList.length > 0
                                    &&
                                    jobApplyList.map((item, index) => {
                                        return (
                                            <div className="reting-list mb-3 ">
                                                <div className="reting-list-top ">
                                                    <div className="pp-pic">
                                                        <Image src={item?.employee_id?.profileimage} alt="" />
                                                    </div>
                                                    <div className="rp-cont">
                                                        <h3>
                                                            {item?.employee_id?.firstname} {item?.employee_id?.lastname}
                                                        </h3>
                                                        <p>
                                                        {new Date().getDay() -
                                new Date(item?.createdAt).getDay()}{" "} days ago

                                                        </p>
                                                        {/* <button className="btn btn-green apr-bt" onClick={(e)=> showProfile(e, item?.employee_id?._id)}>
                                                            View Profile
                                                        </button> */}
                                                        <button onClick={(e) => showProfile(e, item?.employee_id?._id)} className="for-view-p btn btn-green"><i className="bi bi-eye-fill"></i></button>
                                                    </div>
                                                </div>

                                            </div>
                                        )
                                    })
                                }
                            
                             </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </>
    )
}
export default Workedjobdetails;