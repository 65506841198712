import { api, endPoint } from './client';

const createChat = (data) => api.post(`${endPoint.chat}/createChat`, data)

const sendMessage = (data) => api.post(`${endPoint.chat}/sendMessage`, data)
    
const getChatHistory = () => api.get(`${endPoint.chat}/chatHistory`);

const getChat = (chatId) => api.get(`${endPoint.chat}/chat/${chatId}`);

export {
    createChat,
    sendMessage,
    getChatHistory,
    getChat,
}