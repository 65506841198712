
import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Form, Image, Nav, Tab, Dropdown, DropdownButton, Card, Modal } from 'react-bootstrap';
import sendmail from '../../../assets/images/send-mail.png';
import noImage from '../../../assets/images/noImage.png';
const ITEMS_PER_PAGE = 10;
const UserList = ({ type, users, viewUser, handleSearch, ADD_FRIEND, ACCEPT_REQUEST, REJECT_REQUEST, paginate }) => {
    const [addfriendshow, setAddfriendshow] = useState(false);

    const handleClose = () => setAddfriendshow(false);
    const handleSubmit = e => {
        e.preventDefault();
        setAddfriendshow(true);

        const form = document.getElementById('get-emailnew');
        const formData = new FormData(form)
        console.log(Object.fromEntries(formData.entries()).name);
        const obj = {

            search: Object.fromEntries(formData.entries()).name,

        }
        console.log(formData);
    }



    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [visibleItems, setVisibleItems] = useState([]);

    useEffect(() => {
        setTotalPages(Math.ceil(users.length / ITEMS_PER_PAGE));
    }, [users]);

    useEffect(() => {
        const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
        const endIndex = startIndex + ITEMS_PER_PAGE;
        setVisibleItems(users.slice(startIndex, endIndex));
    }, [currentPage, users]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };
    return (
        <>
            {
                // users
                // &&
                // users.length > 0
                // &&
                // type !== 'friendRequest'
                // &&
                <Form className='search-friends' id={'get-emailnew'} onSubmit={e => handleSubmit(e)}>
                    <Row>
                        <Col md={4} lg={4} className="friend-search-add mb-4 pr-0 col-8">

                            <Form.Group className="">
                                <Form.Control className='f-inp' type="text" name="name" placeholder="Search Friends..." onChange={e => handleSearch(e, type)} />
                            </Form.Group>

                        </Col>
                        <Col md={4} className='col-4'>



                            {/* <>
                                {
                                    type === 'addFriend'
                                    &&
                                    <Button className='btn-green btn btn-primary' type='submit' disabled={users.length === 0 ? '' : 'false'}>
                                        Add Friend
                                    </Button>

                                }
                            </> */}

                            <Modal show={addfriendshow} onHide={handleClose}>
                                {/* <Modal.Header closeButton>
                            <Modal.Title>Send Friend Requests</Modal.Title>
                            </Modal.Header> */}
                                <Modal.Body className='p-5'>

                                    <Row>
                                        <Col md={12} className="text-center">
                                            <Image src={sendmail} className="send-mail-img-size" />
                                            <h3 className='text-center p-3'>
                                                This user is not currently in Uverlist, would you like to send them an invite?
                                            </h3>
                                        </Col>
                                        <Col md={12} className="text-center">
                                            <Button className='btn-outline-green btn btn-primary m-2' onClick={handleClose}>
                                                Close
                                            </Button>
                                            <Button className='btn-green btn btn-primary m-2' onClick={handleClose}>
                                                Invite
                                            </Button>
                                        </Col>
                                    </Row>


                                </Modal.Body>

                            </Modal>
                        </Col>
                    </Row>
                </Form>
            }
            <Row>
                {
                    users
                    &&
                    users.length > 0
                    &&
                    visibleItems.map(user => (
                        <Col md={12} className="mb-3" key={user._id}>
                            <Card className="radius-12 frd-list">
                                <Card.Body>
                                    <div className='left-pic'>
                                        <Image src={user.profileimage?user.profileimage:noImage} alt="" />
                                    </div>
                                    <div className='right-text'>
                                        <h4>
                                            {user.firstname} {user.lastname}
                                        </h4>
                                        <p>
                                            {user.email}
                                        </p>
                                        <p>
                                            {user.phone_number}
                                        </p>
                                    </div>
                                    <DropdownButton id="dropdown-basic-button">
                                        <Dropdown.Item onClick={e => viewUser(user)}>View</Dropdown.Item>
                                        {
                                            type === 'addFriend'
                                            &&
                                            <Dropdown.Item onClick={e => ADD_FRIEND(user._id)}>Add</Dropdown.Item>
                                        }
                                        {
                                            type === 'friendRequest'
                                            &&
                                            <>
                                                <Dropdown.Item onClick={e => ACCEPT_REQUEST(user.requestId)}>Accept</Dropdown.Item>
                                                <Dropdown.Item onClick={e => REJECT_REQUEST(user.requestId)}>Reject</Dropdown.Item>
                                            </>
                                        }
                                        {
                                            type === 'friends'
                                            &&
                                            <Dropdown.Item onClick={e => REJECT_REQUEST(user.requestId)}>Remove</Dropdown.Item>
                                        }
                                    </DropdownButton>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                }
                <Col lg={12} className="text-end">
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                        <button
                            key={page}
                            onClick={() => handlePageChange(page)}
                            disabled={page === currentPage}
                            className={page === currentPage ? 'pagina-btn active' : 'pagina-btn'}
                        >
                            {page}
                        </button>
                    ))}

                </Col>
            </Row>
        </>
    )
}

export default UserList;