import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form, Image, Nav, Tab, Card, Table } from 'react-bootstrap';

const JobFilter = ({
    price,
    distance,
    categoryList,
    subcategoryList,
    handleFilterInput,
    selectedCategory,
}) => {
    const [value3, setValue3] = useState(2);
    const [value2, setValue2] = useState(5);
    const [type, setType] = useState('text');
    const [type2, setType2] = useState('text');
    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
        setActive(!isActive);
    };

    return (
        <>
            <Col lg={3} md={5} className="filter">
                <Button className={"btn-green btn btn-primary d-block d-md-none w-100 mb-3  " + (isActive ? 'd-none' : '')} onClick={toggleClass}>
                    Jobs filter
                </Button>
                <Form className={"filter-from d-none d-md-block " + (isActive ? 'show' : '')} >
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control type="text" placeholder="Job Title" className="f-inp " name='title' onChange={handleFilterInput} />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control type="text" placeholder="Location" className="f-inp " name='location' onChange={handleFilterInput} />
                            </Form.Group>
                        </Col>
                        {/* <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Distance: {distance} miles</Form.Label>
                                <div className="mb-3" style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                                    <Form.Label>0</Form.Label>
                                    <Form.Range
                                        name='distance'
                                        value={distance}
                                        onChange={handleFilterInput}
                                        variant='success'
                                        min={0}
                                        max={1000}
                                    />
                                    <Form.Label>1000</Form.Label>
                                </div>

                            </Form.Group>
                        </Col> */}
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Sort</Form.Label>
                                <Form.Select aria-label="Default select example" className="f-inp " name='sortBy' onChange={handleFilterInput}>
                                    <option value=''>Sort by</option>
                                    <option value='pricehightolow'>price high to low</option>
                                    <option value='pricelowtohigh'>price low to high</option>
                                    <option value='posted'>posted date</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        {/* <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Control name='startDate' onChange={handleFilterInput} type={type2} onFocus={() => setType2('date')} onBlur={() => setType2('text')} placeholder="Date From" className="f-inp " />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Control name='endDate' onChange={handleFilterInput} type={type} onFocus={() => setType('date')} onBlur={() => setType('text')}
                                    placeholder="To Date" className="f-inp " />
                            </Form.Group>
                        </Col> */}
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Price: ${price}</Form.Label>

                                <div className="mb-3" style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                                    <Form.Label>$0</Form.Label>
                                    <Form.Range
                                        name='price'
                                        value={price}
                                        onChange={handleFilterInput}
                                        variant='success'
                                        min={0}
                                        max={10000}
                                    />
                                    <Form.Label>$10000</Form.Label>
                                </div>

                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Filter By</Form.Label>
                                <Form.Select
                                    aria-label="Default select example"
                                    className="f-inp "
                                    name='category'
                                    onChange={handleFilterInput}
                                >
                                    <option value=''>Category</option>
                                    {
                                        (categoryList && categoryList.length > 0)
                                        &&
                                        categoryList.map(cat =>
                                            <option key={cat._id} value={cat._id} selected={cat._id === selectedCategory}>{cat.name}</option>
                                        )
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        {
                            subcategoryList
                            &&
                            subcategoryList.length > 0
                            &&
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Filter By</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        className="f-inp "
                                        name='subcategory'
                                        onChange={handleFilterInput}
                                    >
                                        <option value=''>Sub Category</option>
                                        {
                                            subcategoryList.map(subcat =>
                                                <option key={subcat._id} value={subcat._id}>{subcat.name}</option>
                                            )
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        }
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Button className="btn-green btn btn-primary d-block d-md-none" onClick={toggleClass}>
                                Filter Now
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </>
    )
}

export default JobFilter;