import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import about from "../../assets/images/about-right.jpg";
import jobbanner from "../../assets/images/job-banner.png";
import chat from "../../assets/images/chat.png";
import user from "../../assets/images/user.png";
import cattype from "../../assets/images/type.png";
import star from "../../assets/images/star.png";
import {
  Button,
  Row,
  Col,
  Form,
  Image,
  Nav,
  Tab,
  Card,
  Table,
} from "react-bootstrap";
import { useGeolocated } from "react-geolocated";
import JobFilter from "./JobFilter";
import JobList from "./JobList";
import { GET_CATEGORY_LIST, handleCategoryChange } from "../user/service";
import { GET_JOB_LIST } from "./service";
import { checkThrottle, throttle } from "./helper";
import ApplyJobModal from "../../components/ApplyJobModal";
import { applyJob } from "../../api/job";
import view from "../../assets/images/entypo.svg";
import { recentJobList, getCategoryList } from "../../api/job";
const JobsList = ({
  user,
  isLoggedIn,
  displayModal,
  setRecipientId,
  homeSelectedCategoryId,
}) => {
  const navigate = useNavigate();

  // Get Current Location
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  //Pagination
  const [itemsLimit, setItemsLimit] = useState(6);
  const [totalPages, setTotalPages] = useState(0);
  const [pointer, setPointer] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageLimit, setCurrentPageLimit] = useState(0);
  const [totalJob, setTotalJob] = useState(0);
  // Job
  const [jobs, setJobs] = useState([]);

  // Job Filter
  const [searchTitle, setSearchTitle] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [timer, setTimer] = useState(0); //sec
  const [timerId, setTimerId] = useState(null);
  const [isFetchJobs, setIsFetchJobs] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [subcategoryList, setSubCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(
    homeSelectedCategoryId
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [price, setPrice] = useState(0);
  const [distance, setDistance] = useState(1000);
  // Job Application Modal
  const [showModal, setShowModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState({});
  const [resume, setResume] = useState("");
  const { state } = useLocation();

  const handlePageClick = ({ pageIndex, isSetPointer = true }) => {
    window.scrollTo(0, 0);
    if (isSetPointer) setPointer(pageIndex);
    setCurrentPage(pageIndex);
    setIsFetchJobs(true);
  };

  useEffect(() => {
    if (!isFetchJobs || !coords) return;
    GET_JOB_LIST({
      state: {
        userId: user._id,
        currentPage,
        itemsLimit,
        searchTitle: state.title ? state.title : searchTitle,
        searchLocation,
        selectedCategory: state.category ? state.category : selectedCategory,
        selectedSubCategory,
        sortBy,
        startDate,
        endDate,
        price,
        distance: state.distance,
        coords: { latitude: state.lat, longitude: state.long },
      },
      setState: {
        setCurrentPageLimit,
        setTotalPages,
        setJobs,
        setIsFetchJobs,
        setTotalJob,
      },
      func: {
        displayModal,
      },
    });
  }, [user, isFetchJobs, coords, homeSelectedCategoryId]);

  useEffect(() => {
    checkThrottle(timer, setTimer, timerId, setIsFetchJobs);
  }, [timer]);

  const _throttle = () => throttle(timerId, setTimerId, setTimer);

  const handleFilterInput = (e) => {
    switch (e.target.name) {
      case "title": {
        console.log("title search");
        setSearchTitle(e.target.value);
        _throttle();
        break;
      }
      case "location": {
        console.log("location search");
        setSearchLocation(e.target.value);
        _throttle();
        break;
      }
      case "category": {
        console.log("category search");
        handleCategoryChange(e, setSelectedCategory, setSubCategoryList);
        setSelectedSubCategory("");
        setIsFetchJobs(true);
        break;
      }
      case "subcategory": {
        console.log("sub category search");
        setSelectedSubCategory(e.target.value);
        setIsFetchJobs(true);
        break;
      }
      case "sortBy": {
        console.log("sort by ", e.target.value);
        setSortBy(e.target.value);
        setIsFetchJobs(true);
        break;
      }
      case "startDate": {
        console.log("start date ", e.target.value);
        setStartDate(e.target.value);
        if (endDate) setIsFetchJobs(true);
        break;
      }
      case "endDate": {
        console.log("end date ", e.target.value);
        setEndDate(e.target.value);
        if (startDate) setIsFetchJobs(true);
        break;
      }
      case "price": {
        console.log("price range", e.target.value);
        setPrice(e.target.value);
        _throttle();
        break;
      }
      case "distance": {
        const newDistance = e.target.value;
        console.log("search nearest jobs within ", newDistance);
        setDistance(newDistance);
        _throttle();
        break;
      }
    }
  };

  useEffect(() => {
    GET_CATEGORY_LIST(setCategoryList);
  }, []);

  const handleChatNow = (e, employer) => {
    e.preventDefault();

    //1. Set local storage variable
    localStorage.setItem("isRedirectChatNow", true);
    localStorage.setItem("recipientId", employer._id);
    setRecipientId(employer._id);

    if (!localStorage.getItem("uverlist-user-token"))
      return navigate("/signin");
    navigate("/user");
    return;
  };

  const handleApplyJob = (e, job) => {
    e.preventDefault();
    if (!localStorage.getItem("uverlist-user-token"))
      return navigate("/signin");
    console.log("applying to job", job);
    setSelectedJob(job);
    setShowModal(true);
  };

  const handleResumeUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => setResume(reader.result);
    reader.readAsDataURL(file);
  };

  const APPLY_JOB = async (formEl) => {
    try {
      const form = new FormData(formEl);

      const application = {
        job_id: selectedJob._id,
        employee_id: user._id,
        shortDescription: form.get("shortDescription"),
        expectedSalary: form.get("expectedSalary"),
        expectedJoiningDate: form.get("expectedJoiningDate"),
        resume,
      };
      console.log("application", application);
      const res = await applyJob(selectedJob._id, application);
      if (!res.ok) return displayModal("Error", res.data.message);
      setShowModal(false);
      setResume("");
      displayModal("Success", res.data.message);
    } catch (e) {
      console.error(e);
    }
  };

  const viewCategoryJobs = (e, catId) => {
    e.preventDefault();
    try {
      navigate("/SearchJob", { state: { category: catId } });
    } catch (error) {
      console.log(error);
    }
  };

  const GET_CATEGORY_LIST = async () => {
    try {
      const res = await getCategoryList();
      if (!res.ok) return;
      let categories = res.data.data;
      if (!categories.length) return;
      if (categories.length > 8) categories = categories.slice(0, 8);
      setCategoryList(categories);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    GET_CATEGORY_LIST();
  }, []);
  function handleGoBack() {
    navigate(-1);
  }
  return (
    <>
      <ApplyJobModal
        show={showModal}
        setShow={setShowModal}
        job={selectedJob}
        handleResumeUpload={handleResumeUpload}
        APPLY_JOB={APPLY_JOB}
      />
      <section className="job-banner">
        <div className="container">
          <Row>
            <Col md={12} className="text-center">
              <h1>Search Jobs</h1>
              <p>Search your career opportunity through {jobs.length} jobsaa</p>
              <button className="btn btn-green" onClick={handleGoBack}>
                Go back
              </button>
            </Col>
          </Row>
        </div>
      </section>

      <section className="joblispage-top py-5">
        <div className="container">
          <Row>
            <JobFilter
              selectedCategory={selectedCategory}
              categoryList={categoryList}
              subcategoryList={subcategoryList}
              handleFilterInput={handleFilterInput}
              price={price}
              distance={distance}
            />
            <JobList
              coords={coords}
              jobs={jobs}
              limit={itemsLimit}
              currentPageLimit={currentPageLimit}
              totalPages={totalPages}
              pointer={pointer}
              handlePageClick={handlePageClick}
              setPointer={setPointer}
              handleChatNow={handleChatNow}
              handleApplyJob={handleApplyJob}
              distance={state.distance}
            />
          </Row>
        </div>
      </section>
    </>
  );
};

export default JobsList;
