import { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { getMyJobs,getAllJobsByUserId } from '../../api/job';

const JobSelectionModal = ({ show, setShow, expertId, HIRE }) => {
    const [jobs, setJobs] = useState([]);
    const [jobId, setJobId] = useState(null);

    const handleClose = () => setShow(false);

    const checkStatus = (job)=>{
        return job.status!=="closed"
    }

    const GET_JOBS = async () => {
        try {
            const res = await getAllJobsByUserId();
            // console.log(res.data.data,'HHHHHHHHHHHHHHHHHHHH');
            if (!res.ok) return;
            if (!res.data.success) return;

            let jobs = res.data.data.filter(checkStatus);
            if (!jobs) return;
            if (!jobs.length) return;

            jobs = jobs.map(job =>{
                // if(job.status==="closed") return false;
                return { _id: job._id, title: job.title }
            })
            setJobs(jobs);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        GET_JOBS();
    }, [expertId]);

    useEffect(() => {
        console.log('jobId', jobId);
    }, [jobId]);

 

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Select Job to Hire</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <select className='f-inp' style={{ width: '100%', padding: 5, color: '#7b7ea4' }} onChange={e => setJobId(e.target.value)}>
                            <option>select job </option>
                            {
                                jobs.length > 0 
                                &&
                                jobs.map(job => <option key={job._id} value={job._id}>{job.title}</option>)
                            }
                        </select>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={e => HIRE(jobId)}>Hire</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default JobSelectionModal;
