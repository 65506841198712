import { Modal, Button } from 'react-bootstrap';

const ConfirmationModal = ({ show, setShow, title, body,handleDelete }) => {
    const handleClose = () => setShow(false);
    const handelDelete = e =>{
            e.preventDefault();
            handleDelete();     
    }
  return (
    <>
    <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {body}
        </Modal.Body>
        <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleClose}>
                Close
            </Button> */}
            <button className='btn-green btn' onClick={handelDelete}>Yes</button>
            <button className='btn-outline-green btn btn-primary me-2' onClick={handleClose}>No</button>
            {/* <Button variant="primary">Understood</Button> */}
        </Modal.Footer>
    </Modal>
</>
  )
}

export default ConfirmationModal