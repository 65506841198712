import { PulseLoader } from "react-spinners";

const override = {
    margin: '0 auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    borderColor: '#8ecb2e',
};

const SpinnerComponent = ({ isLoading }) => {
    return (
        <>
           
            <PulseLoader className="forspinner" color={'#8ecb2e'} loading={isLoading} cssOverride={override} size={30} />
        </>
    );
}

export default SpinnerComponent;