import React from 'react'
import { GoogleMap, useJsApiLoader,MarkerF } from '@react-google-maps/api';
import { API_KEY } from '../config/google';

const containerStyle = {
    width: '100%',
    height: '400px'
  };
  

const JobDetailGoogleMap = ({center}) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: API_KEY
      })
   return  isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
    >
      
      <MarkerF position={center} />
    </GoogleMap>
) : <></>
}

export default JobDetailGoogleMap