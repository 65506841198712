import { api, endPoint } from './client';

const getExpertList = () => api.get(`${endPoint.users}/expertList`);

const getExpertDetails = (id) => api.get(`${endPoint.users}/expertDetails/${id}`)

const hireCandidate = (data) => api.post(`${endPoint.jobs}/hireCandidate`, data);

export {
    getExpertList,
    getExpertDetails,
    hireCandidate,
};