import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Col,
  Row,
  Container,
  Button,
  Image,
  Card,
  Form,
  Tabs,
  Tab,
  Nav,
  InputGroup,
} from "react-bootstrap";
import ads1 from "../../assets/images/ads1.jpg";
import { useNavigate, NavLink } from "react-router-dom";
import "./createnewads.css";
import AdsItem from "../../components/AdsItem";
import Multiselect from "multiselect-react-dropdown";
import ProceedModal from "./modal";
import check from "../../assets/images/check.png";
import cancel from "../../assets/images/cancel.png";
import urlExist from "url-exist";
import noImage from "../../assets/images/noImage.png";
import { isValidUrl, addNewAds } from "../../api/ads";
import { Context } from "../../components/Context";

const CreateNewAds = ({ displayModal }) => {
  const { isLoading, setIsLoading } = useContext(Context);
  const navigate = useNavigate();
  const autocompleteRef = useRef(null);
  const [range, setRange] = useState("");
  const [form, setForm] = useState({});
  const [price, setPrice] = useState(0);
  const [profileimage, setProfileImage] = useState("");
  const [photo, setPhoto] = useState(ads1);
  const [formData, setFormData] = useState({});
  console.log(
    formData,
    "L:L:L:L:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::"
  );
  const [show, setShow] = useState(false);
  const [showfiletype, setShowfiletype] = useState("image");
  const [toggpasswordInputType, setToggpasswordInputType] = useState("text");
  const [toggledate, setToggledate] = useState("text");
  const [toggledateend, setToggledateend] = useState("text");
  const [mediaElement1, setMediaElement1] = useState("");
  const [http, setHttp] = useState("https://www.");
  const [isValidUrlData, setIsValidUrlData] = useState(false);
  const [value1, setValue1] = useState(1);
  const [value2, setValue2] = useState(1);
  const [result, setResult] = useState(1);
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState();
  const [urlData, setUrlData] = useState("");
  // const [enterTitle, setEnterTitle] = useState(null);
  // console.log(enterTitle, "????????????????????????????????????");
  const [enterDescription, setEnterDescription] = useState("");
  const [enterWebsitelink, setEnterWebsitelink] = useState("");
  const [enterBeginningdate, setEnterBeginningdate] = useState("");
  const [enterMinage, setEnterMinage] = useState("");
  const [enterMaxage, setEnterMaxage] = useState("");
  const [enterAddress, setEnterAddress] = useState("");
  const [enterCountry, setEnterCountry] = useState("");
  const [enterZipcode, setEnterZipcode] = useState("");
  const [enterState, setEnterState] = useState("");
  const [flag, setflag] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);

  // const [bankAccountDetailsObj,setBankAccountDetailsObj] = useState({});
  const [validated, setValidated] = useState(false);
  console.log(validated, "validated>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");

  const [hClick, setHClick] = useState(
    ((value1 / value2) * 0.68 * 1000).toFixed(0)
  );
  const [lClick, setLClick] = useState(
    ((value1 / value2) * 1.98 * 1000).toFixed(0)
  );

  const [error, setError] = useState(null);

  // const handleValue1Change = (e) => {
  //   const newValue = e.target.value;
  //   setValue1(newValue);
  //   setResult(newValue / value2);
  //   const bugetpday = value2 / newValue;
  //   setClickperday(bugetpday * 0.68 * 1000 ) ;
  //   setPeoplereachperday(bugetpday * 1.98 * 1000 ) ;

  // }

  // const handleValue2Change = (e) => {
  //   const newValue = e.target.value;
  //   setValue2(newValue);
  //   setResult(value1 / newValue);
  //   const bugetpday = value1 / newValue;
  //   setClickperday(bugetpday * 0.68 * 1000 ) ;
  //   setPeoplereachperday(bugetpday * 1.98 * 1000 ) ;

  // }

  // const {

  //   handleSubmit,
  //   formState: { errors },

  // } = useForm();

  const handelBudgetChange = (e) => {
    e.preventDefault();
    if (e.target.name === "budget") {
      if (e.target.value < 1) {
        e.target.value = 1;
        setValue1(1);
      }
      setValue1(e.target.value);
    } else if (e.target.name === "budgetDays") {
      if (e.target.value < 1) {
        e.target.value = 1;
        setValue2(1);
      }
      setValue2(e.target.value);
    }

    setResult(value1 / value2);
    // const bugetpday = value1 / e.target.value;
    setHClick(((value1 / value2) * 0.68 * 1000).toFixed(0));
    setLClick(((value1 / value2) * 1.98 * 1000).toFixed(0));
    document.getElementById("hClick").innerHTML = (
      (value1 / value2) *
      0.68 *
      1000
    ).toFixed(0);
    document.getElementById("lClick").innerHTML = (
      (value1 / value2) *
      1.98 *
      1000
    ).toFixed(0);
    document.getElementById("result").innerHTML = (value1 / value2).toFixed(2);

    var date = new Date(startDate);
    date.setDate(date.getDate() + parseInt(e.target.value));
    const d = new Date(date);
    const fullyear = d.getFullYear();

    const fullmonth = ("0" + (d.getMonth() + 1)).slice(-2);
    const fulldate = ("0" + d.getDate()).slice(-2);
    const calend = fullyear + "-" + fullmonth + "-" + fulldate;

    setEndDate(calend);
  };

  useEffect(() => {
    document.getElementById("hClick").innerHTML = (
      (value1 / value2) *
      0.68 *
      1000
    ).toFixed(0);
    document.getElementById("lClick").innerHTML = (
      (value1 / value2) *
      1.98 *
      1000
    ).toFixed(0);
    document.getElementById("result").innerHTML = (value1 / value2).toFixed(2);
  });

  // const handleFileChange = (e) => {
  //     const imageFile = e.target.files[0];
  //     const reader = new FileReader();
  //     reader.onloadend = async () => {
  //         setPhoto(reader.result);
  //     }
  //     reader.readAsDataURL(imageFile);
  // }

  useEffect(() => {
    const autocomplete = new window.google.maps.places.Autocomplete(
      autocompleteRef.current,
      { types: ["geocode"] }
    );

    autocomplete.addListener("place_changed", () => {
      var place = autocomplete.getPlace();
      var address = place.formatted_address;
      var latitude = place.geometry.location.lat();
      var longitude = place.geometry.location.lng();
      var latlng = new window.google.maps.LatLng(latitude, longitude);
      var geocoder = (geocoder = new window.google.maps.Geocoder());

      geocoder.geocode({ latLng: latlng }, function (results, status) {
        if (status == window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            console.log(results);
            var address = results[0].formatted_address;
            var zipcode = "";
            results[0]?.address_components?.forEach((ele) => {
              if (ele?.types?.includes("postal_code")) {
                // zipcode = ele.long_name || '';
                zipcode = ele.long_name || "";
              }
              if (ele?.types?.includes("postal_code_suffix")) {
                zipcode = zipcode + " " + ele.long_name || "";
              }
            });
            // var country = results[0]?.address_components[results[0]?.address_components.length - 2]?.long_name || '';
            var country = "";
            results[0]?.address_components?.forEach((ele) => {
              if (
                ele?.types?.includes("country") &&
                ele?.types?.includes("political")
              ) {
                country = ele.long_name || "";
                return;
              }
            });
            // var state = results[0]?.address_components[results[0]?.address_components.length - 3]?.short_name || '';
            var state = "";
            results[0]?.address_components?.forEach((ele) => {
              if (
                ele?.types?.includes("administrative_area_level_1") &&
                ele?.types?.includes("political")
              ) {
                state = ele.long_name || "";
                return;
              }
            });
            var city =
              results[0].address_components[
                results[0].address_components.length - 5
              ].long_name;

            // document.querySelector('[name=address]').value = address;
            // document.querySelector('[name=city]').value = city;
            document.querySelector("[name=state]").value = state;
            document.querySelector("[name=country]").value = country;
            document.querySelector("[name=zipcode]").value = zipcode;
            document.querySelector("[name=lat]").value = latitude;
            document.querySelector("[name=lng]").value = longitude;

            // const locationObj = {
            //     address:address,
            //     city:city,
            //     state:state,
            //     zipcode:zipcode,
            //     latitude:latitude,
            //     longitude:longitude
            // }
            // setLocation(locationObj)
          }
        }
      });
    });
  }, []);

  const handelCheckRange = (value) => {
    console.log(value);
  };
  const [mediaElement, setMediaElement] = useState(null);

  const convertToBase64 = async (file) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const getbase64data = await convertToBase64(file);
    //    console.log(getbase64data)
    const fileType = file.type.split("/")[0];
    setShowfiletype(fileType);
    if (fileType === "image") {
      const imageElement = <img src={getbase64data} alt="Uploaded image" />;
      setMediaElement(getbase64data);
      setMediaElement1(imageElement);
      setError(null);
    } else if (fileType === "video") {
      const videoElement = (
        <>
          <video
            controls
            style={{ width: "100%", height: " 100%", objectFit: "cover" }}
          >
            <source src={getbase64data} type={file.type} className="w-100" />
            Your browser does not support the video tag.
          </video>
        </>
      );
      setMediaElement(getbase64data);
      setMediaElement1(videoElement);
      setError(null);
    } else {
      setMediaElement(null);
      setError("File type not supported.");
    }
  };
  const handelSubmit = async (e) => {
    setIsSubmit(true);

    // e.preventDefault();
    try {
      const form1 = e.currentTarget;
      setflag(e.currentTarget)
      if (form1.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      }

      setValidated(true);
      e.preventDefault();
      const form = new FormData(e.target);
      setFormData(form);
      setShow(true);
      setPrice(form.get("budget"));
      const bankAccountDetailsObj = {
        profileimage: mediaElement,
        ad_type: form.get("ad_type"),
        title: form.get("title"),
        description: form.get("description"),
        website_link: urlData,
        budget: form.get("budget"),
        country: form.get("country"),
        state: form.get("state"),
        zipcode: form.get("zipcode"),
        interest: form.get("interest"),
        // "file_type": photo.includes('data:image/')?'image':'video',
        file_type: showfiletype,
        start_date: form.get("start_date"),
        end_date: endDate,
        cost_to_advertiser: form.get("budget"),
        budgetDays: form.get("budgetDays"),
        latitude: form.get("lat"),
        longitude: form.get("lng"),
        range: form.get("group1"),
        max_age: form.get("max_age"),
        min_age: form.get("min_age"),
        interests: form.get("interests"),
        budget_per_day: value1 / value2,
      };
      console.log(bankAccountDetailsObj);

      setForm(bankAccountDetailsObj);
      console.log(form.get("budgetDays"));

      // const res = await addNewAds(bankAccountDetailsObj);
      // if (!res.ok) return displayModal('Error', res.data.message);
      // if (!res.data.success) return displayModal('Error', res.data.message);
      // displayModal('Success', res.data.message);

      // navigate('/CreateManageAds')
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const checkValidUrl = async (urlData) => {
    try {
      const res = await isValidUrl({ url: http + urlData });
      console.log(res);
      // setIsValidUrlData(res.data.exists)
      if (urlData) {
        setIsValidUrlData(res.data.exists);
        setUrlData(http + urlData);
      } else {
        // alert(res.data.exists)
        setIsValidUrlData(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handelStartDateChange = (e) => {
    setStartDate(e.target.value); 
  };

  return (
    <>
      {flag&&flag.checkValidity() === true? (
        <ProceedModal
          user={localStorage.getItem("uverlist-user-data")}
          show={show}
          setShow={setShow}
          handleClose={handleClose}
          form={form}
          mediaElement={mediaElement}
          navigate={navigate}
          displayModal={displayModal}
          price={price}
          setIsLoading={setIsLoading}
          // jobData={jobData}
          // setShowRetingModal={setShowRetingModal}
          // GET_JOB_DETAILS={GET_JOB_DETAILS}
        />
      ) : null}
      <section class="aflotop"></section>
      <section className="inner  contact-us page py-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7} className="mb-5">
              <Form
                className="up-account w-100"
                noValidate
                validated={validated}
                onSubmit={handelSubmit}
              >
                <Form.Group className="mb-3">
                  <div className="coverimage">
                    <div className="pic-up">
                      {error && <div>{error}</div>}
                      {mediaElement1 && mediaElement1}
                      <Image src={noImage} alt="" />
                    </div>
                    <Button className="uploadimage">Cover Image / Video</Button>
                    <Form.Control
                      id=""
                      type="file"
                      name="profileimage"
                      onChange={handleFileChange}
                      required
                    />
                      <Form.Control.Feedback type="invalid">
                      {" "}
                      Please Select An Image or Video
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Select className="f-inp" name="ad_type" required>
                    <option>Shopping</option>
                    <option>Video </option>
                    <option>Information</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <label className="mb-2">Enter Title</label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      className="f-inp"
                      name="title"
                      placeholder="Title "
                      aria-describedby="inputGroupPrepend"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      Please Provide a Title
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="mb-3">
                  <label className="mb-2">Enter Description</label>
                  <Form.Control
                    as="textarea"
                    type="text"
                    className=" f-inp"
                    name="description"
                    placeholder="Description"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {" "}
                    Please Provide Some Description
                  </Form.Control.Feedback>
                </Form.Group>
                <Row>
                  <Form.Group className="mb-3 col-lg-6 urlvrri">
                    <label className="mb-2">Enter Website link</label>
                    <Form.Select
                      className="f-inp"
                      name="ad_type"
                      onChange={(e) => setHttp(e.target.value)}
                    >
                      <option value={"https://www."}>https://www.</option>
                      <option value={"http://www."}>http://www.</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3 col-lg-6 urlvrri">
                    <label className="mb-2"></label>
                    <Form.Control
                      type="text"
                      name="website_link"
                      id="website_link"
                      className="mt-2 f-inp"
                      placeholder="Website link "
                      onChange={(e) => checkValidUrl(e.target.value)}
                      autoComplete="off"
                      required
                    />
                    {isValidUrlData ? (
                      <img src={check} />
                    ) : (
                      <img src={cancel} />
                    )}
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      Please Provide a Website 
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                {/* <Form.Group className="mb-3 forpassword">
                                    <Form.Control className="f-inp" name="" type={toggpasswordInputType} onClick={e => setToggpasswordInputType(type => type == 'text' ? 'file' : 'text')} placeholder="Add Image or Video" />
                                </Form.Group> */}
                <Row>
                  <Form.Group className="mb-3 col-lg-6">
                    <label className="mb-2">Beginning Date</label>
                    <Form.Control
                      className=" f-inp"
                      name="start_date"
                      placeholder="Beginning Date "
                      type="date"
                      onFocus={(e) =>
                        setToggledate((type) =>
                          type == "text" ? "date" : "text"
                        )
                      }
                      onBlur={(e) =>
                        setToggledate((type) =>
                          type == "text" ? "text" : "text"
                        )
                      }
                      min={new Date()?.toISOString()?.split("T")[0]}
                      onChange={handelStartDateChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      Please Provide Beginning Date
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3 col-lg-6">
                    <label className="mb-2">Ending Date</label>
                    <Form.Control
                      className=" f-inp"
                      name="end_date"
                      placeholder="Ending Date"
                      type="date"
                      onFocus={(e) =>
                        setToggledateend((type) =>
                          type == "text" ? "date" : "text"
                        )
                      }
                      onBlur={(e) =>
                        setToggledateend((type) =>
                          type == "text" ? "text" : "text"
                        )
                      }
                      min={startDate}
                      id="enddate"
                      disabled={true}
                      value={endDate}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      Please Provide An Website
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <h6 className="mb-3">Target Audience</h6>
                <Row>
                  <Form.Group className="mb-3 col-lg-6">
                    <label className="mb-2">Min Age</label>
                    <Form.Control
                      type="number"
                      className=" f-inp"
                      name={"max_age"}
                      placeholder="Min Age"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      Please Provide Minimum age
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3 col-lg-6">
                    <label className="mb-2">Max Age</label>
                    <Form.Control
                      type="number"
                      min={2}
                      className=" f-inp"
                      name={"min_age"}
                      placeholder="Max Age"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      Please Provide Maximum Age
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    md={12}
                    className="mb-3"
                    controlId="formGridAddress1"
                  >
                    <Form.Label className="min-leb">
                      Select Location From Map Address
                    </Form.Label>
                    {/* <GooglePlacesAutocomplete className="form-control"
                                        selectProps={{
                                            location,
                                            onChange: (address) => getLocation(address, document, setLocation),
                                        }}
                                    /> */}
                    {/* <label className="mb-2"> Address</label> */}
                    <Form.Control
                      placeholder="Address"
                      className="f-inp"
                      ref={autocompleteRef}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      Please Provide An Address
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3 col-lg-4">
                    <label className="mb-2">Country</label>
                    <Form.Control
                      type="text"
                      name="country"
                      className=" f-inp"
                      placeholder="Country"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      Please Provide Country
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3 col-lg-4">
                    <label className="mb-2">Zipcode</label>
                    <Form.Control
                      type="text"
                      name="zipcode"
                      className=" f-inp"
                      placeholder="Zipcode"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      Please Provide Zipcode
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3 col-lg-4">
                    <label className="mb-2">State</label>
                    <Form.Control
                      type="text"
                      name="state"
                      className=" f-inp"
                      placeholder="State"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      Please Provide State
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3 col-lg-4">
                    <Form.Control
                      type="hidden"
                      name="lat"
                      className=" f-inp"
                      placeholder="State"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      Please provide +++++
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3 col-lg-4">
                    <Form.Control
                      type="hidden"
                      name="lng"
                      className=" f-inp"
                      placeholder="State"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      Please Provide  
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    {/* <Multiselect
                                        displayValue="key"
                                        onKeyPressFn={function noRefCheck(){}}
                                        onRemove={function noRefCheck(){}}
                                        onSearch={function noRefCheck(){}}
                                        onSelect={function noRefCheck(){}}
                                        name="interests" 
                                        options={[
                                            {
                                            cat: 'Group 1',
                                            key: 'Option 1'
                                            },
                                            {
                                            cat: 'Group 1',
                                            key: 'Option 2'
                                            },
                                            {
                                            cat: 'Group 1',
                                            key: 'Option 3'
                                            },
                                            {
                                            cat: 'Group 2',
                                            key: 'Option 4'
                                            },
                                            {
                                            cat: 'Group 2',
                                            key: 'Option 5'
                                            },
                                            {
                                            cat: 'Group 2',
                                            key: 'Option 6'
                                            },
                                            {
                                            cat: 'Group 2',
                                            key: 'Option 7'
                                            }
                                        ]}
                                        /> */}
                    <label className="mb-2">Type interests</label>
                    <Form.Control
                      type="text"
                      name="interest"
                      className=" f-inp"
                      placeholder="Type interests(sports, garden, movies, cars, dating) "
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      Please Provide An Interest
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <h6 className="mb-3">Budget</h6>
                <Row>
                  {/* <input type="number" value={value1} onChange={handleValue1Change} />
      <input type="number" value={value2} onChange={handleValue2Change} /> */}
                  {/* <p>Result: <span className="result"></span></p> */}
                  <Form.Group className="mb-3 col-lg-6">
                    <label className="mb-2">Total Budget</label>
                    <Form.Control
                      type="number"
                      name="budget"
                      defaultValue={value1}
                      className=" f-inp"
                      placeholder="Total Budget "
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 col-lg-6">
                    <label className="mb-2">Days</label>
                    <Form.Control
                      type="number"
                      name="budgetDays"
                      defaultValue={value2}
                      className=" f-inp"
                      placeholder="Days"
                      onChange={handelBudgetChange}
                    />
                  </Form.Group>
                  <Col md={12} className="mb-3">
                    <strong className="text-danger">
                      Please select palan Lower range of Clicks / Higher range
                      of Clicks
                    </strong>
                  </Col>

                  <Form.Group className="mb-3 col-lg-4">
                    <div className="card  Costpd">
                      <h4>
                        $<span id="result"></span>
                      </h4>
                      {/* <span className="result"></span> */}
                      <label>Cost Per Day</label>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3 col-lg-8 for hiandloreach">
                    {["radio"].map((type) => (
                      <div key={type} className="mb-3">
                        <Form.Check type={type} id="lrc">
                          <Form.Check.Input
                            type={type}
                            value={"Higher range of Clicks"}
                            name="group1"
                            required
                          />

                          <Form.Check.Label className="card" htmlFor="lrc">
                            <h4
                              id="hClick"
                              name="range"
                              onClick={handelCheckRange(
                                "Higher range of Clicks"
                              )}
                            ></h4>{" "}
                            <span>Lower Range Reach Per Day</span>
                          </Form.Check.Label>
                        </Form.Check>
                        <Form.Check type={type} onClick={(e) => alert("Hello")}>
                          <Form.Check.Input
                            type={type}
                            name="group1"
                            id="hrc"
                            value={"Lower range of Clicks"}
                          />

                          <Form.Check.Label
                            className="card"
                            htmlFor="hrc"
                            onClick={handelCheckRange("Lower range of Clicks")}
                          >
                            <h4 id="lClick" name="range"></h4>{" "}
                            <span>Higher Range Reach Per Day</span>
                          </Form.Check.Label>
                          <Form.Control.Feedback type="invalid">
                            {" "}
                            Please Select An Option
                          </Form.Control.Feedback>
                        </Form.Check>
                      </div>
                    ))}
                  </Form.Group>
                </Row>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </Col>
          </Row>

          {/* <Tab.Container id="left-tabs-example" defaultActiveKey="Step1" >
                    <Row className="account-info ">
                        <Col sm={12} className="mb-5">
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="Step1" className=""><span>1</span>Account Details</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Step2"><span>2</span>Create Company</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Step3"><span>3</span>Targeting and Budget</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Step4"><span>4</span>Checkout</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={12}>
                            <Tab.Content>
                                <Tab.Pane eventKey="Step1">
                                    <Form className="up-account max-with-600 m-auto">
                                        <Form.Group className="mb-3">
                                            <Form.Control type="enail" className=" f-inp" placeholder="Email Id " />
                                        </Form.Group>
                                        <Row>
                                            <Form.Group className="mb-3 col-lg-6">
                                                    <Form.Control type="text" className=" f-inp" placeholder="First Name" />
                                            </Form.Group>
                                            <Form.Group className="mb-3 col-lg-6">
                                                    <Form.Control type="text" className=" f-inp" placeholder="Last Name" />
                                            </Form.Group>
                                        </Row>
                                        <Form.Group className="mb-3">
                                                <Form.Control type="text" className=" f-inp" placeholder="Country" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                                <Form.Control type="text" className=" f-inp" placeholder="Website" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                                <Form.Control type="text" className=" f-inp" placeholder="Company Name" />
                                        </Form.Group>
                                        <Row>
                                            <Col lg={12} className="text-end">
                                                <Button variant="primary" type="submit">
                                                    Next
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Tab.Pane>
                                <Tab.Pane eventKey="Step2">
                                    <Form className="up-account max-with-600 m-auto">
                                    <Form.Group className="mb-3">
                                                <Form.Control type="text" className=" f-inp" placeholder="Company Name" />
                                        </Form.Group>
                                        <h6 className="mb-3">Company Items</h6>
                                        <Form.Group className="mb-3 input-group">
                                            <Form.Control type="text" className=" f-inp border-right-bottom" placeholder="Enter Url" />
                                            <Button  variant="primary" type="button" >Add Url</Button>
                                        </Form.Group>
                                        <Row>
                                            <Col lg={12} className="text-end">
                                                <Button variant="primary" type="submit" className="mx-2">
                                                    Preview 
                                                </Button>
                                                <Button variant="primary" type="submit">
                                                    Next
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Tab.Pane>
                                <Tab.Pane eventKey="Step3">
                                    <Form className="up-account max-with-600 m-auto">
                                        <Form.Group className="mb-3">
                                                <Form.Control type="text" className=" f-inp" placeholder="Country" />
                                        </Form.Group>
                                        <h6 className="mb-3">How much would you lick to spend? </h6>
                                        <Form.Group className="mb-3">
                                                <Form.Control type="text" className=" f-inp" placeholder="Daily Budget" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                                <Form.Control type="text" className=" f-inp" placeholder="Cost Per click" />
                                        </Form.Group>
                                        <Row>
                                            <Col lg={12} className="text-end">
                                                <Button variant="primary" type="submit" className="mx-2">
                                                    Preview 
                                                </Button>
                                                <Button variant="primary" type="submit">
                                                    Next
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Tab.Pane>
                                
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container> */}
        </Container>
      </section>
    </>
  );
};

export default CreateNewAds;
