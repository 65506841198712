import React from 'react';
import { TwitterShareButton, TwitterIcon } from 'react-share';

const Twitter = ({ url, quotes, hashtag }) => {
    return (
        <>
            <TwitterShareButton
                url={url}
                quotes={quotes}
                hashtag={hashtag}
            >
                <TwitterIcon size={50} borderRadius={5} />
            </TwitterShareButton>
        </>
    )
}

export default Twitter;
