import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Image,
  Nav,
  Card,
  Modal,
} from "react-bootstrap";
import cardedit from "../../../../assets/images/icon-edit.svg";
import addnew from "../../../../assets/images/add-new.svg";
import './style.css'
import deleteicon from '../../../../assets/images/delete.svg';
import { addBankDetails,getBankList, getBankdelete,updateDefaultAccount } from "../../../../api/payment";
import Autocomplete from "react-google-autocomplete";
import PhoneInput from 'react-phone-input-2'
import {Context} from '../../../../components/Context'
import ConfirmationModal from "../../../../components/ConfirmationModal";
const BankInfo = ({displayModal, setIsLoggedIn}) => {
  const {isLoading,setIsLoading} = useContext(Context);
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  
  const [bank, setBank] = useState(false);
  const [bankDetails, setBankDetails] = useState({});
  const [location,setLocation]= useState({});
  const [location1, setLocation1] = useState({});
  const [location2, setLocation2] = useState({});
  const [deleteBankId,setDeleteBankId] = useState('');
  const bankClose = () => setBank(false);
  const bankShow = () => setBank(true);
  // const [city,setCity] = useState('')
  // const [state,setState] = useState('')
  // const [country,setCountry] = useState('')
  // const [zipcode,setZipCode] = useState('')
  const [bankList,setBankList] = useState([]);
  const [confirm,setConfirm] = useState(false);
  

  // useEffect(() => {
  //   const autocomplete1 = new window.google.maps.places.Autocomplete(
  //     input1Ref?.current,
  //     { types: ["geocode"] }
  //   );
  //   autocomplete1.addListener('place_changed', () => {
  //       var place = autocomplete1.getPlace();
  //       var address = place.formatted_address;
  //       var latitude = place.geometry.location.lat();
  //       var longitude = place.geometry.location.lng();
  //       var latlng = new window.google.maps.LatLng(latitude, longitude);
  //       var geocoder = geocoder = new window.google.maps.Geocoder();


  //       geocoder.geocode({ 'latLng': latlng }, function (results, status) {
  //           if (status == window.google.maps.GeocoderStatus.OK) {
  //               if (results[0]) {
  //                   console.log(results)
  //                   var address1 = results[0].formatted_address;
  //                   var zipcode = results[0].address_components[results[0].address_components.length - 1].short_name;
  //                   var country = results[0].address_components[results[0].address_components.length - 2].short_name;
  //                   var state = results[0].address_components[results[0].address_components.length - 3].short_name;
  //                   var city = results[0].address_components[results[0].address_components.length - 5].long_name;
  //                   setCity(city)
  //                   setState(state)
  //                   setZipCode(zipcode)
  //                   setCountry(country)
  //                   setLocation1(address1)
  //                   // document.querySelector('[name=address]').value = address;
  //                   // document.querySelector('[name=city]').value = city;
  //                   // document.querySelector('[name=state]').value = state;
  //                   // document.querySelector('[name=country]').value = country;
  //                   // document.querySelector('[name=zipcode]').value = zipcode;
  //                   // document.querySelector('[name=lat]').value = latitude;
  //                   // document.querySelector('[name=lng]').value = longitude;
  //             console.log(city);
  //                   const locationObj = {
  //                       address:address,
  //                       city:city,
  //                       state:state,
  //                       zipcode:zipcode,
  //                       latitude:latitude,
  //                       longitude:longitude
  //                   }
  //                   setLocation(locationObj)
                    
  //               }
  //           }
  //       });
  //   });
  //   const autocomplete2 = new window.google.maps.places.Autocomplete(
  //       input2Ref?.current,
  //       { types: ["geocode"] }
  //     );
  //   autocomplete2.addListener('place_changed', () => {
  //       var place = autocomplete2.getPlace();
  //       var address = place.formatted_address;
  //       var latitude = place.geometry.location.lat();
  //       var longitude = place.geometry.location.lng();
  //       var latlng = new window.google.maps.LatLng(latitude, longitude);
  //       var geocoder = geocoder = new window.google.maps.Geocoder();


  //       geocoder.geocode({ 'latLng': latlng }, function (results1, status) {
  //           if (status == window.google.maps.GeocoderStatus.OK) {
  //               if (results1[0]) {
  //                   // console.log(results)
  //                   setLocation2(results1[0].formatted_address);
  //                   // var zipcode = results[0].address_components[results[0].address_components.length - 1].short_name;
  //                   // var country = results[0].address_components[results[0].address_components.length - 2].short_name;
  //                   // var state = results[0].address_components[results[0].address_components.length - 3].short_name;
  //                   // var city = results[0].address_components[results[0].address_components.length - 5].long_name;
  //                   // setCity(city)
  //                   // setState(state)
  //                   // setZipCode(zipcode)
  //                   // setCountry(country)
  //                   // setLocation1(address)
  //                   // document.querySelector('[name=address]').value = address;
  //                   // document.querySelector('[name=city]').value = city;
  //                   // document.querySelector('[name=state]').value = state;
  //                   // document.querySelector('[name=country]').value = country;
  //                   // document.querySelector('[name=zipcode]').value = zipcode;
  //                   // document.querySelector('[name=lat]').value = latitude;
  //                   // document.querySelector('[name=lng]').value = longitude;
  //           //   console.log(city);
  //           //         const locationObj = {
  //           //             address:address,
  //           //             city:city,
  //           //             state:state,
  //           //             zipcode:zipcode,
  //           //             latitude:latitude,
  //           //             longitude:longitude
  //           //         }
  //           //         setLocation(locationObj)
                    
  //               }
  //           }
  //       });
  //   });
   
  // });

  const GET_BANK_LIST = async ()=>{
    try {
      const res = await getBankList();
      // console.log(res.data,"HHHHHHHHHHHHHHHHHH")
      if(res.data.code===200){
        setBankList(res.data.data);
       
      }else if((res.data.code===500)){
        setBankList(res.data)
       
      }
      
      
    } catch (error) {
       console.log(error)
    }
  }

   const handelSubmit = async (e) => {
    try {
      e.preventDefault();
      const form = new FormData(e.target);

      const bankAccountDetailsObj = {
        first_name: form.get("first_name"),
        last_name: form.get("last_name"),
        account_number: form.get("account_number"),
        routing_number: form.get("routing_number"),
        // ssn_last_4: form.get("ssn_last_4"),
        // dob: form.get("dob"),
        // email: form.get("email"),
        // phone_number: form.get("phone_number"),
        // line1:location1,
        // line2:location2,
        // city:city,
        // state:state,
        // zipcode:zipcode,
        // country:country
      };
      bankClose()
      setIsLoading(true);
      const res = await addBankDetails(bankAccountDetailsObj);
      if(res.ok){
        setIsLoading(false);
        GET_BANK_LIST();
        // alert(res.data.message);
       displayModal('Success', res.data.message);
        // bankClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteConfirm = (e,id) =>{
      e.preventDefault();
      setDeleteBankId(id);
      setConfirm(true);
  }
  const handleDelete = async () => {
      try {
        setIsLoading(true);
        const res = await getBankdelete({id:deleteBankId});
        if(res.ok){
          setDeleteBankId('');
          setIsLoading(false);
          GET_BANK_LIST();
          setConfirm(false);
          displayModal('Success', res.data.message);
         }
      } catch (error) {
        console.error(error);
      }
 
  };
  const handleUpdateDefaultAccount = async (id) => {
      try {
        setIsLoading(true);
        const res = await updateDefaultAccount({id});
        if(res.ok){
          setDeleteBankId('');
          setIsLoading(false);
          GET_BANK_LIST();
          setConfirm(false);
          displayModal('Success', res.data.message);
         }
      } catch (error) {
        console.error(error);
      }
 
  };
  useEffect(()=>{
    GET_BANK_LIST()
  },[])
  return (
    <>
    <ConfirmationModal show={confirm} setShow={setConfirm}   handleDelete={handleDelete} title="Are you sure?" body="Are you sure you want to delete this item?" />
      {/* Bank Information */}
      <Modal show={bank} onHide={bankClose}>
        <Modal.Header closeButton>
          <Modal.Title>Bank Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handelSubmit} id="add-bank-account">
            <Row>
              <Form.Group className="mb-3 col-md-6" controlId="formGroupholdername">
                <Form.Control
                  className="f-inp"
                  type="text"
                  placeholder="Account Holder First Name"
                  name="first_name"
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-6" controlId="formGroupholdername">
                <Form.Control
                  className="f-inp"
                  type="text"
                  placeholder="Account Holder Last Name"
                  name="last_name"
                />
              </Form.Group>
              {/* <Form.Group className="mb-3  col-md-6" controlId="formGroupEmail">
              <Form.Control
                className="f-inp"
                name="email"
                type="email"
                placeholder="Email"
              />
            </Form.Group>
            <Form.Group className="mb-3  col-md-6" controlId="formGroupEmail">
              <PhoneInput
              className="w-100 pormobileaddinfo"
                    name={'phone_number'}
                    country={'us'}
                        inputProps={{
                            name: 'phone_number',
                            
                        }}
                        
                        />
            </Form.Group>
            </Row>
            <Row>
            <Form.Group className="mb-3 col-md-12" controlId="formGridLine1">
              <Form.Control
                placeholder="Address Line 1"
                className="f-inp"
                ref={input1Ref}
              />
            </Form.Group>
            </Row>
            <Row>
            <Form.Group  className="mb-3 col-md-6" controlId="formGridCity">
              <Form.Control
                placeholder="City"
                className="f-inp"
                // name='city'
                value={city}
              />
            </Form.Group>
            <Form.Group  className="mb-3 col-md-6" controlId="formGridLine1">
              <Form.Control
                placeholder="State"
                className="f-inp"
                // name='state'
                value={state}
              />
            </Form.Group>
            <Form.Group  className="mb-3 col-md-6" controlId="formGridLine1">
              <Form.Control
                placeholder="Zipcode"
                className="f-inp"
                value={zipcode}
              />
            </Form.Group>
            <Form.Group  className="mb-3 col-md-6" controlId="formGridLine1">
              <Form.Control
                placeholder="Country"
                className="f-inp"
                value={country}
              />
            </Form.Group>
            <Form.Group className="mb-3 col-md-12" controlId="line2Address">
              <Form.Control
                className="f-inp"
                name="Address Line 2"
                placeholder="Address Line 2"
                ref={input2Ref}
              />
            </Form.Group>
            </Row>
            <Row> */}
            <Form.Group className="mb-3 col-md-12" controlId="formGroupaccount">
              <Form.Control
                name="account_number"
                className="f-inp"
                type="number"
                placeholder="Account Number"
              />
            </Form.Group>

            <Form.Group className="mb-3 col-md-12" controlId="formGrouprouting">
              <Form.Control
                name="routing_number"
                className="f-inp"
                type="number"
                placeholder="Routing Number"
              />
            </Form.Group>
            {/* <Form.Group className="mb-3 col-md-6" controlId="formGroupLast4ssn">
              <Form.Control
                className="f-inp"
                name="ssn_last_4"
                type="number"
                placeholder="Last 4 SSN Number"
              />
            </Form.Group> */}
            </Row>
            
            {/* <Form.Group className="mb-3" controlId="formGroupDob">
              <Form.Control className="f-inp" name="dob" type="date" />
            </Form.Group> */}
              

            <Button variant="primary" className="btn-green" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Row>
        <Col md={12}>
          <h4 className="user-list-subtitle">
            Bank Information
            {/* {bankList?.code == 500 && */}
              <div className="add-new" onClick={bankShow}>
              <Image src={addnew} />
            </div>
            {/* } */}
          </h4>
        </Col>
        <Col md={12}>
          <Row>
            {
              bankList?.length>0
              &&
              bankList?.map((item,index)=>{
                return (
                  <Col md={12} lg={6} key={index}>
                  <Card className="acount-info radius-12">
                    <Card.Body>
                      <div className="ac-profile">{item.bank_name.split(' ').map(word => word.charAt(0)).join('').slice(0,4).toUpperCase()}</div>
                      <div className="ac-cont">
                        <h4>
                          <span>{item.account_holder_name} <input type="checkbox" id="vehicle1" name="vehicle1" checked={item?.default_for_currency} onClick={e=>handleUpdateDefaultAccount(item?.id)} /></span>
                          
                          {/* {item?.status} */}
                          {/* <small>12 may 2020</small> */}
                        </h4>
                        <p className="cnumber">************{item.last4}</p>
                      </div>
                      
                      <div className='ac-edt' onClick={e=>handleDeleteConfirm(e,item?.id)}><Image alt="" src={deleteicon} /></div>
                    </Card.Body>
                  </Card>
                </Col>
                )
              })
            }
            {bankList?.code==500  &&
                            <Col lg={12} md={12}>

                                <Card className='acount-info radius-12 p-5 text-center'>
                                    <h3>
                                    
                                        {bankList.message}
                                    </h3>
                                </Card>
                            </Col>
                        }
            {/* <Col md={12} lg={6}>
              <Card className="acount-info radius-12">
                <Card.Body>
                  <div className="ac-profile">SBI</div>
                  <div className="ac-cont">
                    <h4>
                      <span>Sandip Pyne</span>
                      <small>12 may 2020</small>
                    </h4>
                    <p className="cnumber">************4512</p>
                  </div>
                  <div className="ac-edt">
                    <Image alt="" src={cardedit} />
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={12} lg={6}>
              <Card className="acount-info radius-12">
                <Card.Body>
                  <div className="ac-profile">SBI</div>
                  <div className="ac-cont">
                    <h4>
                      <span>Sandip Pyne</span>
                      <small>12 may 2020</small>
                    </h4>
                    <p className="cnumber">************4512</p>
                  </div>
                  <div className="ac-edt">
                    <Image alt="" src={cardedit} />
                  </div>
                </Card.Body>
              </Card>
            </Col> */}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default BankInfo;
