
import { Button, Form, Image, Modal } from 'react-bootstrap';
import { WhatsappIcon, FacebookIcon, WhatsappShareButton } from 'react-share';
import Facebook from '../socialmedia/Facebook';
import LinkedIn from '../socialmedia/LinkedIn';
import Twitter from '../socialmedia/Twitter';
import Whatsapp from '../socialmedia/Whatsapp';

const SocialMediaShare = ({ 
    show = true, 
    handleClose, 
    title = 'Success', 
    message = 'Job Created Successfully! Share on social media!',
    url,
    quotes,
    hashtag, 
}) => (

    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center p-5'>
            <div>{message}</div>
            <br />
            <br />
            <div>
                <Whatsapp url={url} quotes={quotes} hashtag={hashtag} />&nbsp;&nbsp;
                <Facebook url={url} quotes={quotes} hashtag={hashtag} />&nbsp;&nbsp;
                <LinkedIn url={url} quotes={quotes} hashtag={hashtag} />&nbsp;&nbsp;
                <Twitter url={url} quotes={quotes} hashtag={hashtag} />
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
        </Modal.Footer>
    </Modal>
)

export default SocialMediaShare;