import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form, Image, Nav, Tab, Card, Table } from 'react-bootstrap';
import './allcategory.css'
import CustomPagination from "../../components/CustomPagination";
import { getCategoryList } from "../../api/job";
import noimage from '../../assets/images/noImage.png';
import { useNavigate, NavLink } from "react-router-dom";
const AllCategories = ({setHomeSelectedCategoryId}) =>{
    const navigate = useNavigate()
    const [categoryList, setCategoryList] = useState([]);
    const GET_CATEGORY_LIST = async () => {
        try {
            const res = await getCategoryList();
            if (!res.ok) return;
            let categories = res.data.data;
            if (!categories.length) return;
          //  if (categories.length > 8) categories = categories.slice(0, 8);
            setCategoryList(categories);
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        GET_CATEGORY_LIST()
    }, [])
    
    const viewCategoryJobs = (e, catId) => {
        e.preventDefault();
        try {
           navigate('/SearchCatJob',{state:{category:catId}})
       } catch (error) {
           console.log(error)
       }
    };
function handleGoBack() {
    navigate(-1);
  }
    return(
        <>
           <section className="job-banner">
				<div className="container">
					<Row>
						<Col md={12} className="text-center">
							<h1>
                                All Categories
                                
							</h1>
                            <button className="btn btn-green" onClick={handleGoBack}>Go back</button>
						</Col>
					</Row>
				</div>
			</section>
            <section className="py-5 ctlistpage">
                <div className="container">
                <Row>
                
                {
                                    categoryList.length > 0
                                    &&
                                    categoryList.map(item => (
                                        <Col  lg={2} className="mb-3" >
                                        <a className="celitem " onClick={e => viewCategoryJobs(e, item._id)}>
                                        {item?.icon ? (
                                                        <Image src={item?.icon} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                                ) : (
                                                    <Image src={noimage} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }}/>
                                                )}
                                            <span>{item.name}</span>
                                        </a>
                                        </Col>

                                    ))
                                }
                    
                </Row>
                            <Row>
                                <Col>
                                    <div style={{ display: 'flex', justifyContent: 'right', marginTop: 10, padding: 0 }}>
                                        {/* <CustomPagination
                                            totalPages="20"

                                            pointer='1'
                                            limit='100'
                                        /> */}
                                    </div>
                                </Col>
                            </Row>
                    
                </div>
            </section>
			
        </>
    )
}
export default AllCategories;