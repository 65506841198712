import { Modal, Button, Form, Image } from 'react-bootstrap';

const style = {
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 10
    },
    item: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    label: {
        fontWeight: 'bold'
    },
    value: {
        textAlign: 'right'
    },
    image: {
        width: 160,
        height: 160,
        objectFit: 'contain'
    },
    video: {
        width: '100%',
        height: 150,
        objectFit: 'contain',
        marginTop: 10,
    },
}

const RowItem = ({ label, value }) => (
    <div
        style={style.item}
    >
        <label
            style={style.label}
        >
            {label}
        </label>
        <p
            style={style.value}
        >
            {value}
        </p>
    </div>
)

const JobImageVideo = ({ imageUri, videoUri }) => (
    <div style={style.item}>
        {
            videoUri
            ?
            <video src={videoUri} style={style.video} controls autoPlay />
            :
            <Image src={imageUri} style={style.image} />
        }
    </div>
)

const ViewJobModal = ({ show, setShow, job }) => {

    const handleClose = () => setShow(false);

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Job Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={style.container}>
                        <JobImageVideo imageUri={job.images} videoUri={job.video} />
                        <RowItem label='Job ID' value={job.job_id} />
                        <RowItem label='Title' value={job.title} />
                        <RowItem label='Category' value={job.category} />
                        <RowItem label='Subcategory' value={job.subcategory} />
                        <RowItem label='Created Date' value={job.date} />
                        <RowItem label='Job Type' value={job.job_type} />
                        <RowItem label='Status' value={job.status} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    {/* <Button variant="primary">Understood</Button> */}
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ViewJobModal;
