import React, { useState, useEffect } from "react";
import Banner from "../Layouts/Banner";
// import about from '../../assets/images/about-right.jpg';
import { getCategoryList } from "../api/job";
import { useNavigate, NavLink } from "react-router-dom";
const SetBanner = ( { logo,distance,setDistance } ) => {
	const navigate = useNavigate()
	const [categoryList, setCategoryList] = useState([]);
	const GET_CATEGORY_LIST = async () => {
        try {
            const res = await getCategoryList();
            if (!res.ok) return;
            let categories = res.data.data;
            if (!categories.length) return;
           // if (categories.length > 8) categories = categories.slice(0, 8);
            setCategoryList(categories);
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        GET_CATEGORY_LIST()
    }, [])

	const handelSubmit = async (e)=>{
        e.preventDefault();
        try {
            const form = new FormData(e.target);
            console.log(Object.fromEntries(form.entries()));
             navigate('/SearchJob',{state:Object.fromEntries(form.entries())})
        } catch (error) {
            console.log(error)
        }     
    }
	return (
		<>
        <Banner logo={logo} categoryList={categoryList} handelSubmit={handelSubmit} distance={distance} setDistance={setDistance} />

		</>
	);
};

export default SetBanner;
