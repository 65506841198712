import { api, endPoint } from './client';

const getAllUsers = () => api.get(`${endPoint.users}/allUsers`)

const addFriend = (id) => api.patch(`${endPoint.users}/addFriend/${id}`, {})

const acceptFriendRequests = (id) => api.patch(`${endPoint.users}/acceptFriendRequest/${id}`, {})

const rejectFriendRequests = (id) => api.patch(`${endPoint.users}/rejectFriendRequest/${id}`, {})

const getFriendRequests = () => api.get(`${endPoint.users}/friendRequests`)

const getFriends = () => api.get(`${endPoint.users}/friends`)

const newAllUsers = () => api.get(`${endPoint.users}/newAllUsers`)


export {
    getAllUsers,
    addFriend,
    acceptFriendRequests,
    rejectFriendRequests,
    getFriendRequests,
    getFriends,
    newAllUsers
};