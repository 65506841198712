import React, { useState, useEffect } from "react";
import { Link, NavLink } from 'react-router-dom';
import fb from '../../assets/images/fb.svg';
import linkedin from '../../assets/images/linkedin.svg';
import twitter from '../../assets/images/twitter.svg';
import youtube from '../../assets/images/youtube.svg';
const Footer = ({  }) => {
	
	return (
		<>
        <footer>
			<div className="container">
				<div className="row">
					<div className="col-md-12 text-center socailmedia">
						
						<a href="https://www.facebook.com/" target="_blank"><img src={fb} /></a>
						<a href="https://www.linkedin.com/in/devin-faggs-55b526b6" target="_blank"><img src={linkedin} /></a>
						<a href="https://twitter.com/DevinFaggs" target="_blank"><img src={twitter} /></a>
						<a href="https://www.youtube.com/" target="_blank"><img src={youtube} /></a>
					</div>
				</div>
				<div className="row mt-4">
					<div className="col-md-12 text-center man-menu">
						<NavLink to="/About"  onClick={e => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}>About Us</NavLink>
						<NavLink to="/TermsServices"  onClick={e => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}>Terms of Services</NavLink>
						<NavLink to="/PrivacyPolicy"  onClick={e => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}>Privacy Policy</NavLink>
						<NavLink to="/ContactUs"  onClick={e => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}>Contact us</NavLink>
						<NavLink to="/CreateManageAds" onClick={e => window.scroll({ top: 0, left: 0, behavior: 'smooth' })} >Create/Manage Ads</NavLink>
					</div>
				</div>
				<div className="row my-3">
					<div className="col-md-12 text-center">
						<hr />
					</div>
				</div>
				<div className="row">
					<div className="col-md-12 text-center copyright">
					Copyright © {new Date().getFullYear()} <a href="#">Uverlist</a>.com All rights reserved. Powered by <a href="#">Branium Information Technologies</a>
					</div>
				</div>
			</div>
		</footer>
		</>
	);
};

export default Footer;
