import React, { useEffect } from 'react';
import { FacebookShareButton, FacebookIcon, FacebookMessengerIcon, OKShareButton } from 'react-share';

const Facebook = ({ url, quotes, hashtag }) => {

    return (
        <>

            <FacebookShareButton
                // url={`https://nodeserver.mydevfactory.com:6098/1663919830162-user-profileimage.jpeg\n${linkUrl}`}
            //    quotes={quotes}
            //    hashtag={hashtag}
            //   hashtag={hashtag}
            //   image="https://example.com/image.jpg"

            >
                <FacebookIcon size={50} borderRadius={5} />
            </FacebookShareButton>
        </>
    )
}

export default Facebook;
