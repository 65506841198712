import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Image, Modal, Row, Col } from "react-bootstrap";
import { Link, NavLink, useNavigate } from 'react-router-dom';
import GooglePlacesAutocomplete, { getLatLng, geocodeByAddress } from "react-google-places-autocomplete";
import { getLocation, handleShow, handleClose, handlePriceType } from "./helper";
import banner from '../../assets/images/banner.svg';
import search from '../../assets/images/search.svg';
import job from '../../assets/images/send.svg';
import joblist from '../../assets/images/job-list.svg';
import Download from '../../assets/images/download.svg';
import address from '../../assets/images/address.svg';
import { useGeolocated } from "react-geolocated";
import { API_KEY } from "../../config/google";
import { Context } from "../../components/Context";
import store from "../../utils/store";

import { GoogleMap, useJsApiLoader, MarkerF,CircleF } from '@react-google-maps/api';


const Banner = ({ categoryList, handelSubmit,distance,setDistance, isLoggedIn }) => {
	const navigate = useNavigate();
  const [scroll, setScroll] = useState(0)
	
  const handlePostJob = (e) => {
    e.preventDefault();
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    store.storeLastEventKey('Post-Job')
    navigate(isLoggedIn ? '/user' : '/signin');
  };
  const handleAccount = (e) => {
    e.preventDefault();
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    store.storeLastEventKey('Account')
    navigate(isLoggedIn ? '/user' : '/signin');
  };

const handleFilterInput = (e) => {
		const newDistance = Number(e.target.value);
				
				setDistance(newDistance);

	}


const containerStyle = {
	width: '100%',
	height: '100%'
  };
  
  const center = {
	lat: -3.745,
	lng: -38.523
  };

  const options = {
	strokeColor: '#609a02',
	strokeOpacity: 0.8,
	strokeWeight: 4,
	fillColor: '#fff',
	fillOpacity: 0.8,
	clickable: false,
	draggable: false,
	editable: false,
	visible: true,
	zIndex: 1
  }
  const onLoad = circle => {
	console.log('Circle onLoad circle: ', circle)
  }
  
  const onUnmount = circle => {
	console.log('Circle onUnmount circle: ', circle)
  }

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	
    const handleSaveChangeLocation = (e)=>{
		e.preventDefault();
		console.log(location)
		setShow(false);
	}


	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: API_KEY
	  })
	
	  const [map, setMap] = React.useState(null)
	
	

	const [location, setLocation] = useState({});
	const { coords,setCoords,myAddress, setMyAddress } = useContext(Context);
	

	function showPosition(position) {
		
		return { lat: position.coords.latitude, lon: position.coords.longitude };
	}



	const handleSelect = (selected) => {
		console.log(selected);
	}

	const getLocation = async (address, document, setLocation) => {
		const results = await geocodeByAddress(address.value.description);
	
		if (!results) return;
		if (!results.length) return;
	
		const loc = await getLatLng(results[0]);
	
		const { address_components, formatted_address } = results[0];
	
		const location = {
			latitude: loc.lat,
			longitude: loc.lng,
		}
		
		
		setLocation(location);
		setMyAddress(address.label)
		setCoords(location)
	}

	return (
		<>
		
			<section className="banner">
				<img src={banner} />
				<div className="banner-cont">
					<div className="banner-inner-cont">
						<h1>
							<span>Small Jobs.</span> Big Money.
						</h1>
						<form onSubmit={handelSubmit}>
							<Form.Select aria-label="Default select example" name="category">
								<option value=''>Choose Category</option>
								{
									categoryList.length > 0
									&&
									categoryList.map(item => {
										return <option id={item._id} value={item._id}>{item.name}</option>
									})
								}
							</Form.Select>
							{/* <div className="select-location home-select-location mt-0">
								
								{
									false
										?
										<div >

										</div>
										:
										<div className="">
											<GooglePlacesAutocomplete
												
												selectProps={{
													location,
													onChange: (address) => { setMyAddress(address.value.description) 
													console.log(address)
													}
												}}
											/>
										</div>
								}
							</div>
							<input type="hidden" name="lat" id="getLat" value={coords ? coords.latitude : ''} />
							<input type="hidden" name="long" id="getLong" value={coords ? coords.longitude : ''} /> */}
							<Form.Control type="text" placeholder="Job Title" name="title" />
							<input type="hidden" name="lat" id="getLat" value={coords ? coords.latitude : ''} />
							<input type="hidden" name="long" id="getLong" value={coords ? coords.longitude : ''} />
							<Button variant="primary" type="submit">
								<input type="hidden" name="distance" value={distance}  />
								<img src={search} /> Search
							</Button>
						</form>
						{/* <Button variant="primary" type="button" onClick={e=>dd(e)}>
								<img src={search} /> Search
					    </Button> */}
						<p onClick={handleShow}>Search for jobs with  '<span>{distance}</span>' miles of  <span>{myAddress}</span></p>
						<Row className="common-banner-img">
							<Col md={12} className="d-flex justify-content-center">
							<NavLink className="btn btn-outline-white mx-2" to="/Jobs" onClick={e => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}>
                <Image src={joblist} alt="Jobs listings" /> <span>Jobs listings</span>
              </NavLink>
              <NavLink className="btn btn-outline-white mx-2" to='/' onClick={handlePostJob}>
                <Image src={job} alt="Job" /> <span> Post a Job</span>
              </NavLink>
              <NavLink className="btn btn-outline-green mx-2" to="/" onClick={e => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}>
                <Image src={Download} alt="Download App" /><span> Download App </span>
              </NavLink>
							</Col>
						</Row>
					</div>
				</div>
			</section>
			<Modal show={show} onHide={handleClose} className="w-100 mag-range ">
        <Form>
        <Modal.Body >
			<div className="setaddress">
				<GooglePlacesAutocomplete
					selectProps={{
						location,
						onChange: (address) => { getLocation(address, document, setLocation) }
					}}
				/>
			</div>
			<div className="show-map">
			
				<GoogleMap
					mapContainerStyle={containerStyle}
					center={{lat:coords.latitude,lng:coords.longitude}}
					zoom={9}	
				    	
				>
					<MarkerF position={{lat:coords.latitude,lng:coords.longitude}} />
					<CircleF center={{lat:coords.latitude,lng:coords.longitude}} radius={distance*1609.34} options={options} />
					{/* <Circle
					// optional
					onLoad={onLoad}
					// optional
					onUnmount={onUnmount}
					// required
					center={center}
					// required
					options={options}
					/> */}

{/* <Marker
      onLoad={onLoad}
      position={{lat:coords.latitude,lng:coords.longitude}}
    /> */}
	
				</GoogleMap>
				{/* <GoogleMap
      mapContainerStyle={{
        height: "400px",
        width: "100%",
      }}
      zoom={8}
      center={{lat:coords.latitude,lng:coords.longitude}}
    >
      <Marker position={{lat:coords.latitude,lng:coords.longitude}} />
    </GoogleMap> */}

			</div>
			<div className="Setrange">
				
					<Form.Group className="mb-3">
						<Form.Label>Range: {distance} miles</Form.Label>
						<div className="mb-3" style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
							<Form.Label>0</Form.Label>
							<Form.Range
								name='distance'
								 value={distance}
								 onChange={handleFilterInput}
								variant='success'
								min={0}
								max={60}
							/>
							<Form.Label>60</Form.Label>
						</div>

					</Form.Group>
				
			</div>
			
		</Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-info close"  onClick={handleClose}>
            Close
          </Button>
          <Button className="btn btn-primary Save-change" type="submit"  onClick={handleSaveChangeLocation}>
            Save Changes
          </Button>
        </Modal.Footer>
		</Form>
      </Modal>
		</>
	);
};

export default Banner;
