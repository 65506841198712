import { api, endPoint } from './client';

const getLogoAndContactInfo = () => api.get(`${endPoint.users}/logo-contact-info`)

const getAllCms = () => api.get(`${endPoint.users}/allCms`)

const getdownload = () => api.get(`${endPoint.users}/download`)

export {
    getLogoAndContactInfo,
    getAllCms,
    getdownload
}