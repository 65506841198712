// const baseURL = 'http://localhost:6098';
const baseURL = 'https://nodeserver.mydevfactory.com:6098';

const endPoint = {
    users: '/api/users',
    jobs: '/api/jobs',
    chat: '/api/chat',
    bank: '/api/bank',
    ads: '/api/ads',
};

export {
    baseURL,
    endPoint,
}