import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Image,
  Nav,
  Tab,
  Card,
  Table,
} from "react-bootstrap";

import {
  getSentJobOffers,
  getAppliedApplicationList,
} from "../../../../api/job";
import { getEmployeeEmail, getUserEmail } from "../../../../utils/helper";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../../components/Context";
import { getAcceptedJobOffers, getAcceptedJobApplicantByEmployeer, startJob } from '../../../../api/job';

const JobsYouApplied = ({
  user,
  displayModal,
  isFetchJobs,
  setFetchJobs,
  socket,
}) => {
  const { GET_APPLICATION_LIST, jobList, setJobList } = useContext(Context);
  const navigate = useNavigate();
  useEffect(() => {
    GET_APPLICATION_LIST();
  }, []);

  

  const START_JOB = async (e, id, jobid, employer_id) => {
    try {
        e.preventDefault();
        const res = await startJob(id);
        if (res.ok) {
            socket.emit('notification', {
                title: res.data.message,
                body: `${JSON.parse(localStorage.getItem('uverlist-user-data')).firstname} ${JSON.parse(localStorage.getItem('uverlist-user-data')).lastname} started your job!`,
                receiver: employer_id,
            })
            socket.emit('getjobdetailsweb', {
                jobId: jobid,
                receiver: employer_id,
            })
            socket.emit('getacceptedList', {
                jobId: jobid,
                receiver: employer_id,
            })
            socket.emit("createjob", {
                receiver: employer_id,
            });
            GET_APPLICATION_LIST();

            displayModal('Success', res.data.message);
        }
    } catch (error) {
        console.log(error);
    }
}



  const listenToSocketEvents = () => {
    socket.on("GET_APPLICATION_LIST", (getacceptedList) => {
      setJobList(getacceptedList);
    });
  };
  useEffect(() => {
    if (!socket) return;
    listenToSocketEvents();
  }, [socket]);
  const viewCreatedJob = (e, jobId) => {
    e.preventDefault();
    navigate(`/Workedjobdetails/${jobId}`);
  };
  return (
    <>
      <section className="userinner">
        <Row className="">
          <Col md={12} className="">
            <div className="table-responsive">
              <Table striped hover className="com-table nowsp-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Job Status</th>
                    <th>Apply Date</th>
                    <th>Job Title</th>
                    <th>Job Type</th>
                    <th>Post By</th>
                    <th>Total Apply</th>
                    <th>Amount</th>
                    <th>Phone No</th>
                    <th>Status</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {jobList &&
                    jobList.length > 0 &&
                    jobList.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item?.job_id?.job_id}</td>
                          <td className='fr-status'>
                            <a
                              className={`tbt-view tbtn mx-2 
                                                        ${
                                                          item?.job_id
                                                            ?.status ===
                                                          "closed"
                                                            ? "text-danger"
                                                            : item?.job_id
                                                                ?.status ===
                                                              "applied"
                                                            ? "text-info"
                                                            : item?.job_id
                                                                ?.status ===
                                                              "In process"
                                                            ? "text-warning"
                                                            : item?.job_id
                                                                ?.status ===
                                                              "opened"
                                                            ? "text-primary"
                                                            : item?.job_id
                                                                ?.status ===
                                                              "disapproved"
                                                            ? "text-secondary"
                                                            : item?.job_id
                                                                ?.status ===
                                                              "completed"
                                                            ? "text-success"
                                                            : "text-danger"
                                                        } broder-0`}
                            >
                              {item?.job_id?.status}
                            </a>
                          </td>
                          <td>
                            {/* 10-3-2023   */}
                            {`${new Date(item.createdAt).getDate()}-${
                              new Date(item.createdAt).getMonth() + 1
                            }-${new Date(item.createdAt).getFullYear()}`}
                          </td>
                          <td>{item?.job_id?.title}</td>
                          <td>{item?.job_id?.job_type}</td>
                          <td>
                            {/* {item?.job_id?.price?.type==='fixed'?`$${item?.job_id?.price?.fixed}`:`$${item?.job_id?.price?.min}-$${item?.job_id?.price?.max}`} */}
                            {item?.employer_id?.firstname}{" "}
                            {item?.employer_id?.lastname}
                          </td>
                          <td>
                          <td>{item?.job_id?.totalApply}</td>
                          </td>
                          <td>
                            {item?.job_id?.price?.type === "fixed"
                              ? `$${item?.job_id?.price?.fixed}`
                              : `$${item?.job_id?.price?.min}-$${item?.job_id?.price?.max}`}
                          </td>
                          <td>{item?.employer_id?.phone_number}</td>

                          <td className='fr-status'>
                            <a
                              className={`tbt-view tbtn mx-2 
                                                        ${
                                                          item?.status ===
                                                          "closed"
                                                            ? "text-danger"
                                                            : item?.status ===
                                                              "applied"
                                                            ? "text-info"
                                                            : item?.status ===
                                                              "In process"
                                                            ? "text-warning"
                                                            : item?.status ===
                                                              "approved"
                                                            ? "text-primary"
                                                            : item?.status ===
                                                              "disapproved"
                                                            ? "text-secondary"
                                                            : item?.status ===
                                                              "completed"
                                                            ? "text-success"
                                                            : "text-danger"
                                                        } broder-0`}
                            >
                              {item?.status}
                            </a><br/>
                            {item?.status === "approved"  &&  item?.job_id?.status === "opened" ? <span
                            className="tbt-view tbtn mx-2 text-secondary"
                            style={{ cursor: "pointer" }}
                            onClick={(e) =>
                              START_JOB(
                                e,
                                item._id,
                                item?.job_id?._id,
                                item?.employer_id?._id
                              )
                            }
                          >
                            <i class="bi bi-check2-all"></i>
                            Get started
                          </span> : <></>}

                          
                          </td>
                         
                          <td className="text-center">
                            <a
                              className="tbt-view tbtn mx-2 text-secondary"
                              onClick={(e) =>
                                viewCreatedJob(e, item?.job_id?._id)
                              }
                            >
                              <i class="bi bi-eye-fill"></i>
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default JobsYouApplied;
