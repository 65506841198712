import React, { useState, useEffect,useContext } from "react";
import Slider from "react-slick";
import Banner from "../../Layouts/Banner";
import SetBanner from "../../components/setbanner";
import arrow from '../../assets/images/arrow-view.png';
import appscreen from '../../assets/images/appscreen.png';
import appstor from '../../assets/images/appstroe.png';
import gpstore from '../../assets/images/gpstore.png'; 
import qrcode from '../../assets/images/qrcode.png'; 
import call from '../../assets/images/phone-cal.svg';
import email from '../../assets/images/email.svg';

import icon1 from '../../assets/images/icon1.svg';
import icon2 from '../../assets/images/icon2.svg';
import icon3 from '../../assets/images/icon3.svg';
import icon4 from '../../assets/images/icon4.svg';
import { Image } from "react-bootstrap";
import { recentJobList, getCategoryList } from "../../api/job";
import { getExpectedCost, formatJobs } from "../../utils/helper";
import { useNavigate, NavLink } from "react-router-dom";
import { Context } from "../../components/Context";
import './home.css'
import { getExpertList } from "../../api/expertise";
import {getdownload} from '../../api/cms';
import { getAllJobList,allJobListBody } from "../../api/job";
// import QRCode from "react-qr-code";
import QRCode from 'qrcode'

const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    margin: '15px',
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    slidesToShow: 4,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const HomeNew = ({ user = {}, logo, setHomeSelectedCategoryId }) => {
    const navigate = useNavigate()
    const [categoryList, setCategoryList] = useState([]);
    const [recentJobs, setRecentJobs] = useState([]);
    const [expertList, setExpertList] = useState([]);
    const [download, setDownload] = useState({});
    // const [appleqr,setAppleQr] = useState('');
    // const [googleqr,setGoogleeQr] = useState('');
    // const [distance, setDistance] = useState(15);
    const { distance, setDistance } = useContext(Context);
    const GET_CATEGORY_LIST = async () => {
        try {
            const res = await getCategoryList();
            if (!res.ok) return;
            let categories = res.data.data;
            if (!categories.length) return;
            if (categories.length > 8) categories = categories.slice(0, 8);
            setCategoryList(categories);
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        GET_CATEGORY_LIST()
    }, [])

    const GET_RECENT_JOBS = async () => {
        
        try {
            const res = await recentJobList();
            if (!res.ok) return;
            
            let jobs = res.data.data;
            
            if (!jobs) return;
            if (!jobs.length) return;

            jobs = formatJobs(jobs)
            if (user._id) {
                jobs = jobs.filter(job => job.employer_id._id !== user._id)
            };

            setRecentJobs(jobs.slice(0,8));
        } catch (err) {
            console.error(err);
        }
    }
    // With async/await
const generateQR = async text => {
    try {
      return await QRCode.toDataURL(text)
    } catch (err) {
      return err
    }
  }
    const GET_DOWNLOAD = async () => {
        try {
            const res = await getdownload();
            if (!res.ok) return;
            
            let download = res.data.download;
            QRCode.toCanvas(document.getElementById('canvas1'), download.playstorelink, function (error) {
                if (error) console.error(error)
                console.log('success!');
              })
            QRCode.toCanvas(document.getElementById('canvas2'), download.appstrelink, function (error) {
                if (error) console.error(error)
                console.log('success!');
              })

            setDownload(download);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        GET_DOWNLOAD();
        GET_RECENT_JOBS();
    }, []);

    const handelSubmit = async (e)=>{
        e.preventDefault();
        try {
            const form = new FormData(e.target);
            console.log(Object.fromEntries(form.entries()));
            // let data = Object.fromEntries(form.entries());
            // const res = await allJobListBody(form);
            // console.log(res.data.data.docs);
            // setRecentJobs(res.data.data.docs);
            navigate('/SearchJob',{state:Object.fromEntries(form.entries())})
        } catch (error) {
            console.log(error)
        }     
    }

    const GET_EXPERT_LIST = async () => {
        try {
            const res = await getExpertList();
            if (!res.ok) return;
            let expertList = res.data.data;

            if (user._id) {
                expertList = expertList.filter(usr => usr._id !== user._id);
            };

            setExpertList(expertList);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        GET_EXPERT_LIST();
    }, []);

    const viewJob = (id) => navigate(`/JobDetails/${id}`)

    const viewCategoryJobs = (e, catId) => {
        e.preventDefault();
         try {
            navigate('/SearchJob',{state:{category:catId}})
        } catch (error) {
            console.log(error)
        }
    };

    const getUserEmail = (email) => {
        if (!email) return;
        let idealEmail = "mohammed.thurabudeen@brainiuminfotech.com";
        if (email.length <= idealEmail.length) return email;

        let email1 = email.substring(0, idealEmail.length);
        let email2 = email.substring(idealEmail.length, email.length);
        let email3 = null;

        if (email2.length > idealEmail.length) {
            email2 = email.substring(idealEmail.length, 2 * idealEmail.length);
            email3 = email.substring(2 * idealEmail.length, email.length);
        }

        return (
            email3
                ?
                <span>
                    {email1} <br />
                    {email2} <br />
                    {email3}
                </span>
                :
                <span>
                    {email1} <br />
                    {email2}
                </span>
        )


    };

    const getUserOccupation = (user) => {
        if (!user.occupation) return '';
        if (!user.occupation.length) return '';
        let categoryNames = user.occupation.map(occ => occ.category ? occ.category.name : '');
        return categoryNames.join(', ');
    };

    

    const viewExpertDetails = (id) => {
        navigate(`/ExpertiseDetails/${id}`);
    };

    const handelCategorySubmit = (e)=>{
        e.preventDefault();
       
    }

    return (
        <>
            <SetBanner distance={distance} setDistance={setDistance} />
            {/* <Banner logo={logo} categoryList={categoryList} handelSubmit={handelSubmit} /> */}
            {/* <section className="banner-botom py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="cetagorilist">
                                {
                                    categoryList.length > 0
                                    &&
                                    categoryList.map(item => (
                                        <a className="celitem" onClick={e => viewCategoryJobs(e, item._id)}>
                                            <img src={item.icon} style={{ width: '100%', height: '100%', objectFit: 'contain' }} /><br />
                                            <span>{item.name}</span>
                                        </a>
                                    ))
                                }
                                <NavLink to="/AllCategories" className="celitem active">
                                    <img src={view} /><br />
                                    <span>View all</span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="pt-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 text-center mb-3">
                            <Image src ={download?.imagelink} alt="" className="img-fluid" />
                        </div>
                        <div className="col-lg-7">
                            <h1>
                                 {download?.title}
                            </h1>
                            <p className="mb-5">
                             {download?.description}
                            </p>
                            <div className="downdiv">
                                <div className="downloadlink">
                                <canvas id="canvas2" style={{width:'100%'}} ></canvas>
                                    {/* <Image src={download?.appstreapplink} alt="" /> */}
                                    <a className="btn mt-3" href={download?.appstrelink} target="_blank">
                                        <Image src={appstor} alt="" />
                                    </a>
                                </div>
                                <div className="downloadlink">
                                <canvas id="canvas1" style={{width:'100%'}}></canvas>
                                    {/* <Image src={download?.playstoreapplink} alt="" /> */}
                                    <a className="btn  mt-3" href={download?.playstorelink} target="_blank">
                                        <Image src={gpstore} alt="" />
                                    </a>
                                </div>
                            </div>
                            
                            
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="recentjobs py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="sub-paget-itle">Recent Jobs</h2>
                            
                        </div>
                    </div>
                    <div className="row">
                        {
                            recentJobs
                            &&
                            recentJobs.length > 0
                            &&
                            recentJobs.map(job => (
                                <div className="col-md-3 mb-3" key={job._id} onClick={e => viewJob(job._id)}>
                                    <div className="rjcarsd">
                                        <img src={job.profileImage} />
                                        <div className="top-cont">
                                            <h3>
                                               
                                                {job.title}<br /><small>{job.createdDate}</small>
                                            </h3>
                                            <p>
                                                {job.description ? job.description.substring(0, 73) : ''}
                                            </p>
                                        </div>
                                        <div className="bottom-cont">
                                            {
                                                job.priceType === 'fixed'
                                                    ?
                                                    <span
                                                        style={{
                                                            fontSize: job.salary.toString().includes('.') ? 16 : 18
                                                        }}
                                                    >
                                                        {job.salary}
                                                    </span>
                                                    :
                                                    <span
                                                        style={{
                                                            fontSize: job.maxPrice.toString().includes('.') ? 16 : 18
                                                        }}
                                                    >
                                                        {job.maxPrice}
                                                    </span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        <div className="col-lg-12 text-end mt-3">
                        <NavLink to="/Jobs" className="btn btn-outline-green px-4">
                        View All <img src={arrow} alt="" />
                                </NavLink>
                        </div>
                    </div>
                </div>

            </section> */}

            {/* {
                expertList.length > 0
                &&
                <section className="expatis pb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="sub-paget-itle">Expertise </h2>
                            </div>
                        </div>
                        <div className="row">

                            <Slider {...settings}>
                                {
                                    expertList.map(user => (
                                        <div className="expatis-list pointer" onClick={e => viewExpertDetails(user._id)}>
                                            <div className="expatis-item">
                                                <div className="p-pic">
                                                    <Image src={user.profileimage} alt="" />
                                                </div>
                                                <div className="p-cont">
                                                    <h3>{user.firstname} {user.lastname} <br /> <small>{getUserOccupation(user)}</small></h3>
                                                    <div class="price-details">{getExpectedCost(user.expectedCost)}</div>
                                                    <p className="rating">
                                                        <i class="bi bi-star-fill"></i>
                                                        <i class="bi bi-star-fill"></i>
                                                        <i class="bi bi-star-fill"></i>
                                                        <i class="bi bi-star"></i>
                                                        <i class="bi bi-star"></i>

                                                    </p>
                                                    <p>
                                                        <Image src={call} alt="" /> <span> {user.phone_number}</span>
                                                    </p>
                                                    <p className="email-id-cust">
                                                        <Image src={email} alt="" />
                                                        {
                                                            user.email
                                                            &&
                                                            <span>
                                                                {getUserEmail(user.email)}
                                                            </span>
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                                {expertList.length < 4 &&
                                    Array.from(
                                        { length: 4 - expertList.length },
                                        () => <div></div>
                                    )}

                            </Slider>

                        </div>
                        <div className="row">
                        <div className="col-lg-12 text-end mt-3">
                        <NavLink to="/ExpertiseList" className="btn btn-outline-green px-4">
                        View All <img src={arrow} alt="" />
                                </NavLink>
                        </div>
                        </div>
                    </div>

                </section>
            } */}
            {/* <section className="how-it-works py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="sub-paget-itle">How it Works <small> Instructions Below</small></h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 how-it-list text-center">
                            <img src={icon1} />
                            <h4>
                                CREATE YOUR ACCOUNT
                            </h4>
                            <p>
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                            </p>
                        </div>
                        <div className="col-md-3 how-it-list text-center">
                            <img src={icon2} />
                            <h4>
                                CREATE YOUR ACCOUNT
                            </h4>
                            <p>
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                            </p>
                        </div>
                        <div className="col-md-3 how-it-list text-center">
                            <img src={icon3} />
                            <h4>
                                CREATE YOUR ACCOUNT
                            </h4>
                            <p>
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                            </p>
                        </div>
                        <div className="col-md-3 how-it-list text-center">
                            <img src={icon4} />
                            <h4>
                                CREATE YOUR ACCOUNT
                            </h4>
                            <p>
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                            </p>
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    );
};

export default HomeNew;
