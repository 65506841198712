
import React, { useState } from 'react';
import { Button, Row, Col, Form, Image, Nav, Tab, Dropdown, DropdownButton, Card, Modal, Tabs } from 'react-bootstrap';
import closeicon from '../../../assets/images/close-icon.svg';
import fpic1 from '../../../assets/images/pp-pic.png';
import fpic2 from '../../../assets/images/pp-pic2.png';
import sports from '../../../assets/images/sport.svg';
import automotive from '../../../assets/images/automotive.svg';
import Petcare from '../../../assets/images/petcare.svg';
import Message from '../message';
import noImage from '../../../assets/images/noImage.png';

const getSubCategories = (subcategories) => {
    if (!subcategories) return '';
    if (!subcategories.length) return '';
    return `(${subcategories.map(subcat => subcat.name).join(', ')})`;
}

const getProfession = (user) => {
    if (!user.occupation) return '';
    if (!user.occupation.length) return '';

    return user.occupation.map((occ, index) => (
        <>
            <p>{occ.category ? occ.category.name : ''} {getSubCategories(occ.subcategories)} {index == user.occupation.length - 1 ? '' : ', '}</p>
        </>
    ))
};

const ViewFriendModal = ({ user, show, handleClose }) => (
    <Modal show={show}>
        <Modal.Body className='px-5' >
            <Button variant="primary" className='btn-green closeicon-btn' onClick={handleClose}>
                <Image src={closeicon} alt="" />
            </Button>
            <Row>
                <Col md={12}>
                    <div className="profile-pic">
                        <Image src={user.profileimage?user.profileimage:noImage} />
                    </div>
                    <div className="text-center">
                        <h3>
                            {user.firstname} {user.lastname}
                        </h3>
                        <p className='mb-0'>
                            {user.email}
                        </p>
                        <p>
                            {user.phone_number}
                        </p>
                    </div>
                </Col>
                {/* <Col md={12}>
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </Col> */}
                <Col md={12}>
                    <h5>Service Provided</h5>
                    {getProfession(user)}
                    {/* <div className='service-list'>
                        <div className="celitem">
                            <img src={sports} />
                            <span>Sports</span>
                        </div>
                        <div className="celitem">
                            <img src={automotive} />
                            <span>Automotive</span>
                        </div>
                        <div className="celitem">
                            <img src={Petcare} />
                            <span>Petcare</span>
                        </div>
                    </div> */}
                </Col>
                {/* <Col md={12}>
                    <Button className='btn-green w-100'>Generate private job</Button>
                </Col> */}
            </Row>

        </Modal.Body>
    </Modal>
)

export default ViewFriendModal;