import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import SimpleImageSlider from "react-simple-image-slider";
import about from "../../assets/images/about-right.jpg";
import jobbanner from "../../assets/images/job-banner.png";
import chat from "../../assets/images/chat.png";
import play from "../../assets/images/play.jpg";
import cattype from "../../assets/images/type.png";
import star from "../../assets/images/star.png";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import store from "../../utils/store";
import { FACEBOOK_APP_ID } from "../../config/socalLogin";
import {
  Button,
  Row,
  Col,
  Form,
  Image,
  Nav,
  Tab,
  Card,
  Table,
  Toast,
} from "react-bootstrap";
import { getJobDetails } from "../../api/job";
import { formatJob } from "../../utils/helper";
import ViewUserModal from "./UserModal";
import Facebook from "./socialmedia/Facebook";
import LinkedIn from "./socialmedia/LinkedIn";
import Twitter from "./socialmedia/Twitter";
import Whatsapp from "./socialmedia/Whatsapp";
import ApplyJobModal from "../../components/ApplyJobModal";
import { applyJob } from "../../api/job";
// import Map from '../../components/GoogleMaps';

import JobDetailGoogleMap from "../../components/JobDetailGoogleMap";
import Helmet from "react-helmet";
import { getBankList } from "../../api/payment";
// import DocumentMeta from 'react-document-meta';

// const meta = {
//     title: 'Some Meta Title',
//     description: 'I am a description, and I can create multiple tags',
//     canonical: 'http://example.com/path/to/page',
//     meta: {
//       charset: 'utf-8',
//       name: {
//         keywords: 'react,meta,document,html,tags'
//       }
//     }
// };

const JobDetails = ({
  displayModal,
  user,
  isLoggedIn,
  recipientId,
  setRecipientId,
}) => {
  const [center, setCenter] = useState({});

  const GET_JOB = async () => {
    try {
      const res = await getJobDetails(jobId);

      if (!res.ok) return displayModal("Error", res.data.message);
      if (!res.data.success) return displayModal("Error", res.data.message);
      let job = formatJob(res.data.data);

      setCenter({
        lat: job.loc[1],
        lng: job.loc[0],
      });
      setAvgRating(avgRatingCalculator(job.employer_id.rating));
      setJob(job);
    } catch (err) {
      console.error(err.message);
    }
  };

  const avgRatingCalculator = (ratings) => {
    if (ratings.length === 0) {
      return 0;
    }
    const sum = ratings.reduce((total, rating) => total + rating.rate, 0);
    const avg = sum / ratings.length;
    return avg.toFixed(1);
  };
  const [avgRating, setAvgRating] = useState(0);
  const params = useParams();
  const navigate = useNavigate();
  const jobId = params.id;
  const [job, setJob] = useState({});
  const [isVideoMode, setVideoMode] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [employer, setEmployer] = useState({});

  // Job Application Modal
  const [showModal, setShowModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState({});
  const [resume, setResume] = useState("");
  const [mapElements, setMapElements] = useState([]);
  const handleClose = () => {
    setEmployer({});
    setShowUserModal(false);
  };

  useEffect(() => {
    GET_JOB();
  }, [jobId]);

  const handleChatNow = (e, employer) => {
    e.preventDefault();

    //1. Set local storage variable
    localStorage.setItem("isRedirectChatNow", true);
    localStorage.setItem("recipientId", employer._id);
    localStorage.setItem(`uverlist-last-event-key`, "chat");
    setRecipientId(employer._id);

    if (!isLoggedIn) return navigate("/signin");
    navigate("/user");
    return;
  };
  // /EmployerProfile/:id
  const showUser = (e) => {
    //e.preventDefault();
    setEmployer(job.employer_id);
    // console.log('job.employer_id', job.employer_id)
    setShowUserModal(true);
    navigate(`/EmployerProfile/${job.employer_id._id}`);
  };

  const handleApplyJob = (e, job) => {
    e.preventDefault();
    if (bankList != null && bankList.code == 500) {
      toast.error(bankList.message);
      navigate("/user");
      window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    store.storeLastEventKey('Account')
    } else if (!localStorage.getItem("uverlist-user-token"));

    console.log("applying to job", job);
    setSelectedJob(job);
    setShowModal(true);
  };

  const handleResumeUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => setResume(reader.result);
    reader.readAsDataURL(file);
  };

  const APPLY_JOB = async (formEl) => {
    try {
      const form = new FormData(formEl);

      const application = {
        job_id: selectedJob._id,
        employee_id: user._id,
        shortDescription: form.get("shortDescription"),
        expectedSalary: form.get("expectedSalary"),
        expectedJoiningDate: form.get("expectedJoiningDate"),
        resume,
      };
      console.log("application", application);
      const res = await applyJob(selectedJob._id, application);
      if (!res.ok) return displayModal("Error", res.data.message);
      setShowModal(false);
      setResume("");
      displayModal("Success", res.data.message);
    } catch (e) {
      console.error(e);
    }
  };
  function handleGoBack() {
    navigate(-1);
  }

  const [bankList, setBankList] = useState(null);

  console.log(bankList, "bankList_____-------------");
  const GET_BANK_LIST = async () => {
    try {
      const res = await getBankList();
      // console.log(res.data,"HHHHHHHHHHHHHHHHHH")
      if (res.data.code === 200) {
        setBankList(res.data.data);
        console.log("<<<<<<<<<>>>>>>>>>>", res.data.data);
      } else if (res.data.code === 500) {
        setBankList(res.data);
        console.log("<<<<<<<<<>>>>>>>>>>", res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GET_BANK_LIST();
  }, []);
  return (
    <>
      <Helmet>
        <meta property="og:type" content="jobdetail" />
        <meta property="og:title" content={job?.title} />
        <meta property="og:description" content={job?.description} />
        <meta property="og:image" content={job?.profileImage} />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <ApplyJobModal
        show={showModal}
        setShow={setShowModal}
        job={selectedJob}
        handleResumeUpload={handleResumeUpload}
        APPLY_JOB={APPLY_JOB}
      />

      <section className="job-banner">
        <div className="container">
          <Row>
            <Col md={12} className="text-center">
              <h1>Job Details</h1>
              <button className="btn btn-green" onClick={handleGoBack}>
                Go back
              </button>
              {/* <p>
                                Search your career oppurtunity through 12,800 jobs
                            </p> */}
            </Col>
          </Row>
        </div>
      </section>
      <section className="joblispage-top py-5">
        <div className="container">
          <Row>
            <Col md={7} lg={8} className="job-fil-right">
              <Row className="job-fil">
                <Col md={12} className="mb-4">
                  <Card style={{ background: "#000" }}>
                    {/* <Image src={job.profileImage} alt="" className="w-100" /> */}
                    {isVideoMode
                      ? job.video && (
                          <video
                            src={job.video}
                            autoPlay={true}
                            className="w-100"
                            height={504}
                          />
                        )
                      : job.slides && (
                          <SimpleImageSlider
                            showBullets={true}
                            showNavs={true}
                            images={job.slides}
                            width={"100%"}
                            height={504}
                            autoPlay={true}
                          />
                        )}
                  </Card>
                </Col>

                {job.video ? (
                  <Col md={12} className="mb-4">
                    <div
                      style={{ cursor: "pointer", width: 120 }}
                      onClick={() => setVideoMode(!isVideoMode)}
                    >
                      {isVideoMode ? (
                        <>
                          {isVideoMode}
                          <Image
                            src={play}
                            alt=""
                            width={30}
                            height={30}
                          />{" "}
                          <span>Show Slides</span>
                        </>
                      ) : (
                        <>
                          <Image src={play} alt="" width={30} height={30} />{" "}
                          <span>Play Video</span>
                        </>
                      )}
                    </div>
                  </Col>
                ) : (
                  ""
                )}

                <Col md={12}>
                  <div className="title-cont w-100">
                    <h3>
                      <small>
                        {job.createdDate} - {job.createdTime}
                      </small>
                      <br />
                      {job.title}
                    </h3>
                  </div>
                  <div className="btn-cont w-100 text-end mb-3">
                    <Button
                      className="btn-outline-green btn-active"
                      onClick={(e) => handleChatNow(e, job.employer_id)}
                    >
                      <Image src={chat} alt="" /> Chat With Us
                    </Button>
                    <Button
                      className="btn-green mr-3"
                      onClick={(e) => handleApplyJob(e, job)}
                    >
                      Apply Now
                    </Button>
                  </div>
                  <div className="text-cont w-100">{job.description}</div>
                </Col>
                {job.shareUrl && (
                  <Col>
                    <div className="mt-50">
                      <Whatsapp
                        url={job.shareUrl}
                        quotes={job.title}
                        hashtag={"#Uverlist"}
                      />
                      &nbsp;&nbsp;
                      <Facebook
                        url={job.shareUrl}
                        quotes={job.title}
                        hashtag={"#Uverlist"}
                      />
                      &nbsp;&nbsp;
                      <LinkedIn
                        url={job.shareUrl}
                        quotes={job.title}
                        hashtag={"#Uverlist"}
                      />
                      &nbsp;&nbsp;
                      <Twitter
                        url={job.shareUrl}
                        quotes={job.title}
                        hashtag={"#Uverlist"}
                      />
                      {/* <FacebookShareButton image='https://nodeserver.mydevfactory.com:6098/1663919830162-user-profileimage.jpeg' url={job.shareUrl} quotes={job.title} hashtag={'#Uverlist'}> <FacebookIcon size={32} round={true} /></FacebookShareButton> */}
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
            <Col md={5} lg={4} id={"job-map"}>
              {
                //      <Map
                //      center={{
                //          lat:job.loc[0],
                //          lng:job.loc[1]
                //      }}
                //      jobLocations={[{
                //          job:job._id,
                //          title:job.title,
                //          location:job.location,
                //          url:job.url
                //      }]}
                //      distance= {5}
                //  />
              }

              {/* {mapElements} */}
              {<JobDetailGoogleMap center={center} />}

              <Card className="jod-det-card mt-3">
                <Card.Body>
                  <div className="list-fodet">
                    <h4>Post By</h4>
                    <p onClick={showUser} className="posted-by">
                      {job.employer_name}
                    </p>
                    {/* <NavLink to={`/JobDetails/1}`}>{job.employer_name}</NavLink> */}
                  </div>
                  <div className="list-fodet">
                    <h4>Job Type</h4>
                    <p>{job.job_type}</p>
                  </div>
                  <div className="list-fodet">
                    <h4>Project Amount</h4>
                    <p>{job.salary}</p>
                  </div>
                  <div className="list-fodet">
                    <h4>Rating</h4>
                    <p onClick={showUser}>
                      <Image src={star} alt="user" />{" "}
                      <span>
                        {avgRating} -{" "}
                        {job?.employer_id?.rating
                          ? job?.employer_id?.rating.length
                          : ""}{" "}
                        review
                      </span>
                    </p>
                  </div>
                  <div className="list-fodet">
                    <h4>Location</h4>
                    <p>{job.address}</p>
                  </div>
                  <div className="list-fodet">
                    <h4>Time schedule</h4>
                    {job.isJobMoreThanOneDay ? (
                      <div className="flex-column gap-10 mt-10">
                        <div className="flex-row gap-10">
                          <span className="label w-100-px">Start Date</span>
                          <span>{job.startDate}</span>
                        </div>
                        <div className="flex-row gap-10">
                          <span className="label w-100-px">End Date</span>
                          <span>{job.endDate}</span>
                        </div>
                        <div className="flex-row gap-10">
                          <span className="label w-100-px">Time</span>
                          <span>
                            {job.start_time} - {job.end_time}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="flex-column gap-10 mt-10">
                        <div className="flex-row gap-10">
                          <span className="label w-100-px">Date</span>
                          <span>{job.date}</span>
                        </div>
                        <div className="flex-row gap-10">
                          <span className="label w-100-px">Time</span>
                          <span>
                            {job.start_time} - {job.end_time}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default JobDetails;
