import React, { useEffect, useState, useContext,useRef } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { Button, Row, Col, Form, Image, Nav, Card, Modal } from 'react-bootstrap';
import edit from '../../../../assets/images/eva_edit.svg';
import { updateProfile } from '../../../../api/profile';
import store from '../../../../utils/store';
import { changePassword } from '../../../../api/auth';
import { chainOperator, isValidPassword } from '../../../../utils/helper';
import { GET_CATEGORY_LIST, GET_SUB_CATEGORY_LIST, GET_SUB_CATEGORY_LIST_RETURN, handleCategoryChange } from '../../service';
import { getSubcategoryList } from '../../../../api/job';
import Occupation from './Occupation';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import CurrencyInput from 'react-currency-input-field';
import noimage from '../../../../assets/images/noImage.png';
import {Context} from '../../../../components/Context'
import SpinnerComponent from '../../../../components/Spinner';
import moment from 'moment';
const style = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 60,
    },
    inputs: {
        display: 'flex',
        flexDirection: 'column',
        gap: 7,
        width: '100%',
    },
    placeInput: {
        display: 'flex',
        flexDirection: 'column',
        gap: 7,
        width: '100%',
        padding: 15,
        height: 50,
        borderRadius: 12,
    },
    labels: {
        fontWeight: 'bold',
    },
    flexRow: {
        display: 'flex',
    }
}

const ProfileInfo = ({ user, displayModal, setIsUpdateUser, setIsLoggedIn }) => {
    const dropdownRef = useRef();
    const {isLoading,setIsLoading} = useContext(Context);
    const [gender, setGender] = useState();
    const [photo, setPhoto] = useState(user?.profileimage);
    const [location, setLocation] = useState({});
    const [toggleLocationInput, setToggleLocationInput] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [subcategoryList, setSubCategoryList] = useState([]);
    const [categoryId, setCategoryId] = useState('');
    const [defaultCategoryId, setDefaultCategoryId] = useState('');
    const [defaultSubCategoryId, setDefaultSubCategoryId] = useState('')
    const [occupation, setOccupation] = useState([{}]);
    const [occupationForm, setOccupationForm] = useState([{}]);
    const [renderOccupation, setRenderOccupation] = useState(false);
    const [renderMultiDropDown, setRenderMultiDropDown] = useState(false);
    const [isExpertise, setExpertise] = useState(user.isExpertise);
    const [costType, setCostType] = useState('day');
    const [currency,setCurrency] = useState(user.expectedCost ? user.expectedCost.value : '');

    useEffect(() => {
        GET_CATEGORY_LIST(setCategoryList);
    }, []);
 

    // Get Default Sub Category List
    useEffect(() => {
        GET_SUB_CATEGORY_LIST(defaultCategoryId, setSubCategoryList);
    }, [defaultCategoryId]);

    const getUserOccupation = async () => {
        if (!user.occupation) return;
        if (!user.occupation.length) return;

        let occupationList = [];

        for (const occ of user.occupation) {
            let occupation = {
                category: occ.category,
                subcategoryList: await GET_SUB_CATEGORY_LIST_RETURN(occ.category),
                subcategories: occ.subcategories
            };
            occupationList.push(occupation)
        };

        setOccupation(occupationList);
        setOccupationForm(occupationList);
        setRenderOccupation(!renderOccupation);
    };

    useEffect(() => {
        getUserOccupation();
    }, [user]);

    const handleFileChange = (e) => {
        // Convert Image To Base 64
        const imageFile = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = async () => {
            setPhoto(reader.result);
        }
        reader.readAsDataURL(imageFile);
    }

    const getLocation = async (address) => {
        const results = await geocodeByAddress(address.value.description);

        if (!results) return;
        if (!results.length) return;

        const loc = await getLatLng(results[0]);

        const location = {
            lat: loc.lat,
            lng: loc.lng,
            address: results[0].formatted_address,
        }
        setLocation(location);
    }

    const UPDATE_PROFILE = async (e) => {
        try {
            e.preventDefault();
             
            const form = new FormData(e.target);
            const phone = form.get('phone_number');
            let data = {
                username: form.get('username'),
                firstname: form.get('firstname'),
                lastname: form.get('lastname'),
                phone_number: form.get('phone_number'),
                 bio: form.get('bio'),
                 dob:form.get('dob'),
            //    quote: form.get('quote'),
                latitude: location.lat,
                longitude: location.lng,
                address: location.address,
                gender,
                profileimage: photo,
                isExpertise,
                occupation: occupationForm
            };

            if (isExpertise) {
                data.expectedCost = {
                    type: costType,
                    value: currency
                };
            };

            const category = form.get('category')
            const subcategory = form.get('subcategory');
           

            data.profession = {};

            if (category) {
                data.profession.category = category;
            }
            if(categoryList.length){
                
            }
            if (subcategory) {
                data.profession.subcategory = subcategory;
            }

            // Send Data
            window.scrollTo(0, 0)
            setIsLoading(true);
            const res = await updateProfile(data);

            if (!res.ok){
                setIsLoading(false);
                return displayModal('Error', res.data.message)
            }

            if (!res.data.success){
                setIsLoading(false);
                return displayModal('Error', res.data.message)
            } 

            store.storeUser(res.data.data);
            if(res.ok){
                setIsLoading(false);
                displayModal('Success', res.data.message);
            }
            

            setIsUpdateUser(true);
        } catch (err) {
            console.error(err);
        }
    }

    const CHANGE_PASSWORD = async (e) => {
        try {
            e.preventDefault();
            const form = new FormData(e.target);

            const data = {};

            for (const [key, val] of form) {
                data[key] = val;
            }
            if (data.new_password !== data.confirm_password) return displayModal('Error', 'Password Mismatch!');

            const [err, success] = isValidPassword(data.new_password);
            if (!success) return displayModal('Error', err)
            setIsLoading(true);
            const res = await changePassword(data);


            if (!res.ok) {
                setIsLoading(false);
                return displayModal('Error', res.data.message);
            }
            if (res.ok){
                setIsLoading(false);
                displayModal('Success', res.data.message);
            }
            

            e.target.reset();
        } catch (err) {
            console.error(err);
        }
    }

    const addOccupationSelector = () => {
        setOccupation([...occupation, { category: '', subcategoryList: [] }])
        setOccupationForm([...occupationForm, { category: '', subcategoryList: [] }])
    }

    const removeOccupationSelector = (index) => {
        let occupationList = occupation;
        occupationList.splice(index, 1);
        setOccupation(occupationList);
        setOccupationForm(occupationList)
        setRenderOccupation(!renderOccupation);
    };




    const handleCategoryChangeNew = async (e, occupationIndex) => {
        
        const categoryId = e.target.value;
        let occ = occupation, occForm = occupationForm;
        occ[occupationIndex].category = categoryId;
        occForm[occupationIndex].category = categoryId;

        const res = await getSubcategoryList(categoryId);

        if (!res.ok) {
            occ[occupationIndex].subcategoryList = [];
            setOccupation(occ);
            setOccupationForm(occForm);
            setRenderMultiDropDown(!renderMultiDropDown);
            return setRenderOccupation(!renderOccupation);
        }

        let subcategorylist = res.data.data;


        if (!subcategorylist) {
            occ[occupationIndex].subcategoryList = [];
            occ[occupationIndex].subcategories = [];
            setOccupation(occ);
            setOccupationForm(occForm);
            return setRenderOccupation(!renderOccupation);
        }
        if (!subcategorylist.length) {
            occ[occupationIndex].subcategoryList = [];
            occ[occupationIndex].subcategories = [];
            setOccupation(occ);
            setOccupationForm(occForm);
            return setRenderOccupation(!renderOccupation);
        }
        setRenderOccupation(true);
        subcategorylist = subcategorylist.map(item => ({ _id: item._id, name: item.name, category: item.category }));
        occ[occupationIndex].subcategoryList = subcategorylist;
        // console.log(occ[occupationIndex].subcategories)
        occ[occupationIndex].subcategories = [];
        dropdownRef.current.resetSelectedValues()
        setOccupation(occ);
        setOccupationForm(occForm);
        setRenderMultiDropDown(!renderMultiDropDown);
    // };
        
        // if (!subcategorylist) {
        //     occ[occupationIndex].subcategoryList = [];
        //     occ[occupationIndex].subcategories = [];
        //     setOccupation(occ);
        //     setOccupationForm(occForm);
        //     return setRenderOccupation(!renderOccupation);
        // }
        // if (!subcategorylist.length) {
        //     occ[occupationIndex].subcategoryList = [];
        //     occ[occupationIndex].subcategories = [];
        //     setOccupation(occ);
        //     setOccupationForm(occForm);
        //     return setRenderOccupation(!renderOccupation);
        // }
        // subcategorylist = subcategorylist.map(item => ({ _id: item._id, name: item.name, category: item.category }));
        // occ[occupationIndex].subcategoryList = subcategorylist;
        // // console.log(occ[occupationIndex].subcategories)
        // occ[occupationIndex].subcategories = [];
        // dropdownRef.current.resetSelectedValues()
        // setOccupation(occ);
        // setOccupationForm(occForm);
        // setRenderOccupation(!renderOccupation);
        // setRenderMultiDropDown(!renderMultiDropDown);
    };

    // useEffect(() => {
    //     console.log('occupation', occupation);
    // }, [occupation]);

    const handleSubCategoryChangeNew = (subcats, index) => {
        if (!subcats) return;
        if (!subcats.length) return;
        let occForm = occupationForm;
        occForm[index].subcategories = subcats.map(e => e.value);
        setOccupationForm(occForm);
    };

    return (
        <>
            <section className="userinner">
                <Row>
                    <Col md={12}>
                        <h3 className='user-listingpage-title'>
                            Hello, {user.firstname}<br /><small>Current page - Account</small>
                        </h3>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Form className='up-account' onSubmit={UPDATE_PROFILE}>
                            <Row>
                                <Col md={12} >
                                    <Form.Group className="mb-5 upprofile">
                                        <div className='profilr-pic-edit'>
                                        {photo ? (
                                                <Image src={photo} alt="" />
                                            ) : (
                                                <Image src={noimage} alt=""/>
                                            )}
                                           
                                        </div>
                                        <div className='edit-ac-pic'>
                                            <Image src={edit} alt="" />
                                        </div>
                                        <Form.Control id='profile-image' type='file' name='profileimage' onChange={handleFileChange} />
                                    </Form.Group>
                                </Col>
                                <Col md={6} >
                                    <Form.Group className="mb-3" controlId="First-name">
                                        <Form.Control className='f-inp' type='text' placeholder="First name" name='firstname' defaultValue={user.firstname} />
                                    </Form.Group>
                                </Col>
                                <Col md={6} >
                                    <Form.Group className="mb-3" controlId="Last-name">
                                        <Form.Control className='f-inp' type='text' placeholder="Last name" name='lastname' defaultValue={user.lastname} />
                                    </Form.Group>
                                </Col>
                                <Col md={6} >
                                    <Form.Group className="mb-3" controlId="First-name">
                                        <Form.Control className='f-inp' type='text' placeholder="User name" name='username' defaultValue={user.username} />
                                    </Form.Group>
                                </Col>
                                <Col md={6} >
                                    <Form.Group className="mb-3" controlId="First-name">
                                        <Form.Control className='f-inp' type='email' placeholder="Email Id" readOnly name='email' defaultValue={user.email} />
                                    </Form.Group>
                                </Col>
                                <Col md={6} className="fro-mobile-num" >
                                    <Form.Group className="mb-3 flex-row" controlId="Phone">
                                        {/* <div style={{ padding: 10, opacity: 0.9, fontWeight: 'bold', background: '#eee', borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }} disabled>+1</div>
                                        <Form.Control className='f-inp' type='tel' placeholder="Phone No." name='phone_number' defaultValue={user.phone_number} /> */}
                                        <PhoneInput
                                        name={'phone_number'}
                                        value={user.phone_number} 
                                        enableSearch={true}
                                        country={'us'}
                                            inputProps={{
                                                name: 'phone_number',
                                                
                                            }}
                                            
                                            />
                                    </Form.Group>
                                </Col>
                                <Col md={6} >
                                    <Form.Group className="mb-3" controlId="Gender">
                                        <Form.Select className='f-inp' onChange={e => setGender(e.target.value)} defaultValue={user.gender}>
                                            <option>Gender</option>
                                            <option value='Male'>Male</option>
                                            <option value='Female'>Female</option>
                                            <option value='Prefer not to say'>Prefer not to say</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={12} >
                                    <Form.Group className="mb-3" controlId="dob">
                                    <label className="mb-3" style={{ fontWeight: 'bold' }}>Date Of Birth</label>
                                        <Form.Control className='f-inp' type='date' name='dob' defaultValue={user.dob?.split('T')[0]} max={moment().toISOString()?.split('T')[0]} />
                                    </Form.Group>
                                </Col>
                                <Col md={12} >
                                    <Form.Group className="mb-3" controlId="bio">
                                    <label className="mb-3" style={{ fontWeight: 'bold' }}>Bio</label>
                                        {/* <Form.Control className='f-inp' type='text' placeholder="Author name"  name='bio' defaultValue={user.bio} /> */}
                                        <Form.Control className='f-inp'  as="textarea" rows={3} name='bio' defaultValue={user.bio} />
                                    </Form.Group>
                                    {/* <Form.Group  className="mb-3" controlId="quote">
        
                                       
                                    </Form.Group> */}
                                </Col>
                                <Col md={12} >
                                    <Form.Group className="mb-3" controlId="Location">
                                        <label className="mb-3" style={{ fontWeight: 'bold' }}>Location</label>
                                        {
                                            !toggleLocationInput
                                                ?
                                                <div style={style.flexRow}>
                                                    <input
                                                        disabled
                                                        defaultValue={user.address}
                                                        style={style.placeInput}
                                                    />
                                                    <Button onClick={e => { e.preventDefault(); setToggleLocationInput(!toggleLocationInput) }}>Change</Button>
                                                </div>
                                                :
                                                <GooglePlacesAutocomplete
                                                    selectProps={{
                                                        location,
                                                        onChange: getLocation,
                                                    }}
                                                />
                                        }
                                    </Form.Group>
                                </Col>
                                <label className="mb-3" style={{ fontWeight: 'bold' }}>Profession</label>
                                {
                                    renderOccupation
                                        ?
                                        <Occupation
                                            occupation={occupation}
                                            categoryList={categoryList}
                                            renderMultiDropDown={renderMultiDropDown}
                                            handleCategoryChangeNew={handleCategoryChangeNew}
                                            handleSubCategoryChangeNew={handleSubCategoryChangeNew}
                                            removeOccupationSelector={removeOccupationSelector}
                                            addOccupationSelector={addOccupationSelector}
                                            dropdownRef={dropdownRef}
                                        />
                                        :
                                        <Occupation
                                            occupation={occupation}
                                            categoryList={categoryList}
                                            renderMultiDropDown={renderMultiDropDown}
                                            handleCategoryChangeNew={handleCategoryChangeNew}
                                            handleSubCategoryChangeNew={handleSubCategoryChangeNew}
                                            removeOccupationSelector={removeOccupationSelector}
                                            addOccupationSelector={addOccupationSelector}
                                            dropdownRef={dropdownRef}
                                            
                                        />
                                }
                                {
                                    isExpertise
                                    &&
                                    <Row>
                                        <Form.Group className="mb-0 col-12 col-sm-9" controlId="ExpectedCost" style={{  marginTop: 20 }}>
                                            <label className="mb-3" style={{ fontWeight: 'bold' }}>Expected Cost ($)</label>
                                            {/* <Form.Control type='number'   min={0} className='f-inp' name='expectedCost' defaultValue={user.expectedCost ? user.expectedCost.value : ''} /> */}
                                            <CurrencyInput className='f-inp form-control'
                                                id="ExpectedCost"
                                                name="expectedCost"
                                                placeholder="Please Enter Amount"
                                                defaultValue={user.expectedCost ? user.expectedCost.value : ''}
                                                decimalsLimit={2}
                                                prefix={"$"}
                                                onValueChange={(value, name) => setCurrency(value)}
                                                />
                                            
                                        </Form.Group>
                                        <Form.Group className="mb-0 col-12 col-sm-3" style={{ marginTop: 20 }}>
                                            <label className="mb-3 d-none d-sm-block" style={{ fontWeight: 'bold', visibility: 'hidden' }}>Label</label>
                                            <Form.Select defaultValue={user.expectedCost ? user.expectedCost.type : 'day'} className='f-inp' onChange={e => setCostType(e.target.value)}>
                                                <option value='day'>/ day</option>
                                                <option value='hour'>/ hour</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Row>
                                }
                                <Row>
                                    <Form.Group className="mb-3" style={{ display: 'flex', gap: 10, marginTop: 20 }}>
                                        <Form.Check type='checkbox' onChange={e => setExpertise(e.target.checked)} defaultChecked={user.isExpertise} />
                                        <label className="mb-3">Expertise</label>
                                    </Form.Group>
                                </Row>
                                <Col md={12} className="text-center">
                                    <Button variant="primary" className='btn-green' type="submit">
                                        Update
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <h4 className='user-list-subtitle'>
                            Change Password
                        </h4>
                    </Col>
                    <Col md={12}>
                        <Form className='up-account' onSubmit={CHANGE_PASSWORD}>
                            <Row>
                                <Col md={6} lg={3} className="mb-3">
                                    <Form.Group controlId="First-name">
                                        <Form.Control className='f-inp' type='password' placeholder="Old Password" name='current_password' required />
                                    </Form.Group>
                                </Col>
                                <Col md={6} lg={3} className="mb-3">
                                    <Form.Group controlId="Last-name">
                                        <Form.Control className='f-inp' type='password' placeholder="New Password" name='new_password' required />
                                    </Form.Group>
                                </Col>
                                <Col md={6} lg={3} className="mb-3">
                                    <Form.Group controlId="First-name">
                                        <Form.Control className='f-inp' type='password' placeholder="Confirm Password" name='confirm_password' required />
                                    </Form.Group>
                                </Col>
                                <Col md={6} lg={3} className="mb-3">
                                    <Button variant="primary" className='btn-green' type="submit">
                                        Update
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </section>
        </>
    )
}

export default ProfileInfo;