import React, { useEffect, useState, useRef,useContext } from "react";
import { Button, Row, Col, Image, Card } from "react-bootstrap";
import view from "../../../assets/images/view.svg";
import deletebt from "../../../assets/images/delete.svg";
import {
  getAllNotifications,
  readNotification,
  deleteNotification,
} from "../../../api/notification";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import CustomPagination from "../../../components/CustomPagination";
import moment from 'moment';
import { Context } from '../../../components/Context'

const Notifications = ({ user, displayModal, socket }) => {
  const navigate = useNavigate();

  const [notifications, setNotifications] = useState([]);
  const [limit, setLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const currentPage = useRef(1);
  const { getAllNotificationsCount } = useContext(Context);
  const GET_NOTIFICATIONS = async () => {
    try {
      const query = `?page=${currentPage.current}&limit=${limit}`;
      const res = await getAllNotifications(query);
      if (!res.ok) return;
      let notifications = res.data.data;
      if (!notifications) return;
      if (!notifications.length) return;
      setNotifications(notifications);
      setPageCount(res.data.results.pageCount);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    currentPage.current = 1;
    GET_NOTIFICATIONS();
  }, []);

  const DELETE_NOTIFICATION = async (e, id) => {
    try {
      e.preventDefault();
      const res = await deleteNotification(id);
      if (!res.ok) return displayModal("Error", res.data.message);
      displayModal("Success", "Notification Deleted!");
      GET_NOTIFICATIONS();
      getAllNotificationsCount();
    } catch (err) {
      console.error(err);
    }
  };

  const viewNotification = async (e, notification) => {
    await readNotification(notification._id);
    navigate(`/JobDetails/${notification.job_id._id}`);
    // switch (notification.type) {
    //   case "Private Job Invitation": {
        
    //     break;
    //   }
    // }
  };

  // notification
  const listenToSocketEvents = () => {
    socket.on("notification", (notificationSocket) => {
      // console.log(notificationSocket)
      setNotifications((oldArray) => [notificationSocket,...oldArray]);
    });
  };

  useEffect(() => {
    if (!socket) return;
    listenToSocketEvents();
  }, [socket]);

  const handlePageClick = (e) => {
    currentPage.current = e.selected + 1;
    GET_NOTIFICATIONS();
  };
  return (
    <>
      <section className="userinner">
        <Row>
          <Col md={12}>
            <h3 className="user-listingpage-title">
              Hello, {user.firstname}
              <br />
              <small>Current page - Notifications</small>
            </h3>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {notifications.length > 0 &&
              notifications.map((item) => (
                <Card className="radius-12 mb-3">
                  <Card.Body>
                    <div className="left-noti">
                      <h3>
                        {item.title}
                        <br />
                        {/* <small>{`${new Date(item.createdAt).getFullYear()}-${new Date(item.createdAt).getMonth()+1}-${new Date(item.createdAt).getDate()} , ${moment(item.createdAt, ["HH:mm"]).format("hh:mm A")}`}</small> */}
                        <small>{moment(item?.createdAt).format("dddd, MMMM Do YYYY, h:mm a")}</small>
                      </h3>
                      <p>{item.body}</p>
                      <small>{moment().from(item?.createdAt)} ago</small>
                    </div>
                    <div className="right-noti">
                      {/* <Button
                        className="accesstion-btn"
                        onClick={(e) => viewNotification(e, item)}
                      >
                        <Image src={view} alt="" />
                      </Button> */}
                      <Button
                        className="accesstion-btn"
                        onClick={(e) => DELETE_NOTIFICATION(e, item._id)}
                      >
                        <Image src={deletebt} alt="" />
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              ))}
          </Col>
  
          {notifications.length > 0 && <CustomPagination
             handlePageClick={handlePageClick}
             pageCount={pageCount}
          />
}
        </Row>
        <Row></Row>
      </section>
    </>
  );
};

export default Notifications;
