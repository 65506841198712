import React, { useState, useEffect } from "react";
import Banner from "../../Layouts/Banner";
import about from '../../assets/images/about-right.jpg';
import SetBanner from "../../components/setbanner";
const About = ({ aboutUs }) => {
	const path = 'https://nodeserver.mydevfactory.com:6098/uploads/cms/'
	 
	return (
		<>
        <SetBanner />
        <section className="inner page py-5">
			<div className="container">
				<div className="row">
					<div className="col-md-8">

						<h2 className="sub-paget-itle">{aboutUs.title}</h2>
						<div dangerouslySetInnerHTML={{ __html: aboutUs.content }}></div>
					</div>
                    <div className="col-md-4">
                        <div className="abt-right">
                            
							{aboutUs.bannerImage ?  (
                                            <img src={`${path}${aboutUs.bannerImage}`} />
                                            ) : (
												<img src={about} />
                                           )}
                        </div>
                    </div>
				</div>
			</div>
		</section>
		</>
	);
};

export default About;
