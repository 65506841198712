import { Form, Button, Image } from 'react-bootstrap';
import { handleFileChange, handleFileClick } from "../helper";

const VideoControl = ({ styles, displayModal, video, setVideo }) => (
    <>
        <div style={styles.customFileControl}>
            <video id='video' src={video} alt="" controls style={styles.resolution} />
        </div>
        <Form.Control id='video-control' accept="video/*" type='file' name='video' style={styles.inputFile} onChange={e => handleFileChange(e, displayModal, setVideo)} />
        <Button id='video-button' style={styles.selectButton} onClick={e => handleFileClick(e, document)}>select video &uarr;</Button>
        <br />
    </>
)

export default VideoControl;