import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form, Image } from 'react-bootstrap';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import email from '../../assets/images/email.svg';
import address from '../../assets/images/address.svg';
import facebook from '../../assets/images/facebook.png';
import setpas from '../../assets/images/set-pas.png';

// API
import { login } from "../../api/auth";

// utils
import store from '../../utils/store';

const ResetPassword = ({ setIsUpdateUser, setIsLoggedIn, displayModal }) => {
	const [toggpasswordInputType, setToggpasswordInputType] = useState('password');
	const [toggConfirmpasswordInputType, setToggConfirmpasswordInputType] = useState('password');

	return (
		<>
			<section className="signuplogin  contact-us page py-5">
				<div className="container">
                    
					<div className="si-re">
						<div className="sire-left f-color-w">
							<Image src={setpas} className="img-fluid" />
 						</div>
						<div className="sire-right f-color-w">
							<Form>
								<h2 className="sl-su-title">
                                    New Password
								</h2>
								<p className="mb-5">
									Please create a new password
								</p>
								<Form.Group className="mb-3 forpassword" controlId="password">
								<Form.Control type={toggpasswordInputType} placeholder="Password" name='password' />
									<i className={toggpasswordInputType == 'password' ? 'bi bi-eye-slash btnToggle' : 'bi bi-eye btnToggle' }
                                         id="togglePassword" onClick={e => setToggpasswordInputType(type => type == 'text' ? 'password' : 'text')}></i>
								</Form.Group>
								
								<Form.Group className="mb-3 forpassword" controlId="confirmpassword">
									<Form.Control required type={toggConfirmpasswordInputType} placeholder="Confirm password" name='confirm_password' />
									<i className={toggConfirmpasswordInputType == 'password' ? 'bi bi-eye-slash btnToggle' : 'bi bi-eye btnToggle' }
                                         id="togglePassword" onClick={e => setToggConfirmpasswordInputType(type => type == 'text' ? 'password' : 'text')}></i>
								</Form.Group>
                                <Button variant="primary" type="submit">
                                    Change
                                </Button>
								
								
							</Form>
							<p className="text-center mt-4">Don’t have an account? <NavLink to="/SignUp">Sign Up</NavLink></p>

						</div>
					</div>
				</div>

			</section>
		</>
	);
};

export default ResetPassword;
