import React from 'react';
import { LinkedinShareButton, LinkedinIcon } from 'react-share';

const LinkedIn = ({ url, quotes, hashtag }) => {
    return (
        <>
        <LinkedinShareButton
            url={url}
            quotes={quotes}
            hashtag={hashtag}
        >
            <LinkedinIcon size={50} borderRadius={5} />
        </LinkedinShareButton>
        </>
    )
}

export default LinkedIn;
