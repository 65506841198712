import React, { useState, useEffect } from "react";
import Banner from "../../Layouts/Banner";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import email from '../../assets/images/email.svg';
import address from '../../assets/images/address.svg';
import phone from '../../assets/images/phone-cal.svg';
import { contactAdmin } from "../../api/contactUs";
import SetBanner from "../../components/setbanner";
import PhoneInput from 'react-phone-input-2'


const ContactUs = ({ contactInfo, displayModal }) => {

	const CONTACT_ADMIN = async (e) => {
		try {
			e.preventDefault();
			
			const formEl = e.target;	
			const form = new FormData(formEl);

			const data = {
				name: form.get('name'),
				email: form.get('email'),
				phone: form.get('phone'),
				message: form.get('message'),
			}

			const res = await contactAdmin(data);
			if (!res.ok) return displayModal('Error', res.data.message);
			if (!res.data.success) return displayModal('Error', res.data.message);
			displayModal('Success', res.data.message);
			
			formEl.reset();
		} catch (err) {
			console.error(err);
		}
	}

	useEffect(() => {
		CONTACT_ADMIN();
	}, []);
	
	return (
		<>
        <SetBanner />
        <section className="inner  contact-us page py-5">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-10">
						<div className="card">
							<div className="card-left">
								<h2 className="sub-paget-itle">Contact Us</h2>
								<Form onSubmit={CONTACT_ADMIN}>
									<Form.Group className="mb-3" controlId="formBasicName">
										<Form.Control type="text" placeholder="Your Name" name='name' />
									</Form.Group>
									<Form.Group className="mb-3" controlId="formBasicEmail">
										<Form.Control type="email" placeholder="Email" name='email' />
									</Form.Group>
									<Form.Group className="mb-3" controlId="formBasicPhone">
										{/* <Form.Control type="tel" placeholder="Phone" name='phone' /> */}
										<PhoneInput
                                        name={'phone_number'}
                                        enableSearch={true}
										className="signup-phone"
										inputStyle={{width:"100%",height:"50px",border:"1px solid #cccccc",borderRadius:"7px"}}
                                        country={'us'}
                                            inputProps={{
                                                name: 'phone',
                                                
                                            }}
                                            
                                            />
									</Form.Group>
									<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
										<Form.Control as="textarea" rows={3} placeholder="Message" name='message' />
									</Form.Group>
									<Button variant="primary" type="submit">
										Submit
									</Button>
									</Form>
							</div>
							<div className="card-right">
								<h2 className="sub-paget-itle">Contact Information</h2>
								<div className="relist">
									<div className="relist-item">
										<div className="relist-itemleft">
											<img src={address} />
										</div>
										<div className="relist-itemright" style={{ width: 300 }}>
											{/* P.O.Box: 42438 Hamriyah Free< br />
											Zone, Sharjah,< br />
											United Arab Emirates */}
											{contactInfo.address}
										</div>
									</div>
									<div className="relist-item">
										<div className="relist-itemleft">
											<img src={email} />
										</div>
										<div className="relist-itemright">
											<a href={`mailto:${contactInfo.contactEmail}`}>{contactInfo.contactEmail}</a>
										</div>
									</div>
									<div className="relist-item">
										<div className="relist-itemleft">
											<img src={phone} />
										</div>
										<div className="relist-itemright">
										<a href={`tel:${contactInfo.contact}`}>{contactInfo.contact}</a>
										<a href={`tel:${contactInfo.contact2}`}>{contactInfo.contact2}</a>
										</div>
									</div>
								</div>
							</div>
						</div>
                    </div>
				</div>
			</div>
		</section>
		</>
	);
};

export default ContactUs;
