
import React, { useState } from 'react'
import { Rating } from 'react-simple-star-rating'
import { Button, Form, Image, Modal, Col, Row, FloatingLabel  } from 'react-bootstrap';
import {giveRating} from '../../../api/profile'
    const RetingModal = ({ show = true, setShowRetingModal,jobid,displayModal,GET_PROFILE,userProfile }) => {
        const [rating, setRating] = useState(0)


        const handleStarRating = (rate) => {
            setRating(rate)
          }
        // Catch Rating value
        const handleRating = async e => {
            e.preventDefault();
            const form = new FormData(e.target);
			const data = {
				job_id: form.get('jobid'),
				rate: rating,
				desc: form.get('desc')
			}
            const res = await giveRating(data);
            GET_PROFILE(userProfile)
            // GET_APPROVED_PROFILE(jobid);
            displayModal('Success', res.data.msg)
            // console.log(res);
            if(res.ok){
                setShowRetingModal(false)
            }
        }
        const handleClose = ()=>{
            setShowRetingModal(false)
        }
      
     
        return(
            <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton >
                        <Modal.Title>Give Your Feedback</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='text-center p-5'>
                        <Form onSubmit={e=>handleRating(e)}>
                            <Row>
                                <input type='hidden' name={"jobid"} value={jobid} />
                                <Col lg={12} className="mb-4">
                                    <Rating onClick={handleStarRating} initialValue={rating}  />
                                </Col>
                                <Col lg={12}  className="mb-3">
                                <FloatingLabel controlId="floatingTextarea2" label="Enter Your Feedback" >
                                    <Form.Control
                                    as="textarea"
                                    placeholder="Leave a comment here"
                                    style={{ height: '100px' }}
                                    name={"desc"}
                                    />
                                </FloatingLabel>
                                </Col>
                                <Col lg={12} className="text-end">
                                    <button className='btn-green btn'>Submit</button>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                </Modal>
            )
    }
   
        
        


export default RetingModal;