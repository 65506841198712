import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Form, Image, Nav, Tab, Dropdown, DropdownButton, Card, Modal, Tabs } from 'react-bootstrap';
import closeicon from '../../../assets/images/close-icon.svg';
import fpic1 from '../../../assets/images/pp-pic.png';
import fpic2 from '../../../assets/images/pp-pic2.png';
import sports from '../../../assets/images/sport.svg';
import automotive from '../../../assets/images/automotive.svg';
import Petcare from '../../../assets/images/petcare.svg';
import Message from '../message';
import noImage from '../../../assets/images/noImage.png';
import ViewFriendModal from './Modal';
import UserList from './UserList';
import { getAllUsers, addFriend, getFriendRequests, acceptFriendRequests, getFriends, rejectFriendRequests } from '../../../api/friend';

const Friends = ({ user, displayModal, socket }) => {
    const [show, setShow] = useState(false);
    const [modalData, setModalData] = useState({});
    const [users, setUsers] = useState([]);
    const [initialUsers, setInitialUsers] = useState([]);
    const [friendRequests, setFriendRequests] = useState([]);
    const [newFriendRequests, setNewFriendRequests] = useState([]);
    const [initialFriends, setInitialFriends] = useState([]);
    const [friends, setFriends] = useState([]);
    const [isFetchUsers, setFetchUsers] = useState(false);
    const [isFetchRequests, setFetchRequests] = useState(false);
    const [isFetchFriends, setFetchFriends] = useState(false);

    const handleClose = () => {
        setModalData({});
        setShow(false);
    }

    const handleShow = () => setShow(true);

    const GET_FRIENDS = async () => {
        try {
            const res = await getFriends();
            if (!res.ok) return;
            let list = res.data.data;
            console.log('friends', list)
            console.log(newFriendRequests)
            console.log(friendRequests)
            setFriends(list);
            setInitialFriends(list);
            setFetchFriends(false);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        GET_FRIENDS();
    }, [isFetchFriends]);

    const GET_ALL_USERS = async () => {
        try {
            const res = await getAllUsers();
            if (!res.ok) return;
            setUsers(res.data.data);
            setInitialUsers(res.data.data);
            setFetchUsers(false);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        GET_ALL_USERS();
    }, [isFetchUsers]);

    const GET_FRIEND_REQUESTS = async () => {
        try {
            const res = await getFriendRequests();
            if (!res.ok) return;
            let list = res.data.data;
            list = list.map(item => ({ ...item.sender, requestId: item._id }));
            setNewFriendRequests(list);
            setFriendRequests(list);
            setFetchRequests(false);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        GET_FRIEND_REQUESTS();
    }, [isFetchRequests]);

    // const [isFetch, setFetch] = useState(false);

    // const FRIEND_APPROVAL = async ( id, status) => {
	// 	try {
            
	// 		const res = await addFriend(id, status);
	// 		if (!res) return displayModal('Error', res.data.message);
	// 		displayModal('Success', res.data.message.msg);
    //         setShow(false);
    //         setFetch(true);
    //         for (let index = 0; index < successInviteFullData.length; index++) {
    //             if(successInviteFullData[index]._id===undefined){
    //                 continue;
    //             }
    //         socket.emit('notification',{
    //             title:'New Job Invitation!',
    //                 body:`You have 1 new job invitation "${form.get('title')}" from ${successInviteFullData[index].firstname} ${successInviteFullData[index].lastname} .`,
    //                 receiver:successInviteFullData[index]._id,
    //         })
    //     }
    //     } catch (err) {
	// 		console.error(err);
	// 	}
	// };

    const ADD_FRIEND = async (id) => {
        try {
            const res = await addFriend(id);
            if (!res.ok) return displayModal('Error', res.data.message);
            setFetchUsers(true);
         
            socket.emit('notification',{
                title:'New Friend Request!',
                body:`You have 1 new friend request"`,
                receiver:id,
            })
            return displayModal('Success', res.data.message)
            // socket.emit('notification',{
            //     ...res.data.message.tempNotificationModelSocket,
            //     receiver:employee_id._id,
            // })
         ;
        } catch (err) {
            console.error(err);
        }
    };

    const ACCEPT_REQUEST = async (id) => {
        try {
            const res = await acceptFriendRequests(id);
            if (!res.ok) return displayModal('Error', res.data.message);
            setFetchRequests(true);
            setFetchFriends(true);
            return displayModal('Success', res.data.message);
        } catch (err) {
            console.error(err);
        }
    };

    const REJECT_REQUEST = async (id) => {
        try {
            const res = await rejectFriendRequests(id);
            if (!res.ok) return displayModal('Error', res.data.message);
            setFetchRequests(true);
            setFetchFriends(true);
            setFetchUsers(true);
            return displayModal('Success', res.data.message);
        } catch (err) {
            console.error(err);
        }
    };

    const viewUser = (data) => {
        setModalData(data);
        setShow(true);
    }

    const handleSearch = (e, type) => {
        // alert(type)
        const input = e.target.value;
        const pattern = new RegExp(input, 'ig');

        switch (type) {
            case 'friends': {
                if (!input) return setFriends(initialFriends);
                let users = initialFriends;
                users = users.filter(user => {
                    if (pattern.test(`${user.firstname} ${user.lastname} ${user.email} ${user.phone_number}`)) return user; 
                })
                setFriends(users);
                break;
            }
            case 'addFriend': {
                if (!input) return setUsers(initialUsers);
                let users = initialUsers;
                users = users.filter(user => {
                    if (pattern.test(`${user.firstname} ${user.lastname} ${user.email} ${user.phone_number}`)) return user;
                })
                setUsers(users);
                break;
            }
            case 'friendRequest': {
                if (!input) return setFriendRequests(newFriendRequests);
                let users = newFriendRequests;
                users = users.filter(user => {
                    if (pattern.test(`${user.firstname} ${user.lastname} ${user.email} ${user.phone_number}`)) return user;
                })
                setFriendRequests(users);
                break;
            }
        }
    };

    return (
        <>
            <ViewFriendModal
                show={show}
                user={modalData}
                handleClose={handleClose}
            />
            <section className="userinner">
                <Row>
                    <Col md={12}>
                        <h3 className='user-listingpage-title'>
                            Hello, {user.firstname}<br /><small>Current page - Friends</small>
                        </h3>
                        <hr />
                    </Col>
                </Row>
                <Row className='mt-3 friend-tab-details'>
                    <Col md={12}>
                        <Tabs
                            defaultActiveKey="friends"
                            id="friends-tab-example"
                            className="prr-in-tab "
                            style={{ width: '70%', justifyContent: 'space-between', marginBottom: 20 }}
                        >
                            <Tab eventKey="friends" title="Friends">
                                <UserList
                                    type={'friends'}
                                    users={friends}
                                    viewUser={viewUser}
                                    handleSearch={handleSearch}
                                    REJECT_REQUEST={REJECT_REQUEST}
                                />
                            </Tab>
                            <Tab eventKey="add-friends" title="Suggested Friends">
                                <UserList
                                    type={'addFriend'}
                                    users={users}
                                    viewUser={viewUser}
                                    handleSearch={handleSearch}
                                    ADD_FRIEND={ADD_FRIEND}
                                    socket={socket}
                                />
                            </Tab>
                            <Tab eventKey="friend-request" title="Friend Requests">
                                <UserList
                                    type={'friendRequest'}
                                    users={friendRequests}
                                    viewUser={viewUser}
                                    handleSearch={handleSearch}
                                    ACCEPT_REQUEST={ACCEPT_REQUEST}
                                    REJECT_REQUEST={REJECT_REQUEST}
                                />
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </section>
        </>
    );
};

export default Friends;