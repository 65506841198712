import React, { useState,useEffect } from "react";
import { API_KEY } from "../config/google";
import {getReceivedJobOffers,getAppliedApplicationList,rejectJobOfferList,getAllJobsByUserId,getMyJobs} from '../api/job';
import {
	notificationcount
  } from "../api/notification";
// import {  getAllUsers } from '../api/friend';
export const Context = React.createContext();
export const ContextProvider = ({ children }) => {
	const fetchLocation = (props) => {
		navigator.geolocation.getCurrentPosition(
			function (position) {
				setCoords({
					latitude: position.coords.latitude,
					longitude: position.coords.longitude
				})
				const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${API_KEY}`
				fetch(url)
					.then(res => res.json())
					.then(data => setMyAddress(data.plus_code.compound_code.slice(9)))
					.catch(err => console.log(err))
			},
			function (error) {
				console.error(error);
			}
		);
		// if(navigator.geolocation){  
		//     console.log(navigator.geolocation.getCurrentPosition(showPosition),">>>>>>>")    
		// }  
		// else  
		// {  
		//     alert("Sorry! your browser is not supporting")  
		// }  
		//    console.log(this.coords,">>>>>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<")

	}

	useEffect(() => {
		fetchLocation();
	}, [])
	

	const [isLoading, setIsLoading] = useState(false);
	const [distance,setDistance] = useState(15);
	const [coords,setCoords] = useState({});
	const [myAddress, setMyAddress] = useState("")
    const [jobList,setJobList] = useState([]);
	const [newJobApplicationList,setNewJobApplicationList] = useState([]);
	const [isFetch, setIsFetch] = useState(false);
	const [createJob, setCreateJob] = useState([]);
    const [workedJob, setWorkedJob] = useState([]);
	const [totalNotification,setTotalNotification] = useState(0);
	// const GET_APPLICATION_LIST = async () => {
    //     try {
    //         const res = await getReceivedJobOffers();
            
    //         if (!res.ok) return;
    //         setJobList(res.data.data);
    //     } catch (err) {
    //         console.error(err);
    //     }
    // };
	const GET_APPLICATION_LIST = async () => {
        try {
            const res = await getReceivedJobOffers();
			 console.log(res.data.dataList, "New  .....");
            if (!res.ok) return;
            setJobList(res.data.dataList);
        } catch (err) {
            console.error(err);
        }
    };
	const NEW_GET_APPLICATION_LIST = async () => {
         try {
			const res = await getAppliedApplicationList();
			if(!res.ok) return;
			setNewJobApplicationList(res.data.data);
		 } catch (error) {
			console.log(error);
		 }
	};

	const getMyCreatedJob = async () => {
        setIsLoading(true);
        let res = await getAllJobsByUserId();

     if(res.ok){
        setIsLoading(false);
        setCreateJob(res.data.data);
        
     }
    }

	const getMyWorkedJob = async () => {
        setIsLoading(true);
        let res = await getMyJobs();
            
        if(res.ok){
            setIsLoading(false);
            setWorkedJob(res.data.data);
        }
        
    }

	const getAllNotificationsCount = async ()=>{
		try {
		  const res = await notificationcount();
		  setTotalNotification(res.data.data);
		} catch (error) {
		  console.log(error)
		}
	  }
 

	return (
		<Context.Provider value={{ isLoading, setIsLoading,distance,setDistance,coords,setCoords,myAddress,setMyAddress,jobList,GET_APPLICATION_LIST,newJobApplicationList,setNewJobApplicationList,NEW_GET_APPLICATION_LIST,setJobList ,rejectJobOfferList,setIsFetch,isFetch,createJob,setCreateJob,getMyCreatedJob,workedJob,setWorkedJob,getMyWorkedJob,totalNotification,setTotalNotification,getAllNotificationsCount}}>
			{children}
		</Context.Provider>
	);
};
