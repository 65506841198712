import { Button, Row, Col, Form, Image, Nav, Tabs, Tab, Card } from 'react-bootstrap';
import JobCreated from './jobcreated';
import JobWorked from './jobworked';
import {getAllJobsByUserId} from '../../../api/job'


const Profile = ({ user,socket,displayModal }) => {

    
    return (
        <>
            <section className="userinner">
                <Row>
                    <Col md={12}>
                        <h3 className='user-listingpage-title'>
                            Hello, {user.username}<br /><small>Current page - Profile</small>
                        </h3>
                        <hr />
                    </Col>
                </Row>
                
                <Row className='mt-3'>
                    <Col md={12}>
                        <Tabs
                            defaultActiveKey="job-worked"
                            id="uncontrolled-tab-example"
                            className="mb-3 prr-in-tab"
                        >
                            <Tab eventKey="job-created" title="Jobs Created">
                                <JobCreated userId={user._id} socket={socket} displayModal={displayModal} />
                            </Tab>
                            <Tab eventKey="job-worked" title="Jobs Worked">
                                <JobWorked userId={user._id} socket={socket} />
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </section>
        </>
    );
};

export default Profile;