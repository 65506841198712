import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import { Col, Row, Container, Button, Image, Card } from "react-bootstrap";
import './common.css';
import {recentJobList} from '../api/job'
import { useNavigate, NavLink } from "react-router-dom";
// import ads1 from '../assets/images/ads1.jpg';
// import ads2 from '../assets/images/ads2.jpg';
// import ads3 from '../assets/images/ads3.jpg';
// import ads4 from '../assets/images/ads4.jpg';
// import video from '../assets/video/mountains.mp4'

const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    margin: '15px',
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    slidesToShow: 4,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const filterMyJobs = (item)=>{
    return JSON.parse(localStorage.getItem('uverlist-user-data')).occupation.some(ele=>{
        return ele.category===item.category._id;
    })
}

const JobsList = () => {
    const navigate = useNavigate()
    const [addList,setAddList] = useState([]);
    const GET_ADD = async ()=>{
        try {
            const resAddData = await recentJobList();
            // console.log(JSON.parse(localStorage.getItem('uverlist-user-data')).occupation,"::::::::::::::::::::::");
            // console.log(resAddData.data.data,"::::::::::::::::::::::");
            // console.log(resAddData.data.data.filter(filterMyJobs),"{{{{{{{{}}}}}}}}}}}}}}");
            setAddList(resAddData.data.data.filter(filterMyJobs));
            // console.log(resAddData,"JJJJJJJJJJJJJJJJJJJJJJ")
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(()=>{
        GET_ADD()
    },[])
  return (
    <>
    {
        JSON.parse(localStorage.getItem('uverlist-user-data'))
        &&
        <section className="py-5">
        <Container>
            <Row>
                <Col lg={12} className="mb-2">
                    <h1>
                        Our Recommend Jobs 
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Slider {...settings}>
                        {
                            addList.length>0
                          
                            &&
                            addList.map((item,index)=>{
                                // if(item._id===JSON.parse(localStorage.getItem('uverlist-user-data'))._id){
                                //    return <></>
                                // }
                               return (
                                <div className="additem pointer" key={index}>
                                <div className="add-item-cont">
                                    <div className="p-pic">
                                    <Image src={item?.profileImage} alt="" />
                                    </div>
                                    <div className="p-cont">
                                        <h3>{item?.title}</h3>
                                        <p>{item?.description}</p>
                                        <a className="btn btn-outline-green px-4" onClick={e=>navigate(`/JobDetails/${item?._id}`)}>
                                            Learn More  {item?._id.toString()===JSON.parse(localStorage.getItem('uverlist-user-data'))._id.toString()}
                                        </a>
                                    </div>
                                </div>
                                </div>
                               ) 
                            })
                        }
                          {4 - addList.length>0 &&
                                Array.from(
                                  { length: 4 - addList.length },
                                  () => <div></div>
                                )}
                        {/* <div className="additem pointer">
                            <div className="add-item-cont">
                                <div className="p-pic">
                                    <Image src={ads1} alt="" />
                                </div>
                                <div className="p-cont">
                                    <h3>Basketball </h3>
                                    <p>Players advance the ball by bouncing it while walking or running (dribbling) or by passing</p>
                                    <button className="btn btn-outline-green px-4">
                                        Learn More
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="additem pointer">
                            <div className="add-item-cont">
                                <div className="p-pic">
                                <video
                                            src={video}
                                            autoPlay={false}
                                            controls={true}
                                            className='w-100'
                                            height={215}
                                        />
                                </div>
                                <div className="p-cont">
                                    <h3>Basketball </h3>
                                    <p>Players advance the ball by bouncing it while walking or running (dribbling) or by passing</p>
                                    <button className="btn btn-outline-green px-4">
                                        Watch More
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="additem pointer">
                            <div className="add-item-cont">
                                <div className="p-pic">
                                    <Image src={ads3} alt="" />
                                </div>
                                <div className="p-cont">
                                    <h3>Basketball </h3>
                                    <p>Players advance the ball by bouncing it while walking or running (dribbling) or by passing</p>
                                    <button className="btn btn-outline-green px-4">
                                        Shop Now
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="additem pointer">
                            <div className="add-item-cont">
                                <div className="p-pic">
                                    <Image src={ads4} alt="" />
                                </div>
                                <div className="p-cont">
                                    <h3>Basketball </h3>
                                    <p>Players advance the ball by bouncing it while walking or running (dribbling) or by passing</p>
                                    <button className="btn btn-outline-green px-4">
                                        Shop Now
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="additem pointer">
                            <div className="add-item-cont">
                                <div className="p-pic">
                                    <Image src={ads2} alt="" />
                                </div>
                                <div className="p-cont">
                                    <h3>Basketball </h3>
                                    <p>Players advance the ball by bouncing it while walking or running (dribbling) or by passing</p>
                                    <button className="btn btn-outline-green px-4">
                                        Shop Now
                                    </button>
                                </div>
                            </div>
                        </div> */}
                    </Slider>
                </Col>
            </Row>
        </Container>
    </section>
    }
   
   </>
  )
}

export default JobsList