import React from 'react';
import { HashRouter,BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ContextProvider } from './components/Context'
// import { hydrate, render } from "react-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <HashRouter>
      <ContextProvider>
      <App />
      </ContextProvider>
    </HashRouter>
   </React.StrictMode>
);


// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(
//     <React.StrictMode>
//       <BrowserRouter>
//         <ContextProvider>
//           <App />
//         </ContextProvider>
//       </BrowserRouter>
//     </React.StrictMode>,
//     rootElement
//   );
// } else {
//   render(
//     <React.StrictMode>
//       <BrowserRouter>
//         <ContextProvider>
//           <App />
//         </ContextProvider>
//       </BrowserRouter>
//     </React.StrictMode>,
//     rootElement);
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
