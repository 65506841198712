import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import { Col, Row, Container, Button, Image, Card } from "react-bootstrap";
import './common.css';
import {getAddList} from '../api/ads'
import ads1 from '../assets/images/ads1.jpg';
import ads2 from '../assets/images/ads2.jpg';
import ads3 from '../assets/images/ads3.jpg';
import ads4 from '../assets/images/ads4.jpg';
import video from '../assets/video/mountains.mp4'

const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    margin: '15px',
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    slidesToShow: 4,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};
const AdsItem = () => {
    const [addList,setAddList] = useState([]);
    const GET_ADD = async ()=>{
        try {
            const resAddData = await getAddList();
            setAddList(resAddData.data.data);
            // console.log(resAddData,"JJJJJJJJJJJJJJJJJJJJJJ")
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(()=>{
        GET_ADD()
    },[])
    return(
        <>
            <section className="py-5">
                <Container>
                    <Row>
                        <Col lg={12} className="mb-2">
                            <h1>
                                Our Recommend Ads
                            </h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Slider {...settings}>
                                {
                                    addList.length>0
                                  
                                    &&
                                    addList.map((item,index)=>{
                                       return (
                                        <div className="additem pointer" key={index}>
                                        <div className="add-item-cont">
                                            <div className="p-pic">
                                                {item?.file_type==='image'?<Image src={item?.profileimage} alt="" />:<video src={item?.profileimage} style={{width:'100%', height:'174px'}} controls autoplay={true}>Your browser does not support the video tag.</video>}
                                            </div>
                                            <div className="p-cont">
                                                <h3>{item?.title}</h3>
                                                <p>{item?.description}</p>
                                                <a className="btn btn-outline-green px-4" href={item?.website_link} target='_blank'>
                                                    Learn More
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                       ) 
                                    })
                                }
                                  {4 - addList.length>0 &&
                                        Array.from(
                                          { length: 4 - addList.length },
                                          () => <div></div>
                                        )}
                                {/* <div className="additem pointer">
                                    <div className="add-item-cont">
                                        <div className="p-pic">
                                            <Image src={ads1} alt="" />
                                        </div>
                                        <div className="p-cont">
                                            <h3>Basketball </h3>
                                            <p>Players advance the ball by bouncing it while walking or running (dribbling) or by passing</p>
                                            <button className="btn btn-outline-green px-4">
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="additem pointer">
                                    <div className="add-item-cont">
                                        <div className="p-pic">
                                        <video
                                                    src={video}
                                                    autoPlay={false}
                                                    controls={true}
                                                    className='w-100'
                                                    height={215}
                                                />
                                        </div>
                                        <div className="p-cont">
                                            <h3>Basketball </h3>
                                            <p>Players advance the ball by bouncing it while walking or running (dribbling) or by passing</p>
                                            <button className="btn btn-outline-green px-4">
                                                Watch More
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="additem pointer">
                                    <div className="add-item-cont">
                                        <div className="p-pic">
                                            <Image src={ads3} alt="" />
                                        </div>
                                        <div className="p-cont">
                                            <h3>Basketball </h3>
                                            <p>Players advance the ball by bouncing it while walking or running (dribbling) or by passing</p>
                                            <button className="btn btn-outline-green px-4">
                                                Shop Now
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="additem pointer">
                                    <div className="add-item-cont">
                                        <div className="p-pic">
                                            <Image src={ads4} alt="" />
                                        </div>
                                        <div className="p-cont">
                                            <h3>Basketball </h3>
                                            <p>Players advance the ball by bouncing it while walking or running (dribbling) or by passing</p>
                                            <button className="btn btn-outline-green px-4">
                                                Shop Now
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="additem pointer">
                                    <div className="add-item-cont">
                                        <div className="p-pic">
                                            <Image src={ads2} alt="" />
                                        </div>
                                        <div className="p-cont">
                                            <h3>Basketball </h3>
                                            <p>Players advance the ball by bouncing it while walking or running (dribbling) or by passing</p>
                                            <button className="btn btn-outline-green px-4">
                                                Shop Now
                                            </button>
                                        </div>
                                    </div>
                                </div> */}
                            </Slider>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
export default AdsItem