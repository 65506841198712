import { formatDate, format_time_12, getQueryString } from "../../utils/helper";

const getSalary = (price) => {
    if (!price) return '';
    if (price.type == 'fixed') return `$${price.fixed}`;
    return `$${price.min} - $${price.max}`
}

const getCreatedTime = (job) => {
    const date = new Date(job.createdAt);
    const createdTime = `${date.getHours()}:${date.getMinutes()}`;
    return format_time_12(createdTime);
}

const getCreatedDate = (job) => {
    const date = new Date(job.createdAt);
    return formatDate(date, 'DATE_STRING')
}

const getCategoryName = (cat) => {
    if (!cat) return null;
    return cat.name;
};

const getSubCategoryName = (subcat) => {
    if (!subcat) return null;
    return subcat.name;
};

const formatJobs = (jobs) => {
    
    return jobs.map(job => (
        {
            _id: job._id,
            employer_id: job.employer_id,
            employer_name: `${job.employer_id ? job.employer_id.firstname : ''} ${job.employer_id ? job.employer_id.lastname : ''}`,
            profileImage: job?.profileImage,
            gallery: job.gallery,
            video: job.video,
            title: job.title,
            description: job.description,
            salary: getSalary(job.price),
            start_time: format_time_12(job.start_time),
            end_time: format_time_12(job.end_time),
            job_type: job.job_type,
            address: `${job.address}, ${job.state}, ${job.city} ${job.zipcode}`,
            createdDate: getCreatedDate(job),
            createdTime: getCreatedTime(job),
            isJobMoreThanOneDay: job.isJobMoreThanOneDay,
            location: job.location,
            category: job.category,
            subcategory: job.subcategory,
            categoryName: getCategoryName(job.category),
            subcategoryName: getSubCategoryName(job.subcategory),
        }
    ))
}

const processJobResponse = (res, setCurrentPageLimit, setTotalPages, setJobs) => {
    let { docs, total, limit, page, pages } = res.data.data;
    console.log('docs', docs);
    const jobs = formatJobs(docs);
    setCurrentPageLimit(docs.length);
    setTotalPages(pages);
    setJobs(jobs);
}

// Filter - Include Throttling to Reduce The API Call
// Throttle Condition for Input Fields (search by title, location)
// Instead of sending API call for each letter of inputs,
// once the user types in a letter, timer starts
// if the user hasn't types any letter for 2 sec
// the API call is sent
const THROTTLE_LIMIT = 1;
const THROTTLE_STEPS = 0.5;
const THROTTLE_INTERVAL_MS = 150;

const run_timer = (setTimer) => {
    setTimer(t => t + THROTTLE_STEPS);
}

const stop_timer = (timerId, setTimer) => {
    if (timerId) {
        clearInterval(timerId);
        setTimer(0);
    }
}

const start_timer = (setTimer, setTimerId) => {
    const id = setInterval(() => run_timer(setTimer), THROTTLE_INTERVAL_MS);
    setTimerId(id);
}

const throttle = (timerId, setTimerId, setTimer) => {
    stop_timer(timerId, setTimer);
    start_timer(setTimer, setTimerId);
}

const checkThrottle = (timer, setTimer, timerId, setIsFetchJobs) => {
    if (timer <= THROTTLE_LIMIT) return;
    stop_timer(timerId, setTimer);
    setIsFetchJobs(true);
}

export {
    formatJobs,
    processJobResponse,
    throttle,
    checkThrottle,
}