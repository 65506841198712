import React, { useState, useEffect } from "react";
import { Col, Row, Container, Button, Image, Card } from "react-bootstrap";
import ads1 from "../../assets/images/ads1.jpg";
import ads2 from "../../assets/images/ads2.jpg";
import ads3 from "../../assets/images/ads3.jpg";
import ads4 from "../../assets/images/ads4.jpg";
import video from "../../assets/video/mountains.mp4";
import { useNavigate, NavLink } from "react-router-dom";
import "./Manageads.css";
import AdsItem from "../../components/AdsItem";
import { getMyAdd } from "../../api/ads";

const CreateManageAds = () => {
  const [myAddList, setMyAddList] = useState([]);
  const navigate = useNavigate();
  const viewCreatedJob = (e) => {
    e.preventDefault();
    navigate(`/Adsdetails`);
  };

  const GET_MY_ADD = async () => {
    try {
      const res = await getMyAdd();
      // console.log(res, "LLLLLLLLL");
      setMyAddList(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GET_MY_ADD();
  }, []);

  return (
    <>
      <section class="aflotop"></section>
      <section className="inner  contact-us page py-5">
        <Container>
          <Row>
            <Col lg={12} className="mb-5">
              <Card>
                <div>
                  <h3>Creating new ads</h3>
                  <p>
                    Creating new ads is an essential part of any advertising
                    campaign. Whether you're looking to promote a new product,
                    service, or event, crafting an effective ad is crucial to
                    reaching your target audience and achieving your goals.
                  </p>
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={3} md={4} className="mb-3">
              <NavLink to="/CreatenewAds" className="create-add">
                <i class="bi bi-plus-circle-dotted"></i>
                <h3>Create New Ads</h3>
              </NavLink>
            </Col>
            {myAddList.length > 0 &&
              myAddList.map((item, index) => {
                return (
                  
                  <Col lg={3} md={4} className="mb-3" key={index}>
                    <NavLink to={`/Adsdetails/${item?._id}`} className="ads-list">
                        {
                            item?.file_type ==='image'?<Image alt="..." src={item?.profileimage} />:<video src={item?.profileimage} controls autoplay={true} value={ item?.file_type}>
                            Your browser does not support the video tag.
                          </video>
                        }
                  

                      
                      <div className="type">
                        <i class={item?.ad_type==='Video'?"bi bi-camera-video":item?.ad_type==='Information'?"bi bi-journal-bookmark-fill":"bi bi-shop-window"}></i>
                      </div>
                      <div
                        className={
                          item?.isActive
                            ? "stutas alert-success"
                            : "stutas alert-danger"
                        }
                      >
                        {item?.isActive ? "Active" : "Not-Active"}
                      </div>
                      <div className="ass-cont">
                        <h3>{item?.title}</h3>
                        <p>{item?.description}</p>
                      </div>
                    </NavLink>
                  </Col>
                );
              })}

            {/* <Col lg={3} md={4} className="mb-3">
              <NavLink to="/Adsdetails" className="ads-list">
                <Image alt="" src={ads2} />
                <div className="type">
                  <i class="bi bi-shop-window"></i>
                </div>
                <div className="stutas alert-success">Active</div>
                <div className="ass-cont">
                  <h3>Basketball</h3>
                  <p>
                    Players advance the ball by bouncing it while walking or
                    running (dribbling) or by passing
                  </p>
                </div>
              </NavLink>
            </Col>
            <Col lg={3} md={4} className="mb-3">
              <NavLink to="/Adsdetails" className="ads-list">
                <Image alt="" src={ads3} />
                <div className="type">
                  <i class="bi bi-shop-window"></i>
                </div>
                <div className="stutas alert-success">Active</div>
                <div className="ass-cont">
                  <h3>White t-shirt</h3>
                  <p>
                    Players advance the ball by bouncing it while walking or
                    running (dribbling) or by passing
                  </p>
                </div>
              </NavLink>
            </Col>
            <Col lg={3} md={4} className="mb-3">
              <NavLink to="/Adsdetails" className="ads-list">
                <video
                  src={video}
                  autoPlay={false}
                  className="w-100"
                  height={215}
                />
                <div className="type">
                  <i class="bi bi-camera-video"></i>
                </div>
                <div className="stutas alert-success">Active</div>
                <div className="ass-cont">
                  <h3>Football AFC Cup</h3>
                  <p>
                    Players advance the ball by bouncing it while walking or
                    running (dribbling) or by passing
                  </p>
                </div>
              </NavLink>
            </Col>

            <Col lg={3} md={4} className="mb-3">
              <NavLink to="/" className="ads-list">
                <Image alt="" src={ads1} />
                <div className="type">
                  <i class="bi bi-journal-bookmark-fill"></i>
                </div>
                <div className="stutas alert-danger">Not-Active</div>
                <div className="ass-cont">
                  <h3>Best Notebook</h3>
                  <p>
                    Players advance the ball by bouncing it while walking or
                    running (dribbling) or by passing
                  </p>
                </div>
              </NavLink>
            </Col> */}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default CreateManageAds;
