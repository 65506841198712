import { api, endPoint } from './client';

const updateProfile = (data) => api.put(`${endPoint.users}/update-profile-web`, data)
const getProfile = (id) => api.get(`${endPoint.users}/get-other-profile/${id}`)
const getEarnings = ()=>api.get(`${endPoint.users}/earnings`)
const giveRating = (data)=>api.post(`${endPoint.users}/give-rating`,data)
const getCompliteJob = (startDate,endDate)=>api.get(`${endPoint.users}/getCompliteJob?startDate=${startDate}&endDate=${endDate}`)
const getDashboardCard = ()=>api.get(`${endPoint.users}/getDashboardCard`)




export {
    updateProfile,
    getProfile,
    getEarnings,
    giveRating,
    getCompliteJob,
    getDashboardCard
}