import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate,useLocation } from 'react-router-dom';
import Slider from "react-slick";
import about from '../../assets/images/about-right.jpg';
import jobbanner from '../../assets/images/job-banner.png';
import chat from '../../assets/images/chat.png';
import arrow from '../../assets/images/arrow-view.png';
import call from '../../assets/images/phone-cal.svg';
import email from '../../assets/images/email.svg';
import user from '../../assets/images/user.png';
import cattype from '../../assets/images/type.png';
import star from '../../assets/images/star.png';
import { Button, Row, Col, Form, Image, Nav, Tab, Card, Table } from 'react-bootstrap';
import { useGeolocated } from "react-geolocated";
import JobFilter from "./JobFilter";
import CatJobList from "./CatJobList";
import { GET_CATEGORY_LIST, handleCategoryChange } from "../user/service";
import { GET_JOB_LIST } from './service';
import { checkThrottle, throttle } from "./helper";
import ApplyJobModal from "../../components/ApplyJobModal";
import { applyJob } from '../../api/job';
import { getExpertList } from "../../api/expertise";
import { getExpectedCost, formatJobs } from "../../utils/helper";
import view from '../../assets/images/entypo.svg';
import { recentJobList, getCategoryList } from "../../api/job";
import AdsItem from "../../components/AdsItem";

const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    margin: '15px',
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    slidesToShow: 4,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const JobsList = ({ user, isLoggedIn, displayModal, setRecipientId, homeSelectedCategoryId }) => {
	const navigate = useNavigate();

	// Get Current Location
	const { coords, isGeolocationAvailable, isGeolocationEnabled } =
		useGeolocated({
			positionOptions: {
				enableHighAccuracy: false,
			},
			userDecisionTimeout: 5000,
		});

	//Pagination
	const [itemsLimit, setItemsLimit] = useState(6);
	const [totalPages, setTotalPages] = useState(0);
	const [pointer, setPointer] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [currentPageLimit, setCurrentPageLimit] = useState(0);
    const [totalJob,setTotalJob] = useState(0);
	const [expertList, setExpertList] = useState([]);
	// Job
	const [jobs, setJobs] = useState([]);

	// Job Filter
	const [searchTitle, setSearchTitle] = useState('');
	const [searchLocation, setSearchLocation] = useState('');
	const [timer, setTimer] = useState(0); //sec
	const [timerId, setTimerId] = useState(null);
	const [isFetchJobs, setIsFetchJobs] = useState(true);
	const [categoryList, setCategoryList] = useState([]);
	const [subcategoryList, setSubCategoryList] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState(homeSelectedCategoryId);
	const [selectedSubCategory, setSelectedSubCategory] = useState('');
	const [sortBy, setSortBy] = useState('');
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [price, setPrice] = useState(0);
	const [distance, setDistance] = useState(1000);
	// Job Application Modal
	const [showModal, setShowModal] = useState(false);
	const [selectedJob, setSelectedJob] = useState({});
	const [resume, setResume] = useState('');
    const {state} = useLocation();
	// useEffect(()=>{
    //    alert(state.distance,"KKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKK")
	// },[])
	const handlePageClick = ({ pageIndex, isSetPointer = true }) => {
		window.scrollTo(0, 0)
		if (isSetPointer) setPointer(pageIndex);
		setCurrentPage(pageIndex);
		setIsFetchJobs(true);
	};

	const GET_EXPERT_LIST = async () => {
        try {
            const res = await getExpertList();
            if (!res.ok) return;
            let expertList = res.data.data;

            if (user._id) {
                expertList = expertList.filter(usr => usr._id !== user._id);
            };

            setExpertList(expertList);
        } catch (err) {
            console.error(err);
        }
    };
	const getUserEmail = (email) => {
        if (!email) return;
        let idealEmail = "mohammed.thurabudeen@brainiuminfotech.com";
        if (email.length <= idealEmail.length) return email;

        let email1 = email.substring(0, idealEmail.length);
        let email2 = email.substring(idealEmail.length, email.length);
        let email3 = null;

        if (email2.length > idealEmail.length) {
            email2 = email.substring(idealEmail.length, 2 * idealEmail.length);
            email3 = email.substring(2 * idealEmail.length, email.length);
        }

        return (
            email3
                ?
                <span>
                    {email1} <br />
                    {email2} <br />
                    {email3}
                </span>
                :
                <span>
                    {email1} <br />
                    {email2}
                </span>
        )


    };

    useEffect(() => {
        GET_EXPERT_LIST();
    }, []);

	useEffect(() => {
		if (!isFetchJobs || !coords) return;
        console.log(state,">>>>>>>>>>>>>>>");
		GET_JOB_LIST({
			state: {
				userId: user._id,
				currentPage,
				itemsLimit,
				searchTitle:state.title?state.title:searchTitle,
				searchLocation,
				selectedCategory:state.category?state.category:selectedCategory,
				selectedSubCategory,
				sortBy,
				startDate,
				endDate,
				price,
				distance:state.distance,
				coords:{lat:state.lat,long:state.long}
			},
			setState: {
				setCurrentPageLimit,
				setTotalPages,
				setJobs,
				setIsFetchJobs,
				setTotalJob
			},
			func: {
				displayModal
			}
		});
	}, [user, isFetchJobs, coords, homeSelectedCategoryId])

	useEffect(() => {
		checkThrottle(timer, setTimer, timerId, setIsFetchJobs);
	}, [timer])

	const _throttle = () => throttle(timerId, setTimerId, setTimer)

	const handleFilterInput = (e) => {
		switch (e.target.name) {
			case 'title': {
				console.log('title search')
				setSearchTitle(e.target.value);
				_throttle();
				break;
			}
			case 'location': {
				console.log('location search')
				setSearchLocation(e.target.value);
				_throttle();
				break;
			}
			case 'category': {
				console.log('category search')
				handleCategoryChange(e, setSelectedCategory, setSubCategoryList)
				setSelectedSubCategory('')
				setIsFetchJobs(true);
				break;
			}
			case 'subcategory': {
				console.log('sub category search')
				setSelectedSubCategory(e.target.value);
				setIsFetchJobs(true);
				break;
			}
			case 'sortBy': {
				console.log('sort by ', e.target.value);
				setSortBy(e.target.value);
				setIsFetchJobs(true);
				break;
			}
			case 'startDate': {
				console.log('start date ', e.target.value);
				setStartDate(e.target.value);
				if (endDate) setIsFetchJobs(true);
				break;
			}
			case 'endDate': {
				console.log('end date ', e.target.value);
				setEndDate(e.target.value);
				if (startDate) setIsFetchJobs(true);
				break;
			}
			case 'price': {
				console.log('price range', e.target.value);
				setPrice(e.target.value);
				_throttle();
				break;
			}
			case 'distance': {
				const newDistance = e.target.value;
				console.log('search nearest jobs within ', newDistance);
				setDistance(newDistance);
				_throttle();
				break;
			}
		}
	}

	useEffect(() => {
		GET_CATEGORY_LIST(setCategoryList);
	}, []);
    const viewExpertDetails = (id) => {
        navigate(`/ExpertiseDetails/${id}`);
    };
	const getUserOccupation = (user) => {
        if (!user.occupation) return '';
        if (!user.occupation.length) return '';
        let categoryNames = user.occupation.map(occ => occ.category ? occ.category.name : '');
        return categoryNames.join(', ');
    };
	const handleChatNow = (e, employer) => {
		e.preventDefault();

		//1. Set local storage variable
		localStorage.setItem('isRedirectChatNow', true);
		localStorage.setItem('recipientId', employer._id);
		setRecipientId(employer._id);

		if (!localStorage.getItem('uverlist-user-token')) return navigate('/signin');
		navigate('/user');
		return;
	};

	const handleApplyJob = (e, job) => {
		e.preventDefault();
		if (!localStorage.getItem('uverlist-user-token')) return navigate('/signin');
		console.log('applying to job', job);
		setSelectedJob(job);
		setShowModal(true);
	};

	const handleResumeUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => setResume(reader.result);
        reader.readAsDataURL(file);
    };

    const APPLY_JOB = async (formEl) => {
        try {
            const form = new FormData(formEl);

            const application = {
				job_id: selectedJob._id,
				employee_id: user._id,
				shortDescription: form.get('shortDescription'),
				expectedSalary: form.get('expectedSalary'),
				expectedJoiningDate: form.get('expectedJoiningDate'),
				resume,
            };
			console.log('application', application);
            const res = await applyJob(selectedJob._id, application);
			if (!res.ok) return displayModal('Error', res.data.message);
			setShowModal(false);
			setResume('');
			displayModal('Success', res.data.message);
        } catch (e) {
            console.error(e);
        }
    };

	const viewCategoryJobs = (e, catId) => {
        e.preventDefault();
         try {
            navigate('/SearchJob',{state:{category:catId}})
        } catch (error) {
            console.log(error)
        }
    };

	const GET_CATEGORY_LIST = async () => {
        try {
            const res = await getCategoryList();
            if (!res.ok) return;
            let categories = res.data.data;
            if (!categories.length) return;
            if (categories.length > 8) categories = categories.slice(0, 8);
            setCategoryList(categories);
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        GET_CATEGORY_LIST()
    }, [])
	function handleGoBack() {
		navigate(-1);
	  }
	return (
		<>
		
			<ApplyJobModal 
				show={showModal}
				setShow={setShowModal}
				job={selectedJob}
				handleResumeUpload={handleResumeUpload}
				APPLY_JOB={APPLY_JOB}
			/>
			<section className="job-banner">
				<div className="container">
					<Row>
						<Col md={12} className="text-center">
							<h1>
								Search Jobs
							</h1>
							<p>
								Search your career opportunity through 12800 jobs
							</p>
							<button className="btn btn-green" onClick={handleGoBack}>Go back</button>
						</Col>
					</Row>
				</div>
			</section>
			
			<section className="joblispage-top py-5">
				<div className="container">
					<Row >
						{/* <JobFilter
							selectedCategory={selectedCategory}
							categoryList={categoryList}
							subcategoryList={subcategoryList}
							handleFilterInput={handleFilterInput}
							price={price}
							distance={distance}
						/> */}
						<CatJobList
							coords={coords}
							jobs={jobs}
							limit={itemsLimit}
							currentPageLimit={currentPageLimit}
							totalPages={totalPages}
							pointer={pointer}
							handlePageClick={handlePageClick}
							setPointer={setPointer}
							handleChatNow={handleChatNow}
							handleApplyJob={handleApplyJob}
							distance = {state.distance}
						/>
					</Row>
				</div>
			</section>

			            {
                expertList.length > 0
                &&
                <section className="expatis pb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="sub-paget-itle">Expertise </h2>
                            </div>
                        </div>
                        <div className="row">

                            <Slider {...settings}>
                                {
                                    expertList.map(user => (
                                        <div className="expatis-list pointer" onClick={e => viewExpertDetails(user._id)}>
                                            <div className="expatis-item">
                                                <div className="p-pic">
                                                    <Image src={user.profileimage} alt="" />
                                                </div>
                                                <div className="p-cont">
                                                    <h3>{user.firstname} {user.lastname} <br /> <small>{getUserOccupation(user)}</small></h3>
                                                    <div class="price-details">{getExpectedCost(user.expectedCost)}</div>
                                                    <p className="rating">
                                                        <i class="bi bi-star-fill"></i>
                                                        <i class="bi bi-star-fill"></i>
                                                        <i class="bi bi-star-fill"></i>
                                                        <i class="bi bi-star"></i>
                                                        <i class="bi bi-star"></i>

                                                    </p>
                                                    <p>
                                                        <Image src={call} alt="" /> <span> {user.phone_number}</span>
                                                    </p>
                                                    <p className="email-id-cust">
                                                        <Image src={email} alt="" />
                                                        {
                                                            user.email
                                                            &&
                                                            <span>
                                                                {getUserEmail(user.email)}
                                                            </span>
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                                {expertList.length < 4 &&
                                    Array.from(
                                        { length: 4 - expertList.length },
                                        () => <div></div>
                                    )}

                            </Slider>

                        </div>
                        <div className="row">
                        <div className="col-lg-12 text-end mt-3">
                        <NavLink to="/ExpertiseList" className="btn btn-outline-green px-4">
                        View All <img src={arrow} alt="" />
                                </NavLink>
                        </div>
                        </div>
                    </div>

                </section>
            }
			<AdsItem/>
		</>
	);
};

export default JobsList;
