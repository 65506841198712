import React, { useState, useEffect } from "react";
import Banner from "../../Layouts/Banner";
import about from '../../assets/images/about-right.jpg';
import SetBanner from "../../components/setbanner";
const TermsServices = ({ terms }) => {
	
	return (
		<>
        <SetBanner />
        <section className="inner page py-5">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<h2 className="sub-paget-itle">{terms.title}</h2>
						<div dangerouslySetInnerHTML={{ __html: terms.content }}></div>
					</div>
				</div>
			</div>
		</section>
		</>
	);
};

export default TermsServices;
