import React, { useState, useEffect, useContext } from 'react';
import { Button, Row, Col, Form, Image, Nav, Card, Modal } from 'react-bootstrap';
import cardedit from '../../../../assets/images/icon-edit.svg';
import addnew from '../../../../assets/images/add-new.svg';
import deleteicon from '../../../../assets/images/delete.svg';
import StripeCheckout from 'react-stripe-checkout';
import axios from 'axios';
import { PAY_KEY } from '../../../../config/payment';
import { addCard, getCardList, getCarddelete } from '../../../../api/payment';
import {Context} from '../../../../components/Context'
import ConfirmationModal from '../../../../components/ConfirmationModal';
const CreditCardInfo = ({user, displayModal, setIsLoggedIn}) => {
    const {isLoading,setIsLoading} = useContext(Context);
    const publishableKey = PAY_KEY;
    const [CardList, setCardList] = useState({});
    const [confirm,setConfirm] = useState(false);
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    function isValid_CVV_Number(CVV_Number) {
        // Regex to check valid
        // CVV_Number 
        let regex = new RegExp(/^[0-9]{3,4}$/);

        // if CVV_Number
        // is empty return false
        if (CVV_Number == null) {
            return "false";
        }

        // Return true if the CVV_Number
        // matched the ReGex
        if (regex.test(CVV_Number) == true) {
            return "true";
        }
        else {
            return "false";
        }
    }
    // const handleFailure = () => {
    //     console.log("fail");
    //  };
    const GET_Card_LIST = async () => {
        try {
            const res = await getCardList();
            setCardList(res.data)
            
        } catch (error) {
            console.log(error)
        }
    }
    const addCardinfo = async token => {
        try {
            setIsLoading(true);
            const response = await addCard({ "card_token": token.id });
            if (response.data.code === 200) {
                setIsLoading(false);
                GET_Card_LIST();
                displayModal('Success', response.data.message);
             
                
            }
        } catch (error) {
            console.log(error.AxiosError.message);
        }
    };

    const handleDelete = async (e) => {
        try {
            setIsLoading(true);
            const res = await getCarddelete();
            if (res.data.code === 200) {
                setIsLoading(false);
                GET_Card_LIST();
                setConfirm(false)
                displayModal('Success', res.data.message);
                
            }
           
        } catch (error) {
            console.error(error);
        }
    };

    const handleDeleteConfirm = e =>{
        e.preventDefault();
        setConfirm(true);
    }
    useEffect(() => {
        GET_Card_LIST()
    }, [])
    return (
        <>
        <ConfirmationModal show={confirm} setShow={setConfirm}  handleDelete={handleDelete} title="Are you sure?" body="Are you sure you want to delete this item?" />
            {/* Credit Card Information */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Credit Card Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <Form>
                        <Form.Group className="mb-3" controlId="formGroupEmail">

                            <Form.Control className='f-inp' type="text" placeholder="Full Name" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGroupPassword">
                            <Form.Control className='f-inp' type="text" placeholder="Credit Card Number" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGroupPassword">
                            <Form.Control className='f-inp' type="number" placeholder="CVV Number" onInput={e=>isValid_CVV_Number(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGroupPassword">
                            <Form.Control className='f-inp' type="month" placeholder="Ex Date" onBlur={"(Type = 'text')"} />
                        </Form.Group>
                        <Button variant="primary" className='btn-green' type="submit">
                            Submit
                        </Button>
                    </Form> */}

                </Modal.Body>
            </Modal>

            <Row>
                <Col md={12}>
                    <h4 className='user-list-subtitle'>
                        Credit Card Information<div className='add-new'>
                        {CardList?.code == 500 &&   <StripeCheckout
                                stripeKey={publishableKey}
                                name="Add Card Info"
                                panelLabel="Save Card"
                                email={user.email}
                                billingAddress={true}
                                //shippingAddress
                                currency="USD"
                                token={addCardinfo}
                                children={<span>Custom Pay Button Text</span>}
                            >
                               <Image src={addnew} />
                            </StripeCheckout>}
                        </div>
                    </h4>
                </Col>
                <Col md={12}>
                    <Row>
                        {CardList?.code != 500 && Object.keys(CardList).length >0 &&
                            <Col lg={6} md={6}>
                                <Card className='acount-info radius-12'>
                                    <Card.Body>
                                        <div className='ac-profile'>
                                            {CardList?.data?.brand}
                                        </div>
                                        <div className='ac-cont'>
                                            <h4>
                                                <span>
                                                    {CardList?.data?.name}
                                                </span>
                                                <small>
                                                    Ex. date - {CardList?.data?.exp_month} / {CardList?.data?.exp_year}
                                                </small>
                                            </h4>
                                            <p className='cnumber'>
                                                ************{CardList?.data?.last4}
                                            </p>
                                        </div>

                                        <div className='ac-edt' onClick={handleDeleteConfirm}><Image alt="" src={deleteicon} /></div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        }
                        {CardList?.code == 500 &&
                            <Col lg={12} md={12}>

                                <Card className='acount-info radius-12 p-5 text-center'>
                                    <h3>
                                        {CardList.message}
                                    </h3>
                                </Card>
                            </Col>
                        }

                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default CreditCardInfo;