import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form, Image, Card } from 'react-bootstrap';
import './created.css'
import noimage from '../../../assets/images/noImage.png';
import ep1 from '../../../assets/images/pp1.jpg';
import call from '../../../assets/images/phone-cal.svg';
import email from '../../../assets/images/email.svg';
import thum from '../../../assets/images/ads2.jpg';
import star from '../../../assets/images/star.png';
import { getJobDetails, applyJobList, getApprovedJob, jobApplicationApproval, getOfferJobList } from '../../../api/job'
import { useParams, useNavigate } from 'react-router-dom'
import moment from 'moment';
import StripeCheckout from 'react-stripe-checkout';
import axios from 'axios';
import { PAY_KEY } from "../../../config/payment";
import { getpaymentDetails } from "../../../api/payment";
import { getProfile } from "../../../api/profile";
import ProceedModal from "./modal";
import RetingModal from "../../jobs/modal/retings";
import CurrencyInput from 'react-currency-input-field';
const avgRatingCalculator = (ratings) => {
  if (ratings.length === 0) {
    return 0
  }
  const sum = ratings.reduce((total, rating) => total + rating.rate, 0);
  const avg = sum / ratings.length;
  return avg.toFixed(1);
}

const CreatedJob = ({ user, displayModal,socket }) => {
  const [showretingModal, setShowRetingModal] = useState(false);


  const navigate = useNavigate();
  function handleGoBack() {
    navigate(-1);
  }
  const [fixedPrice, setFixedPrice] = useState("");
  const [tips,setTips] = useState(0)
  const [employer, setEmployer] = useState({});
  let { jobId } = useParams();
  const [jobData, setJobData] = useState({});
  const [jobApplyList, setJobApplyList] = useState([]);
  const [jobOfferList, setJobOfferList] = useState([]);
  const [approvedProfile, setApprovedProfile] = useState({});
  const [show, setShow] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [approvedId, setApprovedId] = useState({})
  const handleClose = () => {
    setShow(false);
  }

  const format = (hrEle, minEle, hrEle1, minEle1, startDate, endDate, date) => {

    if (date !== null) {
      if (hrEle >= 0 && hrEle <= 24 && minEle >= 0 && minEle <= 60 && hrEle1 >= 0 && hrEle1 <= 24 && minEle1 >= 0 && minEle1 <= 60) {
        let AMorPM = 'AM';
        let AMorPM1 = 'AM'
        if (hrEle > 12) AMorPM = 'PM';
        if (hrEle1 > 12) AMorPM1 = 'PM';
        hrEle = (hrEle % 12);
        hrEle1 = (hrEle1 % 12);
        return `${moment(jobData?.date).format("dddd, MMMM Do YYYY")}, ${hrEle}:${minEle} ${AMorPM}  -  ${hrEle1}:${minEle1} ${AMorPM1}`;
      }
    } else {

      if (hrEle >= 0 && hrEle <= 24 && minEle >= 0 && minEle <= 60 && hrEle1 >= 0 && hrEle1 <= 24 && minEle1 >= 0 && minEle1 <= 60) {
        let AMorPM = 'AM';
        let AMorPM1 = 'AM'
        if (hrEle > 12) AMorPM = 'PM';
        if (hrEle1 > 12) AMorPM1 = 'PM';
        hrEle = (hrEle % 12);
        hrEle1 = (hrEle1 % 12);
        return `${moment(jobData?.startDate).format("dddd, MMMM Do YYYY")}  to  ${moment(jobData?.endDate).format("dddd, MMMM Do YYYY")}   ,    ${hrEle} : ${minEle} ${AMorPM}  -  ${hrEle1} : ${minEle1} ${AMorPM1}`;
      }
    }

  }
  // };

  const handelChangeTips = (e)=>{
    if(e.target.value<0){
      e.target.value=0
    }
    setTips(e.target.value)
  }

  const GET_JOB_DETAILS = async () => {
    try {
      const res = await getJobDetails(jobId);
      if (res.data.data.employeesId) {
        setUserProfile(res.data.data.employeesId)
      }
      setJobData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const GET_APPLY_JOB_LIST = async () => {
    try {
      const res = await applyJobList(jobId);
      if (res.ok) {
        setJobApplyList(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const GET_OFFER_JOB_LIST = async () => {
    try {
      const res = await getOfferJobList(jobId);
      console.log(res, "setJobOfferList");
      if (res.ok) {
        setJobOfferList(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const GET_APPROVED_PROFILE = async () => {
    try {
      const res = await getApprovedJob(jobId);
      if (res.ok) {
        setApprovedProfile(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GET_PROFILE = async (user_id) => {
    try {
      const res = await getProfile(user_id);
      if (res.ok) {
        setUserProfile(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const APPLICATION_APPROVAL = async (e, id, status, user_id) => {
    try {
      e.preventDefault();
      const res = await jobApplicationApproval(id, status);
      if (!res) return displayModal("Error", res.data.message);
      GET_APPLY_JOB_LIST();
      GET_JOB_DETAILS();
      socket.emit('notification',{
        ...res.data.message.tempNotificationModelSocket,
        receiver:user_id,
    })
    socket.emit('getAppliedApplicationList',{
      receiver:user_id,
  })
    socket.emit('startjoblist',{
        receiver:user_id,
    })
      // if (status === "approved") {
      //   GET_PROFILE(user_id);
      // } else {
      //   setUserProfile({});
      // }

      displayModal("Success", res.data.message.msg);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    // GET_PROFILE(user_id);
    GET_JOB_DETAILS();
    GET_APPLY_JOB_LIST();
    GET_APPROVED_PROFILE(jobId);
    GET_OFFER_JOB_LIST();
  }, []);

  const handelChange = async (e) => {
    setShow(true);
  };

  // const publishableKey = PAY_KEY;
  // const [product, setProduct] = useState({
  //   name: "Service 1",
  //   price: 5,
  // });
  // const priceForStripe = 100;

  // const handleSuccess = () => {
  //   console.log("success");
  // };
  // const handleFailure = () => {
  //   console.log("fail");
  // };
  // const payNow = async (token) => {
  //   console.log(token);
    // try {
    //   const response = await getpaymentDetails({
    //     data: {
    //       amount: product.price * 100,
    //       token,
    //     },
    //   });
    // } catch (error) {
    //   handleFailure();
    // }
  // };

  const VIEW_PROFILE = (e,id) => {
    e.preventDefault();
    navigate(`/EmployeeProfile/${id}`);
  }
  const showProfile = (e, id) => {
    navigate(`/EmployeeProfile/${id}`);
  }

  const listenToSocketEvents = () => {
    socket.on("getacceptedList", (getacceptedList) => {
      setJobApplyList(getacceptedList)
    });
  };

  const listenToSocketEvents1 = () => {
    socket.on("getjobdetailsweb", (getacceptedList) => {
      // console.log(getacceptedList);
      if (getacceptedList.employeesId) {
        setUserProfile(getacceptedList.employeesId)
      }
      setJobData(getacceptedList);
    });
  };

  useEffect(() => {
    if (!socket) return;
    listenToSocketEvents();
  }, [socket]);

  useEffect(() => {
    if (!socket) return;
    listenToSocketEvents1();
  }, [socket]);

  return (
    <>
      <RetingModal show={showretingModal} setShowRetingModal={setShowRetingModal} jobid={jobData._id} GET_PROFILE={GET_PROFILE} displayModal={displayModal} userProfile={userProfile._id} />
      <ProceedModal
        user={user}
        show={show}
        setShow={setShow}
        handleClose={handleClose}
        jobData={jobData}
        setShowRetingModal={setShowRetingModal}
        tips={tips}
        GET_JOB_DETAILS={GET_JOB_DETAILS}
        socket={socket}
        displayModal={displayModal}
      />
      <section className="job-banner">
        <div className="container">
          <Row>
            <Col md={12} className="text-center">
              <h1>
                Created Job
              </h1>
              <button className="btn btn-green" onClick={handleGoBack}>Go back</button>
              {/* <p>
                                You will achieve the level of safety excellence that you demonstrate you want 
                            </p> */}
            </Col>
          </Row>
        </div>
      </section>
      <section className="joblispage-top py-5">
        <div className="container">
          <Row>
            <Col md={4} lg={3} className="text-center">
              <div className="exp-details">
                {
                  //  approvedProfile?.employee_id?.profileimage
                  //  &&
                  Object.keys(userProfile).length != 0 && (
                    <div className="ex-pic">
                      {userProfile?.profileimage ? (
                        <Image src={userProfile?.profileimage} alt="" />
                      ) : (
                        <Image src={noimage} alt="" className="w-100" />
                      )}

                    </div>
                  )
                }

                <div className="ex-cont">
                  {Object.keys(userProfile).length == 0 && (
                    <>
                      <h3>Select a person</h3>
                      <p>
                        <strong>
                          Please select (Approve) if you want to select a person to complete this job. 
                        </strong>
                      </p>
                    </>
                  )}
                  {Object.keys(userProfile).length != 0 && (
                    <>
                      <h3>
                        {userProfile?.username}
                        <br />{" "}
                        <small>
                          <Image src={star} alt="user" />{" "}
                          {avgRatingCalculator(userProfile?.rating)} -{" "}
                          {userProfile?.rating.length} review
                        </small>
                      </h3>

                      <>
                        <p>
                          <strong>
                            {userProfile?.firstname} {userProfile?.lastname}
                          </strong>
                        </p>

                        <p>
                          <span>{userProfile?.email}</span>
                        </p>
                        <p>
                          <span>{userProfile?.phone_number}</span>
                        </p>

                        <button className="btn btn-green mt-3" onClick={e=>VIEW_PROFILE(e,userProfile?._id)}>
                          View Profile
                        </button>
                      </>
                    </>
                  )}
                </div>
              </div>
            </Col>
            <Col md={8} lg={9} className="ps-lg-5 ps-md-5 ">
              <div className="exptiselist mb-3">
                <Row>
                  <Col lg={3} md={4} className="mb-3">
                    {jobData?.profileImage ? (
                      <Image src={jobData?.profileImage} className="w-100" />
                    ) : (
                      <Image src={noimage} alt="" className="w-100" />
                    )}

                  </Col>
                  <Col lg={9} md={8}>
                    <p>
                      <strong>Job Title</strong>
                      <span>{jobData?.title}</span>
                    </p>
                    <p>
                      <strong>Job Type </strong>
                      <span>{jobData?.job_type}</span>
                    </p>
                    
                    {
                      jobData?.employeesId && jobData?.status === 'In process'
                      &&
                      <p>
                        <strong>Status </strong>
                        <span className="text-warning">
                          {jobData?.status}
                      </span>
                        {/* <span>
                          <Form className="foronline">
                            <Form.Group className="mb-3">
                              <Form.Select
                                id="disabledSelect"
                                onChange={(e) => handelChange(e)}

                              >
                                <option value="In-process">In process</option>
                                <option value="Completed">Completed</option>
                              </Form.Select>
                            </Form.Group>
                          </Form>
                        </span> */}
                      </p>
                    }
                   
                    {
                      jobData?.employeesId && jobData?.status === 'completed'
                      &&
                      <p>
                      <strong>Status </strong>
                      <span className="text-success">
                      {jobData?.status}
                      </span>
                      </p>
                    }
                    {
                      jobData?.status === 'closed'
                      &&
                      <p>
                      <strong>Status </strong>
                      <span className="text-danger">
                      {jobData?.status}
                      </span>
                      </p>
                    }
                    {
                      jobData?.status === 'opened'
                      &&
                      <p>
                      <strong>Status </strong>
                      <span className="text-success">
                      {jobData?.status}
                      </span>
                      </p>
                    }
 
                    <p>
                      <strong>Project Amount</strong>
                      <span>
                        {jobData?.price?.type === "fixed"
                          ? `$${jobData?.price?.fixed}`
                          : jobData?.price ? `$${jobData?.price?.min}-$${jobData?.price?.max}` : ''}
                      </span>
                    </p>
                    <p><strong>Service charge</strong> <span>{jobData?.price?.type === "fixed"
                          ? `$${(jobData?.price?.fixed * 12) / 100}`
                          : jobData?.price ? `((($${jobData?.price?.min}-$${jobData?.price?.max}) * 12) / 100) ` : ''} <small className="text-danger">(**12% service charge applies)</small></span></p>
                    {
                          jobData?.employeesId && jobData?.status === 'In process'
                          &&
                          <p>
                      {/* <strong>Tips </strong> */}
                      <span>
                        <Form className="foronline Tips-info">
                          <Form.Group className="mb-0" controlId="customer">
                            <Form.Control type={'number'} onChange={handelChangeTips} defaultValue={tips} />
                            {/* <CurrencyInput
                                                    className='f-inp form-control'
                                                    id="fixedPrice"
                                                    name="fixed"
                                                    placeholder="$0.00"
                                                    prefix={"$"}
                                                    decimalSeparator="."
                                                    decimalsLimit={2}
                                                    decimalScale={2}
                                                   onValueChange={(value) => setTips(value?value:0)}
                                                /> */}
                            <Form.Text className="text-muted">
                            Enter your percent number, If you want to give any tips to employee
                            </Form.Text>
                          </Form.Group>
                        </Form>

                      </span>

                    </p>
                    }
                    <p>
                      <strong>Time Schedule</strong>
                      <span>
                        {format(
                          jobData?.start_time?.split(":")[0],
                          jobData?.start_time?.split(":")[1],
                          jobData?.end_time?.split(":")[0],
                          jobData?.end_time?.split(":")[1],
                          jobData?.startDate,
                          jobData?.endDate,
                          jobData?.date
                        )}
                      </span>
                    </p>

                    <p>
                      <strong>Address</strong>
                      <span>
                        {jobData?.address}
                        {/* Bidhannagar, Kolkata, West Bengal, India */}
                      </span>
                    </p>
                    {
                       jobData?.employeesId && jobData?.status === 'In process'
                       &&
                      <Button onClick={(e) => handelChange(e)} className="btn btn-green">Pay and Compleate </Button>
                      // <p>
                       
                      //    <span>
                      //     <Form className="foronline">
                      //       <Form.Group className="mb-3">
                      //         <Form.Select
                      //           id="disabledSelect"
                      //           onChange={(e) => handelChange(e)}

                      //         >
                      //           <option value="In-process">In process</option>
                      //           <option value="Completed">Completed</option>
                      //         </Form.Select>
                      //       </Form.Group>
                      //     </Form>
                      //   </span> 
                      // </p>
                    }
                    {/* <StripeCheckout
                                             stripeKey={publishableKey}
                                             label="proceed"
											  billingAddress
											 shippingAddress
											  currency="SGD"
                                            amount={priceForStripe}
                                            description={`Your total is SGD${priceForStripe}`}
                                            token={payNow}
                                          /> */}
                  </Col>
                </Row>
              </div>
              {jobData?.job_type === "public" ? (
                <></>
              ) : (
                <div className="exptiselist mb-3 reting-info applid-lis">
                  <div className="reting-heading mb-3">
                    <h2>Job Offered List</h2>
                    <hr />
                  </div>
                  {
                    jobOfferList.length > 0
                    &&
                    jobOfferList.map((item, index) => {
                      return (
                        <div className="reting-list mb-3 ">
                          <div className="reting-list-top ">
                            <div className="pp-pic">
                              {item?.candidate?.profileimage ? (
                                <Image src={item?.candidate?.profileimage} alt="" />
                              ) : (
                                <Image src={noimage} alt="" className="w-100" />
                              )}
                            </div>
                            <div className="rp-cont">
                              {item?.employer ? <>
                                <h3>{item?.candidate?.firstname} {item?.candidate?.lastname}</h3>
                                <p>{item?.candidate?.email}</p>
                                <p>{new Date(item?.createdAt).getDay() - new Date().getDay()} days ago</p>
                              </> : <>
                                <p>{item?.receiverEmail}</p>

                              </>}

                            </div>
                          </div>
                        </div>
                      )
                    })

                  }

                  {/* <div className="reting-list mb-3 ">
                    <div className="reting-list-top ">
                      <div className="pp-pic">
                        <Image src={ep1} alt="" />
                      </div>
                      <div className="rp-cont">
                        <h3>Sumit pol</h3>
                        <p>5 days ago</p>
                      </div>
                    </div>
                  </div>
                  <div className="reting-list mb-3 ">
                    <div className="reting-list-top ">
                      <div className="pp-pic">
                        <Image src={ep1} alt="" />
                      </div>
                      <div className="rp-cont">
                        <h3>Sumit pol</h3>
                        <p>5 days ago</p>
                      </div>
                    </div>
                  </div> */}
                </div>
              )}

              <div className="exptiselist mb-3 reting-info applid-lis">
                <div className="reting-heading mb-3">
                  <h2>Job Applied list</h2>
                  <hr />
                </div>
                {jobApplyList.length > 0 &&
                  jobApplyList.map((item, index) => {
                    return (
                      <div className="reting-list mb-3 ">
                        <div className="reting-list-top ">
                          <div className="pp-pic">
                            {item?.employee_id?.profileimage ? (
                              <Image
                                src={item?.employee_id?.profileimage}
                                alt=""
                              />
                            ) : (
                              <Image src={noimage} alt="" className="w-100" />
                            )}

                          </div>
                          <div className="rp-cont">
                            <h3>
                              {item?.employee_id?.firstname}{" "}
                              {item?.employee_id?.lastname}

                            </h3>
                            <p className="mb-3">
                              {/* 5 days ago */}
                              {new Date(item?.createdAt).getDay() - new Date().getDay()}{" "}
                              days ago
                            </p>
                            {item.isEditable ? (
                              <button
                                className={
                                  item.status === "approved"
                                    ? "btn btn-danger apr-bt"
                                    : "btn btn-green apr-bt"
                                }
                                onClick={(e) =>
                                  APPLICATION_APPROVAL(
                                    e,
                                    item?._id,
                                    item.status === "approved"
                                      ? "disapproved"
                                      : "approved",
                                    item.employee_id._id
                                  )
                                }
                              >
                                {item.status === "approved"
                                  ? "Disapproved"
                                  : "Approved"}
                              </button>
                            ) : (
                              <button className="btn btn-secondary apr-bt">
                                Approved
                              </button>
                            )}
                            <button onClick={(e) => showProfile(e, item?.employee_id?._id)} className="for-view-p btn btn-green"><i className="bi bi-eye-fill"></i></button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};
export default CreatedJob;
