import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Form, Image, Nav, Tab, Card, Table } from 'react-bootstrap';
import notebook from '../../../assets/images/notebook.svg';
import creditcard from '../../../assets/images/credit-card.svg';
import newjob from '../../../assets/images/add-new-job.svg';
import { getMyJobs } from '../../../api/job';
import { getDashboardCard } from '../../../api/profile';
import { firstLetterCaps, formatDate } from '../../../utils/helper';
import ViewJobModal from '../../../components/ViewJobModal';
import { useNavigate } from 'react-router-dom';
import store from '../../../utils/store';
const Dashboard = ({ user, displayModal, isFetchJobs, setFetchJobs,socket }) => {
    const navigate = useNavigate();
  const [scroll, setScroll] = useState(0)
  const [dashboardCard,setDashboardCard] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [list, setList] = useState([]); 
    const [jobs, setJobs] = useState([]); 
    const [currentJob, setCurrentJob] = useState({});

    const handleWorked = (e) => {
        e.preventDefault();
        window.scroll({ top: 0, left: 0, behavior: 'smooth' })
        store.storeLastEventKey('Profile')
        navigate('/user');
        window.location.reload()
      };
    const getJobField = (job, field) => {
        switch (field) {
            case 'images': {
                if (!job) return null;
                if (!job.images) return null;
                if (!job.images.length) return null;
                return job.images[0].path;
            }
            case 'category': {
                if (!job) return '-';
                if (!job.category) return '-';
                return job.category.name;
            }
            case 'subcategory': {
                if (!job) return '-';
                if (!job.subcategory) return '-';
                return job.subcategory.name;
            }
        }
    }

    const getDateRange = (job) => {
        return (
            job.isJobMoreThanOneDay
                ?
                `${formatDate(job.startDate, 'dd mo yyyy')} - ${formatDate(job.endDate, 'dd mo yyyy')}`
                :
                formatDate(job.date, 'dd mo yyyy')
        )
    }

    const formatJobs = (jobs) => {
        if (!jobs) return [];
        if (!jobs.length) return [];
        return jobs
            .map(job => ({
                _id: job._id,
                job_id: job.job_id,
                title: job.title,
                date: getDateRange(job),
                job_type: firstLetterCaps(job.job_type),
                status: job.status,
                images: getJobField(job, 'images'),
                video: job.video,
                category: getJobField(job, 'category'),
                subcategory: getJobField(job, 'subcategory')
            }))
    }

    const GET_JOBS = async () => {
        try {
            const res = await getMyJobs();
            if (!res.ok) return displayModal('Error', res.data.message);
            if (!res.data.success) return displayModal('Error', res.data.message);

            let list = res.data.data;
            if (!list) return;
            if (!list.length) return;
            // list = list.map(job => job.details)

            setList(list);

            const jobs = formatJobs(list);
             setJobs(jobs);
            setFetchJobs(false);
        } catch (err) {
            console.error(err);
        }
    }

    const GET_DASHBOARD_CARD = async () =>{
        try {
            const cardData = await getDashboardCard();
            // console.log(cardData.data,"KKKKKKKKKKKKKKKKKK")
            setDashboardCard(cardData.data);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        GET_DASHBOARD_CARD();
        GET_JOBS();
    }, [isFetchJobs]);

    const viewJobDetails = (e, job) => {
        e.preventDefault();
        navigate(`/JobDetails/${job._id}`);
    }
    const viewEarnings = (e) => {
        e.preventDefault();
        navigate(`/Earnings`);
    }

    const searchJobs = (e) => {
        const searchInput = e.target.value;

        if (!searchInput) return setJobs(formatJobs(list));
        if (!list.length) return console.error('list is empty!');


        // find jobs by id, title & type

        const _idSearchResults = list.filter(job => searchInput.toLowerCase().includes(job.job_id.toLowerCase().substring(0, searchInput.length)))
        const _titleSearchResults = list.filter(job => searchInput.toLowerCase().includes(job.title.toLowerCase().substring(0, searchInput.length)))
        const _typeSearchResults = list.filter(job => job.job_type.toLowerCase() == searchInput.toLowerCase())

        let jobs = [..._idSearchResults, ..._titleSearchResults, ..._typeSearchResults];
        if (jobs.length) jobs = formatJobs(jobs)
        setJobs(jobs);
    }

    const listenToSocketEvents = () => {
        socket.on("workedjob", (getacceptedList) => {
            let list = getacceptedList;
            if (!list) return;
            if (!list.length) return;
         

            setList(list);

            const jobs = formatJobs(list);
             setJobs(jobs);
            setFetchJobs(false);
        });
      };
    const listenToSocketEvents1 = () => {
        socket.on("getDashboardCard", (getacceptedList) => {
            setDashboardCard(getacceptedList);
        });
      };
 
    
      useEffect(() => {
        if (!socket) return;
        listenToSocketEvents();
        listenToSocketEvents1();
      }, [socket]);

    

    return (
        <>
            <ViewJobModal
                show={showModal}
                setShow={setShowModal}
                job={currentJob}
            />
            <section className="userinner">
                <Row>
                    <Col md={12}>
                        <h3 className='user-listingpage-title'>
                            Hello, {user.firstname}<br /><small>Current page - Dashboard</small>
                        </h3>
                        <hr />
                    </Col>
                </Row>
                <Row className=''>
                    <Col md={12}>
                        <Card className='radius-12 mb-3'>
                            <Card.Body>
                                <h4>Biography </h4>
                                <p className='mb-0'>
                                    {user.bio} <strong> ~ {user?.firstname}</strong>
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className='dashboard-top'>
                    <Col md={4}>
                        <Card className='bt-cd-list mb-3 radius-12' onClick={handleWorked}>
                            <Card.Body>
                                <div className='dt-left'>
                                    <Image src={notebook} alt="" />
                                </div>
                                <div className='dt-right'>
                                    <h1>{dashboardCard?.inprocess}</h1>
                                    <p className='mb-0'>
                                         Jobs : In Process
                                    </p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className='bt-cd-list mb-3 radius-12' onClick={handleWorked}>
                            <Card.Body>
                                <div className='dt-left'>
                                    <Image src={newjob} alt="" />
                                </div>
                                <div className='dt-right'>
                                    <h1>{dashboardCard?.completed}</h1>
                                    <p className='mb-0'>
                                     Recent Jobs : Completed
                                    </p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className='bt-cd-list mb-3 radius-12'>
                            <Card.Body onClick={e =>  viewEarnings(e)}>
                                <div className='dt-left'>
                                    <Image src={creditcard} alt="" />
                                </div>
                                <div className='dt-right'>
                                    <h1>${dashboardCard?.totalEarn}</h1>
                                    <p className='mb-0'>
                                        {dashboardCard?.monthName} : Money Earned
                                    </p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col md={12}>
                        <Form className='for-filter-table'>
                            <Form.Group className="comm-filer">
                                <Form.Control className='f-inp' type="search" placeholder="Enter your keyword" onChange={searchJobs} />
                                <Form.Select className='f-inp' onChange={searchJobs}>
                                    <option> Select Type</option>
                                    <option> Public</option>
                                    <option> Private</option>
                                </Form.Select>
                            </Form.Group>

                        </Form>
                    </Col>
                    <Col md={12} className="mt-3">
                        <div className='table-responsive'> 
                            <Table striped hover className='com-table'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Job ID</th>
                                        <th>Title</th>
                                        <th>Date (Range)</th>
                                        <th>Type</th>
                                        <th>Status</th>
                                        <th className='text-center'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        jobs
                                        &&
                                        jobs.length > 0
                                        &&
                                        jobs.map((job, index) => (
                                            <tr key={job._id}>
                                                <td>{index + 1}</td>
                                                <td>{job.job_id}</td>
                                                <td>{job.title}</td>
                                                <td>{job.date}</td>
                                                <td>{job.job_type}</td>
                                                <td className='worning capitalize'>{job.status}</td>
                                                <td className='text-center'><Button className='btn-green' onClick={e => viewJobDetails(e, job)}> View </Button></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </section>
        </>
    );
};

export default Dashboard;