import React, { useState, useEffect,useContext } from "react";
import { Row, Col,Image } from 'react-bootstrap';
import JobList from "./JobList";
// import JobFilter from "../jobslist/JobFilter";
// import project1 from '../../assets/images/project1.png';
// import project2 from '../../assets/images/project2.png';
// import project3 from '../../assets/images/project3.png';
// import project4 from '../../assets/images/project4.jpg';
// import project5 from '../../assets/images/project5.jpg';
// import project6 from '../../assets/images/project6.jpg';
// import CustomPagination from "../../components/CustomPagination";
import { recentJobList } from "../../api/job";
import { formatJobs } from "../../utils/helper";
import { useNavigate, NavLink } from "react-router-dom";
// import { useGeolocated } from "react-geolocated";
import { GET_CATEGORY_LIST, handleCategoryChange } from "../user/service";
import { GET_JOB_LIST } from '../jobslist/service';
import { checkThrottle, throttle } from "../jobslist/helper";
import { Context } from "../../components/Context";
// import ApplyJobModal from "../../components/ApplyJobModal";
// import { applyJob } from '../../api/job';
import AdsItem from "../../components/AdsItem";
import view from '../../assets/images/entypo.svg';
// import RetingModal from "./modal/retings";
import SetBanner from "../../components/setbanner";
import noimage from '../../assets/images/noImage.png';
import JobsList from "../../components/JobsList";
const AllJobs = ({user={},isLoggedIn, displayModal, setRecipientId, homeSelectedCategoryId}) =>{
    
    const viewCategoryJobs = (e, catId) => {
        e.preventDefault();
         try {
            navigate('/SearchCatJob',{state:{category:catId}})
        } catch (error) {
            console.log(error)
        }
    };
    const [recentJobs, setRecentJobs] = useState([]);

    	//Pagination
	const [itemsLimit, setItemsLimit] = useState(9);
	const [totalPages, setTotalPages] = useState(0);
	const [pointer, setPointer] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [currentPageLimit, setCurrentPageLimit] = useState(0);

    	// Job Filter
	const [searchTitle, setSearchTitle] = useState('');
	const [searchLocation, setSearchLocation] = useState('');
	const [timer, setTimer] = useState(0); //sec
	const [timerId, setTimerId] = useState(null);
	const [isFetchJobs, setIsFetchJobs] = useState(true);
	const [categoryList, setCategoryList] = useState([]);
	const [subcategoryList, setSubCategoryList] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState(homeSelectedCategoryId);
	const [selectedSubCategory, setSelectedSubCategory] = useState('');
	const [sortBy, setSortBy] = useState('');
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [price, setPrice] = useState(0);
	// const [distance, setDistance] = useState(1000);
    const navigate = useNavigate()

    // Job Application Modal
	const [showModal, setShowModal] = useState(false);
	const [selectedJob, setSelectedJob] = useState({});
	const [resume, setResume] = useState('');
    // const {state} = useLocation();

     // Job
    const [jobs, setJobs] = useState([]);
    const { distance,setDistance,coords } = useContext(Context);
    
    // const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    // useGeolocated({
    //     positionOptions: {
    //         enableHighAccuracy: false,
    //     },
    //     userDecisionTimeout: 5000,
    // });

    const GET_RECENT_JOBS = async () => {
        try {
            const res = await recentJobList();
            
            if (!res.ok) return;
            
            let jobs = res.data.data;
            
            if (!jobs) return;
            if (!jobs.length) return;

            jobs = formatJobs(jobs)

            if (user._id) {
                jobs = jobs.filter(job => job.employer_id._id !== user._id)
            };

            setRecentJobs(jobs.slice(0,8));
        } catch (err) {
            console.error(err);
        }
    }

    const handlePageClick = ({ pageIndex, isSetPointer = true }) => {
		window.scrollTo(0, 0)
		if (isSetPointer) setPointer(pageIndex);
		setCurrentPage(pageIndex);
		setIsFetchJobs(true);
	};

	useEffect(() => {
        // console.log(isFetchJobs,"????????????..............///////..")
		// if (!isFetchJobs || !coords) return;
		if (!coords) return;
        // console.log(coords,"????????????????...........//////////////")
		GET_JOB_LIST({
			state: {
				userId: user._id,
				currentPage,
				itemsLimit,
				searchTitle,
				searchLocation,
				selectedCategory,
				selectedSubCategory,
				sortBy,
				startDate,
				endDate,
				price,
				distance,
				coords
			},
			setState: {
				setCurrentPageLimit,
				setTotalPages,
				setJobs,
				setIsFetchJobs,
			},
			func: {
				displayModal
			}
		});
	}, [user, isFetchJobs, coords, homeSelectedCategoryId,distance])

	useEffect(() => {
		checkThrottle(timer, setTimer, timerId, setIsFetchJobs);
	}, [timer])

	const _throttle = () => throttle(timerId, setTimerId, setTimer)

	const handleFilterInput = (e) => {
		switch (e.target.name) {
			case 'title': {
				console.log('title search')
				setSearchTitle(e.target.value);
				_throttle();
				break;
			}
			case 'location': {
				console.log('location search')
				setSearchLocation(e.target.value);
				_throttle();
				break;
			}
			case 'category': {
				console.log('category search')
				handleCategoryChange(e, setSelectedCategory, setSubCategoryList)
				setSelectedSubCategory('')
				setIsFetchJobs(true);
				break;
			}
			case 'subcategory': {
				console.log('sub category search')
				setSelectedSubCategory(e.target.value);
				setIsFetchJobs(true);
				break;
			}
			case 'sortBy': {
				console.log('sort by ', e.target.value);
				setSortBy(e.target.value);
				setIsFetchJobs(true);
				break;
			}
			case 'startDate': {
				console.log('start date ', e.target.value);
				setStartDate(e.target.value);
				if (endDate) setIsFetchJobs(true);
				break;
			}
			case 'endDate': {
				console.log('end date ', e.target.value);
				setEndDate(e.target.value);
				if (startDate) setIsFetchJobs(true);
				break;
			}
			case 'price': {
				console.log('price range', e.target.value);
				setPrice(e.target.value);
				_throttle();
				break;
			}
			case 'distance': {
				const newDistance = e.target.value;
				console.log('search nearest jobs within ', newDistance);
				setDistance(newDistance);
				_throttle();
				break;
			}
		}
	}

    useEffect(() => {
        GET_RECENT_JOBS();
    }, []);
    const viewJob = (id) => navigate(`/JobDetails/${id}`)
    useEffect(() => {
		GET_CATEGORY_LIST(setCategoryList);
	}, []);
    
    return(
        <>
        
           {/* <section className="job-banner">
				<div className="container">
					<Row>
						<Col md={12} className="text-center">
							<h1>
								All Jobs
							</h1>
							<p>
								Search your career opportunity through {recentJobs.length} jobs
							</p>
						</Col>
					</Row>
				</div>
			</section> */}
            <SetBanner distance={distance} setDistance={setDistance} className="job-banner"/>
            <section className="banner-botom py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="cetagorilist">
                                
                                {
                                    categoryList.length > 0
                                    &&
                                    categoryList.slice(0, 8).map((item, index) => (
                                        <a key={index} className="celitem" onClick={e => viewCategoryJobs(e, item._id)}>
                                            {item?.icon ? (
                                                        <Image src={item?.icon} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                                ) : (
                                                    <Image src={noimage} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }}/>
                                                )}
                                            <br />
                                            <span>{item.name}</span>
                                        </a>
                                    ))
                                }
                                <NavLink to="/AllCategories" className="celitem active">
                                    <img src={view} /><br />
                                    <span>View all</span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                        </section>
            <section className="py-5">
                <div className="container">
                    <Row>
                        {/* <JobFilter
							selectedCategory={selectedCategory}
							categoryList={categoryList}
							subcategoryList={subcategoryList}
							handleFilterInput={handleFilterInput}
							price={price}
							distance={distance}
						/> */}
                        <Col lg={12}>
                        <Row>
                        {/* <Col lg={4} className="mb-3">
                            <div className="rjcarsd">
                                <img src={project5} />
                                <div className="top-cont">
                                    <h3>
                                    Testing<br /><small>Wed, 21st Sep, 2022</small>
                                    </h3>
                                    <p>
                                    Need to clean the grass
                                    </p>
                                </div>
                                <div className="bottom-cont">
                                    
                                            <span
                                                style={{
                                                    fontSize: 18
                                                }}
                                            >
                                                $900
                                            </span>
                                            
                                    
                                </div>
                            </div>
                        </Col> */}
                        <Row>
                        {/* {
                            recentJobs
                            &&
                            recentJobs.length > 0
                            &&
                            recentJobs.map(job => (
                                <Col lg={4} className="mb-3" onClick={e => viewJob(job._id)}>
                            <div className="rjcarsd">
                                <img src={job.profileImage} />
                                <div className="top-cont">
                                    <h3>
                                    {job.title}<br /><small>{job.createdDate}</small>
                                    </h3>
                                    <p>
                                    {job.description ? job.description.substring(0, 73) : ''}
                                    </p>
                                </div>
                                         <div className="bottom-cont">
                                             {
                                                 job.priceType === 'fixed'
                                                     ?
                                                     <span
                                                         style={{
                                                             fontSize: job.salary.toString().includes('.') ? 16 : 18
                                                         }}
                                                     >
                                                         {job.salary}
                                                     </span>
                                                     :
                                                     <span
                                                         style={{
                                                             fontSize: job.maxPrice.toString().includes('.') ? 16 : 18
                                                         }}
                                                     >
                                                         {job.maxPrice}
                                                     </span>
                                             }
                                         </div>
                            </div>
                                </Col>
                            ))
                        } */}
                        <JobList
							// coords={coords}
							jobs={jobs}
							limit={itemsLimit}
							currentPageLimit={currentPageLimit}
							totalPages={totalPages}
							pointer={pointer}
							handlePageClick={handlePageClick}
							setPointer={setPointer}
							// handleChatNow={handleChatNow}
							// handleApplyJob={handleApplyJob}
						/>
                         </Row>
                        {/* <Col lg={4} className="mb-3">
                            <div className="rjcarsd">
                                <img src={project5} />
                                <div className="top-cont">
                                    <h3>
                                    Testing<br /><small>Wed, 21st Sep, 2022</small>
                                    </h3>
                                    <p>
                                    Need to clean the grass
                                    </p>
                                </div>
                                <div className="bottom-cont">
                                    
                                            <span
                                                style={{
                                                    fontSize: 18
                                                }}
                                            >
                                                $900
                                            </span>
                                            
                                    
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} className="mb-3">
                            <div className="rjcarsd">
                                <img src={project3} />
                                <div className="top-cont">
                                    <h3>
                                    Testing<br /><small>Wed, 21st Sep, 2022</small>
                                    </h3>
                                    <p>
                                    Need to clean the grass
                                    </p>
                                </div>
                                <div className="bottom-cont">
                                    
                                            <span
                                                style={{
                                                    fontSize: 18
                                                }}
                                            >
                                                $900
                                            </span>
                                            
                                    
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} className="mb-3">
                            <div className="rjcarsd">
                                <img src={project3} />
                                <div className="top-cont">
                                    <h3>
                                    Testing<br /><small>Wed, 21st Sep, 2022</small>
                                    </h3>
                                    <p>
                                    Need to clean the grass
                                    </p>
                                </div>
                                <div className="bottom-cont">
                                    
                                            <span
                                                style={{
                                                    fontSize: 18
                                                }}
                                            >
                                                $900
                                            </span>
                                            
                                    
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} className="mb-3">
                            <div className="rjcarsd">
                                <img src={project3} />
                                <div className="top-cont">
                                    <h3>
                                    Testing<br /><small>Wed, 21st Sep, 2022</small>
                                    </h3>
                                    <p>
                                    Need to clean the grass
                                    </p>
                                </div>
                                <div className="bottom-cont">
                                    
                                            <span
                                                style={{
                                                    fontSize: 18
                                                }}
                                            >
                                                $900
                                            </span>
                                            
                                    
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} className="mb-3">
                            <div className="rjcarsd">
                                <img src={project3} />
                                <div className="top-cont">
                                    <h3>
                                    Testing<br /><small>Wed, 21st Sep, 2022</small>
                                    </h3>
                                    <p>
                                    Need to clean the grass
                                    </p>
                                </div>
                                <div className="bottom-cont">
                                    
                                            <span
                                                style={{
                                                    fontSize: 18
                                                }}
                                            >
                                                $900
                                            </span>
                                            
                                    
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} className="mb-3">
                            <div className="rjcarsd">
                                <img src={project4} />
                                <div className="top-cont">
                                    <h3>
                                    Testing<br /><small>Wed, 21st Sep, 2022</small>
                                    </h3>
                                    <p>
                                    Need to clean the grass
                                    </p>
                                </div>
                                <div className="bottom-cont">
                                    
                                            <span
                                                style={{
                                                    fontSize: 18
                                                }}
                                            >
                                                $900
                                            </span>
                                            
                                    
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} className="mb-3">
                            <div className="rjcarsd">
                                <img src={project4} />
                                <div className="top-cont">
                                    <h3>
                                    Testing<br /><small>Wed, 21st Sep, 2022</small>
                                    </h3>
                                    <p>
                                    Need to clean the grass
                                    </p>
                                </div>
                                <div className="bottom-cont">
                                    
                                            <span
                                                style={{
                                                    fontSize: 18
                                                }}
                                            >
                                                $900
                                            </span>
                                            
                                    
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} className="mb-3">
                            <div className="rjcarsd">
                                <img src={project1} />
                                <div className="top-cont">
                                    <h3>
                                    Testing<br /><small>Wed, 21st Sep, 2022</small>
                                    </h3>
                                    <p>
                                    Need to clean the grass
                                    </p>
                                </div>
                                <div className="bottom-cont">
                                    
                                            <span
                                                style={{
                                                    fontSize: 18
                                                }}
                                            >
                                                $900
                                            </span>
                                            
                                    
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} className="mb-3">
                            <div className="rjcarsd">
                                <img src={project5} />
                                <div className="top-cont">
                                    <h3>
                                    Testing<br /><small>Wed, 21st Sep, 2022</small>
                                    </h3>
                                    <p>
                                    Need to clean the grass
                                    </p>
                                </div>
                                <div className="bottom-cont">
                                    
                                            <span
                                                style={{
                                                    fontSize: 18
                                                }}
                                            >
                                                $900
                                            </span>
                                            
                                    
                                </div>
                            </div>
                        </Col> */}
                    </Row>
                    <Row>
                    {/* <Col>
                        <div style={{ display: 'flex', justifyContent: 'right', marginTop: 10, padding: 0 }}>
                            <CustomPagination
                                totalPages="20"

                                pointer='1'
                                limit='100'
                            />
                        </div>
                    </Col> */}
                </Row>
                        </Col>
                    </Row>
                    
                </div>
            </section>
			{/* <AdsItem /> */}
            <JobsList />
        </>
    )
}
export default AllJobs;