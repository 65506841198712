import store from "./store";

const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const DAYS = {
    'Sun': 'Sunday',
    'Mon': 'Monday',
    'Tue': 'Tuesday',
    'Wed': 'Wednesday',
    'Thu': 'Thursday',
    'Fri': 'Friday',
    'Sat': 'Saturday',
};

const getDateSuffix = (dateInt) => {

    let lastDigit = dateInt.length > 1 ? dateInt.split('')[1] : dateInt;
    lastDigit = Number(lastDigit);

    switch (lastDigit) {
        case 1: return `${dateInt}st`;
        case 2: return `${dateInt}nd`;
        case 3: return `${dateInt}rd`;
        default: return `${dateInt}th`;
    }
}

const formatDate = (date, pattern) => {
    if (!date) return null;

    date = new Date(date);
    let dd = date.getDate(),
        month_index = date.getMonth(),
        mm = month_index + 1,
        mo = MONTHS[month_index],
        yyyy = date.getFullYear();

    switch (pattern) {
        case 'dd mo yyyy': {
            return `${dd} ${mo} ${yyyy}`;
        }
        case 'DATE_STRING': {
            let dateStr = date.toString();
            let dateArr = dateStr.split(' ');
            // return `${dateArr[0]}, ${getDateSuffix(dateArr[2])} ${dateArr[1]}, ${dateArr[3]}`;
            return `${DAYS[dateArr[0]]}, ${dateArr[2]} ${mo}, ${dateArr[3]}`;
        }
        default: return `${yyyy}-${mm}-${dd}`;
    }
}

const format_time_24 = (timeString, meridian) => {
    if (!timeString) return console.log('time string is empty');

    if (timeString == '12:00' && meridian.toLowerCase() == 'am') return '00:00';

    if (timeString == '12:00' || meridian.toLowerCase() == 'am') return timeString;

    let hours = timeString.split(':')[0], min = timeString.split(':')[1];

    hours = Number(hours);

    hours = hours + 12;

    return `${hours}:${min}`
}

const format_time_12 = (timeString) => {
    timeString = timeString.split(':');
    let hours = timeString[0];
    let min = timeString[1];
    let meridian = Number(hours) > 12 ? 'pm' : 'am';
    hours = Number(hours) % 12;
    hours = hours == 0 ? '00' : hours;
    return `${hours}:${min}${meridian}`;
}

const firstLetterCaps = (str) => {
    if (!str) return;
    let str_arr = str.split('');
    let f_letter = str_arr[0].toUpperCase();
    str_arr.splice(0, 1, f_letter);
    str = str_arr.join('');
    return str;
}

const isValidPassword = (password) => {
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    if (!passwordRegex.test(password)) return ['Password must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters', false]
    return [null, true]
}

const chainOperator = (data, arr_of_nested_keys, default_return_value_falsy) => {
    // user {} ['profession']
    if (!data) return default_return_value_falsy;

    for (const key of arr_of_nested_keys) {
        if (!data[key]) return default_return_value_falsy;
        data = data[key];
    }
    return data;
}

const getQueryString = (query) => {
    let queryString = '';

    for (const [key, val] of Object.entries(query)) {
        queryString += `${key}=${val}&&`;
    }

    return queryString;
}

const getSalary = (price) => {
    if (!price) return '';
    if (price.type == 'fixed') return `$${Number(price.fixed).toFixed(2)}`;
    return `$${Number(price.min).toFixed(2)} - $${Number(price.max).toFixed(2)}`
}

const getSlides = (profileImage, gallery) => {
    let slides = [];
    if (profileImage) slides.push({ url: profileImage })
    if (gallery) slides = [...slides, ...gallery.map(uri => ({ url: uri }))];
    return slides;
}

const formatJob = (job) => {

    const date = new Date(job.createdAt);
    const createdTime = `${date.getHours()}:${date.getMinutes()}`;

    if (!job) return {};

    let data = {
        _id: job._id,
        employer_id: job.employer_id,
        employer_name: `${job.employer_id ? job.employer_id.firstname : ''} ${job.employer_id ? job.employer_id.lastname : ''}`,
        profileImage: job?.profileImage,
        gallery: job.gallery,
        video: job.video,
        slides: getSlides(job?.profileImage, job.gallery),
        title: job.title,
        description: job.description,
        salary: getSalary(job.price),
        priceType: job.price ? job.price.type : 'fixed',
        minPrice: job.price ? '$' + job.price.min : null,
        maxPrice: job.price ? '$' + job.price.max : null,
        start_time: format_time_12(job.start_time),
        end_time: format_time_12(job.end_time),
        job_type: job.job_type,
        address: `${job.address}, ${job.state}, ${job.city} ${job.zipcode}`,
        createdDate: formatDate(date, 'DATE_STRING'),
        createdTime: format_time_12(createdTime),
        isJobMoreThanOneDay: job.isJobMoreThanOneDay,
        shareUrl: job.shareUrl,
        loc:job.location.coordinates
    }

    if (job.isJobMoreThanOneDay) {
        data.startDate = formatDate(job.startDate, 'DATE_STRING');
        data.endDate = formatDate(job.endDate, 'DATE_STRING');
    } else {
        data.date = formatDate(job.date, 'DATE_STRING')
    }

    return data;
}

const formatJobs = (jobs) => jobs.map(job => formatJob(job))

const delay = (ms) => new Promise(resolve => setTimeout(() => resolve(true), ms))

const getEmployeeEmail = (employee_id) => {
    if (!employee_id) return '';
    if (!employee_id.email) return '';
    
    let email = employee_id.email;

    return (
        <>
            <p>{email.split('@')[0]}@{email.split('@')[1]}</p>
           
        </>
    )
}

const getExpectedCost = (cost) => {
    if (!cost) return '$';

    if (cost.type === 'day') {
        return `$${cost.value} / day`;
    } else {
        return `$${cost.value} / hr`;
    }
};

export {
    formatDate,
    format_time_24,
    format_time_12,
    firstLetterCaps,
    isValidPassword,
    chainOperator,
    getQueryString,
    formatJob,
    formatJobs,
    delay,
    getEmployeeEmail,
    getExpectedCost,
}