import React, { useState, useContext } from "react";
// import Banner from "../../Layouts/Banner";
import { Button, Row, Col, Form } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
// import email from "../../assets/images/email.svg";
// import address from "../../assets/images/address.svg";
// import phone from "../../assets/images/phone-cal.svg";
// import SignIn from "../signin";
import { signup } from "../../api/auth";
import { isValidPassword } from "../../utils/helper";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// import { getCategoryList, getSubcategoryList } from "../../api/job";
import { Context } from "../../components/Context";
const SignUp = ({ displayModal, setIsLoggedIn }) => {
  const [isOtpVerify, setIsOtpVerify] = useState(true);
  const { setIsLoading } = useContext(Context);
  const [toggpasswordInputType, setToggpasswordInputType] =
    useState("password");
  const [toggConfirmpasswordInputType, setToggConfirmpasswordInputType] =
    useState("password");
  const navigate = useNavigate();

  const formValidation = (form) => {
    // Phone Number
    let phone = form.get("phone_number");

    // if (phone.toString().length !== 12) {
    // 	return ['Invalide Phone Number!', false];
    // }

    // Password Mismatch
    if (form.get("password") !== form.get("confirm_password")) {
      return ["Password Mismatch!", false];
    }

    // Validate Password
    const [err, success] = isValidPassword(form.get("password"));
    if (!success) return [err, false];

    // Terms Checkbox
    const termsChecked = document.getElementById("check-api-checkbox");
    if (!termsChecked.checked)
      return ["Please Agree To The Terms & Conditions!", false];

    // Validation Success
    return [null, true];
  };

  const SUBMIT = async (e) => {
    try {
      e.preventDefault();
      const form = new FormData(e.target);

      // Form Validation
      const [err, success] = formValidation(form);
      if (err) return displayModal("Error", err);

      // API call
      const data = {
        username: form.get("username"),
        firstname: form.get("firstname"),
        lastname: form.get("lastname"),
        email: form.get("email"),
        password: form.get("password"),
        phone_number: form.get("phone_number"),
        otp: isOtpVerify
      };
      setIsLoading(true);
      const res = await signup(data);
      if (!res.ok) {
        setIsLoading(false);
        return displayModal("Error", res.data.message);
      }
      if (!res.data.success) {
        setIsLoading(false);
        return displayModal("Error", res.data.message);
      }
      if (res.data.success) {
        setIsLoading(false);
        displayModal("Success", res.data.message);
      }
      if (data.otp) {
        navigate(`/otp/${res.data.data._id}/${res.data.data.email}`)
      } else {
        navigate("/signin");
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
      <section className="signuplogin  contact-us page py-5">
        <div className="container">
          <div className="si-re">
            <div className="sire-left f-color-w">
              <h1 className="sl-title">Flow The Step</h1>
              <p>Register and create your account</p>
              <p>Verify email and log in to your account</p>
              <p>Search for job/ post a job</p>
              <p>Apply for job/ choose employee</p>
              <p>Make payment once Job is complete/ get paid</p>
            </div>
            <div className="sire-right f-color-w">
              <Form onSubmit={SUBMIT}>
                <h2 className="sl-su-title">Welcome</h2>
                <p className="mb-5">
                  It is a long established fact that a reader will
                </p>
                <Form.Group className="mb-3" controlId="Username">
                  <Form.Control
                    required
                    type="text"
                    placeholder="User Name"
                    autoFocus={true}
                    name="username"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="FirstName">
                  <Form.Control
                    required
                    type="text"
                    placeholder="First Name"
                    name="firstname"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="LastName">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Last Name"
                    name="lastname"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="Emailid">
                  <Form.Control
                    required
                    type="email"
                    placeholder="Enter Email"
                    name="email"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="PhoneNumber">

                  <PhoneInput
                    name={"phone_number"}
                    enableSearch={true}
                    className="signup-phone"
                    country={"us"}
                    inputProps={{
                      name: "phone_number",
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3 forpassword" controlId="password">
                  <Form.Control
                    type={toggpasswordInputType}
                    placeholder="Password"
                    name="password"
                  />
                  <i
                    className={
                      toggpasswordInputType == "password"
                        ? "bi bi-eye-slash btnToggle"
                        : "bi bi-eye btnToggle"
                    }
                    id="togglePassword"
                    onClick={(e) =>
                      setToggpasswordInputType((type) =>
                        type == "text" ? "password" : "text"
                      )
                    }
                  ></i>
                </Form.Group>

                <Form.Group
                  className="mb-3 forpassword"
                  controlId="confirmpassword"
                >
                  <Form.Control
                    required
                    type={toggConfirmpasswordInputType}
                    placeholder="Confirm Password"
                    name="confirm_password"
                  />
                  <i
                    className={
                      toggConfirmpasswordInputType == "password"
                        ? "bi bi-eye-slash btnToggle"
                        : "bi bi-eye btnToggle"
                    }
                    id="togglePassword"
                    onClick={(e) =>
                      setToggConfirmpasswordInputType((type) =>
                        type == "text" ? "password" : "text"
                      )
                    }
                  ></i>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">


                  {["radio"].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                      <Form.Check
                        inline
                        label="Otp Verification"
                        name="group1"
                        type={type}
                        id={`inline-${type}-2`}
                        onClick={e=>setIsOtpVerify(true)}
                        checked
                      />
                      <Form.Check
                        inline
                        label="Link Verification"
                        name="group1"
                        type={type}
                        id={`inline-${type}-1`}
                        onClick={e=>setIsOtpVerify(false)}
                      />

                    </div>
                    // <></>
                  ))}

                  {["checkbox"].map((type) => (
                    <div key={type} className="mb-3">
                      <Form.Check type={type} id={`check-api-${type}`}>
                        <Form.Check.Input type={type} isValid />
                        <Form.Check.Label>
                          I agree to the{" "}
                          <NavLink to="/PrivacyPolicy">Privacy policy</NavLink>{" "}
                          and{" "}
                          <NavLink to="/TermsServices">
                            Terms of Services
                          </NavLink>
                        </Form.Check.Label>
                      </Form.Check>
                    </div>
                  ))}
                </Form.Group>

                <Button variant="primary" type="submit">
                  Sign Up
                </Button>
              </Form>
              <p className="text-center mt-4">
                Already have an account? <NavLink to="/signin">Sign In</NavLink>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUp;
