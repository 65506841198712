import React, { useState, useEffect } from "react";
import { Table, Form, Button, Card, Row, Col } from "react-bootstrap";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import Banner from "../../Layouts/Banner";
import about from '../../assets/images/about-right.jpg';
import { Chart } from "react-google-charts";
import {getEarnings,getCompliteJob} from "../../api/profile"
import moment from 'moment'; 

const Earnings = () => {
    const navigate = useNavigate();
    const [chartData,setChartData] = useState({});
    const [completeJob,setCompleteJob] = useState([]);
    const [totalEarn,setTotalEarn] = useState(0);
    const [startDate,setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate,setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const FETCH_DATA = async ()=>{
        const res = await getEarnings();
        setChartData(res.data);
    }
    useEffect(()=>{
        
        FETCH_DATA();
    },[])
    const FETCH_COMPLITE_JOB = async ()=>{
        const compliteJobData = await getCompliteJob(startDate?startDate:undefined,endDate?endDate:undefined);
        console.log(compliteJobData.data.data)
        setTotalEarn(compliteJobData.data.total)
        setCompleteJob(compliteJobData.data.data);
    }
    useEffect(()=>{
        FETCH_COMPLITE_JOB();
    },[startDate,endDate])

    // const CALENDER_FILTER = async ()=>{
      
    //     const compliteJobData = await getCompliteJob(startDate,endDate);
        
    //     const sumvalue = compliteJobData.data.data.reduce(
    //         (accumulator, currentValue) => accumulator + Number(currentValue.price.fixed),
    //         totalEarn
    //       );
    //       setTotalEarn(sumvalue)
    //     setCompleteJob(compliteJobData.data.data);
    // }
    function handleGoBack() {
        navigate(-1);
      }
	return (
		<>
        {/* <section class="aflotop"></section> */}
        <section className="job-banner">
        <div className="container">
          <Row>
            <Col md={12} className="text-center">
              <h1>Earnings Details</h1>
              <button className="btn btn-green" onClick={handleGoBack}>Go back</button>
              {/* <p>
                                Search your career oppurtunity through 12,800 jobs
                            </p> */}
            </Col>
          </Row>
        </div>
      </section>
        <section className="inner page py-5">
			<div className="container">
				
                <div className="row ">
                    <div className="col-lg-6 text-end">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label className="py-1">Filter By:</Form.Label>
                                
                            </Form.Group>
                    </div>
                    <div className="col-lg-6">
                        <Form className="row">
                            <Form.Group className="mb-3  col-6" controlId="formBasicEmail">
                                <Form.Control type="date" onChange={e=>setStartDate(moment(e.target.value).format('YYYY-MM-DD'))} max={endDate} defaultValue={startDate}   />
                            </Form.Group>

                            <Form.Group className="mb-3 col-6">
                                <Form.Control type="date" min={startDate} onChange={e=>setEndDate(moment(e.target.value).format('YYYY-MM-DD'))} defaultValue={endDate} />
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="col-lg-12">
                    <div className='table-responsive'> 
                            <Table striped hover className='com-table'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Job Id </th>
                                        <th>Date</th>
                                        <th>Name</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        completeJob.length>0
                                        &&
                                        completeJob.map((item,index)=>{
                                            return (
                                                <tr>
                                                <td>{index+1}</td>
                                                <td>{item?.job_id}</td>
                                                <td>{new Date(item?.actualDate).getDate()}/{new Date(item?.actualDate).getMonth()+1}/{new Date(item?.actualDate).getFullYear()}</td>
                                                <td>{item?.employer_id?.firstname} {item?.employer_id?.lastname}</td>
                                                <td>${item?.price?.fixed}</td>
                                            </tr>
                                            )
                                        })
                                    }
                                    {/* <tr>
                                        <td>01</td>
                                        <td>#000123</td>
                                        <td>01/01/023</td>
                                        <td>Sandip Pyne</td>
                                        <td>$500</td>
                                    </tr>
                                    <tr>
                                        <td>01</td>
                                        <td>#000123</td>
                                        <td>01/01/023</td>
                                        <td>Sandip Pyne</td>
                                        <td>$500</td>
                                    </tr>
                                    <tr>
                                        <td>01</td>
                                        <td>#000123</td>
                                        <td>01/01/023</td>
                                        <td>Sandip Pyne</td>
                                        <td>$500</td>
                                    </tr>
                                    <tr>
                                        <td>01</td>
                                        <td>#000123</td>
                                        <td>01/01/023</td>
                                        <td>Sandip Pyne</td>
                                        <td>$500</td>
                                    </tr>
                                    <tr>
                                        <td>01</td>
                                        <td>#000123</td>
                                        <td>01/01/023</td>
                                        <td>Sandip Pyne</td>
                                        <td>$500</td>
                                    </tr>
                                    <tr>
                                        <td>01</td>
                                        <td>#000123</td>
                                        <td>01/01/023</td>
                                        <td>Sandip Pyne</td>
                                        <td>$500</td>
                                    </tr> */}
                                </tbody>
                            </Table>
                    </div>
                    </div>
                    <div className="col-lg-8"></div>
                    <div className="col-lg-4">
                        <Card className="p-3 text-center">
                            <h4>Total Earnings : ${totalEarn} </h4>
                        </Card>
                    </div>
                </div>
                <div className="row mt-5">
					
                    <div className="col-md-6 mb3">
                        <Chart
                        chartType="Bar"
                        width="100%"
                        height="400px"
                        data={chartData?.weekData}
                        options={chartData?.Week}
                        />
                    </div>
                    <div className="col-md-6 mb3">
                        <Chart
                        chartType="Bar"
                        width="100%"
                        height="400px"
                        data={chartData?.yearData}
                        options={chartData?.Year}
                        />
                    </div>
                    <div className="col-md-12 mb3">
                        <Chart
                        chartType="Bar"
                        width="100%"
                        height="400px"
                        data={chartData?.monthData}
                        options={chartData?.month}
                        
                        />
                    </div>
				</div>
			</div>
		</section>
		</>
	);
};

export default Earnings;
