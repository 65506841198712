import React, { Component, useState, useEffect, useRef,useContext } from "react";
import GooglePlacesAutocomplete, { getLatLng, geocodeByAddress } from "react-google-places-autocomplete";
import { Button, Row, Col, Form, Image, Nav, Tab, Modal, Card } from 'react-bootstrap';
import profile from '../../../assets/images/pp1.jpg';
import profile1 from '../../../assets/images/noImage.png';
import profilepic from '../../../assets/images/profile-pic.svg';
import edit from '../../../assets/images/eva_edit.svg';
import { GET_USER_CATEGORY_LIST, GET_USER_SUB_CATEGORY_LIST } from '../service';
import { getLocation, handleShow, handleClose, handlePriceType } from "./helper";
// import { SUBMIT } from "./service";
import GalleryControl from "./subcomponents/GalleryControl";
import ProfileImageControl from "./subcomponents/ProfileImageControl";
import VideoControl from "./subcomponents/VideoControl";
import PrivateJobInviteModal from "./modals/PrivateJobinviteModal";
import SocialMediaShare from "./modals/SocialMediaShareModal";
// import CurrencyInput from 'react-currency-input';
import CurrencyInput from 'react-currency-input-field';
import TimePicker from 'react-time-picker';
import { useNavigate } from "react-router-dom"
import ReactSimpleImageSlider from 'react-simple-image-slider';
import avatar from '../../../assets/images/avatar.png';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { resetForm } from "./helper";
import { format_time_12 } from "../../../utils/helper";
import { createJob } from '../../../api/job';
import {Context} from '../../../components/Context'
import Autocomplete from "../../../components/Autocomplete";
// import Autocomplete from 'react-autocomplete'

const styles = {
   

    selectButton: {
        width: '100%',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 8px #ccc',
        color: '#777',
        border: 0,
        outline: 0,
    },
    inputFile: {
        position: 'absolute',
        visibility: 'hidden'
    },
    customFileControl: {
        borderRadius: 0,
        boxShadow: '0px 0px 8px #ccc',
    },
    gallery: {
        borderRadius: 0,
        boxShadow: '0px 0px 8px #ccc',
        width: '100%',
        height: '100%'
    },
    resolution: {
        width: '100%',
        height: 250,
        objectFit: 'cover',
    },
    resolutionSmall: {
        width: '50%',
        height: '50%'
    }
}

const PostJob = ({ user, displayModal, setFetchJobs,socket }) => {
    const autocompleteRef = useRef(null);
    const {getMyCreatedJob} = useContext(Context);


    const [images, setImages] = useState([]);
    const [showInviteModal, setShowInviteModal] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [subcategoryList, setSubCategoryList] = useState([]);
    const [categoryId, setCategoryId] = useState('');
    const [location, setLocation] = useState({});
    const [profileImage, setProfileImage] = useState("")
    const [gallery, setGallery] = useState([]);
    const [video, setVideo] = useState(null);
    const [priceType, setPriceType] = useState('fixed');
    const [isJobMoreThanOneDay, setIsJobMoreThanOneDay] = useState(false);
    const [jobType, setJobType] = useState('public');
    const [isSocialMediaShare, setSocialMediaShare] = useState(false);
    const [shareDetails, setShareDetails] = useState({});
    const [privateEmployeeInputs, setPrivateEmployeeInputs] = useState([]);
    // const [privateEmployeeEmails, setPrivateEmployeeEmails] = useState([]);
    const [successInvite, setSuccessInvite] = useState([]);
    const [successInviteFullData, setSuccessInviteFullData] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [fixedPrice, setFixedPrice] = useState("");
    const [minPrice, setMinPrice] = useState("");
    const [maxPrice, setMaxPrice] = useState("");
    // const [value, onChange] = useState([new Date(), new Date()]);
    const [subCategoryListLable,setSubCategoryListLable] = useState([]);
    

    // console.log(autocompleteRef.current,"kkkkkkkkkkkkkkkkkkkk");

    useEffect(() => {
       
        const autocomplete = new window.google.maps.places.Autocomplete(
            autocompleteRef.current,
            { types: ['geocode'] }
        );
     
        autocomplete.addListener('place_changed', () => {
            var place = autocomplete.getPlace();
            var address = place.formatted_address;
            var latitude = place.geometry.location.lat();
            var longitude = place.geometry.location.lng();
            var latlng = new window.google.maps.LatLng(latitude, longitude);
            var geocoder = geocoder = new window.google.maps.Geocoder();


            geocoder.geocode({ 'latLng': latlng }, function (results, status) {
                if (status == window.google.maps.GeocoderStatus.OK) {
                    if (results[0]) {
                        console.log(results[0])
                        var address = results[0]?.formatted_address || '';
                        // var zipcode = results[0]?.address_components[results[0]?.address_components.length - 1]?.short_name || '';
                        var zipcode = '';
                        results[0]?.address_components?.forEach(ele=>{
                            if(ele?.types?.includes("postal_code")){
                                // zipcode = ele.long_name || '';
                                zipcode = ele.long_name || '';
                            }
                            if(ele?.types?.includes("postal_code_suffix")){
                                zipcode = zipcode+'-'+ele.long_name || '';
                            }
                           
                         })
                        // var country = results[0]?.address_components[results[0]?.address_components.length - 2]?.long_name || '';
                        var country = '';
                         results[0]?.address_components?.forEach(ele=>{
                            if(ele?.types?.includes("country") && ele?.types?.includes("political")){
                                country = ele.long_name || '';
                                return;
                            }
                           
                         })
                        // var state = results[0]?.address_components[results[0]?.address_components.length - 3]?.short_name || '';
                        var state = ''
                        results[0]?.address_components?.forEach(ele=>{
                            if(ele?.types?.includes("administrative_area_level_1") && ele?.types?.includes("political")){
                                state = ele.long_name || '';
                                return;
                            }
                         })
                        // var city = results[0]?.address_components[results[0]?.address_components.length - 5]?.long_name || '' ;
                        var city = '';
                        results[0]?.address_components?.forEach(ele=>{
                            if(ele?.types?.includes("locality") && ele?.types?.includes("political")){
                                city = ele.long_name || '';
                                return;
                            }
                         })
                        document.querySelector('[name=address]').value = address;
                        document.querySelector('[name=city]').value = city;
                        document.querySelector('[name=state]').value = state;
                        document.querySelector('[name=country]').value = country;
                        document.querySelector('[name=zipcode]').value = zipcode;
                        document.querySelector('[name=lat]').value = latitude;
                        document.querySelector('[name=lng]').value = longitude;

                        const locationObj = {
                            address:address,
                            city:city,
                            state:state,
                            zipcode:zipcode,
                            latitude:latitude,
                            longitude:longitude
                        }
                        setLocation(locationObj)
                        
                    }
                }
            });
        });
    }, []);

    const [startTime, setStartTime] = useState(`${new Date().getHours().toString()}:${new Date().getMinutes().toString()}`);
    const [endTime, setEndTime] = useState(`${new Date().getHours().toString()}:${new Date().getMinutes().toString()}`);

    const navigate = useNavigate();

    useEffect(() => {
        GET_USER_CATEGORY_LIST(setCategoryList);
    }, [user]);

    const handleCategoryChange = async (e) => {
        const categoryId = e.target.value;
        setCategoryId(categoryId)
        const subcategories = await GET_USER_SUB_CATEGORY_LIST(categoryId);
        setSubCategoryList(subcategories);
    };

    const handelStartDateChange = (e) => {
        setStartDate(e.target.value)
    }

    // const handelCheckProfession = (e)=>{
    //     e.preventDefault();
    //     if(categoryList.length<1){
    //         displayModal('Error', "Please Update Your Profession!");
    //     }else{
    //         return;
    //     }
    // }

    // useEffect(()=>{
    //     console.log(gallery[1])
    // },[gallery])

    const convertToBase64 = async (file) => {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(file);
        })
    }
    const handleFileSelect = async (event) => {
        const base64Image = await convertToBase64(event.target.files[0]);
        let obj = {
            url: base64Image,
            caption: ''
        }
        if (gallery.length > 3) return displayModal('Error', 'Cannot Upload More than 4 Images!');
        setGallery(gallery => [...gallery, obj])
    };

    const today = new Date();
    const todayISO = today.toISOString();


    const handelSubmit = async ({
        e,
        document,
        displayModal,
        setFetchJobs,
        profileImage,
        setProfileImage,
        gallery,
        setGallery,
        video,
        setVideo,
        priceType,
        setPriceType,
        isJobMoreThanOneDay,
        setIsJobMoreThanOneDay,
        location,
        setLocation,
        isSocialMediaShare,
        setShowShareModal,
        setShareDetails,
        // privateEmployeeEmails,  
        successInvite,
        // fixed,
        fixedPrice,
        minPrice,
        maxPrice,  
        startTime,
        endTime,
        setFixedPrice,
        setPrivateEmployeeInputs,
        setSuccessInviteFullData,
        successInviteFullData,
        setSuccessInvite,
        socket,                                                  
    }) => {
        try {
            e.preventDefault();
         
          
            const form = new FormData(e.target);
            const publicJob = document.getElementById('public-job');
            const privateJob = document.getElementById('private-job');
    
            if (!publicJob.checked && !privateJob.checked) return displayModal('Error', 'Please Select Job Type!')
    
            let data = {};
    
            for (const [key, val] of form) {
                data[key] = val;
            }
    
            data.start_time = startTime 
            data.end_time = endTime
            data.profileImage = profileImage;
            data.gallery = gallery;
            data.video = video;
            
            data.price = {
                type: priceType
            }
            
            if (priceType == 'fixed') {
                data.price.fixed =fixedPrice;
             
            } else {
                data.price.min = minPrice;
                data.price.max = maxPrice;
          
            }

            if(subcategoryList.length){
                if(form.get('subcategory')===''){
                    return displayModal('Error', `Subcategory cann't be blank!`)
                }  
            }
            
            data.job_type = document.getElementById('public-job').checked ? 'public' : 'private';
            data.isJobMoreThanOneDay = isJobMoreThanOneDay;
            data.latitude = location.latitude;
            data.longitude = location.longitude;
    
            if (isJobMoreThanOneDay) {
                data.startDate = form.get('startDate');
                data.endDate = form.get('endDate');
            } else {
                data.date = form.get('date');
            }
    
            // private emplyee info
            if (data.job_type == 'private') {
                data.privateEmployees = successInvite;
            }
            const res = await createJob(data);
            getMyCreatedJob();
            const resData = await res.data.data;
            if(res.ok){
              await  socket.emit('getjoboffer',{
                    receiver:successInviteFullData,
                })

             for (let index = 0; index < successInviteFullData.length; index++) {
                if(successInviteFullData[index]._id===undefined){
                    continue;
                }
                await socket.emit('notification',{
                    title:'New Job Invitation!',
                    body:`You have 1 new job invitation "${form.get('title')}" from ${successInviteFullData[index].firstname} ${successInviteFullData[index].lastname} .`,
                    receiver:successInviteFullData[index]._id,
                })
             }
             
            }
            
            if (!res.ok) return displayModal('Error', res.data.message)
            if (!res.data.success) return displayModal('Error', res.data.message)
    
            if (isSocialMediaShare) {
                const shareDetails = {
                    url: resData.shareUrl,
                    quotes: resData.jobTitle
                };
                setShareDetails(shareDetails);
                setShowShareModal(true);
            } else {
                displayModal('Success', res.data.message)
            }
    
            setFetchJobs(true)
            resetForm({
                el: e.target,
                setProfileImage,
                setGallery,
                setVideo,
                setPriceType,
                document,
                setIsJobMoreThanOneDay,
                setLocation,
                fixedPrice,
                minPrice,
                maxPrice,  
                startTime,
                endTime,
                setFixedPrice,
                setSuccessInviteFullData,
                setPrivateEmployeeInputs,
                setSuccessInvite,
                socket,
                successInviteFullData,
                profile1,
                setStartTime,
                setEndTime
            });

          
      
        } catch (err) {
            console.error(err);
        }
    
    }







    return (

        <>
            <PrivateJobInviteModal
                show={showInviteModal}
                handleClose={() => handleClose(setShowInviteModal)}
                privateEmployeeInputs={privateEmployeeInputs}
                setPrivateEmployeeInputs={setPrivateEmployeeInputs}
                setSuccessInvite={setSuccessInvite}
                successInvite={successInvite}
                successInviteFullData={successInviteFullData}
                setSuccessInviteFullData={setSuccessInviteFullData}
            />
            <SocialMediaShare
                show={showShareModal}
                handleClose={() => handleClose(setShowShareModal)}
                url={shareDetails.url}
                quotes={shareDetails.quotes}
                hashtag={'Uverlist'}
            />
            <section className="userinner">
                <Row>
                    <Col md={12}>
                        <h3 className='user-listingpage-title'>
                            Hello, {user.firstname}<br /><small>Current page -  Post Job</small>
                        </h3>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Form
                            className='up-account'
                            id='post-job-form'
                            onSubmit={e => handelSubmit({
                                e,
                                document,
                                displayModal,
                                setFetchJobs,
                                profileImage,
                                setProfileImage,
                                gallery,
                                setGallery,
                                video,
                                setVideo,
                                priceType,
                                setPriceType,
                                isJobMoreThanOneDay,
                                setIsJobMoreThanOneDay,
                                location,
                                setLocation,
                                isSocialMediaShare,
                                setShowShareModal,
                                setShareDetails,
                                successInvite,
                                fixedPrice,
                                minPrice,
                                maxPrice,
                                startTime,
                                endTime,
                                setFixedPrice,
                                setPrivateEmployeeInputs,
                                setSuccessInviteFullData,
                                successInviteFullData,
                                setSuccessInvite,
                                socket,
                              
                            })}
                        >
                            <Row>
                                <Col md={12} >
                                    <Row>
                                        <Col md={4}>

                                        </Col>
                                    </Row>
                                    <div className="flex-row space-evenly align-center pgv-list flxdrection-clom">
                                        <div className="flex-column center gap-10 common-col">
                                            <Form.Label className="label">Profile Image</Form.Label>

                                            <ProfileImageControl
                                                styles={styles}
                                                displayModal={displayModal}
                                                profileImage={profileImage}
                                                setProfileImage={setProfileImage}
                                       
                                            />
                                        </div>

                                        <div className="flex-column center gap-10 common-col mr-32">
                                            <Form.Label className="label">Gallery</Form.Label>
                                            <input type="file"  accept="image/png, image/gif, image/jpeg"  multiple onChange={handleFileSelect} className="form-control" id='gallery-image' />
                                            <div className="gldiv">
                                                {gallery.length > 0 && (
                                                    <ReactSimpleImageSlider
                                                        images={gallery}
                                                        width="250px"
                                                        showBullets={true}
                                                        height="250px"
                                                        showNavs={true}
                                                        autoPlay={false}
                                                        transitionTime={1000}
                                                    />
                                                )}
                                            </div>


                                            <label for="gallery-image" className="for-multiimage" style={{ ...styles.selectButton, height: 37, }} >select images &uarr;</label>
                                            {/* <GalleryControl
                                                styles={styles}
                                                displayModal={displayModal}
                                                gallery={gallery}
                                                setGallery={setGallery}
                                            /> */}
                                        </div>


                                        <div className="flex-column center gap-10 common-col">
                                            <Form.Label className="label">Video</Form.Label>

                                            <VideoControl
                                                styles={styles}
                                                displayModal={displayModal}
                                                video={video}
                                                setVideo={setVideo}
                                                
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group md={12} controlId="formGridEmail">
                                    <Form.Control className='f-inp' type="tetx" placeholder="Job Title" name='title' />
                                </Form.Group>
                            </Row>
                            <>
                                <Row className="mb-3">
                                    <Form.Group md={12} controlId="formGridEmail">
                                        <select name='category' onChange={e => handleCategoryChange(e)} className='f-inp' style={{ width: '100%', padding: 5, color: '#7b7ea4' }}>
                                            <option value=''>select category</option>
                                            {
                                                (categoryList && categoryList.length > 0)
                                                &&
                                                categoryList.map(cat =>
                                                    <option key={cat._id} value={cat._id}>{cat.name}</option>
                                                )
                                            }
                                        </select>
                                    </Form.Group>
                                </Row>
                                {
                                    (subcategoryList && subcategoryList.length > 0)
                                    &&
                                    // <Row className="mb-3">
                                    //     <Form.Group md={12} controlId="formGridEmail">
                                    //         <select name='subcategory' className='f-inp' style={{ width: '100%', padding: 5, color: '#7b7ea4' }}>
                                    //             <option value=''>select sub category</option>

                                    //             {
                                    //                 subcategoryList.map(subcat =>
                                    //                     <option key={subcat._id} value={subcat._id}>{subcat.name}</option>
                                    //                 )
                                    //             }
                                    //         </select>
                                    //     </Form.Group>
                                    // </Row>
                                //     <Autocomplete
                                //     getItemValue={(item) => item.label}
                                //     items={subcategoryList}
                                //     renderItem={(item, isHighlighted) =>
                                //       <div style={{ background: isHighlighted ? 'lightgray' : 'black',color:"#fff" ,zIndex:'100' }}>
                                //         {item.label}
                                //       </div>
                                //     }
                                //     // value={value}
                                //     // onChange={(e) => value = e.target.value}
                                //     // onSelect={(val) => value = val}
                                //   />
                                <Autocomplete
                                options={subcategoryList}
                                />
                                }
                            </>
                            <Row>
                                <Form.Group md={12} controlId="formGridEmail" className='for-fr-check'>
                                    {['radio'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3 flex-row space-between">
                                            <Form.Check
                                                inline
                                                 label="Public Job"
                                                name="group1"
                                                type={type}
                                                id={`public-job`}
                                                defaultChecked={true}
                                                onChange={e => setJobType(e.target.checked ? 'public' : 'private')}
                                            />
                                            {jobType == 'public'
                                                &&
                                                <Form.Check
                                                    inline
                                                    label="share on social media"
                                                    name="social-media-share"
                                                    type={'checkbox'}
                                                    id={`social-media-share`}
                                                    onChange={e => setSocialMediaShare(e.target.checked)}
                                                />
                                            }
                                            <Form.Check
                                                inline
                                                label="Private Job"
                                                name="group1"
                                                type={type}
                                                id={`private-job`}
                                                onClick={e => handleShow({ e, setShowInviteModal, setJobType, setSocialMediaShare })}
                                            />
                                        </div>
                                    ))}
                                </Form.Group>
                            </Row>
                            <Row>
                                {
                                    successInviteFullData.length > 0
                                    &&
                                    successInviteFullData.map((item, index) => {
                                        return (
                                            <Col md={6}>
                                                <Card className="radius-12 frd-list serflist mb-3">
                                                    <Card.Body>
                                                        <div className='left-pic'>
                                                            <Image src={item?.profileimage ? item?.profileimage : profile1} alt="" />
                                                        </div>
                                                        <div className='right-text'>
                                                            <h4>
                                                                {item?.firstname} {item?.lastname}
                                                            </h4>
                                                            <p>
                                                                {item?.email}
                                                            </p>
                                                            <p>
                                                                {item?.phone_number}
                                                            </p>
                                                        </div>
                                                        {/* <Button variant="primary" className='btn-green' type="submit" disabled>
                                                            Send
                                                        </Button> */}
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        )
                                    })
                                }

                                {/* <Col md={6}>
                            <Card className="radius-12 frd-list serflist mb-3">
                                <Card.Body>
                                    <div className='left-pic foremail'>
                                        sh
                                    </div>
                                    <div className='right-text'>
                                        <p>
                                            shrabochakraborty@gmail.com
                                        </p>
                                        
                                    </div>
                                    <Button variant="primary" className='btn-green' type="submit" disabled>
                                        Send
                                    </Button>
                                </Card.Body>
                            </Card>
                                </Col>
                                <Col md={6}>
                            <Card className="radius-12 frd-list serflist mb-3">
                                <Card.Body>
                                    <div className='left-pic'>
                                        <Image src={profile} alt="" />
                                    </div>
                                    <div className='right-text'>
                                        <h4>
                                            Shrabo Chakraborty
                                        </h4>
                                        <p>
                                            shrabochakraborty@gmail.com
                                        </p>
                                        <p>
                                            +91 8675589895
                                        </p>
                                    </div>
                                    <Button variant="primary" className='btn-green' type="submit">
                                        Invite
                                    </Button>
                                </Card.Body>
                            </Card>
                                </Col> */}
                            </Row>
                            <Row className="mb-3">
                                <Form.Group md={12} controlId="formGridEmail">
                                    <Form.Control as="textarea" rows={4} placeholder="Description" name='description' />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group md={12} className="mb-3" controlId="formGridAddress1">
                                    <Form.Label className='min-leb'>Select Location From Map</Form.Label>
                                    {/* <GooglePlacesAutocomplete className="form-control"
                                        selectProps={{
                                            location,
                                            onChange: (address) => getLocation(address, document, setLocation),
                                        }}
                                    /> */}
                                    <Form.Control placeholder="Address" className='f-inp' ref={autocompleteRef} />
                                </Form.Group>
                                <Form.Group md={12} className="mb-3" controlId="formGridAddress1">
                                    <Form.Control placeholder="Address" className='f-inp' name='address' />
                                </Form.Group>
                                <Form.Group className="mb-3 col-md-3" controlId="formGridAddress1">
                                    <Form.Control placeholder="City" className='f-inp' name='city' />
                                </Form.Group>
                                <Form.Group className="mb-3 col-md-3" controlId="formGridAddress1">
                                    <Form.Control placeholder="State" className='f-inp' name='state' />
                                </Form.Group>
                                <Form.Group className="mb-3 col-md-3" controlId="formGridAddress1">
                                    <Form.Control placeholder="Zipcode" className='f-inp' name='zipcode' />
                                </Form.Group>
                                <Form.Group className="mb-3 col-md-3" controlId="formGridAddress1">
                                    <Form.Control placeholder="Country" className='f-inp ' name='country' />
                                </Form.Group>
                                <Form.Control type="hidden" placeholder="Lat" className='f-inp ' name='lat' />
                                <Form.Control type="hidden" placeholder="Lng" className='f-inp ' name='lng' />
                            </Row>
                            <Row className="mb-3">
                                <Col md={12}>
                                    <Form.Group controlId="formGridAddress2">
                                        <Form.Label className='min-leb'>Pricing</Form.Label>
                                        <div className="flex-row space-between w-50 d-none">
                                            <div>
                                                <Form.Check type="radio" id='fixed' name='priceType' defaultChecked={true} onChange={e => handlePriceType(e, document, setPriceType)} /> Fixed
                                            </div>
                                            {/* <div>
                                                <Form.Check type="radio" id='range' name='priceType' onChange={e => handlePriceType(e, document, setPriceType)} /> Range
                                            </div> */}
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={12}>
                                    {
                                        priceType == 'fixed'
                                            ?
                                            <Form.Group controlId="formGridAddress2">
                                                <CurrencyInput
                                                    className='f-inp form-control'
                                                    id="fixedPrice"
                                                    name="fixed"
                                                    placeholder="$0.00"
                                                    prefix={"$"}
                                                    value={fixedPrice}
                                                    // defaultValue={fixedPrice}
                                                    decimalSeparator="."
                                                    decimalsLimit={2}
                                                    decimalScale={2}
                                                    onValueChange={(value) => setFixedPrice(value)}
                                                />

                                            </Form.Group>
                                            :
                                            <Form.Group controlId="formGridAddress2">
                                                <div className="flex-row space-between gap-20">
                                                    <CurrencyInput
                                                        className='f-inp form-control'
                                                        id="minPrice"
                                                        name="min"
                                                        placeholder="$0.00"
                                                        prefix={"$"}
                                                        decimalSeparator="."
                                                        decimalsLimit={2}
                                                        decimalScale={2}
                                                        onValueChange={(value) => setMinPrice(value)}
                                                    />
                                                    <CurrencyInput
                                                        className='f-inp form-control'
                                                        id="maxPrice"
                                                        name="max"
                                                        placeholder="$0.00"
                                                        prefix={"$"}
                                                        decimalSeparator="."
                                                        decimalsLimit={2}
                                                        decimalScale={2}
                                                        onValueChange={(value) => setMaxPrice(value)}
                                                    />
                                                </div>
                                            </Form.Group>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Form.Group md={12} controlId="formGridAddress1" className='for-fr-check'>
                                    {['checkbox'].map((type) => (
                                        <div key={`job-${type}`} className="mb-3">
                                            <Form.Check
                                                inline
                                                label="Click if job is more than one day"
                                                name="job"
                                                type={type}
                                                id={`isJobMoreThanOneDay`}
                                                onChange={e => setIsJobMoreThanOneDay(e.target.checked)}
                                            />
                                        </div>
                                    ))}
                                </Form.Group>
                            </Row>
                            <Row>
                                {
                                    isJobMoreThanOneDay
                                        ?
                                        <Form.Group md={12} className="mb-3" controlId="formGridAddress1">
                                            <div className="flex-row w-100 gap-20">
                                                <div style={{ width: '50%' }}>
                                                    <Form.Label className='min-leb'>Start Date</Form.Label>
                                                    {/* <Form.Control type="date" defaultValue={todayISO} min={todayISO} className='f-inp' name='startDate' onChange={handelStartDateChange} /> */}
                                                    <Form.Control type="date" defaultValue={todayISO} min={new Date().toISOString().split("T")[0]}   className='f-inp' name='startDate' onChange={handelStartDateChange} />
                                                </div>
                                                <div style={{ width: '50%' }}>
                                                    <Form.Label className='min-leb'>End Date</Form.Label>
                                                    {/* <Form.Control defaultValue={todayISO} min={todayISO} type="date" className='f-inp' name='endDate' /> */}
                                                    <Form.Control defaultValue={todayISO} min={startDate}  type="date" className='f-inp' name='endDate' />
                                                </div>

                                            </div>
                                        </Form.Group>
                                        :
                                        <Form.Group md={12} className="mb-3" controlId="formGridAddress1">
                                            <Form.Label className='min-leb'>Date</Form.Label>
                                            <Form.Control type="date" defaultValue={todayISO} min={new Date().toISOString().split("T")[0]} className='f-inp' name='date'  />
                                            {/* <Form.Control type="date" defaultValue={todayISO} min={todayISO} className='f-inp' name='date'  /> */}
                                            {/* <DateTimeRangePicker onChange={alert(value)} value={value} minDate={new Date()}/> */}

                                        </Form.Group>
                                }
                                {/* <Form.Group md={12} controlId="formGridAddress1">
                                    
                                </Form.Group> */}
                                {/* <Form.Group className="mb-3 col-1 text-center" controlId="formGridAddress1">
                                    <Form.Control type='text' placeholder="hh" maxLength={2} className='f-inp' name='start_time_hour' required />
                                </Form.Group>
                                <Form.Group className="mb-3 col-1 text-center" controlId="formGridAddress12">
                                    <Form.Control type='text' placeholder="mm" maxLength={2} className='f-inp' name='start_time_min' required />
                                </Form.Group> */}
                                <Col md={12}>
                                    <Row className="d-flex align-items-center justify-content-center">
                                        <Form.Group className="mb-5 col-5" controlId="formGridAddress1">
                                            {/* <Form.Select defaultValue="AM" className='f-inp' name='start_time_meridian'>
                                        <option>PM</option>
                                        <option>AM</option>
                                    </Form.Select> */}
                                            <Form.Label> Start Time</Form.Label>
                                            <TimePicker onChange={setStartTime} className='f-inp form-control' value={startTime} name="start_time" disableClock={true}  />
                                        </Form.Group>

                                        <Col md={2} className="text-center">
                                            <strong className='fr-to text-center'>To</strong>
                                        </Col>
                                        {/* <Form.Group className="mb-3 col-lg-2 col-md-12 text-center" controlId="formGridAddress1">
                                    
                                </Form.Group>
                                <Form.Group className="mb-3 col-1 text-center" controlId="formGridAddress1">
                                    <Form.Control type='text' placeholder="hh" maxLength={2} className='f-inp' name='end_time_hour' required />
                                </Form.Group> */}
                                        <Form.Group className="mb-5 col-5" controlId="formGridAddress1">
                                            {/* <Form.Control type='text' placeholder="mm" maxLength={2} className='f-inp' name='end_time_min' required />
                                </Form.Group>
                                <Form.Group className="mb-3 col-2 text-center" controlId="formGridAddress1">
                                    <Form.Select defaultValue="PM" className='f-inp' name='end_time_meridian'>
                                        <option>PM</option>
                                        <option>AM</option>
                                    </Form.Select> */}
                                            <Form.Label> End Time</Form.Label>
                                            <TimePicker onChange={setEndTime} className='f-inp form-control' value={endTime} name="end_time" disableClock={true}  />
                                        </Form.Group>
                                    </Row>
                                </Col>
                            </Row>
                            {/* <Row>
                                <Form.Group md={12} className="mb-3" controlId="formGridAddress1">
                                    <Form.Label className='min-leb'>Card Details</Form.Label>
                                    <Form.Control placeholder="Card no." className='f-inp' />
                                </Form.Group>
                                <Form.Group className="mb-3 col-lg-4 col-md-12" controlId="formGridAddress1">
                                    <Form.Select defaultValue="Choose..." className='f-inp'>
                                        <option>Exp Month</option>
                                        <option>...</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3 col-lg-4 col-md-12" controlId="formGridAddress1">
                                    <Form.Select defaultValue="Choose..." className='f-inp'>
                                        <option>Exp Year</option>
                                        <option>...</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3 col-lg-4 col-md-12" controlId="formGridAddress1">
                                    <Form.Control placeholder="Cvvv Code" className='f-inp' />
                                </Form.Group>
                                <Form.Group className="mb-3 col-md-12" controlId="formGridAddress1">
                                    <Form.Control placeholder="Name" className='f-inp' />
                                </Form.Group>
                            </Row> */}
                           
                            <Button variant="primary" type="submit" className='btn-green'>
                                Create Job
                            </Button>
                            <Row className="mb-1">
                                <Col lg={12}>
                                    <p><strong className="text-warning">**12% service charge applies to every post/job</strong></p>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </section>
        </>
    );
};

export default PostJob;