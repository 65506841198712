import { chainOperator, delay } from "../../../utils/helper";
import noImage from '../../../assets/images/noImage.png';
const processMessages = (messages, user) => {
    if (!user) return [];
    if (!messages) return [];
    if (!messages.length) return [];

    return messages.map(msg => ({
        ...msg,
        self: chainOperator(msg, ['sender', '_id'], '') === user._id
    }))
};

const checkOnline = (remoteUser) => {
    const activeClassName = 'pro-pic active', inActiveClassName = 'pro-pic';
    if (!remoteUser) return inActiveClassName;
    if (!remoteUser) return inActiveClassName;
    if (!remoteUser.isOnline) return inActiveClassName;
    return activeClassName;
};

const getRemoteUserData = (chat, attr, user) => {
    if (!user) return;
    if (!chat) return;
    if (!chat.remoteUser) return;
    if (!chat.remoteUser._id) return;

    const remoteUser = chat.remoteUser._id !== user._id ? chat.remoteUser : chat.user;
    
    switch (attr) {
        case 'id': {
            return remoteUser._id;
        };
        case 'profileImage': {
            return remoteUser?.profileimage?remoteUser?.profileimage:noImage;
        };
        case 'fullname': {
            return `${remoteUser?.firstname} ${remoteUser?.lastname}`;
        };
        case 'firstname': {
            return `${remoteUser?.firstname}`;
        };
        case 'status': {
            return checkOnline(remoteUser);
        };
        case 'status-text': {
            return remoteUser?.isOnline ? 'online' : 'offline';
        };
        default: {
            return remoteUser;
        }
    };
};



export {
    processMessages,
    getRemoteUserData,
};