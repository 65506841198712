
import { Button, Row, Col, Form, Image, Nav, Tab, Dropdown, DropdownButton, Card, Modal } from 'react-bootstrap';
import invt from '../../../../assets/images/invt.svg';
import avatar from '../../../../assets/images/noImage.png';
import profile from '../../../../assets/images/pp1.jpg';
import {  newAllUsers } from '../../../../api/friend';
import sendmail from '../../../../assets/images/send-mail.png';
import { useEffect, useState } from 'react';


const PrivateJobInviteModal = ({ show, handleClose, privateEmployeeInputs, setPrivateEmployeeInputs, setSuccessInvite,successInvite,successInviteFullData,setSuccessInviteFullData,setPrivateEmployeeIds }) => {
    const [newEmail,setNewEmail]= useState([]);
    const [user,setUser]=useState([]);
    const [tempUser,setTempUser] = useState([]);
    const [addInvite, setAddInvite] = useState(false);
    const [inviteId,setInviteId] = useState({});


    const handleSubmit = e => {
        e.preventDefault();
        const form = document.getElementById('get-email');
        const formData = new FormData(form)
        const obj = {
            firstname:'',
            lastname:'',
            email:Object.fromEntries(formData.entries()).email,
            phone_number:''
        }
        let isExist = user.some(el => el.email === obj.email)
        if(!isExist){
            setUser([...user,obj])
            setPrivateEmployeeInputs([...privateEmployeeInputs,obj])
        }
    }

    const userFun = async ()=>{
        const res = await newAllUsers();
        setUser(res.data.data)
        setTempUser(res.data.data)
    } 

    useEffect(()=>{
        userFun();
    },[])

    const userList = (value) => user.filter(item=>item.email?.match(new RegExp(value, "gi")) || item.phone_number?.replace(/\D/g, '')?.match(new RegExp(value, "gi")));
        // if(user.filter(item=>item.email?.match(new RegExp(value, "gi"))).length>0){
        //    return user.filter(item=>item.email?.match(new RegExp(value, "gi")));
        // }else{
        //    return user.filter(item=>item.phone_number?.replace(/\D/g, '')?.match(new RegExp(value, "gi")));
        // }
        
    


    const handelChange = e =>{
        e.preventDefault();
        if(e.target.value==''){
            setPrivateEmployeeInputs([])
        }else{
            const res = userList(e.target.value);

          setPrivateEmployeeInputs([...newEmail,...res]);
        
        } 
    }

 
    const handelSend = (e,id,email,item) =>{
        e.preventDefault();
        setInviteId({id,email})
        if(!tempUser.some(ele => ele.email === email)){
            setAddInvite(true)
        }else{
            setSuccessInviteFullData([...successInviteFullData,item])
            setSuccessInvite([...successInvite,email])
            setInviteId({})
        }
        
    }

    const handelInvite = (e)=>{
        e.preventDefault();
        setSuccessInviteFullData([...successInviteFullData,{email:inviteId.email,profileimage:avatar}])
        setSuccessInvite([...successInvite,inviteId.email])
        setInviteId({})
        setAddInvite(false)
    }

    const handelInviteClose = (e)=>{
        e.preventDefault();
        setInviteId({})
        setAddInvite(false)
    }



    return (
        <>
          <Modal show={addInvite} onHide={handleClose}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Send Friend Requests</Modal.Title>
        </Modal.Header> */}
        <Modal.Body className='p-5'>

            <Row>
                <Col md={12} className="text-center">
                        <Image src={sendmail} className="send-mail-img-size" />
                <h3 className='text-center p-3'>
                    This user is not currently in Uverlist, would you like to send them an invite?
                    </h3>
                </Col>
                <Col md={12} className="text-center">
                    <Button className='btn-outline-green btn btn-primary m-2' onClick={e=>handelInviteClose(e)}>
                        Close
                    </Button>
                    <Button className='btn-green btn btn-primary m-2' onClick={handelInvite}>
                        Invite
                    </Button>
                </Col>
            </Row>
            
            
        </Modal.Body>
        
          </Modal>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Private Job Invitation</Modal.Title>
            </Modal.Header>
            <Modal.Body className='text-center p-5'>
                <Image src={invt} />
                <Form className='text-center search-flist' id={'get-email'} onSubmit={e=>handleSubmit(e)}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control type="email" className='f-inp' name={`email`} placeholder="Search..." required onChange={handelChange} />
        
                    </Form.Group>
                    {/* {
                        privateEmployeeInputs.length > 0
                        &&
                        privateEmployeeInputs.map((input, i) => (
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control type="email" className='f-inp' name={`employeeEmail-${i}`} placeholder="Enter User Email Id / Phone No." required />
                            </Form.Group>
                        ))
                    } */}
                    {
                        privateEmployeeInputs.length>0
                        ?
                        <Button className='mb-3 add-btn-circle disabled' type='submit' disabled={true}>
                        + 
                        </Button>
                        :
                        <Button className='mb-3 add-btn-circle ' type='submit'>
                        + 
                        </Button>
                    } 
                  
                  
                     {/* <Button variant="primary" className='btn-green' type="submit">
                        Invite
                    </Button> */}
                </Form>
                    <Col md={12} className="mb-3" >
                            {/* <Card className="radius-12 frd-list serflist mb-3">
                                <Card.Body>
                                    <div className='left-pic'>
                                        <Image src={profile} alt="" />
                                    </div>
                                    <div className='right-text'>
                                        <h4>
                                            Shrabo Chakraborty
                                        </h4>
                                        <p>
                                            shrabochakraborty@gmail.com
                                        </p>
                                        <p>
                                            +91 8675589895
                                        </p>
                                    </div>
                                    <Button variant="primary" className='btn-green' type="submit" disabled>
                                        Send
                                    </Button>
                                </Card.Body>
                            </Card>
                            <Card className="radius-12 frd-list serflist mb-3">
                                <Card.Body>
                                    <div className='left-pic foremail'>
                                        sh
                                    </div>
                                    <div className='right-text'>
                                        <p>
                                            shrabochakraborty@gmail.com
                                        </p>
                                        
                                    </div>
                                    <Button variant="primary" className='btn-green' type="submit" disabled>
                                        Send
                                    </Button>
                                </Card.Body>
                            </Card>
                            <Card className="radius-12 frd-list serflist mb-3">
                                <Card.Body>
                                    <div className='left-pic'>
                                        <Image src={profile} alt="" />
                                    </div>
                                    <div className='right-text'>
                                        <h4>
                                            Shrabo Chakraborty
                                        </h4>
                                        <p>
                                            shrabochakraborty@gmail.com
                                        </p>
                                        <p>
                                            +91 8675589895
                                        </p>
                                    </div>
                                    <Button variant="primary" className='btn-green' type="submit">
                                        Invite
                                    </Button>
                                </Card.Body>
                            </Card> */}
                            {
                                privateEmployeeInputs.length>0 &&
                                privateEmployeeInputs.map((item,index)=>{
                                    return (
                                        <Card className="radius-12 frd-list serflist mb-3" key={index}>
                                <Card.Body>
                                    <div className='left-pic'>
                                        <Image src={item?.profileimage?item?.profileimage:avatar} alt="" />
                                        {/* <Image src={avatar} alt="" /> */}
                                    </div>
                                    <div className='right-text'>
                                        <h4>
                                            {item?.firstname} {item?.lastname}
                                        </h4>
                                        <p>
                                            {item?.email}
                                        </p>
                                        <p>
                                            {item?.phone_number}
                                        </p>
                                    </div>
                                    <Button variant="primary" className='btn-green' type="submit" onClick={e=>handelSend(e,index,item.email,item)} disabled={successInvite.includes(item.email)}>
                                         {successInvite.includes(item.email)?'Send':'Invite'}
                                    </Button>
                                </Card.Body>
                            </Card>
                                    )
                                })
                            }
                            
                    </Col>
            </Modal.Body>

        </Modal >
        </>
    
    )
}

export default PrivateJobInviteModal;