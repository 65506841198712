import React, { Component, useState, useEffect } from "react";
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import AppliedList from "./AppliedList";
import ReceivedList from "./RecievedList";
import UnacceptedList from "./UnacceptedList";
import JobsyouOfferedto from "../profile/jobs-you-offered-to";
const JobApplications = ({ user, displayModal,socket }) => {
    return (
        <>
        
            <Row>
                <Col lg={12} className="mb-3">
                    <h2>
                        Jobs You Offered To
                    </h2>
                    <hr />
                </Col>
                <Col lg={12}>
                    <JobsyouOfferedto userId={user._id} displayModal={displayModal} socket={socket} />
                     {/* <Tabs
                        defaultActiveKey="applications-sent"
                        id="uncontrolled-tab-example"
                        className="prr-in-tab"
                        style={{
                            width: 500
                        }}
                    >
                        <Tab eventKey="applications-sent" title="Offered">
                            <AppliedList displayModal={displayModal} socket={socket} />
                        </Tab>
                        <Tab eventKey="applications-received" title="Accepted">
                            <ReceivedList displayModal={displayModal} socket={socket} />
                        </Tab>
                        <Tab eventKey="applications-approved" title="Disapproved">
                            
                            <UnacceptedList displayModal={displayModal} socket={socket} />
                        </Tab>
                    </Tabs>  */}
                </Col>
            </Row>
        </>
    );
};

export default JobApplications;