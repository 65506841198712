import React from "react";
import Header from "../Header";
import Footer from "../Footer";

const Layout = (props) => {
	return (
		<>
			<Header logo={props.logo} isLoggedIn={props.isLoggedIn} user={props.user} setIsUpdateUser={props.setIsUpdateUser} displayModal={props.displayModal} LOGOUT={props.LOGOUT} socket={props.socket} />
			{props.children}
			<Footer />
		</>
	);
};

export default Layout;
