import React, { useRef } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import idelete from '../../../../assets/images/delete.svg';
import { Button, Row, Col, Form, Image, Nav, Card, Modal } from 'react-bootstrap';

const getSubcategoryOptions = (subcategories) => {
    return subcategories.map((sub, i) => ({ name: sub.name, id: i, value: sub._id, }))
}



const Occupation = ({
    occupation,
    categoryList,
    handleCategoryChangeNew,
    handleSubCategoryChangeNew,
    removeOccupationSelector,
    addOccupationSelector,
    renderMultiDropDown,
    dropdownRef
}) => (

    <>
        {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}> */}
        <div className='row'>
            
            
            
        </div>
        {
            occupation.map((occ, index) => (
                <>
                    <br />
                    {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}> */}
                    <div className='row for-cat-subcat'>
                        <Form.Group className="mb-3 col"   controlId="Profession">
                        <label className="mb-3" style={{ fontWeight: 'bold', color: '#777' }}>Category</label>
                            <Form.Select name='category' onChange={e => handleCategoryChangeNew(e, index)}>
                                <option value=''>select</option>
                                {
                                    (categoryList && categoryList.length > 0)
                                    &&
                                    categoryList.map(cat =>
                                        <option key={cat._id} value={cat._id} selected={cat._id === occ.category}>{cat.name}</option>
                                    )
                                }
                            </Form.Select>
                        </Form.Group>

                        {
                            renderMultiDropDown
                                ?
                                (
                                    (occ.subcategoryList && occ.subcategoryList.length > 0)
                                    &&
                                    <Form.Group className="mb-3 col"  controlId="Profession">
<label className="mb-3" style={{ fontWeight: 'bold', color: '#777' }}>Subcategory</label>
                                        <div>
                                            <Multiselect
                                                options={getSubcategoryOptions(occ.subcategoryList)}
                                                selectedValues={getSubcategoryOptions(occ.subcategories)}
                                                onSelect={e => handleSubCategoryChangeNew(e, index)}
                                                onRemove={e => handleSubCategoryChangeNew(e, index)}
                                                displayValue="name"
                                                showCheckbox={true}
                                                ref={dropdownRef}
                                            />
                                        </div>
                                    </Form.Group>
                                )
                                :
                                (
                                    (occ.subcategoryList && occ.subcategoryList.length > 0)
                                    &&
                                    <Form.Group className="mb-3 col" md={3} controlId="Profession">
<label className="mb-3" style={{ fontWeight: 'bold', color: '#777' }}>Subcategory</label>
                                        <div >
                                            <Multiselect
                                                options={getSubcategoryOptions(occ.subcategoryList)}
                                                selectedValues={getSubcategoryOptions(occ.subcategories)}
                                                onSelect={e => handleSubCategoryChangeNew(e, index)}
                                                onRemove={e => handleSubCategoryChangeNew(e, index)}
                                                displayValue="name"
                                                showCheckbox={true}
                                                ref={dropdownRef}
                                            />
                                        </div>
                                    </Form.Group>
                                )
                        }


<Form.Group className="mb-3 col-1" >
<label className="mb-3 " style={{ fontWeight: 'bold', color: '#777', visibility: 'hidden' }}>Label</label>
<div className='delete-btn-square' onClick={e => removeOccupationSelector(index)}>
                          <Image src={idelete} />
                        </div>
</Form.Group>
                        
                    </div>
                </>
            ))
        }
        < br />
        <div onClick={addOccupationSelector} style={{ width: 50, height: 50 }}>
            <div className='add-btn-circle'>+</div>
        </div>
    </>
)

export default Occupation;