import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form, Image, Card } from 'react-bootstrap';
import './expertise.css'
import ep1 from '../../assets/images/pp1.jpg';
import call from '../../assets/images/phone-cal.svg';
import email from '../../assets/images/email.svg';
import chat from '../../assets/images/chat.png';
import star from '../../assets/images/star.png';
import { useNavigate, useParams } from "react-router-dom";
import { getExpertDetails, hireCandidate } from "../../api/expertise";
import { getExpectedCost } from "../../utils/helper";
import JobSelectionModal from "./JobSelectionModal";

const ExpertiseDetails = ({ user, displayModal }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [expert, setExpert] = useState({});
    const [showModal, setShowModal] = useState(false);

    const GET_EXPERT_DETAILS = async () => {
        try {
            const res = await getExpertDetails(id);
            if (!res.ok) return;
            setExpert(res.data.data);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        GET_EXPERT_DETAILS();
    }, [id]);

    const getSubCategories = (subcategories) => {
        if (!subcategories) return '';
        if (!subcategories.length) return '';
        return `(${subcategories.map(subcat => subcat.name).join(', ')})`;
    }

    const getProfession = () => {
        if (!expert.occupation) return '';
        if (!expert.occupation.length) return '';

        return expert.occupation.map((occ, index) => (
            <>
                <p>{occ.category ? occ.category.name : ''} {getSubCategories(occ.subcategories)} {index == expert.occupation.length - 1 ? '' : ', '}</p>
            </>
        ))
    };

    const handleChatNow = (e) => {
        e.preventDefault();
        if (expert._id == user._id) return;

        localStorage.setItem('isRedirectChatNow', true);
        localStorage.setItem('recipientId', expert._id);

        if (!localStorage.getItem('uverlist-user-token')) return navigate('/signin');
        navigate('/user');
        return;
    };

    const handleHireNow = (e) => {
        e.preventDefault();
        if (expert._id == user._id) return;

        localStorage.setItem('isRedirectExpertise', true);
        localStorage.setItem('expertId', expert._id);
        
        if (!localStorage.getItem('uverlist-user-token')) return navigate('/signin');
        
        setShowModal(true);
        return;
    };

    const HIRE = async (jobId) => {
        try {
            setShowModal(false);
            const data = {
                job: jobId,
                candidate: expert._id
            };
            const res = await hireCandidate(data);
            if (!res.ok) return displayModal('Error', res.data.message);
            return displayModal('Success', res.data.message);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <>
            <JobSelectionModal 
                show={showModal}
                setShow={setShowModal}
                expertId={expert._id}
                HIRE={HIRE}
            />
            <section className="job-banner">
                <div className="container">
                    <Row>
                        <Col md={12} className="text-center">
                            <h1>
                                Expertise Details
                            </h1>
                            {/* <p>
                                You will achieve the level of safety excellence that you demonstrate you want 
                            </p> */}
                        </Col>
                    </Row>
                </div>
            </section>
            <section className="joblispage-top py-5">
                <div className="container">
                    <Row>
                        <Col md={4} lg={3} className="text-center">
                            <div className="exp-details">
                                <div className="ex-pic">
                                    <Image src={expert?.profileimage} alt="" />
                                </div>
                                <div className="ex-cont">
                                    <h3>{expert.firstname} <br /> <small><Image src={star} alt="user" /> 4.5 - 39 view</small></h3>
                                    {
                                        user._id !== expert._id
                                        &&
                                        <>
                                            <Button className="btn-outline-green mb-3 w-100" onClick={handleChatNow}>
                                                <Image src={chat} alt="" /> Chat
                                            </Button>
                                            <Button className="btn-green mb-3 w-100" onClick={handleHireNow}>
                                                Hire Now
                                            </Button>
                                        </>
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col md={8} lg={9} className="ps-lg-5 ps-md-5 ">
                            <div className="exptiselist">
                                <div className="price-det">
                                    {/* $120.00 / Day */}
                                    {getExpectedCost(expert.expectedCost)}
                                </div>
                                <p>
                                    <strong>
                                        Full Name
                                    </strong>
                                    <span>
                                        {expert.firstname} {expert.lastname}
                                    </span>
                                </p>
                                <p>
                                    <strong>
                                        Email Id
                                    </strong>
                                    <span>
                                        {expert.email}
                                    </span>
                                </p>
                                <p>
                                    <strong>
                                        Phone No
                                    </strong>
                                    <span>
                                        {expert.phone_number}
                                    </span>
                                </p>
                                <p>
                                    <strong>
                                        Status
                                    </strong>
                                    {
                                        expert.is_active
                                            ?
                                            <span className="bg-green bg-vailable">
                                                Available
                                            </span>
                                            :
                                            <span className="bg-nt-vailable">
                                                Not Available
                                            </span>
                                    }
                                </p>
                                {
                                    expert.gender
                                    &&
                                    <p>
                                        <strong>
                                            Gender
                                        </strong>
                                        <span>
                                            {expert.gender}
                                        </span>
                                    </p>
                                }
                                <p>
                                    <strong>
                                        Address
                                    </strong>
                                    <span>
                                        {expert.address}
                                    </span>
                                </p>
                                <div className="profession">
                                    <div>
                                        <strong>
                                            Profession
                                        </strong>
                                    </div>
                                    <div>
                                        {/* Designer (UI/UX Designer) */}
                                        {getProfession()}
                                    </div>
                                </div>
                            </div>


                        </Col>
                    </Row>
                </div>
            </section>
        </>
    )
}
export default ExpertiseDetails;