import React, { Component, useState, useEffect } from "react";
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import AcceptedJobOffers from "./AcceptedJobOffers";
import ReceivedJobOffers from "./ReceivedJobOffers";
import SentJobOffers from "./SentJobOffers";
import JobsYouApplied from "../profile/jobs-you-applied-to";
const JobOffers = ({ user, displayModal,socket }) => {

    return (
        
        <>
            <Row>
                <Col lg={12} className="mb-3">
                    <h2>
                    Jobs You Applied To
                    </h2>
                    <hr />
                </Col>
                <Col lg={12}>
                <JobsYouApplied displayModal={displayModal} socket={socket} />
                    {/* <Tabs
                        defaultActiveKey="offers-sent"
                        id="uncontrolled-tab-example"
                        className="prr-in-tab"
                        style={{
                            width: 500
                        }}
                    >
                        <Tab eventKey="offers-sent" title="Applied">
                            <SentJobOffers displayModal={displayModal} socket={socket} />
                        </Tab>
                        
                        <Tab eventKey="offers-accepted" title="Approved">
                            <AcceptedJobOffers displayModal={displayModal} socket={socket} />
                            
                        </Tab>
                        <Tab eventKey="offers-received" title="Denied">
                            <ReceivedJobOffers displayModal={displayModal} socket={socket} />
                        </Tab>
                    </Tabs> */}
                </Col>
            </Row>
        </>
    );
};

export default JobOffers;