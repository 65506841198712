import { api, endPoint } from "./client";

const getAllNotifications = (query) => api.get(`${endPoint.jobs}/notificationlist${query}`);

const readNotification = (id) => api.put(`${endPoint.jobs}/notificationread/${id}`)

const deleteNotification = (id) => api.delete(`${endPoint.jobs}/notificationdelete/${id}`)
const notificationcount = (id) => api.get(`${endPoint.jobs}/notificationcount`)

export {
    getAllNotifications,
    readNotification,
    deleteNotification,
    notificationcount,
}